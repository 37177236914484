(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$http', '$state', '$rootScope', '$timeout', '$translate'];

    function HomeController($scope, $http, $state, $rootScope, $timeout, $translate) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.changeMetaDescription = changeMetaDescription;

        function loadAll() {
            if ($rootScope.environment == null) {
                $http.get('api/common/_getEnvironment').then(function (result) {
                    $rootScope.environment = result.data;

                    if (result.data === 'IO') {
                        vm.changeMetaDescription(result.data);
                        $state.go('home.io');
                    } else if (result.data === 'MZ') {
                        vm.changeMetaDescription(result.data);
                        $state.go('home.mz');
                    } else {
                        vm.changeMetaDescription("FL");
                        $state.go('home.fl');
                    }
                });
            } else {
                $timeout(function () {
                    if ($rootScope.environment === 'IO') {
                        vm.changeMetaDescription($rootScope.environment);
                        $state.go('home.io');
                    } else if ($rootScope.environment === 'MZ') {
                        vm.changeMetaDescription($rootScope.environment);
                        $state.go('home.mz');
                    } else {
                        vm.changeMetaDescription("FL");
                        $state.go('home.fl');
                    }
                }, 0);
            }
        }

        function changeMetaDescription(env) {
            var description = $translate.instant("home." + env.toString().toLowerCase() + ".description");
            var metaTag = document.querySelector('meta[name="description"]');
            if (metaTag) {
                metaTag.setAttribute('content', description);
            } else {
                var newMetaTag = document.createElement('meta');
                newMetaTag.setAttribute('name', 'description');
                newMetaTag.setAttribute('content', description);
                document.head.appendChild(newMetaTag);
            }
        }

        loadAll();
    }
})();
