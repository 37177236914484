(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingSubmitIncompleteController', PharmaPickingSubmitIncompleteController);

    PharmaPickingSubmitIncompleteController.$inject = ['Notification', '$uibModal', '$timeout', 'blockUI', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'pharmaPicking', 'PharmaPicking', 'PortalSettings', 'ModuleSettings', '$rootScope', '$scope', 'user', 'InvoiceAndClaim'];

    function PharmaPickingSubmitIncompleteController(Notification, $uibModal, $timeout, blockUI, $uibModalInstance, $translate, $state, $http, $filter, pharmaPicking, PharmaPicking, PortalSettings, ModuleSettings, $rootScope, $scope, user, InvoiceAndClaim) {
        var vm = this;

        vm.portalSettings = PortalSettings.query();

        vm.loadAll = loadAll;

        vm.clear = clear;
        vm.confirmPharmaPickingSubmit = confirmPharmaPickingSubmit;
        vm.pharmaPicking = pharmaPicking;
        vm.user = user.user;
        vm.supplier = vm.pharmaPicking.supplierCode + " - " + vm.pharmaPicking.supplierName;
        vm.allSelected = true;
        vm.passwordInvalid = false;
        vm.confirmedProducts = false;

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                }
            });
        }

        vm.loadAll();

        function clear(flag) {
            $uibModalInstance.dismiss(flag);
        }

        function openConfirmModal(flag, missingProducts) {

            $uibModal.open({
                templateUrl: 'app/entities/pharma-picking/pharma-picking-confirm-products.html',
                controller: 'PharmaPickingConfirmProductsController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    confirmProducts: function () {
                        return flag;
                    },
                    missingProducts: function () {
                        return missingProducts;
                    },
                    pharmaPicking: function () {
                        return vm.pharmaPicking;
                    }
                }
            }).result.then(function () {
                vm.clear('close');
            }, function () {
                vm.clear('close');
            });
        }

        function confirmPharmaPickingSubmit(operation) {
            vm.passwordInvalid = false;
            if (vm.password != vm.portalSettings.pickingPassword) {
                vm.passwordInvalid = true;
            } else {
                confirmSubmit(operation);
            }
        }

        function loadProducts() {
            vm.products = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
                return line.invoicedQuantity - line.receivedQuantity > 0;
            }, true);
            $scope.totalItems = vm.products.length;
            vm.page = 1;
            $scope.itemsPerPage = 7;

            $scope.$watch("vm.page", function () {
                setPagingData(vm.page);
            });

            $timeout(function () {
                selectProducts(true);
            });

        }

        loadProducts();

        vm.selectAll = function () {
            selectProducts(vm.allSelected);
        }

        function setPagingData(page) {
            var pagedData = vm.products.slice(
                (page - 1) * $scope.itemsPerPage,
                page * $scope.itemsPerPage
            );
            $scope.products = pagedData;
        }

        function selectProducts(flag) {
            for (var i = 0; i < $scope.products.length; i++) {
                $scope.products[i].selected = flag;
            }
            for (var i = 0; i < vm.products.length; i++) {
                vm.products[i].selected = flag;
            }
        }

        vm.selectProduct = function () {
            var products = $filter('filter')(vm.products, {selected: false});
            if (products.length != 0) {
                vm.allSelected = false;
            } else {
                vm.allSelected = true;
            }
        }

        function confirmSubmit(operation) {

            var products = $filter('filter')(vm.products, {selected: true});
            if (products.length == 0) {
                Notification.warning({message: "Deve selecionar pelo menos 1 produto", delay: 2000});
            } else if (vm.allSelected && !vm.confirmedProducts && operation != 'complaint') {
                vm.operation = operation;
                confirmProducts();
            } else {

                var received = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (item) {
                    return item.receivedQuantity > 0;
                });
                if (received.length > 0) {
                    vm.showConfirmProducts = false;
                    $uibModal.open({
                        templateUrl: 'app/entities/pharma-picking/pharma-picking-keep-in-pharmacy-dialog.html',
                        controller: 'PharmaPickingKeepInPharmacyController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            pharmaPicking: vm.pharmaPicking,
                            complaint: operation == 'complaint',
                            user: {user: vm.user}
                        }
                    }).result.then(function () {
                        // success callback implemented on own controller
                        vm.products = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
                            return line.invoicedQuantity - line.receivedQuantity > 0;
                        }, true);
                        products = $filter('filter')(vm.products, function (item) {
                            return item.selected == true || item.isSelectedSuggestion == true;
                        });
                        makeOperation(products, operation);
                    }, function (flag) {
                        // Back button
                        if (flag == 'goToPickingDetail') {
                            vm.clear('close');
                        }
                    });
                } else {
                    makeOperation(products, operation);
                }
            }

        }

        function makeOperation(products, operation) {
            var productsMissing = $filter('filter')(vm.products, {selected: false});
            switch (operation) {
                case 'complaint':
                    vm.pharmaPicking.changeState = true;

                    $uibModalInstance.dismiss('complaint');
                    break;
                default:
                    if (productsMissing.length > 0) {
                        vm.pharmaPicking.changeState = false;
                        openConfirmModal(false, productsMissing);
                    } else {
                        $uibModalInstance.close(true);
                        InvoiceAndClaim.invoice(vm.pharmaPicking, vm.products, vm.user);
                    }
                    break;
            }
        }

        function confirmProducts() {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-picking/user-question.html',
                controller: 'UserQuestionController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    data: {allSelected: vm.allSelected}
                }
            }).result.then(function () {
                vm.confirmedProducts = true;
                vm.confirmPharmaPickingSubmit(vm.operation)
            }, function () {
            });
        }

    }
})();
