(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaCovidDialogController', PharmaCovidDialogController);

    PharmaCovidDialogController.$inject = ['Notification', '$translate', '$uibModalInstance', 'startDate', 'endDate'];

    function PharmaCovidDialogController(Notification, $translate, $uibModalInstance, startDate, endDate) {
        var vm = this;
        vm.startDate = startDate;
        vm.endDate = endDate;

        vm.proceed = proceed;
        vm.clear = clear;

        function proceed() {
            if (vm.startDate == null || vm.endDate == null) {
                Notification.error({message: $translate.instant("pharmaPortalApp.pharmaCovid.form.emptyFields")});
            } else if (vm.startDate > vm.endDate) {
                Notification.error({message: $translate.instant("pharmaPortalApp.pharmaCovid.form.invalidInterval")});
            } else {
                $uibModalInstance.close({startDate: vm.startDate, endDate: vm.endDate});
            }
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        vm.openDatePopup = function (i) {
            if (i == 1) { vm.datePopup1.opened = true; }
            else { vm.datePopup2.opened = true; }
        };

        vm.dateOptions = {formatYear: 'yyyy', startingDay: 1, showWeeks: false};
        vm.datePopup1 = {opened: false};
        vm.datePopup2 = {opened: false};
    }
})();
