(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalProductController', PortalProductController);

    PortalProductController.$inject = ['Notification', '$rootScope', '$scope', '$state', '$http', '$filter', '$translate', '$q', 'ModuleSettings', 'Principal', 'PortalSettings', 'AdminSettings'];

    function PortalProductController(Notification, $rootScope, $scope, $state, $http, $filter, $translate, $q, ModuleSettings, Principal, PortalSettings, AdminSettings) {
        var vm = this;

        vm.searchByLab = searchByLab;
        vm.clearSearchInTable = clearSearchInTable;
        vm.exportToExcel = exportToExcel;
        vm.loadAll = loadAll;

        vm.settings = PortalSettings.query();
        $scope.searchInTable = "";
        $scope.productsList = [];
        $scope.search = {
            searchQuery: "",
            showNoStock: false,
            showInactive: false,
            useExternalDB: false,
            dispSegFilter: ""
        };

        $scope.syncProducts = function () {
            AdminSettings.syncProductsServ();
            Notification.info({message: $translate.instant('pharmaPortalApp.portalProducts.externalSyncs.syncNotif'), delay: 5000});
        };

        $scope.searchProducts = function () {
            $scope.productsList = [];
            if ($scope.search && $scope.search.searchQuery && $scope.search.searchQuery.length > 2) {
                $rootScope.searchProductsAction();
            } else {
                // SE showNoStock então não permite
                if ($scope.search.showNoStock == true && $scope.search.dispSegFilter != "true") {
                    $rootScope.message = "NOK";
                    $scope.productsList = [];
                    $state.go("portal-product.fullSearch");
                } else {
                    // SE showNoStock = FALSE então ABRE DIALOG A CONFIRMAR
                    if ($scope.search.searchQuery == undefined) {
                        $scope.search.searchQuery = "";
                    }
                    $rootScope.message = "OK";
                    $state.go("portal-product.fullSearch");
                }
            }
        };

        var canceler = $q.defer();

        $rootScope.searchProductsAction = function () {
            canceler.resolve();
            canceler = $q.defer();

            $scope.resultProductsList = [];
            $http.post("api/common/_searchProductsFromDbPharmaWithOpts", $scope.search, {
                timeout: canceler.promise
            }).then(function (result) {
                $scope.resultProductsList = result.data;
            }).then(function () {
                $scope.productsList = [];

                if (vm.loggedUserClientPe && vm.settings.includeCMPColumn) {
                    vm.policyName = "CMPCalculation";
                    vm.policyData = [];

                    $($scope.resultProductsList).each(function (index, prod) {
                        var prodLab = prod.forncode ? "CIL" + prod.forncode : prod.labcode ? prod.labcode : "99999";
                        vm.policyData.push({
                            "hierarchy": [vm.loggedUserClientPe, prodLab, prod.cnp]
                        });
                        prod.hash = vm.loggedUserClientPe + "" + prodLab + "" + prod.cnp;
                    });

                    var requestBody = {
                        "policyType": vm.policyName,
                        "policyData": vm.policyData
                    };

                    $http.post("api/policyExecuter/execute", requestBody, {
                        timeout: canceler.promise
                    }).then(function (result2) {
                        $($scope.resultProductsList).each(function (index, prod) {

                            var curResult = $filter('filter')(result2.data.output, {
                                hash: prod.hash
                            }, true);

                            if (curResult[0]) {
                                prod.pcm = curResult[0].result;
                            } else {
                                prod.pcm = 0;
                            }
                        });
                    }, function (result) {
                        Notification.warning({message: "Error! Status = " + result.status + " Message = " + result, delay: 5000});
                    });
                }
                $scope.productsList = $scope.resultProductsList;
            });
        }

        $scope.isSearchEmpty = function () {
            return $scope.productsList.length <= 0;
        }

        function searchByLab(lab) {
            $scope.search.searchQuery = lab;
            $scope.searchProducts();
        }

        function clearSearchInTable() {
            $scope.searchInTable = "";
        }

        function exportToExcel() {
            $http.post("api/portal-product/toExcel", $scope.productsList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Produtos_Portal_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: "Listagem de produtos exportada com sucesso",
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: "Erro ao exportar listagem",
                    delay: ''
                });
            });
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.productsModule) {
                    $state.go('accessdenied');
                }
            });

            Principal.identity().then(function (account) {
                if (account) {
                    vm.loggedUser = account;
                    if (account.company) {
                        vm.loggedUserClientPe = account.clientPe;
                    } else {
                        vm.loggedUserClientPe = null;
                    }
                } else {
                    vm.loggedUser = null;
                    vm.loggedUserClientPe = null;
                }
            });
        }

        loadAll();

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
    }
})();
