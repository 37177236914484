(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-purchase', {
			parent : 'entity',
			url : '/pharma-purchase',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_USER' ],
				pageTitle : 'pharmaPortalApp.pharmaPurchase.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-purchase/pharma-purchases.html',
					controller : 'PharmaPurchaseController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaPurchase');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-purchase.alert', {
			parent : 'pharma-purchase',
			url : '/alert',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-alert-dialog.html',
					controller : 'PurchaseAlertController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-purchase.success', {
			parent : 'pharma-purchase',
			url : '/success',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-success-dialog.html',
					controller : 'PurchaseSuccessController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-purchase.clearOrder', {
			parent : 'pharma-purchase',
			url : '/clear',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-clear-order-dialog.html',
					controller : 'PurchaseClearOrderController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-purchase.confirmOrder', {
			parent : 'pharma-purchase',
			url : '/confirm',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-confirm-order-dialog.html',
					controller : 'PurchaseConfirmOrderController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-purchase.removeProduct', {
			parent : 'pharma-purchase',
			url : '/removeProduct/{cnpToDel}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-remove-product-dialog.html',
					controller : 'PurchaseRemoveProductController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								cnpToDel : $stateParams.cnpToDel
							};
						}
					}
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-purchase.fullSearch', {
			parent : 'pharma-purchase',
			url : '/fullSearch',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-confirm.fullsearch-dialog.html',
					controller : 'PurchaseConfirmFullSearchController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-purchase.saveTemplate', {
			parent : 'pharma-purchase',
			url : '/saveTemplate',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-purchase/purchase-save-template-dialog.html',
					controller : 'PurchaseSaveTemplateController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-purchase', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		});
	}

})();
