(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-trade', {
			parent : 'entity',
			url : '/pharma-trade',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ],
				pageTitle : 'pharmaPortalApp.pharmaTrade.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-trade/pharma-trades.html',
					controller : 'PharmaTradeController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaTrade');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-trade.clearOrder', {
			parent : 'pharma-trade',
			url : '/clear',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-trade/trade-clear-order-dialog.html',
					controller : 'TradeClearOrderController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-trade', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-trade.confirmOrder', {
			parent : 'pharma-trade',
			url : '/confirm',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-trade/trade-confirm-order-dialog.html',
					controller : 'TradeConfirmOrderController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-trade', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-trade.removeProduct', {
			parent : 'pharma-trade',
			url : '/removeProduct/{cnpToDel}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-trade/trade-remove-product-dialog.html',
					controller : 'TradeRemoveProductController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								cnpToDel : $stateParams.cnpToDel
							};
						}
					}
				}).result.then(function() {
					$state.go('pharma-trade', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-trade.inactive', {
            parent : 'pharma-trade.confirmOrder',
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/pharma-trade/pharma-trade.inactive-dialog.html',
                    controller : 'PharmaTradeInactiveController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                    $state.go('pharma-trade.confirmOrder', null, {
                        reload : true
                    });
                }, function() {
                    $state.go('^');
                });
            } ]
        });
	}
})();
