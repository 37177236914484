(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('ModuleSettings', ModuleSettings);

	ModuleSettings.$inject = [ '$resource' ];

	function ModuleSettings($resource) {
		var resourceUrl = 'api/moduleSettings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : false
			},
			'save' : {
				method : 'POST',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {

						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			}
		});
	}
})();
