(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PurchaseOrderItemDetailController', PurchaseOrderItemDetailController);

	PurchaseOrderItemDetailController.$inject = [ '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PurchaseOrderItem' ];

	function PurchaseOrderItemDetailController($scope, $rootScope, $stateParams, previousState, entity, PurchaseOrderItem) {
		var vm = this;

		vm.purchaseOrderItem = entity;
		vm.previousState = previousState.name;

		var unsubscribe = $rootScope.$on('pharmaPortalApp:purchaseOrderItemUpdate', function(event, result) {
			vm.purchaseOrderItem = result;
		});
		$scope.$on('$destroy', unsubscribe);
	}
})();
