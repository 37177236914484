(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaTradeController', PharmaTradeController);

    PharmaTradeController.$inject = ['Notification', '$q', '$rootScope', '$scope', '$http', 'PharmaTrade', '$localStorage', '$filter', '$state', 'ModuleSettings', 'PortalSettings', 'Principal'];

    function PharmaTradeController(Notification, $q, $rootScope, $scope, $http, PharmaTrade, $localStorage, $filter, $state, ModuleSettings, PortalSettings, Principal) {
        var vm = this;

        vm.portalSettings = PortalSettings.query()

        vm.loadAll = loadAll;
        vm.addProduct = addProduct;
        vm.isNumeric = isNumeric;
        vm.exportToExcel = exportToExcel;
        vm.toggleLineEditMode = toggleLineEditMode;
        vm.validateLines = validateLines;
        vm.lastQuery = "";
        vm.validPolicyPrice = false;

        vm.products;
        $rootScope.totalizer = {
            qtd: 0,
            price: 0,
            docTotal: 0
        };

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.tradesModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        if (account) {
                            vm.loggedUser = account.login;
                            if (account.company && account.company.clientType == "F") {
                                vm.loggedUserClientPe = account.clientPe;

                                if (account.company.pharmaWarehouse) {
                                    vm.loggedUserWarehouse = account.company.pharmaWarehouse;
                                } else {
                                    vm.loggedUserWarehouse = account.company.warehouse;
                                }
                            } else {
                                vm.loggedUserClientPe = null;
                                $state.go('accessdenied');
                            }
                        } else {
                            vm.loggedUser = null;
                            vm.loggedUserClientPe = null;
                            $state.go('accessdenied');
                        }
                    });
                    validateLines();
                }
            });
        }

        loadAll();

        function addProduct() {
            // SE "CNP" ESTÁ VAZIO
            if (!vm.products || !vm.products.selected || !vm.products.selected.cnp) {
                Notification.error({
                    message: "Não indicou nenhum artigo",
                    delay: ''
                });
                return;
            }

            // SE "Qtd" ESTÁ VAZIO OU NÃO NUMÉRICO OU É MENOR QUE ZERO
            if (!vm.products.selected.qtd) {
                Notification.error({
                    message: "Não indicou a quantidade a encomendar",
                    delay: ''
                });
                return;
            } else {
                vm.products.selected.qtd = vm.products.selected.qtd.toString().replace(",", ".");
                if (!isNumeric(vm.products.selected.qtd)) {
                    Notification.error({
                        message: "A quantidade a encomendar que indicou não é um número válido",
                        delay: ''
                    });
                    return;
                } else if (vm.products.selected.qtd < 1) {
                    Notification.error({
                        message: "A quantidade a encomendar tem de ser maior que zero",
                        delay: ''
                    });
                    return;
                }
            }

            // SE "Preço" ESTÁ VAZIO OU NÃO NUMÉRICO OU É MENOR QUE ZERO
            if (!vm.products.selected.price) {
                Notification.error({
                    message: "Não indicou o preço unitário do produto a encomendar",
                    delay: ''
                });
                return;
            } else {
                vm.products.selected.price = vm.products.selected.price.toString().replace(",", ".");
                if (!isNumeric(vm.products.selected.qtd)) {
                    Notification.error({
                        message: "O preço unitário que indicou não é um número válido",
                        delay: ''
                    });
                    return;
                } else {
                    if (vm.products.selected.price < 0) {
                        Notification.error({
                            message: "O preço unitário do produto tem de ser maior que zero",
                            delay: ''
                        });
                        return;
                    }
                }
            }

            // SE "CNP" VALIDA SE EXISTE NO ARRAY DE CNP"s
            if ($rootScope.orderList.length > 0) {
                var keepGoing = true;
                angular.forEach($rootScope.orderList, function (obj, index) {
                    if (keepGoing) {
                        if (obj.cnp === vm.products.selected.cnp) {
                            $rootScope.orderList.splice(index, 1);
                            keepGoing = false;
                        }
                    }
                });

                if (!keepGoing) {
                    Notification.warning({
                        message: "O produto indicado já existe nesta encomenda. Os valores indicados como quantidade e preço unitário foram atualizados",
                        delay: 5000
                    });
                }
            }

            vm.products.selected.validPolicyPrice = vm.validPolicyPrice;
            $rootScope.orderList.push(angular.copy(vm.products.selected));
            $rootScope.updateTotalizers();
            vm.products.selected = null;
            // get the ui select controller
            var uiSelect = angular.element('#filterProduct').controller('uiSelect');
            // focus the focusser, putting focus onto select but without opening
            // the dropdown
            uiSelect.focusser[0].focus();
        }

        function isNumeric(n) {
            // Check if passed value is numeric
            n = n.toString().replace(/,/g, ".");
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function exportToExcel() {
            $http.post("api/pharma-trade/toExcel", $rootScope.orderList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Trocas_Farmacias_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: "Encomenda exportada com sucesso",
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: "Erro ao exportar encomenda",
                    delay: ''
                });
            });
        }

        function toggleLineEditMode(orderProdLine) {
            if (angular.isDefined(orderProdLine.editMode) && orderProdLine.editMode == true) {
                orderProdLine.editMode = false;
            } else {
                orderProdLine.editMode = true;
            }
            $rootScope.updateTotalizers();
        }

        function validateLines(orderProdLine, keyEvent) {
            if (orderProdLine) {
                if (keyEvent.type == "blur" || (keyEvent.type == "keypress" && keyEvent.which === 13)) {
                    vm.hasErrors = false;
                    orderProdLine.editMode = false;
                    $rootScope.updateTotalizers();
                    angular.forEach($rootScope.orderList, function (value, key) {
                        isQtdInvalid(value);
                        isPriceInvalid(value);
                    });
                }
            } else {
                $rootScope.updateTotalizers();
                angular.forEach($rootScope.orderList, function (value, key) {
                    isQtdInvalid(value);
                    isPriceInvalid(value);
                });
            }
        }

        function isQtdInvalid(orderProdLine) {
            if (orderProdLine.qtd && orderProdLine.qtd >= 0 && parseInt(orderProdLine.qtd)) {
                orderProdLine.invalidQtd = false;
            } else {
                orderProdLine.invalidQtd = true;
                vm.hasErrors = true;
            }
        }

        function isPriceInvalid(orderProdLine) {
            if (orderProdLine.price && orderProdLine.price >= 0 && parseFloat(orderProdLine.price)) {
                orderProdLine.invalidPrice = false;
            } else {
                orderProdLine.invalidPrice = true;
                vm.hasErrors = true;
            }
        }

        $scope.onOpenClose = function (isOpen) {
            angular.element('.ui-select-choices-row').removeClass('active');
        }

        $scope.isOrderEmpty = function () {
            return $rootScope.orderList.length <= 0;
        }

        $scope.refreshSelectModel = function (query) {
            if (vm.lastQuery != query) {
                vm.lastQuery = query
                $http.get("api/common/_searchProductsForSelect/" + query).then(function (result) {
                    if (result.data.length > 500) {
                        Notification.warning({
                            message: "O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa",
                            delay: 5000
                        });
                        return;
                    } else {
                        vm.products = result.data;
                    }
                });
            }
        };

        if ($localStorage && $localStorage.tradeOrder) {
            $rootScope.orderList = $localStorage.tradeOrder;
        } else {
            $rootScope.orderList = [];
        }

        $scope.$watch($rootScope.orderList, function () {
            $localStorage.tradeOrder = $rootScope.orderList;
        }, true);

        $rootScope.updateTotalizers = function () {
            $rootScope.totalizer = {
                qtd: 0,
                price: 0,
                docTotal: 0
            };
            angular.forEach($rootScope.orderList, function (value, key) {
                $rootScope.totalizer.qtd += value.qtd * 1;
                $rootScope.totalizer.price += value.qtd * value.price;
                $rootScope.totalizer.docTotal += value.qtd * value.price * (1 + value.txiva / 100);
            });
        }
        $rootScope.updateTotalizers();

        var canceler = $q.defer();

        $scope.getPriceFromPolicy = function ($item, $model) {
            if (vm.portalSettings.priceFromPolicyForTrades) {
                // PENDING HTTP CALLS CANCEL
                canceler.resolve();
                canceler = $q.defer();

                vm.validPolicyPrice = false;
                $item.labcode = $item.labcode != null ? $item.labcode : 0;
                $item.hash = vm.loggedUserClientPe + "" + $item.labcode + "" + $item.cnp;
                vm.requestBody = {
                    "policyType": "PharmaTradePriceCalculation",
                    "policyData": [{
                        "hierarchy": [vm.loggedUserClientPe, $item.labcode, $item.cnp],
                        "parameters": [vm.loggedUserWarehouse]
                    }]
                };

                $http.post("api/policyExecuter/execute", vm.requestBody, {
                    timeout: canceler.promise
                }).then(function (result) {
                    vm.validPolicyPrice = true;

                    var curResult = $filter('filter')(result.data.output, {
                        hash: $item.hash
                    }, true);

                    vm.products.selected.price = curResult[0].result;
                    if (!curResult[0].result || curResult[0].result <= 0) {
                        vm.products.selected.price = 0;
                        vm.validPolicyPrice = false;
                    }
                });
            }
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
    }
})();
