(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-reception', {
			parent : 'entity',
			url : '/pharma-reception',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaReception.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-reception/pharma-reception.html',
					controller : 'PharmaReceptionController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				}
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort)
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-reception-detail', {
			parent : 'entity',
			url : '/pharma-reception/:id',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaReception.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-reception/pharma-reception-detail.html',
					controller : 'PharmaReceptionDetailController',
					controllerAs : 'vm',
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'PharmaReception', function($stateParams, PharmaReception) {
					return PharmaReception.get({
						id : $stateParams.id
					}).$promise;
				} ]
			}
		}).state('pharma-reception.new', {
			parent : 'entity',
			url : '/pharma-reception/new',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaReception.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-reception/pharma-reception-new.html',
					controller : 'PharmaReceptionNewController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-reception.delete', {
			parent : 'pharma-reception',
			url : '/{id}/delete',
			method : 'DELETE',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-reception/pharma-reception-delete-dialog.html',
					controller : 'PharmaReceptionDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						pharmaReception : [ 'PharmaReception', function(PharmaReception) {
							return PharmaReception.get({
								id : $stateParams.id
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-reception', null, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-reception.confirmDifferences', {
			parent : 'pharma-reception',
			url : '/{id}/confirmDifferences',
			method : 'POST',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-reception/pharma-reception-confirm-differences-dialog.html',
					controller : 'PharmaReceptionConfirmDifferencesController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						pharmaReception : [ '$stateParams', 'PharmaReception', function($stateParams, PharmaReception) {
							return PharmaReception.get({
								id : $stateParams.id
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-reception', {}, {
						reload : true
					});
				}, function() {
					$state.go('pharma-reception', {}, {
						reload : true
					});
				});
			} ]
		}).state('pharma-reception-line', {
			parent : 'entity',
			url : '/pharma-reception-line',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaReception.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-reception/pharma-reception-line.html',
					controller : 'PharmaReceptionController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-reception-line.new', {
			parent : 'pharma-reception-detail',
			url : '/new',
			method : 'POST',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-reception/pharma-reception-line-new-dialog.html',
					controller : 'PharmaReceptionLineNewController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						pharmaReception : [ 'PharmaReception', function(PharmaReception) {
							return PharmaReception.get({
								id : $stateParams.id
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-reception-line.new', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-reception-line.delete', {
			parent : 'pharma-reception-detail',
			url : '/delete/{lineId}',
			method : 'DELETE',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-reception/pharma-reception-line-delete-dialog.html',
					controller : 'PharmaReceptionLineDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						pharmaReceptionLine : [ 'PharmaReceptionLine', function(PharmaReceptionLine) {
							return PharmaReceptionLine.get({
								id : $stateParams.lineId
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-reception-detail', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-reception.inactive', {
            parent : 'pharma-reception.new',
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/pharma-reception/pharma-reception-new.inactive-dialog.html',
                    controller : 'PharmaReceptionInactiveController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                    $state.go('pharma-reception.new', null, {
                        reload : true
                    });
                }, function() {
                    $state.go('^');
                });
            } ]
        });
	}
})();
