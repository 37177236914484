(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('SaleConfirmOrderController', SaleConfirmOrderController);

    SaleConfirmOrderController.$inject = ['Notification', '$uibModalInstance', '$rootScope', '$http', '$localStorage', '$scope', 'PortalSettings', '$state', 'Principal',
        'ModuleSettings'];

    function SaleConfirmOrderController(Notification, $uibModalInstance, $rootScope, $http, $localStorage, $scope, PortalSettings, $state, Principal, ModuleSettings) {
        var vm = this;

        vm.orderInfoValues = [];
        vm.orderReady = false;
        vm.orderDescription;
        vm.bodyRequest;
        vm.processing = false;

        vm.clear = clear;
        vm.confirmOrder = confirmOrder;
        vm.loadAll = loadAll;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmOrder() {
            vm.processing = true;

            vm.bodyRequest = {
                "description": vm.orderDescription,
                "orderProducts": $rootScope.saleOrderList,
                "invoiceTypeCode": vm.portalSettings.invoiceTypeCode,
                "invoiceTypeDesc": vm.portalSettings.invoiceTypeDesc,
                "billingTypeRetainedProductsCode": vm.portalSettings.billingTypeRetainedProductsCode,
                "billingTypeRetainedProductsDesc": vm.portalSettings.billingTypeRetainedProductsDesc,
                "chargeShippingCosts": vm.portalSettings.chargeShippingCosts,
                "cecServiceTypeCode": vm.portalSettings.cecServiceTypeCode,
                "cecServiceTypeDesc": vm.portalSettings.cecServiceTypeDesc,
                "shippingCosts": vm.portalSettings.shippingCosts,
                "isRetainedProductsOrder": $rootScope.isRetainedProductsOrder,
                "currentClient": $localStorage.currentCompany.client,
                "currentClientPe": $localStorage.currentCompany.deliveryPoint
            };

            $http.post("/api/pharma-sale/saveOrder", vm.bodyRequest).then(function successCallback(response) {
                if (response.data.success) {
                    $rootScope.saleOrderList = [];
                    $rootScope.saleTotals = {
                        total: 0,
                        totalPvf: 0,
                        totalDesc: 0
                    };
                    $uibModalInstance.close(true);
                    delete $localStorage.saleOrder;
                    vm.processing = false;
                    Notification.success({message: response.data.message, delay: 5000});
                } else {
                    $uibModalInstance.close(true);
                    vm.processing = false;
                    Notification.error({message: response.data.message, delay: ''});
                }
            }, function errorCallback(response) {
                $uibModalInstance.close(true);
                vm.processing = false;
                Notification.error({message: response.data, delay: ''});
            });
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.salesModule) {
                    $state.go('accessdenied');
                } else {
                    vm.portalSettings = PortalSettings.query(function (settings) {
                        if (settings.hasMinOrderValue == true && settings.minOrderValue > $rootScope.saleTotals.total && $rootScope.saleTotals.total > 0) {
                            vm.orderInfoValues.minValue = settings.minOrderValue;
                            vm.orderInfoValues.curDiff = settings.minOrderValue - $rootScope.saleTotals.total;
                            vm.orderReady = false;
                        } else {
                            vm.orderInfoValues = [];
                            vm.orderReady = true;
                        }
                    });
                }
            });
        }

        loadAll();
    }
})();
