(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaReceptionController', PharmaReceptionController);

    PharmaReceptionController.$inject = ['Notification', '$scope', '$state', '$http', '$filter', 'PharmaReception', 'ParseLinks', 'paginationConstants', 'pagingParams', 'ModuleSettings'];

    function PharmaReceptionController(Notification, $scope, $state, $http, $filter, PharmaReception, ParseLinks, paginationConstants, pagingParams, ModuleSettings) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.getWarehouseFullDescription = getWarehouseFullDescription;
        vm.getSupplierFullDescription = getSupplierFullDescription;
        vm.getOrderTypeFullDescription = getOrderTypeFullDescription;

        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.receptionsModule) {
                    $state.go('accessdenied');
                } else {
                    PharmaReception.query({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                    $http.get("api/common/_searchWarehousesForSelect").then(function (result) {
                        vm.warehouses = result.data;
                    });

                    $http.get("api/common/_searchSuppliersForSelect").then(function (result) {
                        vm.suppliers = result.data;
                    });
                    $http.get("api/common/_searchOrderTypesForSelect").then(function (result) {
                        vm.orderTypes = result.data;
                    });
                }
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.pharmaReceptionHeaders = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function getWarehouseFullDescription(warehouseCode) {
            if (angular.isDefined(vm.warehouses)) {
                var warehouse = $filter('filter')(vm.warehouses, {
                    code: warehouseCode
                })[0];
                return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
            } else {
                return warehouseCode;
            }
        }

        function getSupplierFullDescription(supplierCode) {
            if (angular.isDefined(vm.suppliers)) {
                var supplier = $filter('filter')(vm.suppliers, {
                    code: supplierCode
                })[0];
                return supplierCode + (supplier != null ? ' - ' + supplier.description : '');
            } else {
                return supplierCode;
            }
        }

        function getOrderTypeFullDescription(orderType) {
            if (angular.isDefined(vm.orderTypes)) {
                var orderTypeObject = $filter('filter')(vm.orderTypes, {
                    type: orderType
                })[0];
                return orderType + (orderTypeObject != null ? ' - ' + orderTypeObject.name : '');
            } else {
                return orderType;
            }
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
    }
})();
