(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('PharmaSelfTest', PharmaSelfTest);

    PharmaSelfTest.$inject = ['$resource'];

    function PharmaSelfTest($resource) {
        var resourceUrl = 'api/pharma-self-test';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
