(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('PharmaPicking', PharmaPicking);

	PharmaPicking.$inject = [ '$resource' ];

	function PharmaPicking($resource) {
		var resourceUrl = 'api/pharma-picking/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
            'pharmaciesFilter' : {
                method : 'GET',
                url : 'api/pharma-picking/pharmacies-filter',
                isArray : true
            },
            'usersFilter' : {
                method : 'GET',
                url : 'api/pharma-picking/users-filter',
                isArray : true
            },
            'suppliersFilter' : {
                method : 'GET',
                url : 'api/pharma-picking/suppliers-filter',
                isArray : true
            },
            'haveItemsToTransport' : {
                method : 'GET',
                isArray : false,
                url : 'api/pharma-picking/items-to-transport',
                transformResponse: [function (data, headers) {
                    return {body: data};
                }]
            },
			'save' : {
				method : 'PUT',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {
						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			},
            'update' : {
                method : 'PUT',
                url : 'api/pharma-picking'
            },
			'submit' : {
				method : 'GET',
				url : 'api/pharma-picking/submit'
			},
            'getAllImportFiles': {
                method : 'GET',
                url : 'api/pharma-picking-import-files',
                isArray: true
            },
            'deleteImportFile': {
                method : 'DELETE',
                url : 'api/pharma-picking-import-files/:id'
            },
            'getImportFileByPharmacyWarehouse': {
                method : 'GET',
                url : 'api/pharma-picking-import-files/pharmacy-warehouse',
                isArray: true
            },
            'importFileCompaniesFilter' : {
                method : 'GET',
                url : 'api/pharma-picking-import-files/companies',
                isArray : true
            },
            'getProductsForHistory' : {
                method : 'GET',
                url : 'api/pharma-picking-show-history',
                isArray : true
            },
            'getPickingHeaderForProductHistory' : {
                method : 'GET',
                url : 'api/pharma-picking-for-product-history',
                isArray : true
            },
		});
	}
})();
