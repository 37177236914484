(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('SelloutIntegrationDialogController', SelloutIntegrationDialogController);

	SelloutIntegrationDialogController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', 'importDate', 'curCompany', 'moment', 'ModuleSettings', 'Upload', '$translate', '$http' ];

	function SelloutIntegrationDialogController(Notification, blockUI, $uibModalInstance, importDate, curCompany, moment, ModuleSettings, Upload, $translate, $http) {
		var vm = this;

		vm.settingsFault = false;
        vm.curCompanyFault = false;

		vm.loadAll = loadAll;
		vm.clear = clear;
		vm.proceed = proceed;
		vm.uploadFile = uploadFile;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.selloutsModule) {
					$state.go('accessdenied');
				} else {
					if(!curCompany){
						vm.curCompanyFault = true;
                        return;
					}

					vm.params = {
						date : moment(importDate).format('YYYY-MM-DD'),
						pharmaCode : curCompany.client,
						deliveryPoint : curCompany.deliveryPoint,
						selloutNickname : curCompany.selloutNickname,
						selloutFileType : curCompany.selloutFileType
					}

				    if(!(vm.params.date && vm.params.pharmaCode && vm.params.deliveryPoint && vm.params.selloutNickname && vm.params.selloutFileType)){
						vm.settingsFault = true;
                        return;
					}
					
					$http.get("/api/sellout-integration-histories/checkExists", {params : { companyId : curCompany.id, integrationDate : importDate.toISOString() }}).then(function(result) {
						vm.dayHasIntegrations = result.data > 0;
					});
				}
			});
		}
		loadAll();

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}
		
		function proceed() {
			vm.dayHasIntegrations = false;
		}

		function uploadFile() {
			blockUI.start($translate.instant('pharmaPortalApp.selloutIntegrationDialog.message.uploadingSellouts'));
			
			if(vm.selloutIntegrationDialog && vm.selloutIntegrationDialog.file) {
				file.upload = Upload.upload({
					url : '/api/sellout-integration-histories/upload',
					fields : {
						date : vm.params.date,
						pharmaCode : vm.params.pharmaCode,
						deliveryPoint : vm.params.deliveryPoint,
						selloutNickname : vm.params.selloutNickname,
						selloutFileType : vm.params.selloutFileType,
					},
					file : vm.selloutIntegrationDialog.file
				}).then(function(response) {
					blockUI.stop();
					$('#selloutIntegrationHistoryCalendar').fullCalendar('refetchEvents');
					$uibModalInstance.dismiss('cancel');
					
					if(response.data.success) {
						Notification.success({message: response.data.message, delay: 5000});
					} else {
						Notification.error({message: response.data.message, delay: ''});
					}
					
				}, function(response) {
					blockUI.stop();

					if(response.data.success) {
						Notification.success({message: response.data.message, delay: 5000});
					} else {
						Notification.error({message: response.data.message, delay: ''});
					}
				});
			} else {
				blockUI.stop();
				Notification.error({message: $translate.instant('pharmaPortalApp.selloutIntegrationDialog.message.file'), delay: ''});
			}
		}
	}
})();
