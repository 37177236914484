(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PurchaseRemoveProductController', PurchaseRemoveProductController);

	PurchaseRemoveProductController.$inject = [ 'Notification', '$uibModalInstance', 'entity', '$rootScope', '$state', 'Principal', 'ModuleSettings', '$translate' ];

	function PurchaseRemoveProductController(Notification, $uibModalInstance, entity, $rootScope, $state, Principal, ModuleSettings, $translate) {
		var vm = this;

		vm.cnpToDel = entity.cnpToDel;
		vm.product = null;

		vm.clear = clear;
		vm.confirmRemoveProduct = confirmRemoveProduct;
		vm.filterItemFromArray = filterItemFromArray;
		vm.deleteFilteredItem = deleteFilteredItem;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function confirmRemoveProduct() {
			deleteFilteredItem(vm.cnpToDel, $rootScope.purchaseOrderList);
			$rootScope.updatePurchaseTotals();
			$uibModalInstance.close(true);
			Notification.warning({
				message : $translate.instant('pharmaPortalApp.pharmaPurchase.messages.productRemoveSuccess'),
				delay : 5000
			});
		}

		function filterItemFromArray(cnpToDel, sourceArray) {
			vm.product = null;
			angular.forEach(sourceArray, function(obj, index) {
				if (obj.cnp === cnpToDel) {
					vm.product = obj;
					return;
				}
			});
		}

		function deleteFilteredItem(cnpToDel, sourceArray) {
			angular.forEach(sourceArray, function(obj, index) {
				if (obj.cnp === cnpToDel) {
					sourceArray.splice(index, 1);
					return;
				}
			});
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.purchasesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (account && account.company) {
							filterItemFromArray(vm.cnpToDel, $rootScope.purchaseOrderList);
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();