(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('company', {
			parent : 'entity',
			url : '/company?page&sort&search',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'pharmaPortalApp.company.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/company/companies.html',
					controller : 'CompanyController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				},
				search : null
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort),
						search : $stateParams.search
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('company');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('company-detail', {
			parent : 'entity',
			url : '/company/{id}',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'pharmaPortalApp.company.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/company/company-detail.html',
					controller : 'CompanyDetailController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('company');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'Company', function($stateParams, Company) {
					return Company.get({
						id : $stateParams.id
					}).$promise;
				} ],
				previousState : [ "$state", function($state) {
					var currentStateData = {
						name : $state.current.name || 'company',
						params : $state.params,
						url : $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				} ]
			}
		}).state('company.edit', {
			parent : 'company',
			url : '/{id}/edit',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/company/company-dialog.html',
					controller : 'CompanyDialogController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'md',
					resolve : {
						entity : [ 'Company', function(Company) {
							return Company.get({
								id : $stateParams.id
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('company', {}, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('company-detail.edit', {
			parent : 'company-detail',
			url : '/detail/edit',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/company/company-dialog.html',
					controller : 'CompanyDialogController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'md',
					resolve : {
						entity : [ 'Company', function(Company) {
							return Company.get({
								id : $stateParams.id
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('^', {}, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		});
	}
})();