(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('SelfInvoiceCollectionConfirmController', SelfInvoiceCollectionConfirmController);

    SelfInvoiceCollectionConfirmController.$inject = ['$translate', 'Notification', '$uibModalInstance', '$rootScope', '$scope', 'Company', '$http', '$localStorage', '$state', 'Principal', 'ModuleSettings',
        'PortalSettings'];

    function SelfInvoiceCollectionConfirmController($translate, Notification, $uibModalInstance, $rootScope, $scope, Company, $http, $localStorage, $state, Principal, ModuleSettings, PortalSettings) {
        var vm = this;

        vm.portalSettings = PortalSettings.query();

        vm.confirm = confirm;
        vm.clear = clear;
        vm.loadAll = loadAll;

        vm.processing = false;

        function confirm() {
            vm.processing = true;

            for (var i = 0; i < $rootScope.selfInvoiceCollectionOrderList.length; i++) {
                $rootScope.selfInvoiceCollectionOrderList[i].name = $rootScope.selfInvoiceCollectionOrderList[i].nome;
            }

            var selfInvoiceCollectionHeader = {
                'companyId': $localStorage.currentCompanyForSelfInvoiceCollection.id,
                'selfInvoiceCollectionLines': $rootScope.selfInvoiceCollectionOrderList
            };

            $http.post("api/self-invoice-collection-headers", selfInvoiceCollectionHeader).then(function successCallback(response) {
                Notification.success({
                    message: $translate.instant('pharmaPortalApp.selfInvoiceCollection.submitSelfInvoiceCollection.success'),
                    delay: 5000
                });
                vm.processing = false;
                delete $localStorage.selfInvoiceCollectionOrder;
                $uibModalInstance.close(true);
            }, function errorCallback(response) {
                Notification.error({message: response.data.message, delay: ''});
                vm.processing = false;
                $uibModalInstance.close(true);
            });

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadAll() {

            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.selfInvoiceCollectionModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        if (account.authorities.indexOf("ROLE_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if (account.authorities.indexOf("ROLE_SUPER_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if (account.authorities.indexOf("ROLE_PHARMA_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if ((account && account.company && account.company.clientType == "F") || vm.isAdmin) {
                            vm.order.returnGuide = "";
                        } else {
                            $state.go('accessdenied');
                        }
                    });
                }
            });
        }

        loadAll();
    }
})();
