(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingDefinePharmacyController', PharmaPickingDefinePharmacyController);

    PharmaPickingDefinePharmacyController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', '$translate', '$state', '$document', '$scope', '$http'];

	function PharmaPickingDefinePharmacyController(Notification, blockUI, $uibModalInstance, $translate, $state, $document, $scope, $http) {
		var vm = this;

		vm.clear = clear;

        vm.warehouseSelected = {};

		function clear(flag) {
			$uibModalInstance.dismiss(flag);
		}

		function loadAll(){
            $http.get("api/companies/withPharmacyWarehouse").then(function (result) {
                $scope.warehouses = result.data;
            });
        }

        loadAll();

       vm.submit = function() {
            if (angular.isDefined(vm.settings.warehouseSales)) {
                vm.warehouseSelected.warehouseSalesCode = vm.settings.warehouseSales.code;
                vm.warehouseSelected.warehouseSalesDesc = vm.settings.warehouseSales.description;
            }
           clear(vm.settings.warehouseSales);
        }

	}
})();
