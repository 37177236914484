(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PortalAppBoardController', PortalAppBoardController);

	PortalAppBoardController.$inject = [ 'DataUtils', 'PortalApp', 'ParseLinks', '$http', '$stateParams', '$state', 'ModuleSettings' ];

	function PortalAppBoardController(DataUtils, PortalApp, ParseLinks, $http, $stateParams, $state, ModuleSettings) {
		var vm = this;

		vm.portalApps = [];

		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.applicationsModule) {
					$state.go('accessdenied');
				} else {
					vm.portalApps = PortalApp.query();
				}
			});
		}
		loadAll();
	}
})();