(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('PharmaCovid', PharmaCovid);

    PharmaCovid.$inject = ['$resource'];

    function PharmaCovid($resource) {
        var resourceUrl = 'api/pharma-covid';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
