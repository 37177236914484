(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ReportDeleteController',ReportDeleteController);

	ReportDeleteController.$inject = ['Notification', '$uibModalInstance', '$translate', 'entity', 'Report', '$state', 'ModuleSettings'];

	function ReportDeleteController(Notification, $uibModalInstance, $translate, entity, Report, $state, ModuleSettings) {
		var vm = this;

		vm.report = entity;

		vm.clear = clear;
		vm.confirmDelete = confirmDelete;
		vm.loadAll = loadAll;

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmDelete (id) {
			Report.delete({id: id}, function () { $uibModalInstance.close(true); });
			Notification.success({message: $translate.instant('pharmaPortalApp.report.deleted'), delay: 5000});
		}

		function loadAll () {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function (result) {
				vm.moduleSettings = result;
	
				if(!vm.moduleSettings.queriesModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
	}
})();