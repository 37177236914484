(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingImportFilesController', PharmaPickingImportFilesController);

    PharmaPickingImportFilesController.$inject = ['$translate', 'blockUI', 'Notification', '$scope', '$state', '$http', '$filter', 'PharmaPicking', 'ParseLinks', 'paginationConstants', 'pagingParams',
        'ModuleSettings', 'Principal', '$uibModal', '$localStorage', 'Upload'];

    function PharmaPickingImportFilesController($translate, blockUI, Notification, $scope, $state, $http, $filter, PharmaPicking, ParseLinks, paginationConstants, pagingParams, ModuleSettings, Principal, $uibModal, $localStorage, Upload) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.clearFilter = clearFilter;

        vm.uploadFile = uploadFile;
        vm.downloadFile = downloadFile;
        vm.exportFile = exportFile;

        vm.deleteLine = deleteLine;

        vm.importFile = {
            file: null,
            filename: null
        };

        vm.companylist = [];

        vm.datePopup = {
            opened: false
        };

        vm.openDatePopup = function () {
            vm.datePopup.opened = true;
        };

        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.tableFilters = {
            company: {label: '&nbsp;', value: ''},
            product: '',
            initDate: null
        };

        function clearFilter() {

            vm.tableFilters = {
                company: {label: '&nbsp;', value: ''},
                product: '',
                initDate: null
            };
            transition();
        }
        function uploadFile() {

            blockUI.start($translate.instant('pharmaPortalApp.pharmaPickingImportFile.message.uploading'));
            if (vm.importFile && vm.importFile.file) {

                file.upload = Upload.upload({
                    url: '/api/pharma-picking-import-files/upload',
                    file: vm.importFile.file,
                    data: {name: vm.importFile.filename}
                }).then(function (response) {
                    blockUI.stop();
                    Notification.success({message: response.data.message, delay: ''});
                    vm.importFile = {
                        file: null,
                        filename: null
                    };

                    var temp = document.getElementById('filename');
                    if (temp) {
                        temp.value = '';
                    }

                    loadAll();
                }, function (response) {
                    blockUI.stop();
                    Notification.error({message: response.data.message, delay: ''});
                });
            } else {
                blockUI.stop();
                loadAll();
                Notification.error({
                    message: $translate.instant('pharmaPortalApp.pharmaPickingImportFile.message.importError'),
                    delay: ''
                });
            }
        }
        function changeFile() {

            vm.importFile.filename = vm.importFile.file != null ? vm.importFile.file.name : null;
        }
        function loadAll() {

            vm.moduleSettings = ModuleSettings.query();

            Principal.identity().then(function (account) {
                if (account) {
                    vm.user = account.login;
                    vm.userData = account;
                }
            });
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;
                if (!vm.moduleSettings.pickingModule) {

                    $state.go('accessdenied');
                } else {
                    PharmaPicking.getAllImportFiles({
                        tableFilters: prepareFilters(),
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            });
        }
        loadAll();

        function getCompaniesFilter() {
            vm.companylist = [{label: '&nbsp;', value: ''}];
            PharmaPicking.importFileCompaniesFilter({}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    vm.companylist.push({
                        label: data[i].value,
                        value: data[i].key
                    });
                }
            }, function (err) {
            });
        }

        getCompaniesFilter();

        function downloadFile() {
            window.open('/api/pharma-picking-import-files/template');
        }

        function exportFile() {
            blockUI.start('A exportar linhas para Excel .......');
            $http.post("/api/pharma-picking-import-files/export-file", {},
                {responseType: "arraybuffer"})
                .then(function successCallback(response) {
                    blockUI.stop();
                    var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmmss");
                    var blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    saveAs(blob, dateTime + ".xlsx", true);
                    Notification.success({message: "ficheiro gerado com sucesso", delay: 5000});
                }, function errorCallback(response) {
                    blockUI.stop();
                    Notification.error({message: "Erro ao gerar ficheiro", delay: ''});
                });
        }

        function deleteLine(line) {
            PharmaPicking.deleteImportFile({id: line.id}, function (result) {
                Notification.success({message: 'Linha eliminada com sucesso.'})
                loadAll();
            }, onError);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.importFiles = data;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        vm.sort = function () {
            $state.transitionTo($state.$current, {
                tableFilters: prepareFilters(),
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function transition() {
            PharmaPicking.getAllImportFiles({
                tableFilters: prepareFilters(),
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        //Filters...
        function prepareFilters() {
            var filters = {};
            filters.company = textOrNull(vm.tableFilters.company.value);
            filters.product = textOrNull(vm.tableFilters.product);


            filters.initDate = textOrNull(vm.tableFilters.initDate);
            if(filters.initDate != null) filters.initDate = new Date(Date.UTC(filters.initDate.getFullYear(),filters.initDate.getMonth(),filters.initDate.getDate(),23,59,59,0));
            return filters;
        }

        function textOrNull(field) {
            return field != '' ? field : null;
        }
    }
})();
