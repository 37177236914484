(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('ProductStockQtd', ProductStockQtd);

    ProductStockQtd.$inject = ['$resource'];

    function ProductStockQtd($resource) {
        var resourceUrl = 'api/product-stock-qtd/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
