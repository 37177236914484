(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('report-group', {
            parent: 'entity',
            url: '/report-group',
            data: {
                pageTitle: 'pharmaPortalApp.reportGroup.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report-group/report-group.html',
                    controller: 'ReportGroupController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reportGroup');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('report-group-detail', {
                parent: 'report-group',
                url: '/report-group/{id}',
                data: {
                    pageTitle: 'pharmaPortalApp.reportGroup.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report-group/report-group-detail.html',
                        controller: 'ReportGroupDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reportGroup');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams','ReportGroup', function ($stateParams, ReportGroup) {
                        return ReportGroup.get({
                            id:$stateParams.id
                        }).$promise;
                    }],
                    previousState: [ "$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'report-group',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('report-group-detail.edit', {
                parent: 'report-group-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report-group/report-group-dialog.html',
                        controller: 'ReportGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ReportGroup', function (ReportGroup) {
                                return ReportGroup.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        state.go('^');
                    });
                }]
            })
            .state('report-group.new', {
                parent: 'report-group',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report-group/report-group-dialog.html',
                        controller: 'ReportGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    createdDate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('report-group', null, {reload: 'report-group'});
                    }, function () {
                        state.go('report-group');
                    });
                }]
            })
            .state('report-group.edit', {
                parent: 'report-group',
                url: '/{id}/edit',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report-group/report-group-dialog.html',
                        controller: 'ReportGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ReportGroup', function (ReportGroup) {
                                return ReportGroup.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('report-group', null, {reload: 'report-group'});
                    }, function () {
                        state.go('^');
                    });
                }]
            })
            .state('report-group.delete', {
                parent: 'report-group',
                url: '/{id}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report-group/report-group-delete-dialog.html',
                        controller: 'ReportGroupDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ReportGroup', function (ReportGroup) {
                                return ReportGroup.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('report-group', null, {reload: 'report-group'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
