(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalNotificationDetailController', PortalNotificationDetailController);

    PortalNotificationDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'entity'];

    function PortalNotificationDetailController($scope, $rootScope, previousState, entity) {
        var vm = this;

        vm.portalNotification = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pharmaPortalApp:portalNotificationUpdate', function (event, result) {
            vm.portalNotification = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
