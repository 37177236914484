(function () {
    'use strict';

    angular.module('pharmaPortalApp').directive('autoFocus', autoFocus);

    function autoFocus() {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, $element) {
            $timeout(function () {
                $element[0].focus();
            }, 0);
        };
    }
})();
