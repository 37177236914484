(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('PharmaReception', PharmaReception);

	PharmaReception.$inject = [ '$resource' ];

	function PharmaReception($resource) {
		var resourceUrl = 'api/pharma-reception/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'save' : {
				method : 'PUT',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {
						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			},
			'submit' : {
				method : 'GET',
				url : 'api/pharma-reception/:id/submit/:createReceptionNote',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {
						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			},
			'submitInvoice' : {
				method : 'POST',
				url : 'api/pharma-reception/submitSupplierInvoice',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {
						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			}
		});
	}
})();
