(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ExternalSyncsController', ExternalSyncsController);

    ExternalSyncsController.$inject = ['Notification', '$state', '$filter', '$scope', 'AdminSettings', '$translate'];

    function ExternalSyncsController(Notification, $state, $filter, $scope, AdminSettings, $translate) {

        $scope.syncProducts = function () {
            AdminSettings.syncProductsServ();
            Notification.info({message: $translate.instant('pharmaPortalApp.externalSyncs.syncNotif'), delay: 5000});
        };
    }
})();
