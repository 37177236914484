(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('self-invoice-collection', {
			parent : 'entity',
			url : '/self-invoice-collection',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.selfInvoiceCollection.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/self-invoice-collection/self-invoice-collection.html',
					controller : 'SelfInvoiceCollectionController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('selfInvoiceCollection');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('self-invoice-collection.clearSelfInvoiceCollection', {
			parent : 'self-invoice-collection',
			url : '/clear',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/self-invoice-collection/self-invoice-collection-clear-dialog.html',
					controller : 'SelfInvoiceCollectionClearController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('self-invoice-collection', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('self-invoice-collection.confirmSelfInvoiceCollection', {
			parent : 'self-invoice-collection',
			url : '/confirm',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal, $localStorage) {
				$uibModal.open({
					templateUrl : 'app/entities/self-invoice-collection/self-invoice-collection-confirm-dialog.html',
					controller : 'SelfInvoiceCollectionConfirmController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
                    $state.go('self-invoice-collection', null, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('self-invoice-collection.removeLine', {
			parent : 'self-invoice-collection',
			url : '/removeLine/{cnpToDel}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/self-invoice-collection/self-invoice-collection-remove-line-dialog.html',
					controller : 'SelfInvoiceCollectionRemoveLineController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								cnpToDel : $stateParams.cnpToDel
							};
						}
					}
				}).result.then(function() {
					$state.go('self-invoice-collection', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('self-invoice-collection.inactive', {
            parent : 'self-invoice-collection',
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/self-invoice-collection/self-invoice-collection.inactive-dialog.html',
                    controller : 'SelfInvoiceCollectionInactiveController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                    $state.go('self-invoice-collection', null, {
                        reload : true
                    });
                }, function() {
                    $state.go('^');
                });
            } ]
        });
	}
})();
