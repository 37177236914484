(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('product-erp-integration', {
            parent: 'entity',
            url: '/product-erp-integration?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pharmaPortalApp.productErpIntegration.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-erp-integration/product-erp-integrations.html',
                    controller: 'ProductErpIntegrationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'importationDate,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productErpIntegration');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-erp-integration-detail', {
            parent: 'entity',
            url: '/product-erp-integration/{id}?page&sort',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pharmaPortalApp.productErpIntegration.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-erp-integration/product-erp-integration-detail.html',
                    controller: 'ProductErpIntegrationDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productErpIntegration');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ProductErpIntegration', function ($stateParams, ProductErpIntegration) {
                    return ProductErpIntegration.get({id: $stateParams.id}).$promise;
                }]
            }
        })
        .state('product-erp-integration.delete', {
            parent: 'product-erp-integration',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-erp-integration/product-erp-integration-delete-dialog.html',
                    controller: 'ProductErpIntegrationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ProductErpIntegration', function (ProductErpIntegration) {
                            return ProductErpIntegration.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('product-erp-integration', null, {reload: 'product-erp-integration'});
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();
