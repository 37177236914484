(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('SidenavController', SidenavController);

	SidenavController.$inject = ['$state', 'Auth', 'Principal', '$http', '$filter', '$scope', 'ModuleSettings', 'PolicySettings', 'ReportGroup', 'PortalSettings'];

	function SidenavController($state, Auth, Principal, $http, $filter, $scope, ModuleSettings, PolicySettings, ReportGroup, PortalSettings) {
		var vm = this;

		vm.moduleSettings = ModuleSettings.query();
		// vm.policyTypes = [];
		vm.loggedUser = null;
        vm.portalSettings = PortalSettings.query();

        vm.canTrade = true;
        vm.activeGroups = [];
		vm.openNav = openNav;
		vm.closeNav = closeNav;
		vm.logout = logout;
		vm.loadAll = loadAll;
		// vm.checkPolicySettings = checkPolicySettings;
		vm.expandNode = expandNode;
		vm.expandLevel2Node = expandLevel2Node;
		vm.getReportGroups = getReportGroups;

		function openNav() {
			loadAll();
			angular.element("#mySidenav").css("width", "300px");
			angular.element(".sidenav-overlay").css("width", "100%").css("backgroundColor", "rgba(0,0,0,0.4)");
		}

		function closeNav() {
			angular.element("#mySidenav").css("width", "0");
			angular.element(".sidenav-overlay").css("width", "0").css("backgroundColor", "white");
			loadAll();
		}

		function logout() {
			closeNav();
			Auth.logout();
			$state.go('home');
		}

		function loadAll() {
			Principal.identity().then(function(account) {
				vm.isPharma = false;
				if(account) {
					vm.loggedUser = account.login;
					if (account.company && account.company.clientType == "F") {
							vm.isPharma = true;
					}
                    if(account.authorities.length > 0) {
                        vm.reportGroups = ReportGroup.query();
                    }
				} else {
					vm.loggedUser = null;
				}

				if (vm.portalSettings.onlyTradeInGroup) {
                    PortalSettings.getAllActiveClientGroups(function (result2) {
                        vm.activeGroups = result2;
                        vm.canTrade = false;
                        for (var i = 0; i < vm.activeGroups.length; i++) {
                            if (account.company.clientGroup == vm.activeGroups[i].name) {
                                vm.canTrade = true;
                            }
                        }
                    });
                }
			});
		}
		loadAll();

		// function checkPolicySettings() {
		// 	$http.get("api/policy-builder/interfacesBySettings").then(function(result) {
		// 		vm.policyTypes = [];
        //
		// 		angular.forEach(result.data, function(v, k) {
		// 			vm.policyTypes.push({
		// 				'fqn' : v,
		// 				'name' : $filter('translate')(v)
		// 			});
		// 		});
		// 	});
		// }

		function expandNode(nodeNb) {
			$scope.nodeLevel2Status = null;
			if($scope.nodeStatus == nodeNb) {
				$scope.nodeStatus = null;
			} else {
				$scope.nodeStatus = nodeNb;
			}
		}

		function expandLevel2Node(nodeNb, nodeNb2) {
			$scope.nodeStatus = nodeNb;
			if($scope.nodeLevel2Status == nodeNb2) {
				$scope.nodeLevel2Status = null;
			} else {
				$scope.nodeLevel2Status = nodeNb2;
			}
		}

        function getReportGroups(nodeStatus) {
            if(nodeStatus != 1){
                vm.reportGroups = ReportGroup.query();
            }
        }
	}
})();
