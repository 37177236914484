(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingTransportController', PharmaPickingTransportController);

    PharmaPickingTransportController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', '$translate', '$state', '$document', '$scope'];

	function PharmaPickingTransportController(Notification, blockUI, $uibModalInstance, $translate, $state, $document, $scope) {
		var vm = this;

		vm.clear = clear;

		vm.employeeNumber = null;
        var readEmployeeNumber = '';

		function clear(flag) {
			$uibModalInstance.dismiss(flag);
		}

        $($document).ready(function () {
            addInputListener();
        });

        function addInputListener() {
            window.addEventListener("keypress", myEventHandler, false);
        }

        $scope.$on("$destroy", function () {
            window.removeEventListener("keypress", myEventHandler, false);
        });

        $scope.numberFocused = function () {
            vm.numberFocus = true;
        };

        $scope.numberBlurred = function () {
            vm.numberFocus = false;
        };

        $scope.otherFocused = function () {
            vm.otherFocus = true;
        };

        $scope.otherBlurred = function () {
            vm.otherFocus = false;
        };

        function myEventHandler(e) {
            if (!vm.otherFocus && !vm.numberFocus) {
                var keyCode = e.keyCode;
                if (keyCode == 8) {
                    if (readEmployeeNumber.length > 0) {
                        readEmployeeNumber = readBarcode.substring(0, readBarcode.length - 1);
                    }
                    e.preventDefault();
                } else if (keyCode == 13) {
                    if (vm.employeeNumber !== readEmployeeNumber) {
                        $scope.$apply(function() {
                            vm.employeeNumber = readEmployeeNumber;
                        });
                    }
                    readEmployeeNumber = '';
                } else {
                    readEmployeeNumber = readEmployeeNumber + e.key;
                }
            }
        }

        vm.submit = function() {
            if(vm.employeeNumber && vm.employeeNumber != ''){
                $uibModalInstance.dismiss(vm.employeeNumber);
            }
        }

		function onSubmitSuccess(result) {
			$uibModalInstance.close(true);
			blockUI.stop();
            Notification.success({message: "Transportado com sucesso", delay: 5000});
		}

		function onSubmitError(result) {
			blockUI.stop();
			$uibModalInstance.close(true);
		}

	}
})();
