(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('HomeFLController', HomeFLController);

	HomeFLController.$inject = ['$scope', 'Principal', '$state', 'Auth', '$http', 'PortalSettings' ];

	function HomeFLController ($scope, Principal, $state, Auth, $http, PortalSettings) {
		var vm = this;
		vm.settings = PortalSettings.query();

		vm.authenticationError = false;
		vm.username = null;
		vm.password = null;
		vm.rememberMe = true;
		vm.login = login;
		vm.logout = logout;
		vm.goHome = goHome;
		vm.requestResetPassword = requestResetPassword;

		vm.account = null;
		vm.isAuthenticated = null;
		vm.environment = null;

		$scope.$on('authenticationSuccess', function() {
			getAccount();
		});

		getAccount();

		function getAccount() {
			Principal.identity().then(function(account) {
				vm.account = account;
				if(account && account.authorities.length){
					vm.isAuthenticated = Principal.isAuthenticated;
					// $state.go('pharma-purchase');
				} else {
					$state.go('home.fl');
				}
			}).catch(function () {
				vm.isAuthenticated = null;
				$state.go('home.fl');
			});
		}

		function login(event) {
			event.preventDefault();
			Auth.login({
				username: vm.username,
				password: vm.password,
				rememberMe: vm.rememberMe
			}).then(function () {
				vm.authenticationError = false;
				$state.go('pharma-purchase');
			}).catch(function () {
				vm.authenticationError = true;
				$state.go('home.fl');
			});
		};

		function logout() {
			Auth.logout();
			$state.go('home.fl');
		}

		function goHome() {
			$state.go('pharma-purchase');
		}

		function requestResetPassword () {
			$state.go('requestReset');
		}
	}
})();