(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ProductListDialogController', ProductListDialogController);

	ProductListDialogController.$inject = [ 'Notification','$uibModalInstance', '$timeout', '$scope', '$stateParams', 'DataUtils', 'entity', 'ProductList', '$state' ];

	function ProductListDialogController( Notification, $uibModalInstance, $timeout, $scope, $stateParams, DataUtils, entity, ProductList, $state) {
		var vm = this;
		vm.productList = entity;
		vm.clear = clear;
		vm.save = save;
		vm.loadAll = loadAll;

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			if (vm.productList.id !== null) {
				ProductList.update(vm.productList, onSaveSuccess, onSaveError);
			} else {
				ProductList.save(vm.productList, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$uibModalInstance.close(result);
			vm.isSaving = false;
		}

        function onSaveError(response) {
            vm.isSaving = false;
            Notification.error({message : $translate.instant(response.headers('x-pharmaportalapp-error')), delay : ''});
        }

		function loadAll() {

		}
		loadAll();
	}
})();
