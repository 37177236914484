(function() {
	'use strict';

	angular.module('pharmaPortalApp').filter('prefixTranslate', prefixTranslate);

	prefixTranslate.$inject = [ '$filter' ];

	function prefixTranslate($filter) {
		return prefixTranslateFilter;

		function prefixTranslateFilter(input, param, prefix) {
			if (!param) {
				return input;
			}

			var searchVal = param.toLowerCase();
			var result = [];
			angular.forEach(input, function(value) {
				var translated = "";
				if (prefix) {
					translated = $filter('translate')(prefix + value);
				} else {
					translated = $filter('translate')(value);
				}
				if (translated.toLowerCase().indexOf(searchVal) !== -1) {
					result.push(value);
				}
			});
			return result;

			if (input !== null) {
				input = input.toLowerCase();
				input = input.substring(0, 1).toUpperCase() + input.substring(1);
			}
			return input;
		}
	}
})();