(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PurchaseOrderController', PurchaseOrderController);

	PurchaseOrderController.$inject = [ 'Notification', '$scope', '$state', 'PurchaseOrder', 'ParseLinks', 'paginationConstants', 'pagingParams' ];

	function PurchaseOrderController(Notification, $scope, $state, PurchaseOrder, ParseLinks, paginationConstants, pagingParams) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.clear = clear;
		vm.loadAll = loadAll;

		loadAll();

		function loadAll() {
			PurchaseOrder.query({
				page : pagingParams.page - 1,
				size : vm.itemsPerPage,
				sort : sort()
			}, onSuccess, onError);
			function sort() {
				var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.purchaseOrders = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				Notification.error({message: error.data.message, delay: ''});
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
			});
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.predicate = 'id';
			vm.reverse = true;
			vm.transition();
		}
	}
})();
