(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaCovidDetailController', PharmaCovidDetailController);

    PharmaCovidDetailController.$inject = ['Notification', 'blockUI', '$http', '$translate', '$timeout', '$uibModal', 'PharmaCovid'];

    function PharmaCovidDetailController(Notification, blockUI, $http, $translate, $timeout, $uibModal, PharmaCovid) {
        var vm = this;
        vm.startDate = null;
        vm.endDate = null;

        vm.exportCSV = exportCSV;
        vm.intervalModal = intervalModal;
        vm.covidList = [];

        vm.loadAll = function () {
            if (vm.startDate == null || vm.endDate == null) {
                vm.intervalModal();
            }
        };
        vm.loadAll();

        function exportCSV() {
            $http.post("api/pharma-covid/toCsv", vm.covidList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var frmStartDate = moment(vm.startDate).format('YYYYMMDD');
                var frmEndDate = moment(vm.endDate).format('YYYYMMDD');

                var blob = new Blob([response.data], {
                    type: "text/csv"
                });
                saveAs(blob, "ReportCovid-" + frmStartDate + "-" + frmEndDate + ".csv");
                Notification.success({
                    message: "Relatório exportado com sucesso",
                    delay: 5000
                });
            }, function errorCallback() {
                Notification.error({
                    message: "Erro ao exportar relatório",
                    delay: ''
                });
            });
        }

        function intervalModal() {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-covid/pharma-covid-dialog.html',
                controller: 'PharmaCovidDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    startDate: vm.startDate,
                    endDate: vm.endDate,
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pharmaCovid');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (response) {
                blockUI.start($translate.instant('pharmaPortalApp.pharmaCovid.loading'));
                vm.startDate = response.startDate;
                vm.endDate = response.endDate;
                vm.covidList = PharmaCovid.query({startDate: moment(response.startDate).format('YYYYMMDD'), endDate: moment(response.endDate).format('YYYYMMDD')});

                vm.covidList.$promise.then(function () {
                    $timeout(function () {
                        blockUI.stop();
                    }, 250);
                }, function () {
                    $timeout(function () {
                        blockUI.stop();
                    }, 250);
                });
            }, function (error) {
                blockUI.stop();
            });
        }
    }
})();
