(function() {
	'use strict';

	var jhiItemCount = {
		template : '<div class="info">{{"global.itemCount.showing" | translate}} '
				+ '{{(($ctrl.page - 1) * $ctrl.itemsPerPage) == 0 ? ($ctrl.queryCount == 0 ? "0" : "1") : (($ctrl.page - 1) * $ctrl.itemsPerPage + 1)}} '
				+ '{{"global.itemCount.until" | translate}} {{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}} '
				+ '{{"global.itemCount.of" | translate}} {{$ctrl.queryCount}} {{"global.itemCount.item" | translate}}</div>',
		bindings : {
			page : '<',
			queryCount : '<total',
			itemsPerPage : '<'
		}
	};

	angular.module('pharmaPortalApp').component('jhiItemCount', jhiItemCount);
})();