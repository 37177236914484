(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaDashboardTableDialogController', PharmaDashboardTableDialogController);

    PharmaDashboardTableDialogController.$inject = ['$uibModalInstance', 'tableType', 'tableFilter', 'PharmaDashboard', '$scope'];

    function PharmaDashboardTableDialogController($uibModalInstance, tableType, tableFilter, PharmaDashboard, $scope) {
        var vm = this;

        vm.tableType = tableType;
        vm.filter = tableFilter;
        vm.allUsers = {};
        vm.cancel = cancel;

        function cancel() {
            vm.tableType = {};
            vm.filter = {};
            $uibModalInstance.dismiss('cancel');
        }

        switch (vm.tableType) {
            case 'global':
                vm.filter.campaignType = null;
                if (vm.filter.pharmacyCode == null) {
                    PharmaDashboard.queryAllSelloutPharmacySalesman(vm.filter, function (result) {
                        vm.popupSalesman = result;
                    }, function (error) {
                    });
                } else {
                    PharmaDashboard.queryAllSelloutPharmacySalesmanAndPharmacy(vm.filter, function (result) {
                        vm.popupSalesman = result;
                    }, function (error) {
                    });
                }
                break;
            case 'challenge':
                vm.filter.campaignType = "CHALLENGE";
                if (vm.filter.campaignCode != null && vm.filter.pharmacyCode != null) {
                    PharmaDashboard.queryTopAllSalesmanByPharmacyAndCampaignOrderByValueList(vm.filter, function (resultP) {
                        vm.popupSalesman = resultP;
                    }, function (error) {
                    });
                } else if (vm.filter.campaignCode != null && vm.filter.pharmacyCode == null) {
                    PharmaDashboard.queryTopAllSalesmanByCampaignOrderByValueList(vm.filter, function (resultP) {
                        vm.popupSalesman = resultP;
                    }, function (error) {
                    });
                } else if (vm.filter.campaignCode == null && vm.filter.pharmacyCode != null) {
                    if (vm.filter.campaignType != null) {
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeAndPharmacyOrderByValueList(vm.filter, function (result) {
                            vm.popupSalesman = result;
                        }, function (error) {
                        });
                    } else {
                        vm.filter.campaignType = "CHALLENGE";
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeAndPharmacyOrderByValueList(vm.filter, function (resultP) {
                            vm.popupSalesman = resultP;
                        }, function (error) {
                        });
                        vm.filter.campaignType = tableFilter.campaignType != null ? tableFilter.campaignType : null;
                    }
                } else {
                    if (vm.filter.campaignType != null) {
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeOrderByValueList(vm.filter, function (result) {
                            vm.popupSalesman = result;
                        }, function (error) {
                        });
                    } else {
                        vm.filter.campaignType = "CHALLENGE";
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeOrderByValueList(vm.filter, function (resultP) {
                            vm.popupSalesman = resultP;
                        }, function (error) {
                        });
                        vm.filter.campaignType = tableFilter.campaignType != null ? tableFilter.campaignType : null;
                    }
                }
                break;
            case 'promotion':
                vm.filter.campaignType = "PROMOTION";
                if (vm.filter.campaignCode != null && vm.filter.pharmacyCode != null) {
                    PharmaDashboard.queryTopAllSalesmanByPharmacyAndCampaignOrderByQuantityList(vm.filter, function (resultP) {
                        vm.popupSalesman = resultP;
                    }, function (error) {
                    });
                } else if (vm.filter.campaignCode != null && vm.filter.pharmacyCode == null) {
                    PharmaDashboard.queryTopAllSalesmanByCampaignOrderByQuantityList(vm.filter, function (resultP) {
                        vm.popupSalesman = resultP;
                    }, function (error) {
                    });
                } else if (vm.filter.campaignCode == null && vm.filter.pharmacyCode != null) {
                    if (vm.filter.campaignType != null) {
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeAndPharmacyOrderByQuantityList(vm.filter, function (result) {
                            vm.popupSalesman = result;
                        }, function (error) {
                        });
                    } else {
                        vm.filter.campaignType = "PROMOTION";
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeAndPharmacyOrderByQuantityList(vm.filter, function (resultP) {
                            vm.popupSalesman = resultP;
                        }, function (error) {
                        });
                        vm.filter.campaignType = tableFilter.campaignType != null ? tableFilter.campaignType : null;
                    }
                } else {
                    if (vm.filter.campaignType != null) {
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeOrderByQuantityList(vm.filter, function (result) {
                            vm.popupSalesman = result;
                        }, function (error) {
                        });
                    } else {
                        vm.filter.campaignType = "PROMOTION";
                        PharmaDashboard.queryTopAllSalesmanByCampaignTypeOrderByQuantityList(vm.filter, function (resultP) {
                            vm.popupSalesman = resultP;
                        }, function (error) {
                        });
                        vm.filter.campaignType = tableFilter.campaignType != null ? tableFilter.campaignType : null;
                    }
                }
                break;
        }

        function loadUsers() {
            PharmaDashboard.getPortalUsers(function (result) {
                vm.allUsers = result;
            }, function (error) {
            });
        }

        loadUsers();

        $scope.loadPhotos = function (salesmanDes) {
            for (var i = 0; i < vm.allUsers.length; i++) {
                if (vm.allUsers[i].indicatorsName == salesmanDes) {
                    vm.tableUser = vm.allUsers[i];

                    if (vm.tableUser && vm.tableUser.thumbnailContentType) {
                        return vm.tableUser;
                    } else {
                        return null;
                    }
                }
            }
            return null;
        };
    }
})();
