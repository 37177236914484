(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ProductErpIntegrationController', ProductErpIntegrationController);

    ProductErpIntegrationController.$inject = ['Notification', '$state', '$uibModal', '$translate', 'ModuleSettings', '$window', 'ProductErpIntegration', 'ParseLinks', 'paginationConstants', 'pagingParams'];

    function ProductErpIntegrationController(Notification, $state, $uibModal, $translate, ModuleSettings, $window, ProductErpIntegration, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.transition = transition;
        vm.openNewImportDialog = openNewImportDialog;
        vm.formatSizeUnits = formatSizeUnits;
        vm.getTemplate = getTemplate;
        vm.getFormattedLineStatusMessage = getFormattedLineStatusMessage;

        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.productsModule) {
                    $state.go('accessdenied');
                } else {
                    ProductErpIntegration.query({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.productErpIntegrations = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function openNewImportDialog() {
            $uibModal.open({
                templateUrl: 'app/entities/product-erp-integration/product-erp-integration-dialog.html',
                controller: 'ProductErpIntegrationDialogController',
                controllerAs: 'vm',
                size: 'lg'
            });
        }

        function formatSizeUnits(bytes) {
            if (bytes >= 1073741824) {
                bytes = (bytes / 1073741824).toFixed(2) + " GB";
            } else if (bytes >= 1048576) {
                bytes = (bytes / 1048576).toFixed(2) + " MB";
            } else if (bytes >= 1024) {
                bytes = (bytes / 1024).toFixed(2) + " KB";
            } else if (bytes > 1) {
                bytes = bytes + " bytes";
            } else if (bytes === 1) {
                bytes = bytes + " byte";
            } else {
                bytes = "0 bytes";
            }
            return bytes;
        }

        function getTemplate() {
            $window.open("/api/product-erp-integration/template");
        }

        function getFormattedLineStatusMessage(productErpIntegration) {
            var str = "" + productErpIntegration.nbLines;
            str += " (" + productErpIntegration.validLines + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.valid') + " / ";
            str += productErpIntegration.errorLines + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.invalid') + ")";
            return str;
        }
    }
})();
