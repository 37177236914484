(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-transf-reception', {
			parent : 'entity',
			url : '/pharma-transf-reception',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaTransfReception.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception.html',
					controller : 'PharmaTransfReceptionController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'receptionDate,desc',
					squash : true
				}
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort)
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaTransfReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-transf-reception-edit', {
			parent : 'entity',
			url : '/pharma-transf-reception/:id/edit',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaTransfReception.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-edit.html',
					controller : 'PharmaTransfReceptionEditController',
					controllerAs : 'vm',
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaTransfReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'PharmaTransfReception', function($stateParams, PharmaTransfReception) {
					return PharmaTransfReception.get({
						id : $stateParams.id
					}).$promise;
				} ],
				previousState : [ "$state", function($state) {
					var currentStateData = {
						name : $state.current.name || 'pharma-transf-reception',
						params : $state.params,
						url : $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				} ]
			}
		}).state('pharma-transf-reception.new', {
			parent : 'entity',
			url : '/pharma-transf-reception/new',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaTransfReception.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-new.html',
					controller : 'PharmaTransfReceptionNewController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaTransfReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ],
				previousState : [ "$state", function($state) {
					var currentStateData = {
						name : $state.current.name || 'pharma-transf-reception',
						params : $state.params,
						url : $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				} ]
			}
		}).state('pharma-transf-reception.delete', {
			parent : 'pharma-transf-reception',
			url : '/{id}/delete',
			method : 'DELETE',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-delete-dialog.html',
					controller : 'PharmaTransfReceptionDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						pharmaTransfReception : [ 'PharmaTransfReception', function(PharmaTransfReception) {
							return PharmaTransfReception.get({
								id : $stateParams.id
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-transf-reception', null, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-transf-reception-line', {
			parent : 'entity',
			url : '/pharma-transf-reception-line',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaTransfReception.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-line.html',
					controller : 'PharmaTransfReceptionController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaTransfReception');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-transf-reception-line.new', {
			parent : 'pharma-transf-reception-edit',
			url : '/newLine',
			method : 'POST',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-line-new-dialog.html',
					controller : 'PharmaTransfReceptionLineNewController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : [ 'PharmaTransfReception', function(PharmaTransfReception) {
							return PharmaTransfReception.get({
								id : $stateParams.id
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-transf-reception-line.new', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-transf-reception-line.delete', {
			parent : 'pharma-transf-reception-edit',
			url : '/delete/{lineId}',
			method : 'DELETE',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-line-delete-dialog.html',
					controller : 'PharmaTransfReceptionLineDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : [ 'PharmaTransfReceptionLine', function(PharmaTransfReceptionLine) {
							return PharmaTransfReceptionLine.get({
								id : $stateParams.lineId
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-transf-reception-edit', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-transf-reception-detail', {
			parent : 'pharma-transf-reception',
			url : '/{id}/detail',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-detail-dialog.html',
					controller : 'PharmaTransfReceptionDetailController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'lg',
					resolve : {
						pharmaTransfReception : [ 'PharmaTransfReception', function(PharmaTransfReception) {
							return PharmaTransfReception.get({
								id : $stateParams.id
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-transf-reception', {}, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-transf-reception.inactive', {
            parent : 'pharma-transf-reception.new',
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-new.inactive-dialog.html',
                    controller : 'PharmaTransfReceptionInactiveController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                    $state.go('pharma-transf-reception.new', null, {
                        reload : true
                    });
                }, function() {
                    $state.go('^');
                });
            } ]
        });
	}
})();
