(function() {
    'use strict';

    angular.module('pharmaPortalApp').controller('SelloutIntegrationHistoryController', SelloutIntegrationHistoryController);

    SelloutIntegrationHistoryController.$inject = [ 'Notification', 'Principal', '$state', 'SelloutIntegrationHistory', '$http', '$translate', 'moment', '$uibModal', 'ModuleSettings' ];

    function SelloutIntegrationHistoryController(Notification, Principal, $state, SelloutIntegrationHistory, $http, $translate, moment, $uibModal, ModuleSettings) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.updateCompany = updateCompany;

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function(result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.selloutsModule) {
                    $state.go('accessdenied');
                } else {
                    $http.get("/api/companies/findAllNotEmptyTypePharmacy").then(function(result) {
                        vm.companies = result.data;
                    });

                    Principal.identity().then(function(account) {
                        vm.currentCompany = account.company;
                    });
                }
            });
        }
        loadAll();

        function updateCompany($item, $model) {
            vm.currentCompany = $item;
        }

        /* Calendar Configuration */
        vm.calendarConfig = {
            lang : $translate.use(),
            height : 'auto',
            editable : true,
            droppable : false,
            themeSystem : 'bootstrap3',
            header : {
                left : 'prev',
                center : 'title',
                right : 'next'
            },
            footer : {
                right : 'today'
            },
            views : {
                month : {
                    titleFormat : 'MMMM [de] YYYY'
                }
            },
            displayEventTime : false,
            columnFormat : 'dddd',
            eventRender : customEventRender,
            dayClick : customDayClick,
            eventClick : customEventClick
        };

        function getEventSources(start, end, timezone, callback) {
            var events = SelloutIntegrationHistory.query({
                'fromDate' : start.toISOString(),
                'toDate' : end.toISOString()
            }).$promise.then(function(data) {
                angular.forEach(data, function(v, k) {
                    v.title = v.company.naming;
                    v.allDay = true;
                    v.start = v.integrationDate;
                    v.className = "alert-danger";
                    v.color = v.selloutFileStatus;
                    v.editable = false;
                });
                callback(data);
            });
        }

        function customEventRender(event, element) {
            switch (event.color) {
                case "GREEN":
                    element.find('.fc-title').prepend('<i class="fa fa-thumbs-up"></i>&nbsp;');
                    break;
                case "ORANGE":
                    element.find('.fc-title').prepend('<i class="fa fa-exclamation-triangle"></i>&nbsp;');
                    break;
                case "BLUE":
                    element.find('.fc-title').prepend('<i class="fa fa-cogs"></i>&nbsp;');
                    break;
                case "RED":
                    element.find('.fc-title').prepend('<i class="fa fa-thumbs-down"></i>&nbsp;');
                    break;
            }
        }

        function customDayClick(date, jsEvent, view) {
            // TEST IF DATE IS FUTURE AND HIGHLIGHT IF NOT
            jQuery(".fc-day").removeClass("alert-warning");
            jQuery(".fc-day-top").removeClass("alert-warning");
            if (moment().diff(date, 'hours') >= 0) {
                jQuery("td[data-date=" + date.format() + "]").addClass("alert-warning");
            } else {
                Notification.warning({ message : $translate.instant('pharmaPortalApp.selloutIntegrationHistory.message.futureDate'), delay : 5000 });
                return;
            }

            $uibModal.open({
                animation : true,
                templateUrl : 'app/entities/sellout-integration/sellout-integration-dialog.html',
                controller : 'SelloutIntegrationDialogController',
                controllerAs : 'vm',
                resolve : {
                    importDate : date,
                    curCompany: vm.currentCompany,
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('selloutIntegrationHistory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    } ]
                }
            });
        }

        function customEventClick(calEvent, jsEvent, view) {
            $uibModal.open({
                animation : true,
                templateUrl : 'app/entities/sellout-integration/sellout-integration-status-dialog.html',
                controller : 'SelloutIntegrationStatusDialogController',
                controllerAs : 'vm',
                resolve : {
                    entity : calEvent.id,
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('selloutIntegrationHistory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    } ]
                }
            });
        }
        vm.eventSources = [ getEventSources ];
    }
})();

