(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-balance', {
			parent : 'entity',
			url : '/pharma-balance',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaBalance.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-balance/pharma-balances.html',
					controller : 'PharmaBalanceController',
					controllerAs : 'vm'
				}
			},
			params : {
				workingPeriod : null
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaBalance');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-balance.report', {
			parent : 'pharma-balance',
			url : '/report/{period}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-balance/pharma-balance-report-dialog.html',
					controller : 'PharmaBalanceReportController',
					controllerAs : 'vm',
					size : 'lg modal-exl',
					resolve : {
						period : function() {
							return {
								period : $stateParams.period
							};
						}
					}
				}).result.then(function() {
					$state.go('pharma-balance', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-balance.closeMonth', {
			parent : 'pharma-balance',
			url : '/closeMonth/{period}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-balance/pharma-balance-confirm-dialog.html',
					controller : 'PharmaBalanceConfirmController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						period : function() {
							return {
								period : $stateParams.period
							};
						}
					}
				}).result.then(function() {
					$state.go('pharma-balance', {workingPeriod : $stateParams.period}, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		});
	}
})();