(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('psychotropics', {
            parent: 'entity',
            url: '/psychotropics',
            data: {
                authorities: ['ROLE_INFARMED'],
                pageTitle: 'pharmaPortalApp.psychotropics.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pharma-infarmed/psychotropicsRequisitions.html',
                    controller: 'PsychotropicsRequisitionsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil.parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil.parsePredicate($stateParams.sort),
                        ascending : PaginationUtil.parseAscending($stateParams.sort)
                    };
                } ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('psychotropics');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('psychotropics.detail', {
            parent: 'entity',
            url: '/psychotropics/detail',
            data: {
                authorities: ['ROLE_INFARMED'],
                pageTitle: 'pharmaPortalApp.psychotropics.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pharma-infarmed/psychotropicsRequisitions-detail.html',
                    controller: 'PsychotropicsRequisitionsDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                requisition: null,
                back: null,
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil.parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        requisition : $stateParams.requisition,
                        back : $stateParams.back,
                        predicate : PaginationUtil.parsePredicate($stateParams.sort),
                        ascending : PaginationUtil.parseAscending($stateParams.sort)
                    };
                } ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('psychotropics');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
