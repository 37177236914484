(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(translationConfig);

	translationConfig.$inject = [ '$translateProvider', 'tmhDynamicLocaleProvider' ];

	function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
		// Initialize angular-translate
		$translateProvider.useLoader('$translatePartialLoader', {
			urlTemplate : 'i18n/{lang}/{part}.json'
		});

		$translateProvider.preferredLanguage('pt-pt');
		$translateProvider.useStorage('translationStorageProvider');
		$translateProvider.useSanitizeValueStrategy('escaped');
		$translateProvider.addInterpolation('$translateMessageFormatInterpolation');

		tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
		tmhDynamicLocaleProvider.useCookieStorage();
		tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
	}
})();
