(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('TradeClearOrderController', TradeClearOrderController);

	TradeClearOrderController.$inject = [ 'Notification', '$uibModalInstance', '$translate', '$rootScope', '$localStorage', '$state', 'Principal', 'ModuleSettings' ];

	function TradeClearOrderController(Notification, $uibModalInstance, $translate, $rootScope, $localStorage, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.clear = clear;
		vm.confirmClearOrder = confirmClearOrder;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmClearOrder() {
			$rootScope.orderList = [];
			$rootScope.totalizer = {
				qtd : 0,
				price : 0,
				docTotal : 0
			};
			$uibModalInstance.close(true);
			delete $localStorage.tradeOrder;
			Notification.success({message : $translate.instant('pharmaPortalApp.pharmaTrade.message.tradeCleared'), delay : 5000});
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.tradesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (account && account.company && account.company.clientType == "F") {
							// DO NOTHING
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();