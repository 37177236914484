(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PsychotropicsRequisitionsController', PsychotropicsRequisitionsController);

    PsychotropicsRequisitionsController.$inject = ['$timeout', 'DataUtils', 'Principal', 'blockUI', '$http', '$filter', '$scope', '$translate', 'Notification', 'pagingParams', 'paginationConstants', 'PsychotropicsRequisitions', 'ParseLinks', '$uibModal', '$state'];

    function PsychotropicsRequisitionsController($timeout, DataUtils, Principal, blockUI, $http, $filter, $scope, $translate, Notification, pagingParams, paginationConstants, PsychotropicsRequisitions, ParseLinks, $uibModal, $state) {
        var vm = this;
        $scope.showSearch = true
        vm.loadAll = loadAll;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.selectAll = false;

        vm.selectedYear = null;
        vm.selectedMonth = null;

        vm.status = ['CREATED', 'AVAILABLE', 'VALIDATED'];//, 'DELETED'
        vm.usersResponsible = [];
        vm.tableFilters = {
            numberRequisition: null,
            date: null,
            search: null,
            cnp: null,
            taxNumber: null,
            table: null,
            responsible: null,
            type: null,
            status: 'AVAILABLE'
        };

        vm.generateInitialTree = function () {
            $timeout(function () {
                if (vm.account.company != null) {
                    vm.tableFilters.taxNumber = vm.account.company.nif;
                }
                PsychotropicsRequisitions.queryAllYearsToTree({tableFilters: vm.tableFilters}, function (result) {
                    $scope.data = result;
                });
            }, 100);
        };

        vm.getAllMonthsToTree = function (node) {
            PsychotropicsRequisitions.queryAllMonthsToTree({
                year: node.label,
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };

        vm.getAllEntitiesToTree = function (node) {
            PsychotropicsRequisitions.queryAllEntitiesToTree({
                jsonHashMap: JSON.stringify(node.parents),
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };

        vm.getAllTypesToTree = function (node) {
            PsychotropicsRequisitions.getAllTypesToTree({
                jsonHashMap: JSON.stringify(node.parents),
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };

        vm.getAllTablesToTree = function (node) {
            PsychotropicsRequisitions.getAllTablesToTree({
                jsonHashMap: JSON.stringify(node.parents),
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };

        vm.selectedOption = function () {
            vm.resetDataFilter();

            if (vm.tableFilters.cnp != null) {
                vm.beforeChangeProduct = vm.tableFilters.cnp;
                vm.tableFilters.cnp = vm.tableFilters.cnp.cnp;
            }
            vm.generateInitialTree();
            vm.saveFilterToLocalStorage();
            PsychotropicsRequisitions.queryAllFilters({
                tableFilters: vm.tableFilters,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        };

        vm.resetDataFilter = function () {
            vm.tableFilters.numberRequisition = null;
            vm.tableFilters.date = null;
            vm.tableFilters.search = null;
            vm.tableFilters.type = null;
            vm.tableFilters.table = null;
            vm.tableFilters.taxNumber = null;
            vm.selectedYear = null;
            vm.selectedMonth = null;
            vm.actualStatus = vm.tableFilters.status;
        }

        vm.applyFiltersTree = function (node) {
            vm.resetDataFilter();
            if (node.parents == null || node.parents.length === 0) {
                return;
            }
            loadAll();
            vm.tableFilters.search = node.parents["searchBy"];
            vm.tableFilters.taxNumber = node.parents["taxNumber"];

            if (node.level === 3) {
                vm.tableFilters.type = node.label;
            }

            if (node.level === 4) {
                vm.tableFilters.table = node.label;
            }

            PsychotropicsRequisitions.queryAllFilters({
                tableFilters: vm.tableFilters,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        };


        vm.getNextTreeLevel = function (value, node) {
            if (!value || node.children.length !== 0) {
                return;
            }
            if (vm.account.company != null && node.parents != null) {
                vm.tableFilters.taxNumber = vm.account.company.nif;
            }
            switch (node.level) {
                case 0:
                    vm.getAllMonthsToTree(node);
                    break;
                case 1:
                    vm.getAllEntitiesToTree(node);
                    break;
                case 2:
                    vm.getAllTypesToTree(node);
                    break;
                case 3:
                    vm.getAllTablesToTree(node);
                    break;
            }
        }
        getAccount();

        function loadAll() {
            vm.beforeChange = null;
            vm.beforeChangeProduct = null;
            if (localStorage.getItem("filterMainPage") != null) {
                vm.tableFilters = JSON.parse(localStorage.getItem("filterMainPage"))
            }

            if (vm.selectedYear != null && vm.selectedMonth != null) {
                vm.tableFilters.search = vm.selectedMonth.id + "" + vm.selectedYear
            }
            if (vm.tableFilters.taxNumber != null) {
                vm.beforeChange = vm.tableFilters.taxNumber;
                vm.tableFilters.taxNumber = vm.tableFilters.taxNumber.taxNumber;
            }
            if (vm.tableFilters.cnp != null) {
                vm.beforeChangeProduct = vm.tableFilters.cnp;
                vm.tableFilters.cnp = vm.tableFilters.cnp.cnp;
            }

            vm.actualStatus = vm.tableFilters.status;
            PsychotropicsRequisitions.queryAllFilters({
                tableFilters: vm.tableFilters,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            vm.generateInitialTree();

        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.psychotropicsHeaders = data;
            vm.page = pagingParams.page;
            vm.tableFilters.taxNumber = vm.beforeChange;
            vm.tableFilters.cnp = vm.beforeChangeProduct;
            vm.beforeChangeProduct = null;
            vm.beforeChange = null;
            addUsersfilter();
        }

        function addUsersfilter() {
            var pageUsers = vm.psychotropicsHeaders.reduce(function (sum, header) {
                if (sum.indexOf(header.responsible) < 0) sum.push(header.responsible);
                return sum;
            }, []);
            if (vm.usersResponsible.length === 0) {
                vm.usersResponsible = pageUsers;
            } else {
                vm.usersResponsible = vm.usersResponsible.concat(pageUsers).unique();
            }
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }


        loadAll();

        vm.deleteRequisition = function (id, status) {
            PsychotropicsRequisitions.deleteRequisition({id: id, status: status}, function () {
                vm.loadAll();
                showSuccess("pharmaPortalApp.psychotropics.success.deleted");
            }, function () {
                showError("pharmaPortalApp.psychotropics.error.deleteRequisition")
            });
        };

        function showSuccess(message) {
            Notification.success({
                message: $translate.instant(message),
                delay: ''
            });
        }

        vm.clearFilter = function () {
            vm.tableFilters = {
                numberRequisition: null,
                date: null,
                search: null,
                cnp: null,
                type: null,
                taxNumber: null,
                table: null,
                responsible: null,
                status: null
            };
            vm.selectedYear = null;
            vm.selectedMonth = null;
            vm.saveFilterToLocalStorage();
            loadAll();
        };

        function showWarning(message) {
            Notification.warning({
                message: $translate.instant(message),
                delay: ''
            });
        }

        function showError(message) {
            Notification.error({
                message: $translate.instant(message),
                delay: ''
            });
        }

        Array.prototype.unique = function () {
            var a = this.concat();
            for (var i = 0; i < a.length; ++i) {
                for (var j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j])
                        a.splice(j--, 1);
                }
            }

            return a;
        };

        vm.saveFilterToLocalStorage = function () {
            localStorage.setItem("filterMainPage", JSON.stringify(vm.tableFilters));
        };

        vm.openChooseModal = function () {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-infarmed/choose-psychotropics-date.html',
                controller: 'ChoosePsychotropicsDate',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    pagingParams: function () {
                        return pagingParams;
                    },
                    paginationConstants: function () {
                        return paginationConstants;
                    },
                    allowValidations: function () {
                        return vm.allowAvailable;
                    }
                }
            }).result.then(function () {
                // success callback implemented on own controller
            }, function (flag) {
                loadAll();
            });
        };

        vm.validateRequisition = function (found) {
            if (vm.account.professionalWalletNumber === undefined || vm.account.professionalWalletNumber == null) {
                showWarning('pharmaPortalApp.psychotropics.warning.professionalWalletNumber');
            } else {

                PsychotropicsRequisitions.validateRequisition(found, function () {
                    showSuccess("pharmaPortalApp.psychotropics.success.validation");
                    vm.loadAll();
                }, function () {
                    showError("pharmaPortalApp.psychotropics.error.validationRequisitions")
                });
            }
        };

        vm.checkIfIsAllLinesSelected = function () {
            var found = $filter('filter')(vm.psychotropicsHeaders, function (value) {
                return value.selected === undefined || value.selected === false
            }, true);
            vm.selectAll = found.length === 0;
        };

        vm.disableButtons = true;

        vm.selectLine = function () {

            var found = $filter('filter')(vm.psychotropicsHeaders, function (value) {
                return value.selected !== undefined && value.selected === true
            }, true);
            $timeout(function () {
                $scope.$apply(function () {
                    vm.disableButtons = found.length === 0;
                })
            })

        };

        vm.selectAllPage = function (flag) {
            vm.disableButtons = !flag;
            angular.forEach(vm.psychotropicsHeaders, function (value) {
                value.selected = flag;
            });
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (account && account.authorities.length) {
                    // $state.go('product-erp-integration');
                } else {
                    $state.go('home.fl');
                }
                if (vm.account.responsibleValidator === true && vm.account.professionalWalletNumber !== undefined && vm.account.professionalWalletNumber !== null && vm.account.company != null) {
                    vm.allowValidations = true;
                }
                if (vm.account.responsibleValidator === true && vm.account.professionalWalletNumber !== undefined && vm.account.professionalWalletNumber !== null && vm.account.company == null) {
                    vm.allowAvailable = true;
                    vm.allowCreate = true;
                }
                if (vm.account.company == null) {
                    vm.allowCreate = true;
                }
            }).catch(function () {
                vm.isAuthenticated = null;
                $state.go('home.fl');
            });
        }

        vm.validateAll = function () {
            if (vm.account.professionalWalletNumber === undefined || vm.account.professionalWalletNumber == null) {
                showWarning('pharmaPortalApp.psychotropics.warning.professionalWalletNumber');
            } else {
                var found = $filter('filter')(vm.psychotropicsHeaders, {selected: true}, true);
                if (found.length === 0) {
                    showWarning('pharmaPortalApp.psychotropics.warning.selectAtLeastOne');
                } else {
                    PsychotropicsRequisitions.validateListRequisitions(found, function () {
                        showSuccess("pharmaPortalApp.psychotropics.success.validation");
                        vm.selectAll = false;
                        vm.loadAll();
                    }, function () {
                        showError("pharmaPortalApp.psychotropics.error.validationRequisitions")
                    });
                }
            }
        };

        $scope.refreshSelectModel = function (query) {
            if (vm.lastQuery != query) {
                vm.lastQuery = query;
                PsychotropicsRequisitions.getAllFiltersData({search: query}, function (result) {
                    vm.products = result.productsFilter;
                });
            }
        };

        vm.onOpenClose = function () {
            angular.element('.ui-select-choices-row').removeClass('active');
        };

        vm.generateAll = function () {
            var found = $filter('filter')(vm.psychotropicsHeaders, {selected: true}, true);
            if (found.length === 0) {
                showWarning('pharmaPortalApp.psychotropics.warning.selectAtLeastOne');
            } else {
                vm.generateReports(found);
            }
        };
        vm.availableAll = function () {
            var found = $filter('filter')(vm.psychotropicsHeaders, {selected: true}, true);
            if (found.length === 0) {
                showWarning('pharmaPortalApp.psychotropics.warning.selectAtLeastOne');
            } else {
                PsychotropicsRequisitions.availableListRequisitions(found, function () {
                    vm.loadAll();
                    showSuccess("pharmaPortalApp.psychotropics.success.available");
                }, function () {
                    showError("pharmaPortalApp.psychotropics.error.availableRequisitions")
                });
            }
        };


        vm.generateReports = function (listFound) {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-infarmed/choose-psychotropics-reports-number.html',
                controller: 'ChoosePsychotropicsReportsNumber',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            }).result.then(function () {
                // success callback implemented on own controller
            }, function (flag) {
                if (flag != 'cancel') {
                    blockUI.start($translate.instant('pharmaPortalApp.psychotropics.loading'));
                    $http.post("/api/psychotropics-requisitions/generatePDF-all",
                        JSON.stringify({"via": flag, "list": listFound}),
                        {responseType: "arraybuffer"})
                        .then(function successCallback(response) {
                            blockUI.stop();
                            var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");

                            response.filename = "Requisitions" + "_" + dateTime + ".zip"
                            DataUtils.viewServerFile(response);
                            showSuccess('pharmaPortalApp.psychotropics.successReports');

                        }, function errorCallback(response) {
                            blockUI.stop();
                            Notification.error({message: "Erro ao gerar report", delay: ''});
                        });
                }
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            vm.willChangePage = true;
            if (localStorage.getItem("filterMainPage") != null) {
                vm.tableFilters = JSON.parse(localStorage.getItem("filterMainPage"))
            }
            vm.removeLocalStorage = true;
            $state.transitionTo($state.$current, {
                page: vm.page,
                tableFilters: vm.tableFilters,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        vm.generatePdf = function (requisition) {
            vm.generateReports([requisition]);
        };

        vm.getPeriodTolabel = function (searchBy) {
            if (searchBy == null) {
                return '';
            }

            var month;
            var year;
            if (searchBy.length === 6) {
                month = searchBy.substring(0, 2);
                year = searchBy.substring(2, searchBy.length);
            } else {
                month = searchBy.substring(0, 1);
                year = searchBy.substring(1, searchBy.length);
            }

            return searchArrayByKey(parseInt(month), vm.months).label + " " + year;

        };

        vm.getTablesFromLines = function (psychotropicsHeader) {
            var list = [];
            psychotropicsHeader.infarmedRequisitionLines.forEach(function (element) {
                if (!list.includes(element.typeTab)) {
                    list.push(element.typeTab);
                }
            });

            return list;
        };

        var actualYear = new Date().getFullYear();
        var actualMonth = new Date().getMonth();
        vm.years = [actualYear - 1, actualYear];

        function searchArrayByKey(nameKey, myArray) {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i].id === nameKey) {
                    return myArray[i];
                }
            }
        }

        vm.months = [
            {
                id: 0,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.January')
            },
            {
                id: 1,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.February')
            },
            {
                id: 2,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.March')
            },
            {
                id: 3,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.April')
            },
            {
                id: 4,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.May')
            },
            {
                id: 5,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.June')
            },
            {
                id: 6,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.July')
            },
            {
                id: 7,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.August')
            },
            {
                id: 8,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.September')
            },
            {
                id: 9,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.October')
            },
            {
                id: 10,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.November')
            },
            {
                id: 11,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.December')
            }
        ]
        vm.monthsToShow = vm.months;

        vm.yearSelected = function (yearSelected) {
            if (vm.selectedYear != null && actualYear == yearSelected) {
                vm.monthsToShow = $filter('filter')(vm.months, function (value) {
                    return value.id <= actualMonth
                });
            } else {
                vm.monthsToShow = vm.months;
            }
            vm.selectedMonth = null;
            return vm.monthsToShow;
        };

        vm.removeLocalStorage = null;

        $scope.$on('$destroy', function () {
            if (!vm.removeLocalStorage) {
                localStorage.removeItem("filterMainPage");
            }
        });

        vm.availableRequisition = function (found) {
            PsychotropicsRequisitions.availableRequisition(found, function () {
                showSuccess("pharmaPortalApp.psychotropics.success.available");
                vm.loadAll();
            }, function () {
                showError("pharmaPortalApp.psychotropics.error.availableRequisitions")
            });
        };

    }
})();

