(function() {
    'use strict';
    angular.module('pharmaPortalApp').factory('Campaign', Campaign);

    Campaign.$inject = [ '$resource' ];

    function Campaign($resource) {
        var resourceUrl = 'api/campaigns/:id';

        return $resource(resourceUrl, {}, {
            'query' : {
                method : 'GET',
                isArray : true
            },
            'get' : {
                method : 'GET',
                transformResponse : function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update' : {
                method : 'PUT'
            },
            'getAllCampaigns':{
                method : 'GET',
                isArray : true,
                url:"api/allCampaigns"
            },
            'activeByDashboardPeriod':{
                method : 'GET',
                isArray : true,
                url:"api/campaigns/activeByDashboardPeriod"
            },
            'getById':{
                method : 'GET',
                isArray : true,
                url: "api/campaigns/getById"
            }
        });
    }
})();
