(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-return', {
			parent : 'entity',
			url : '/pharma-return',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ],
				pageTitle : 'pharmaPortalApp.pharmaReturn.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-return/pharma-returns.html',
					controller : 'PharmaReturnController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaReturn');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-return.clearReturn', {
			parent : 'pharma-return',
			url : '/clear',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-return/return-clear-dialog.html',
					controller : 'ReturnClearController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-return', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-return.confirmReturn', {
			parent : 'pharma-return',
			url : '/confirm',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-return/return-confirm-dialog.html',
					controller : 'ReturnConfirmController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-return', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-return.removeLine', {
			parent : 'pharma-return',
			url : '/removeLine/{cnpToDel}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_PHARMA' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-return/return-remove-line-dialog.html',
					controller : 'ReturnRemoveLineController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								cnpToDel : $stateParams.cnpToDel
							};
						}
					}
				}).result.then(function() {
					$state.go('pharma-return', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-return.inactive', {
            parent : 'pharma-return',
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/pharma-return/pharma-return.inactive-dialog.html',
                    controller : 'PharmaReturnInactiveController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                    $state.go('pharma-return', null, {
                        reload : true
                    });
                }, function() {
                    $state.go('^');
                });
            } ]
        });
	}
})();
