(function () {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .controller('PharmaFaultReportHeaderController', PharmaFaultReportHeaderController);

    PharmaFaultReportHeaderController.$inject = ['Notification', 'blockUI', '$translate', '$scope', '$state', '$http', '$timeout', 'PharmaFaultReportHeader', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PharmaFaultReportHeaderController(Notification, blockUI, $translate, $scope, $state, $http, $timeout, PharmaFaultReportHeader, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.resync = resync;
        vm.resend = resend;

        loadAll();

        function loadAll() {
            PharmaFaultReportHeader.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pharmaFaultReportHeaders = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.expandCollapse = function (item) {
            var expandCurrent = angular.isUndefined(item.expanded) || item.expanded == false;
            angular.forEach(vm.pharmaFaultReportHeaders, function (value) {
                value.expanded = item.id == value.id;
            });
            item.expanded = expandCurrent;
        }

        function resync() {
            blockUI.start($translate.instant('pharmaPortalApp.pharmaFaultReportHeader.loading'));
            $http.get('/api/pharma-fault-report-headers/resync').then(function (result) {
                Notification.success({
                    message: $translate.instant('pharmaPortalApp.pharmaFaultReportHeader.message.success', {lines: result.data}),
                    delay: 5000
                });
                $timeout(function () {
                    blockUI.stop();
                }, 250);
                $state.go('pharma-fault-report-header', null, {
                    reload : true
                });
            }, function (error) {
                Notification.error({message: $translate.instant('pharmaPortalApp.pharmaFaultReportHeader.message.error'), delay: 5000});
                $timeout(function () {
                    blockUI.stop();
                }, 250);
            });
        }

        function resend() {
            blockUI.start($translate.instant('pharmaPortalApp.pharmaFaultReportHeader.message.resending'));
            $http.get('/api/pharma-fault-report-headers/resend').then(function (result) {
                Notification.success({
                    message: $translate.instant('pharmaPortalApp.pharmaFaultReportHeader.message.resending.success'),
                    delay: 5000
                });
                $timeout(function () {
                    blockUI.stop();
                }, 250);
                $state.go('pharma-fault-report-header', null, {
                    reload : true
                });
            }, function (error) {
                Notification.error({message: $translate.instant('pharmaPortalApp.pharmaFaultReportHeader.message.resending.error'), delay: 5000});
                $timeout(function () {
                    blockUI.stop();
                }, 250);
            });
        }
    }
})();
