(function() {
    'use strict';
    angular
        .module('pharmaPortalApp')
        .factory('PharmaFaultReportLine', PharmaFaultReportLine);

    PharmaFaultReportLine.$inject = ['$resource'];

    function PharmaFaultReportLine ($resource) {
        var resourceUrl =  'api/pharma-fault-report-lines/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
