(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pharma-fault-settings', {
            parent: 'entity',
            url: '/pharma-fault-settings',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pharmaPortalApp.pharmaFaultSettings.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pharma-fault-settings/pharma-fault-settings.html',
                    controller: 'PharmaFaultSettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaFaultSettings');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PharmaFaultSettings', function ($stateParams, PharmaFaultSettings) {
                    return PharmaFaultSettings.get({id: $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pharma-fault-settings',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();
