(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingKeepInPharmacyController', PharmaPickingKeepInPharmacyController);

    PharmaPickingKeepInPharmacyController.$inject = ['Notification', '$uibModal', '$timeout', 'blockUI', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'pharmaPicking', 'complaint', 'PharmaPicking', 'PortalSettings', 'ModuleSettings', '$rootScope', '$scope', 'user'];

    function PharmaPickingKeepInPharmacyController(Notification, $uibModal, $timeout, blockUI, $uibModalInstance, $translate, $state, $http, $filter, pharmaPicking, complaint, PharmaPicking, PortalSettings, ModuleSettings, $rootScope, $scope, user) {
        var vm = this;

        vm.portalSettings = PortalSettings.query();

        vm.loadAll = loadAll;

        vm.clear = clear;
        vm.confirmPharmaPickingSubmit = confirmPharmaPickingSubmit;
        vm.pharmaPicking = pharmaPicking;
        vm.user = user.user;
        vm.complaint = complaint;
        vm.supplier = vm.pharmaPicking.supplierCode + " - " + vm.pharmaPicking.supplierName;
        vm.allSelected = true;
        vm.passwordInvalid = false;
        vm.showConfirmProducts = false;
        vm.confirmedProducts = false;

        vm.toggleLineEditMode = toggleLineEditMode;
        vm.checkSuggestionError = checkSuggestionError;
        vm.checkSuggestionQuantity = checkSuggestionQuantity;

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                }
            });

            PharmaPicking.getImportFileByPharmacyWarehouse({pharmacyWarehouse: vm.pharmaPicking.pharmacyWarehouse},
                function (result) {
                    vm.importFiles = result;
                    vm.readProduct();
                }, function (e) {
                    vm.importFiles = [];
                    vm.readProduct();
                });
        }

        vm.loadAll();

        function checkSuggestionError() {
            var temp = $filter('filter')(vm.products, function (line) {
                if (!line.suggestion) {
                    return line;
                }
            }, true);
            return temp && vm.products && temp.length == vm.products.length;
        }

        function toggleLineEditMode(product) {
            if (angular.isDefined(product.editMode) && product.editMode == true) {
                product.editMode = false;
            } else {
                product.editMode = true;
                selectText(product.id);
            }
        }

        function selectText(id) {
            var input = document.getElementById(id);
            $timeout(function () {
                input.focus();
                input.select();
            }, 70);
        }

        function checkSuggestionQuantity() {
            var temp = $filter('filter')(vm.products, function (line) {
                // if(line.suggestion > line.receivedQuantity || line.suggestion < 0) {
                if (!line.suggestion) {
                    return line;
                }
            }, true);
        }

        vm.readProduct = function () {
            var temp = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
                if (line.receivedQuantity && line.receivedQuantity != 0) {
                    for (var i = 0; i < vm.importFiles.length; i++) {
                        if (line.cnp == vm.importFiles[i].productCNP) {
                            var invoiced = 0;
                            if ((!vm.complaint && line.selected) || (vm.complaint && !line.selected)) {
                                invoiced = line.invoicedQuantity - line.receivedQuantity;
                            }
                            line.suggestion = Math.min(line.receivedQuantity, (vm.importFiles[i].quantity - vm.importFiles[i].soldQuantity - invoiced));
                            if (line.suggestion <= 0) {
                                line.suggestion = undefined;
                            }
                            break;
                        }
                    }

                    line.isSelectedSuggestion = false;

                    return line;
                }
            }, true);
            vm.products = $filter('orderBy')(temp, 'suggestion', true, function (item1, item2) {

                return (item1.value ? item1.value : 0) >= (item2.value ? item2.value : 0) ? 1 : -1;

            });

            $scope.totalItems = vm.products.length;
            vm.page = 1;
            $scope.itemsPerPage = 7;

            $scope.$watch("vm.page", function () {
                setPagingData(vm.page);
            });
            vm.selectProduct();
            // $timeout(function () {
            //     selectProducts(true);
            // });

        };

        function clear(flag) {
            $uibModalInstance.dismiss(flag);
        }

        function isValid() {
            var temp = $filter('filter')(vm.products, function (line) {
                if (line.isSelectedSuggestion === true) {
                    if (!line.suggestion || line.suggestion > line.receivedQuantity || line.suggestion < 0) {
                        return line;
                    }
                }
            }, true);
            return temp.length <= 0;
        }

        function confirmPharmaPickingSubmit() {
            if (!isValid()) {
                vm.invalidSuggestion = true;
            } else {
                vm.invalidSuggestion = false;
                var temp = $filter('filter')(vm.products, {isSelectedSuggestion: true}, true);
                angular.forEach(temp, function (item) {
                    item.receivedQuantity = item.receivedQuantity - (item.suggestion ? item.suggestion : 0)
                });
                vm.pharmaPicking.lastModifyingDate = new Date();
                if (vm.user == vm.pharmaPicking.lastModifyingUser) {
                    vm.pharmaPicking.lastModifyingUser = vm.user;
                    PharmaPicking.save(vm.pharmaPicking, function onSaveSuccess(result) {
                        $uibModalInstance.close('submit');
                    }, function onSaveError(result) {
                        if (result.data.success) {
                            Notification.success({message: result.data.message, delay: 5000});
                        } else {
                            Notification.error({message: result.data.message, delay: ''});
                        }
                        $state.go("pharma-picking-detail.edit", {
                            id: result.id
                        });
                    });
                } else {
                    Notification.error({message: "Utilizador sem permissões para efetuar operação!", delay: ''});
                }
            }
        }

        function setPagingData(page) {
            var pagedData = vm.products.slice(
                (page - 1) * $scope.itemsPerPage,
                page * $scope.itemsPerPage
            );
            $scope.products = pagedData;
        }

        function selectProducts(flag) {
            for (var i = 0; i < $scope.products.length; i++) {
                $scope.products[i].isSelectedSuggestion = flag;
            }
            for (var i = 0; i < vm.products.length; i++) {
                vm.products[i].isSelectedSuggestion = flag;
            }
        }

        vm.selectAll = function () {
            selectProducts(vm.allSelected);
        }

        vm.selectProduct = function () {
            var products = $filter('filter')(vm.products, {isSelectedSuggestion: false});
            if (products.length != 0) {
                vm.allSelected = false;
            } else {
                vm.allSelected = true;
            }
        }

        vm.changeConfirmProducts = function () {
            vm.showConfirmProducts = false;
        }
    }
})();
