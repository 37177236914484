(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('TradeRemoveProductController', TradeRemoveProductController);

	TradeRemoveProductController.$inject = [ 'Notification', '$uibModalInstance', '$translate', 'entity', '$rootScope', '$state', 'Principal', 'ModuleSettings' ];

	function TradeRemoveProductController(Notification, $uibModalInstance, $translate, entity, $rootScope, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.cnpToDel = entity.cnpToDel;
		vm.product = null;

		vm.clear = clear;
		vm.confirmRemoveProduct = confirmRemoveProduct;
		vm.filterItemFromArray = filterItemFromArray;
		vm.deleteFilteredItem = deleteFilteredItem;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmRemoveProduct() {
			deleteFilteredItem(vm.cnpToDel, $rootScope.orderList);
			$rootScope.updateTotalizers();
			$uibModalInstance.close(true);
			Notification.success({message: $translate.instant('pharmaPortalApp.pharmaTrade.message.productDeleted'), delay: 5000});
		}

		function filterItemFromArray(cnpToDel, sourceArray) {
			vm.product = null;
			angular.forEach(sourceArray, function(obj, index) {
				if (obj.cnp === cnpToDel) {
					vm.product = obj;
					return;
				}
			});
		}

		function deleteFilteredItem(cnpToDel, sourceArray) {
			angular.forEach(sourceArray, function(obj, index) {
				if (obj.cnp === cnpToDel) {
					sourceArray.splice(index, 1);
					return;
				}
			});
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.tradesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if(account && account.company && account.company.clientType == "F") {
							filterItemFromArray(vm.cnpToDel, $rootScope.orderList);
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();