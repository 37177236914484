(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('InvoicedAndClaimedDialogController', InvoicedAndClaimedDialogController);

    InvoicedAndClaimedDialogController.$inject = ['$uibModalInstance', 'pharmaPicking', '$filter'];

    function InvoicedAndClaimedDialogController($uibModalInstance, pharmaPicking, $filter) {
        var vm = this;

        vm.clear = clear;
        vm.close = close;

        vm.pharmaPicking = angular.copy(pharmaPicking);

        vm.pharmaPicking.pharmaPickingLines = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
            var i = line.invoicedQuantity ? line.invoicedQuantity : 0;
            var r = line.receivedQuantity ? line.receivedQuantity : 0;
            return i - r > 0;
        }, true);

        function clear(flag) {
            $uibModalInstance.dismiss(flag);
        }

        function close(flag) {
            $uibModalInstance.close(flag);
        }

    }
})();
