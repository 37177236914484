(function () {
    'use strict';

    angular.module('pharmaPortalApp')
        .controller('ProductErpIntegrationDialogController', ProductErpIntegrationDialogController);

    ProductErpIntegrationDialogController.$inject = ['Notification', '$state', '$translate', '$uibModalInstance', 'ModuleSettings', 'Upload'];

    function ProductErpIntegrationDialogController(Notification, $state, $translate, $uibModalInstance, ModuleSettings, Upload) {
        var vm = this;
        vm.working = false;
        vm.loadAll = loadAll;
        vm.clear = clear;
        vm.uploadFile = uploadFile;

        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.productsModule) {
                    $state.go('accessdenied');
                } else {
                    vm.productErpIntegration = {};
                }
            });
        }

        function clear() {
            vm.working = false;
            $uibModalInstance.dismiss('cancel');
        }

        function uploadFile() {
            vm.working = true;
            file.upload = Upload.upload({
                url: '/api/product-erp-integration/upload',
                fields: {
                    integrationType: vm.productErpIntegration.integrationType
                },
                file: vm.productErpIntegration.file
            }).then(function (response) {
                if (response.status === 200) {
                    $uibModalInstance.dismiss("close");
                    Notification.success({
                        message: $translate.instant('pharmaPortalApp.productErpIntegration.import.success'),
                        delay: 5000
                    });
                    $state.go("product-erp-integration-detail", {id: response.data.id});
                } else {
                    if(response.headers("X-pharmaPortalApp-hasError") == "true") {
                        Notification.error({
                            message: response.headers("X-pharmaPortalApp-alert"),
                            delay: 5000
                        });
                    } else {
                        Notification.error({
                            message: $translate.instant('pharmaPortalApp.productErpIntegration.import.error'),
                            delay: 5000
                        });
                    }
                }
                vm.working = false;
            }, function (response) {
                if (response.data.success) {
                    Notification.success({
                        message: response.data.message,
                        delay: 5000
                    });
                } else {
                    Notification.error({
                        message: response.data.message,
                        delay: ''
                    });
                }
                vm.working = false;
            });
        }
    }
})();
