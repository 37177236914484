(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingShowHistoryController', PharmaPickingShowHistoryController);

    PharmaPickingShowHistoryController.$inject = ['$translate', 'blockUI', 'Notification', '$scope', '$state', '$http', '$filter', 'PharmaPicking', 'ParseLinks', 'paginationConstants', 'pagingParams',
        'ModuleSettings', 'Principal', '$uibModal', '$localStorage'];

    function PharmaPickingShowHistoryController($translate, blockUI, Notification, $scope, $state, $http, $filter, PharmaPicking, ParseLinks, paginationConstants, pagingParams, ModuleSettings, Principal, $uibModal, $localStorage) {
        var vm = this;

        var date = new Date();

        vm.tableFilters = {
            company: {label: '&nbsp;', value: ''},
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            product: ''
        };

        vm.saveSearch = undefined;
        vm.saveSearchFunction = function (product) {
            vm.saveSearch.description = product.productDescription;
            vm.saveSearch.company = {
                value: product.pharmacyWarehouse, label: product.pharmacyWarehouse + " - " + product.pharmacyName
            };
            return vm.saveSearch;
        };
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.getWarehouseFullDescription = getWarehouseFullDescription;
        vm.getSupplierFullDescription = getSupplierFullDescription;
        vm.clearFilter = clearFilter;

        $scope.showSearch = false;

        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            Principal.identity().then(function (account) {
                if (account) {
                    vm.user = account.login;
                    vm.userData = account;
                    if (vm.userData.company && vm.userData.company.pharmaWarehouse != null) {
                        vm.tableFilters.company.value = vm.userData.company.pharmaWarehouse;
                        vm.tableFilters.company.label = vm.userData.company.pharmaWarehouse + ' - ' + vm.userData.company.name1;
                    }
                }
            });
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                } else {
                    PharmaPicking.getProductsForHistory({
                        tableFilters: prepareFilters(),
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function onSuccess(data, headers) {
            vm.saveSearch = angular.copy(vm.tableFilters, vm.saveSearch);
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.pharmaPickingShowHistory = data;
        }

        function onError(error) {
            if (error.status == 401) {
                Notification.error({
                    message: "É necessário estar autenticado!",
                    delay: ''
                });
            } else {
                Notification.error({
                    message: error.data.message,
                    delay: ''
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function getSuppliersFilter() {
            vm.supplierFilterPicklist = [{label: '&nbsp;', value: ''}];
            PharmaPicking.suppliersFilter({}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    vm.supplierFilterPicklist.push({
                        label: data[i].supplierCode + ' - ' + data[i].supplierName,
                        value: data[i].supplierCode
                    });
                }
            }, function (err) {
            });
        }

        getSuppliersFilter();

        function getPharmaciesFilter() {
            vm.pharmaciesFilterPicklist = [{label: '&nbsp;', value: ''}];
            PharmaPicking.pharmaciesFilter({}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    vm.pharmaciesFilterPicklist.push({
                        label: data[i].pharmacyWarehouse + ' - ' + data[i].pharmacyName,
                        value: data[i].pharmacyWarehouse
                    });
                }
            }, function (err) {
            });
        }

        getPharmaciesFilter();

        function filter() {
            PharmaPicking.getProductsForHistory({
                tableFilters: vm.tableFilters,
                page: pagingParams.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

        }

        function clearFilter() {
            vm.tableFilters = {
                company: {label: '&nbsp;', value: ''},
                year: null,
                month: null,
                product: ''
            };
            transition();
        }

        vm.sort = function () {
            transition();
        };

        function transition() {
            PharmaPicking.getProductsForHistory({
                tableFilters: prepareFilters(),
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

        }

        function getWarehouseFullDescription(warehouseCode) {
            if (angular.isDefined(vm.warehouses)) {
                var warehouse = $filter('filter')(vm.warehouses, {
                    code: warehouseCode
                })[0];
                return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
            } else {
                return warehouseCode;
            }
        }

        function getSupplierFullDescription(supplierCode) {
            if (angular.isDefined(vm.suppliers)) {
                var supplier = $filter('filter')(vm.suppliers, {
                    code: supplierCode
                })[0];
                return supplierCode + (supplier != null ? ' - ' + supplier.description : '');
            } else {
                return supplierCode;
            }
        }

        //Filters...
        function prepareFilters() {
            var filters = {};
            filters.company = textOrNull(vm.tableFilters.company.value);
            filters.year = textOrNull(vm.tableFilters.year);
            filters.month = textOrNull(vm.tableFilters.month);
            filters.product = textOrNull(vm.tableFilters.product);
            // filters.pharmacy = textOrNull(vm.tableFilters.pharmacy.value);
            return filters;
        }

        function textOrNull(field) {
            return field != '' ? field : null;
        }


        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
    }
})();
