(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingDefineWarehouseController', PharmaPickingDefineWarehouseController);

    PharmaPickingDefineWarehouseController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', '$translate', '$state', '$document', '$scope', '$http'];

	function PharmaPickingDefineWarehouseController(Notification, blockUI, $uibModalInstance, $translate, $state, $document, $scope, $http) {
		var vm = this;

		vm.clear = clear;

        vm.warehouseSelected = {};
        vm.warehouseSales = {};

		function clear(flag) {
			$uibModalInstance.dismiss(flag);
		}

		function loadAll(){
            $http.get("api/common/_searchWarehousesForSelectPharma").then(function (result) {
                $scope.warehouses = result.data;
            });
        }

        loadAll();

       vm.submit = function() {
            if (angular.isDefined(vm.warehouseSales)) {
                vm.warehouseSelected.warehouseSalesCode = vm.warehouseSales.code;
                vm.warehouseSelected.warehouseSalesDesc = vm.warehouseSales.description;
            }
           clear(vm.warehouseSales);
        }

	}
})();
