(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('PsychotropicsRequisitions', PsychotropicsRequisitions);

    PsychotropicsRequisitions.$inject = ['$resource'];

    function PsychotropicsRequisitions($resource) {
        var resourceUrl = 'api/psychotropics-requisitions';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'queryAllFilters': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/all-filters',
                isArray: true
            },
            'queryWithFilters': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/filters',
                isArray: true
            },
            'queryAllYearsToTree': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/data-tree/years',
                isArray: true
            },
            'queryMonthAndYearToTree': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/data-tree-new/initial-data',
                isArray: true
            },
            'queryAllEntitiesToTree': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/data-tree/entities',
                isArray: true
            },
            'getAllTypesToTree': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/data-tree/types',
                isArray: true
            },
            'getAllTablesToTree': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/data-tree/tables',
                isArray: true
            },
            'queryAllMonthsToTree': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/data-tree/months',
                isArray: true
            },
            'availableRequisition': {
                method: 'PUT',
                url: 'api/psychotropics-requisitions/available'
            },
            'validateRequisition': {
                method: 'PUT',
                url: 'api/psychotropics-requisitions/validate'

            },
            'availableListRequisitions': {
                method: 'PUT'
            },
            'validateListRequisitions': {
                method: 'PUT',
                url: 'api/psychotropics-requisitions/list/validate'
            },
            'cancelRequisition': {
                method: 'PUT',
                url: 'api/psychotropics-requisitions/cancel'
            }
            ,
            'deleteRequisition': {
                method: 'DELETE',
                url: 'api/psychotropics-requisitions/delete'
            },
            'deleteAllRequisition': {
                method: 'POST',
                url: 'api/psychotropics-requisitions/delete/list'
            },
            'getAllFiltersData': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/all-entities'
            },
            'getAllRequisitionLines': {
                method: 'GET',
                isArray: true,
                url: 'api/psychotropics-requisitions/lines'
            },
            'getAllFilterLines': {
                method: 'GET',
                url: 'api/psychotropics-requisitions/lines/filters'
            }
        });
    }
})();
