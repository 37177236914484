(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ReportGroupDetailController',ReportGroupDetailController);

    ReportGroupDetailController.$inject = ['$scope','$rootScope','$stateParams','previousState','entity', 'ReportGroup', 'ModuleSettings','$state'];

    function ReportGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, ReportGroup, ModuleSettings,$state) {
        var vm = this;

        vm.reportGroup = entity;
        vm.previousState = previousState.name;

        vm.loadAll = loadAll;

        var unsubscribe = $rootScope.$on('pharmaPortalApp:reportGroupUpdate',function (event,result) {
            vm.reportGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function(result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.queriesModule) {
                    $state.go('accessdenied');
                }
            });
        }
        loadAll();
    }
})();
