(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaReceptionLineNewController', PharmaReceptionLineNewController);

	PharmaReceptionLineNewController.$inject = [ 'Notification', '$uibModalInstance', '$scope', '$state', '$timeout', '$http', 'pharmaReception', 'PharmaReceptionLine', 'PortalSettings', 'PharmaReceptionServiceList' ];

	function PharmaReceptionLineNewController(Notification, $uibModalInstance, $scope, $state, $timeout, $http, pharmaReception, PharmaReceptionLine, PortalSettings, PharmaReceptionServiceList) {
		var vm = this;
		
		vm.pharmaReception = pharmaReception;
		vm.portalSettings = PortalSettings.query();
		vm.clear = clear;
		vm.createPharmaReceptionLine = createPharmaReceptionLine;
		vm.createPharmaReceptionServiceLine = createPharmaReceptionServiceLine;
		vm.loadAll = loadAll;
		
		vm.isSaving = false;
		
		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			vm.pharmaReceptionLine = {};

			$scope.dialogForm.$setPristine();
			Notification.success({message: "Linha adicionada com sucesso", delay: 5000});
		    
			$state.go("pharma-reception-detail", {
				id : pharmaReception.id
			}, {
				reload : true
			});
			// $uibModalInstance.close(true);
		}

		function onSaveError(result) {
			vm.isSaving = false;
			
			if (result.data.success){
				Notification.success({message: result.data.message, delay: 5000});
			} else {
				Notification.error({message: result.data.message, delay: ''});
			}
		}

		function createPharmaReceptionLine() {
			vm.activeTab = 0;
			vm.isSaving = true;
			vm.pharmaReceptionLine.pharmaReceptionHeader = pharmaReception;
			vm.pharmaReceptionLine.cnp = vm.pharmaReceptionLine.product.cnp;
			vm.pharmaReceptionLine.description = vm.pharmaReceptionLine.product.fullName;
			vm.pharmaReceptionLine.vatRate = vm.pharmaReceptionLine.product.txiva;
			if (!angular.isDefined(vm.pharmaReceptionLine.invoicedQuantity)) {
				vm.pharmaReceptionLine.invoicedQuantity = vm.pharmaReceptionLine.receivedQuantity - (angular.isDefined(vm.pharmaReceptionLine.bonusQuantity) ? vm.pharmaReceptionLine.bonusQuantity : 0);
			}
			PharmaReceptionLine.save(vm.pharmaReceptionLine, onSaveSuccess, onSaveError);
		}
		
		function createPharmaReceptionServiceLine() {
			vm.activeTab = 1;
			vm.isSaving = true;
			vm.pharmaReceptionLine.pharmaReceptionHeader = pharmaReception;
			vm.pharmaReceptionLine.cnp = vm.pharmaReceptionLine.serviceType.code;
			vm.pharmaReceptionLine.description = vm.pharmaReceptionLine.serviceType.description;
			vm.pharmaReceptionLine.vatRate = vm.pharmaReceptionLine.serviceType.vatRate;
			vm.pharmaReceptionLine.invoicedQuantity = 0;
			vm.pharmaReceptionLine.receivedQuantity = 0;
			vm.pharmaReceptionLine.unitPrice = vm.pharmaReceptionLine.lineNetPrice;
			vm.pharmaReceptionLine.lot = vm.pharmaReceptionLine.serviceType.vatCode;
			vm.pharmaReceptionLine.serviceLine = true;
			PharmaReceptionLine.save(vm.pharmaReceptionLine, onSaveSuccess, onSaveError);
		}

		function loadAll() {
			$timeout( function(){
				if(pharmaReception.status === 'SUBMITTED') {
					vm.activeTab = 1;
				}

				$http.get("api/common/_searchQualityStatusesForSelect").then(function(result) {
					vm.qualityStatuses = [ { "code" : '', "description" : '' } ].concat(result.data);
				});
			
				if(vm.portalSettings.filterServicesToShow) {
					vm.serviceTypes = PharmaReceptionServiceList.query();
				} else {
					$http.get("api/common/_getServicesForSelect").then(function(result) {
						vm.serviceTypes = result.data;
					});
				}
			}, 500 );
		}
		loadAll();

		$scope.onOpenClose = function(isOpen) {
			angular.element('.ui-select-choices-row').removeClass('active');
		}

		$scope.refreshSelectModel = function(query) {
			$http.get("api/common/_searchProductsForSelect/" + query).then(function(result) {
				if (result.data.length > 500) {
					Notification.warning({message: "O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa", delay: 5000});
					return;
				} else {
					vm.products = result.data;
				}
			});
		};
	}
})();
