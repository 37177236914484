(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CompanyController', CompanyController);

	CompanyController.$inject = [ 'Notification', '$state', 'Company', 'ParseLinks', 'paginationConstants', 'pagingParams', '$http', '$translate' ];

	function CompanyController(Notification, $state, Company, ParseLinks, paginationConstants, pagingParams, $http, $translate) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.synch = synch;

		vm.clear = clear;
		vm.search = search;
		vm.loadAll = loadAll;
		vm.searchQuery = pagingParams.search;
		vm.currentSearch = pagingParams.search;

		loadAll();

		function loadAll() {
			Company.query({
				query : vm.searchQuery ? vm.searchQuery : "",
				page : pagingParams.page - 1,
				size : vm.itemsPerPage
			}, onSuccess, onError);
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.companies = data;
				angular.forEach(vm.companies, function (item) {
                    item.client = parseInt(item.client, 10);
                    if(item.pharmaWarehouse) {
                        item.pharmaWarehouse = parseInt(item.pharmaWarehouse, 10);
                    }
                    if(item.warehouse) {
                        item.warehouse = parseInt(item.warehouse, 10);
                    }
                });
				vm.page = pagingParams.page;
				vm.searchQuery = pagingParams.search;
			}
			function onError(error) {
				Notification.error({
					message : error.data.message,
					delay : ''
				});
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				search : vm.currentSearch
			});
		}

		function search() {
			if (!vm.searchQuery) {
				return vm.clear();
			}
			vm.links = null;
			vm.page = 1;
			vm.currentSearch = vm.searchQuery;
			vm.searchQuery = vm.searchQuery;
			vm.transition();
		}

		function clear() {
			vm.links = null;
			vm.page = 1;
			vm.currentSearch = null;
			vm.transition();
		}

		function synch() {
			$http.get('api/companies/synch').then(function(result) {
				if (result.status === 200) {
					Notification.success({ message : $translate.instant(result.headers("X-pharmaPortalApp-alert")), delay : 5000 });
				} else {
					Notification.error({ message : $translate.instant(result.headers("X-pharmaPortalApp-alert")), delay : '' });
				}
				vm.clear();
				loadAll();
			});
		}
	}
})();
