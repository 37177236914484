(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('HomeIOController', HomeIOController).value('duScrollOffset', 50);

	HomeIOController.$inject = [ 'Notification', '$scope', '$http', '$interval', '$animate' ];

	function HomeIOController(Notification, $scope, $http, $interval, $animate) {
		var vm = this;

		// FIRST SECTION ============================================================================================================
		$interval(function() {
			var domSection = document.getElementById("first_section");
			angular.element(domSection).hasClass("background2") ? angular.element(domSection).removeClass('background2') : angular.element(domSection).addClass('background2');
		}, 7000);

		$scope.showLabel = function($event) {
			$animate.addClass(angular.element($event.currentTarget).children('.popin'), 'animated');
		}

		$scope.hideLabel = function($event) {
			$animate.removeClass(angular.element($event.currentTarget).children('.popin'), 'animated');
		}

		// ABOUT US SECTION =========================================================================================================
		vm.slides = []
		vm.slides = [
 			{
 				"image" : "content/images/slider/farmacias.jpg",
 				"text" : "Distribuição por Farmácias",
 				"active" : "true"
 			}, {
 				"image" : "content/images/slider/boas-praticas.jpg",
 				"text" : "Cumprimento de boas práticas na distribuição de produtos farmacêuticos",
 				"active" : "false"
 			}, {
 				"image" : "content/images/slider/distribuidores-na-europa.jpg",
 				"text" : "Distribuidores na Europa",
 				"active" : "false"
 			}, {
 				"image" : "content/images/slider/distribuidores-em-africa.jpg",
 				"text" : "Distribuidores em África",
 				"active" : "false"
 			}, {
                "image" : "content/images/slider/iso_9001.jpg",
                "text" : "Empresa certificada pela Norma NP EN ISO 9001",
                "active" : "false"
            }, {
                "image" : "content/images/slider/pme_2021.jpg",
                "text" : "Empresa com estatuto PME líder 2021",
                "active" : "false"
            }
 		];
		// PARTNER SECTION ==========================================================================================================
		// CONTACT SECTION ==========================================================================================================
		$scope.sendEmail = function () {
			if ($scope.user && $scope.user.name && $scope.user.email && $scope.user.subjectMessage && $scope.user.message){
				$http.post('api/portal/sendContact', $scope.user).then(function(result) {
					Notification.success({message: "O seu formulário foi enviado com sucesso, obrigado", delay: 5000});
				}, function(result) {
					Notification.error({message: "Ocorreu um erro ao enviar o seu formulário. Por favor, tente novamente", delay: ''});
				});
			} else {
				Notification.warning({message: "O seu formulário tem erros de preenchimento. Por favor, tente novamente", delay: 5000});
			}
		}
	}
})();
