(function () {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .controller('PharmaFaultReportLineHistoryController', PharmaFaultReportLineHistoryController);

    PharmaFaultReportLineHistoryController.$inject = ['$uibModalInstance', 'entity'];

    function PharmaFaultReportLineHistoryController($uibModalInstance, entity) {
        var vm = this;

        vm.lineErrors = entity.pharmaRepLineErrors;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
