(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CampaignProductDeleteDialogController', CampaignProductDeleteDialogController);

	CampaignProductDeleteDialogController.$inject = ['Notification', '$uibModalInstance', 'entity', 'CampaignProduct', '$translate', 'ModuleSettings' ];

	function CampaignProductDeleteDialogController(Notification, $uibModalInstance, entity, CampaignProduct, $translate, ModuleSettings) {
		var vm = this;

		vm.campaignProduct = entity;

		vm.clear = clear;
		vm.confirmDelete = confirmDelete;
		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;
				
				if (!vm.moduleSettings.campaignsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
		
		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function onDeleteSuccess(result) {
			Notification.success({message: $translate.instant('pharmaPortalApp.campaignProduct.deleted'), delay: 5000});
			$uibModalInstance.close(true);
		}

		function onDeleteError(result) {
			Notification.error({message: result.data.message, delay: ''});
			$uibModalInstance.close(true);
		}

		function confirmDelete(id) {
			CampaignProduct.delete({id: vm.campaignProduct.id}, onDeleteSuccess, onDeleteError);
		}
	}
})();