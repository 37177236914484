(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CampaignProductClearListDialogController', CampaignProductClearListDialogController);

	CampaignProductClearListDialogController.$inject = [ 'Notification', '$uibModalInstance', 'entity', 'Campaign', '$translate', '$state', 'ModuleSettings' ];

	function CampaignProductClearListDialogController(Notification, $uibModalInstance, entity, Campaign, $translate, $state, ModuleSettings) {
		var vm = this;

		vm.campaign = entity;

		vm.clear = clear;
		vm.confirmDelete = confirmDelete;
		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;
				
				if (!vm.moduleSettings.campaignsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmDelete(id) {
			vm.campaign.products = [];
			Campaign.update(vm.campaign, onSaveSuccess, onSaveError);
		}

		function onSaveSuccess(result) {
			Notification.success({
				message : $translate.instant("pharmaPortalApp.campaignProduct.deleteAll.message.success"),
				delay : 5000
			});
			$uibModalInstance.close('true');
		}

		function onSaveError() {
			Notification.error({
				message : $translate.instant("pharmaPortalApp.campaignProduct.deleteAll.message.error"),
				delay : ''
			});
		}
	}
})();