(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ReportGroupDeleteController', ReportGroupDeleteController);

    ReportGroupDeleteController.$inject=['$uibModalInstance','entity','ReportGroup','$timeout','$translate', 'Report'];

    function ReportGroupDeleteController($uibModalInstance,entity,ReportGroup,$timeout,$translate, Report) {
        var vm = this;

        vm.reportGroup = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.parentUsed = [];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            ReportGroup.isUsed({"id": id}, function (result) {
                vm.parentUsed = result;
                if (vm.parentUsed.length===0){
                    ReportGroup.delete({id: id},function () {
                        $uibModalInstance.close(true);
                    });
                }else{
                    ReportGroup.delete({id: id},function () {
                        $uibModalInstance.close(true);
                    });
                }
            }, function (error) {
            });
        }
    }
})();
