(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-supplier-complaint', {
			parent : 'entity',
			url : '/pharma-supplier-complaint/:id',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaPicking.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-supplier-complaint/pharma-supplier-complaint.html',
					controller : 'PharmaSupplierComplaintController',
					controllerAs : 'vm'
				}
			},
			params : {
                pharmaPicking: null
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams) {
				    if($stateParams.pharmaPicking){
				        return {pharmaPicking: $stateParams.pharmaPicking};
                    }else{
				        return null;
                    }
				} ],
                entity : [ '$stateParams', 'PharmaPicking', function($stateParams, PharmaPicking) {
                    if($stateParams.id){
                        return PharmaPicking.get({
                            id : $stateParams.id
                        }).$promise;
                    }else{
                        return null;
                    }
                } ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaSupplierComplaint');
					$translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('pharmaPickingImportFile');
                    $translatePartialLoader.addPart('pharmaPicking');
					return $translate.refresh();
				} ]
			}
		})
	}
})();
