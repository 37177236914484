(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionSubmitController', PharmaTransfReceptionSubmitController);

	PharmaTransfReceptionSubmitController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'entity', 'PharmaTransfReception', 'PortalSettings' ];

	function PharmaTransfReceptionSubmitController(Notification, blockUI, $uibModalInstance, $translate, $state, $http, $filter, entity, PharmaTransfReception, PortalSettings) {
		var vm = this;

		vm.portalSettings = PortalSettings.query();
		
		vm.loadAll = loadAll;
		vm.getWarehouseFullDescription = getWarehouseFullDescription;
		vm.getTransferTypeFullDescription = getTransferTypeFullDescription;
		
		vm.clear = clear;
		vm.confirmPharmaTransfReceptionSubmit = confirmPharmaTransfReceptionSubmit;
		vm.hasDiffs = false;
		vm.pharmaTransfReception = entity;

		function loadAll() {
			$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
				vm.warehouses = result.data;
			});
			$http.get("api/common/_searchQualityStatusesForSelect").then(function(result) {
				vm.qualityStatuses = [ {
					"code" : '',
					"description" : ''
				} ].concat(result.data);
			});
			$http.get("api/common/_getTransferTypeDescription").then(function(result) {
				vm.transferTypes = result.data;
			});

			$http.get("api/pharma-transf-reception/" + entity.id + "/getLinesDiffs").then(function(result) {
				vm.pharmaTransfReceptionLines = [];
				angular.forEach(result.data, function(value, key) {
					if (vm.hasDiffs == false) {
						vm.hasDiffs = value.quantitydifferences != 0;
					}
					if(value.quantitydifferences != 0){
						vm.pharmaTransfReceptionLines.push(value);
					}
				});
			});
		}
		vm.loadAll();

		function getWarehouseFullDescription(warehouseCode) {
			if (angular.isDefined(vm.warehouses) && warehouseCode != '') {
				var warehouse = $filter('filter')(vm.warehouses, {
					code : warehouseCode
				})[0];
				return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
			} else {
				return warehouseCode;
			}
		}

		function getTransferTypeFullDescription(transferTypeCode) {
			if (angular.isDefined(vm.transferTypes) && transferTypeCode != '') {
				var transferType = $filter('filter')(vm.transferTypes, {
					code : transferTypeCode
				})[0];
				return transferTypeCode + (transferType != null ? ' - ' + transferType.description : '');
			} else {
				return transferTypeCode;
			}
		}
		
		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onSubmitSuccess(result) {
			$uibModalInstance.close(true);
			blockUI.stop();
			if (result.success){
				Notification.success({message: result.message, delay: 5000});
				$state.go("pharma-transf-reception", {}, {
					reload : true
				});
			} else {
				Notification.error({message: result.message, delay: ''});
				$state.go("pharma-transf-reception-edit", {
					id : vm.pharmaTransfReception.id
				}, {
					reload : true
				});
			}
		}

		function onSubmitError(result) {
			$uibModalInstance.close(true);
			blockUI.stop();
			
			if(result.success) {
				Notification.success({message: result.message, delay: 5000});
			} else {
				Notification.error({message: result.message, delay: ''});
			}
		}

		function confirmPharmaTransfReceptionSubmit() {
			blockUI.start($translate.instant('pharmaPortalApp.pharmaTransfReception.message.submittingPharmaTransfReception'));
			PharmaTransfReception.submit({
				id : vm.pharmaTransfReception.id
			}, onSubmitSuccess, onSubmitError);
		}

	}
})();
