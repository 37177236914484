(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CompanyDetailController', CompanyDetailController);

	CompanyDetailController.$inject = [ 'previousState', 'entity', 'Company' ];

	function CompanyDetailController(previousState, entity, Company) {
		var vm = this;

        vm.schedule = {};
		vm.company = entity;
		vm.previousState = previousState.name;
		vm.param = {
		    companyId:vm.company.id
        };

        Company.getCompanySchedule(vm.param, function (result) {
            vm.schedule=result;
        }, function (error) {
        });
	}
})();
