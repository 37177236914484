(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'PortalSettings', 'Notification', '$http', '$rootScope', '$timeout'];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, PortalSettings, Notification, $http, $rootScope, $timeout) {
        var vm = this;
        vm.settings = PortalSettings.query();

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.isFullyAuthenticated = false;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.loadAll = loadAll;
        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.nameInitials = null;

        vm.$state = $state;

        // refresh again in 60 seconds
        $timeout(function(){
            getUnreadNotifications();
        },60000);

        $rootScope.$on('readOrUnreadEvent', function (event) {
            getUnreadNotifications();
        });

        function getUnreadNotifications() {
            $http.get('api/portalNotifications/unreadNotifications').then(function (result) {
                if (result.status === 200) {
                    vm.numberOfNotifications = result.data;
                } else {
                    Notification.error({message: $translate.instant(result.headers("X-pharmaPortalApp-alert")), delay: ''});
                }
            });
        }

        $rootScope.$on('updateNavbar', function (event) {
            Principal.identity(true).then(function (account) {
                vm.loggedUser = account;
            });
        });

        function loadAll() {
            Principal.identity().then(function (account) {
                vm.loggedUser = account;

                var name = vm.loggedUser.firstName + ' ' + vm.loggedUser.lastName;
                var initials = name.match(/\b\w/g) || [];
                initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                vm.nameInitials = initials;

                if (account.authorities.length > 0) {
                    getUnreadNotifications();
                    vm.isFullyAuthenticated = true;
                } else {
                    vm.isFullyAuthenticated = false;
                }
            });
        }

        loadAll();

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            vm.isFullyAuthenticated = false;
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
