(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaDashboardController', PharmaDashboardController);

    PharmaDashboardController.$inject = ['$scope', 'Notification', '$state', 'Company', 'Campaign', 'PharmaDashboard', 'ModuleSettings', '$translate', '$http', '$timeout', '$filter', 'Principal', '$uibModal', '$interval', '$q'];

    function PharmaDashboardController($scope, Notification, $state, Company, Campaign, PharmaDashboard, ModuleSettings, $translate, $http, $timeout, $filter, Principal, $uibModal, $interval, $q) {
        var vm = this;
        vm.campaigns = [];
        vm.companies = Company.findByPharmacyClientAndSelloutNickname();
        PharmaDashboard.getCampaignTypes(function (result) {
            vm.campaignTypes = result;
        }, function (error) {
        });

        vm.filterDashboard = filterDashboard;
        vm.clearFilter = clearFilter;
        vm.changeFilter = changeFilter;

        vm.filteredCampaigns = [];
        vm.filteredPharmacies = [];
        vm.quantityTableUser = null;
        vm.valueTableUser = null;
        vm.valueUsers = [];
        vm.quantityUsers = [];
        vm.salesmanValueNames = [];
        vm.salesmanQuantityNames = [];
        vm.allUsers = [];
        vm.showFilter = false;
        vm.popup = "";
        vm.popupSalesman = [];
        vm.fullFilterRequest = {};

        vm.isSM = false;
        vm.isMD = false;

        $scope.loadCampaigns = function () {
            var x = 0;
            vm.filteredCampaigns = [];

            var periodForCamp = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null
            };

            Campaign.activeByDashboardPeriod(periodForCamp, function (result) {
                vm.campaigns = result;
                PharmaDashboard.getCampaignsByPeriod(periodForCamp, function (result2) {
                    var loopPromises = [];
                    angular.forEach(result2, function (x, y) {
                        var exists = false;
                        for (var i = 0; i < vm.campaigns.length; i++) {
                            if (vm.campaigns[i].id === x) {
                                exists = true;
                            }
                        }
                        if (!exists) {
                            var updateCampaign = Campaign.getById({id: x}).$promise;
                            loopPromises.push(updateCampaign);
                            updateCampaign.then(function (result) {
                                if (result[0] != null) {
                                    vm.campaigns.push(result[0]);
                                }
                            });
                        }
                    });
                    $q.all(loopPromises).then(function () {
                        if (vm.filter.campaignType == null) {
                            vm.filter.campaign = null;
                        }
                        for (var i = 0; i < vm.campaigns.length; i++) {
                            if (vm.campaigns[i].campaignType === vm.filter.campaignType) {
                                vm.filteredCampaigns[x] = vm.campaigns[i];
                                x++;
                            }
                        }
                    });
                });

            }, function (error) {
            });

        };

        function loadUsers() {
            PharmaDashboard.getPortalUsers(function (result) {
                vm.allUsers = result;
            }, function (error) {
            });
        }

        loadUsers();

        $scope.loadPharmacies = function () {
            vm.filteredPharmacies = [];

            var filterCampaignPharmacies = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null
            };

            PharmaDashboard.queryPharmacyByCampaignOrderByValue(filterCampaignPharmacies, function (resultP) {
                vm.filteredPharmacies = resultP;
            }, function (error) {
            });

        };

        vm.filter = [{
            period: null,
            periodType: null,
            campaignType: null,
            campaign: null,
            company: null,
            salesman: null
        }];

        function clearFilter() {
            vm.filter = [{
                period: null,
                periodType: null,
                campaignType: null,
                campaign: null,
                company: null,
                salesman: null
            }];
            vm.salesman = {};
            vm.lastSalesman = {};
            vm.pharmacy = {};
            vm.lastPharmacy = {};
            vm.salesmanValue = [];
            vm.selloutTop = [];
            vm.salesmanQuantity = [];
            vm.topPharmacies = [];
            loadAll();
        }

        function queryPeriod() {
            PharmaDashboard.getPeriodFromInd(function (result) {
                vm.periods = result;
                if (vm.filter.period == null) {
                    vm.filter.period = result[1];
                }
                queryPeriodType();
            }, function (error) {
            });
        }

        function queryPeriodType() {
            PharmaDashboard.getPeriodTypeFromInd(function (result2) {
                vm.periodTypes = result2;
                if (vm.filter.periodType == null) {
                    vm.filter.periodType = result2[0];
                }
            }, function (error) {
            });
        }

        function loadAll() {
            vm.screenWidth = window.innerWidth;
            vm.isSM = false;
            vm.isMD = false;

            if (vm.screenWidth < 1100) {
                vm.isSM = true;
            } else if (vm.screenWidth < 1400) {
                vm.isMD = true;
            }

            Principal.identity().then(function (account) {
                vm.loggedUser = account;
                vm.filter.company = vm.loggedUser.company;
                vm.isFullyAuthenticated = account.authorities.length > 0; // TRUE
                vm.isAuthorized = false;
                for (var i = 0; i < account.authorities.length; i++) {
                    if (account.authorities[i] == "ROLE_PHARMA_ADMIN") {
                        vm.isAuthorized = true;
                    }
                }
            });
            queryPeriod();

            $timeout(function () {
                vm.filteredSalesman = [];
                vm.filter.salesman = null;

                var filterPharmacySalesman = {
                    year: vm.filter.period != null ? vm.filter.period.year : null,
                    month: vm.filter.period != null ? vm.filter.period.month : null,
                    periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                    pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                };

                PharmaDashboard.queryPharmacySalesman(filterPharmacySalesman, function (result) {
                    vm.filteredSalesman = result;
                }, function (error) {
                });
            }, 1000);

            $timeout(function () {
                filterDashboard()
            }, 1000);
        }

        loadAll();

        $scope.loadSalesman = function () {
            vm.salesman = {};
            vm.lastSalesman = {};
            vm.pharmacy = {};
            vm.lastPharmacy = {};
            vm.filter.salesman = null;

            var filterPharmacySalesman = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
            };

            PharmaDashboard.queryPharmacySalesman(filterPharmacySalesman, function (result) {
                vm.filteredSalesman = result;
            }, function (error) {
            });
        };

        function changeFilter() {
            vm.showFilter = !vm.showFilter;
        }

        function filterDashboard() {
            vm.salesman = {};
            vm.lastSalesman = {};
            vm.pharmacy = {};
            vm.lastPharmacy = {};
            vm.salesmanValue = [];
            vm.selloutTop = [];
            vm.salesmanQuantity = [];
            vm.topPharmacies = [];
            if (vm.filter.company == null && vm.filter.period == null && vm.filter.campaign == null && vm.filter.periodType == null && vm.filter.salesman == null) {
                return vm.clearFilter();
            }

            vm.fullFilterRequest = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
            };
            var filterRequestNoCampaign = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
            };
            var filterRequestNoSalesman = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
            };
            var filterRequestNoSalesmanNoPharmacy = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null
            };
            var filterRequestCampaignTypeNoPharmacy = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null
            };
            var filterRequestCampaignTypeAndPharmacy = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
            };
            var filterRequestCampaignTypeAndPharmacyAndSalesman = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
            };
            var filterRequestNoSalesmanNoCampaign = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
            };
            var filterRequestNoSalesmanNoCampaignNoPharmacy = {
                year: vm.filter.period != null ? vm.filter.period.year : null,
                month: vm.filter.period != null ? vm.filter.period.month : null,
                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null
            };
            if (vm.filter.company == null) {
                PharmaDashboard.querySelloutPharmacySalesman(filterRequestNoSalesmanNoCampaignNoPharmacy, function (result) {
                    vm.selloutTop = result;
                }, function (error) {
                });
            } else {
                PharmaDashboard.querySelloutPharmacySalesmanAndPharmacy(filterRequestNoSalesmanNoCampaign, function (result) {
                    vm.selloutTop = result;
                }, function (error) {
                });
            }

            if (vm.filter.campaign != null && vm.filter.company != null) {
                PharmaDashboard
                    .queryTopSalesmanByPharmacyAndCampaignOrderByValueList(filterRequestNoSalesman, function (resultP) {
                        vm.salesmanValue = resultP;
                    }, function (error) {
                    });

                PharmaDashboard
                    .queryTopSalesmanByPharmacyAndCampaignOrderByQuantityList(filterRequestNoSalesman, function (resultP) {
                        vm.salesmanQuantity = resultP;
                    }, function (error) {
                    });

            } else if (vm.filter.campaign != null && vm.filter.company == null) {
                PharmaDashboard.queryTopSalesmanByCampaignOrderByValueList(filterRequestNoSalesmanNoPharmacy, function (resultP) {
                    vm.salesmanValue = resultP;
                }, function (error) {
                });

                PharmaDashboard.queryTopSalesmanByCampaignOrderByQuantityList(filterRequestNoSalesmanNoPharmacy, function (resultP) {
                    vm.salesmanQuantity = resultP;
                }, function (error) {
                });

            } else if (vm.filter.campaign == null && vm.filter.company != null) {
                if (vm.filter.campaignType != null) {
                    PharmaDashboard.queryTopSalesmanByCampaignTypeAndPharmacyOrderByQuantityList(filterRequestCampaignTypeAndPharmacy, function (result) {
                        vm.salesmanQuantity = result;
                    }, function (error) {
                    });

                    PharmaDashboard.queryTopSalesmanByCampaignTypeAndPharmacyOrderByValueList(filterRequestCampaignTypeAndPharmacy, function (result) {
                        vm.salesmanValue = result;
                    }, function (error) {
                    });
                } else {
                    filterRequestCampaignTypeAndPharmacy.campaignType = "CHALLENGE";
                    PharmaDashboard.queryTopSalesmanByCampaignTypeAndPharmacyOrderByValueList(filterRequestCampaignTypeAndPharmacy, function (resultP) {
                        vm.salesmanValue = resultP;
                    }, function (error) {
                    });
                    filterRequestCampaignTypeAndPharmacy.campaignType = "PROMOTION";
                    PharmaDashboard.queryTopSalesmanByCampaignTypeAndPharmacyOrderByQuantityList(filterRequestCampaignTypeAndPharmacy, function (resultP) {
                        vm.salesmanQuantity = resultP;
                    }, function (error) {
                    });
                    filterRequestCampaignTypeAndPharmacy.campaignType = vm.filter.campaignType != null ? vm.filter.campaignType : null;
                }

            } else {
                if (vm.filter.campaignType != null) {
                    PharmaDashboard.queryTopSalesmanByCampaignTypeOrderByValueList(filterRequestCampaignTypeNoPharmacy, function (result) {
                        vm.salesmanValue = result;
                    }, function (error) {
                    });

                    PharmaDashboard.queryTopSalesmanByCampaignTypeOrderByQuantityList(filterRequestCampaignTypeNoPharmacy, function (result) {
                        vm.salesmanQuantity = result;
                    }, function (error) {
                    });
                } else {
                    filterRequestCampaignTypeNoPharmacy.campaignType = "CHALLENGE";
                    PharmaDashboard.queryTopSalesmanByCampaignTypeOrderByValueList(filterRequestCampaignTypeNoPharmacy, function (resultP) {
                        vm.salesmanValue = resultP;
                    }, function (error) {
                    });

                    filterRequestCampaignTypeNoPharmacy.campaignType = "PROMOTION";
                    PharmaDashboard.queryTopSalesmanByCampaignTypeOrderByQuantityList(filterRequestCampaignTypeNoPharmacy, function (resultP) {
                        vm.salesmanQuantity = resultP;
                    }, function (error) {
                    });

                    filterRequestCampaignTypeNoPharmacy.campaignType = vm.filter.campaignType != null ? vm.filter.campaignType : null;
                }
            }

            if (vm.filter.campaign != null) {
                PharmaDashboard.queryPharmacyByCampaignOrderByValue(filterRequestNoSalesmanNoPharmacy, function (resultP) {
                    vm.topPharmacies = resultP;
                    vm.maxPharmValue = resultP[0].meanValue;
                }, function (error) {
                });
            } else {
                if (vm.filter.campaignType != null) {
                    PharmaDashboard.queryPharmacyByCampaignTypeOrderByValue(filterRequestCampaignTypeNoPharmacy, function (result) {
                        vm.topPharmacies = result;
                        vm.maxPharmValue = result[0].meanValue;
                    }, function (error) {

                    })
                } else {
                    PharmaDashboard.querySelloutPharmacy(filterRequestNoSalesmanNoCampaignNoPharmacy, function (resultP) {
                        vm.topPharmacies = resultP;
                        vm.maxPharmValue = resultP[0].meanValue;
                    }, function (error) {
                    });
                }
            }

            if (vm.filter.campaign != null && vm.filter.company != null && vm.filter.salesman != null) {
                PharmaDashboard.querySalesmanByPharmacyAndCampaign(vm.fullFilterRequest, function (result) {
                    vm.salesman = result[0];
                    if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                        vm.lastPeriodMonth = vm.filter.period.month;
                        vm.lastPeriodType = "FORTNIGHT1";

                        var lastPeriodFullFilter = {
                            year: vm.filter.period != null ? vm.filter.period.year : null,
                            month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                            periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                            campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                            campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                            pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                            salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                        };
                    } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                        if (vm.filter.period.month == 1) {
                            vm.lastPeriodMonth = 12;
                            vm.lastYear = vm.filter.period.year - 1;
                        } else {
                            vm.lastPeriodMonth = vm.filter.period.month - 1;
                            vm.lastYear = vm.filter.period.year;
                        }

                        vm.lastPeriodType = "FORTNIGHT2";

                        var lastPeriodFullFilter = {
                            year: vm.lastYear != null ? vm.lastYear : null,
                            month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                            periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                            campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                            campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                            pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                            salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                        };
                    } else {
                        if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                            vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                            vm.lastYear = vm.filter.period.year - 1;
                        } else {
                            vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                            vm.lastYear = vm.filter.period.year;
                        }

                        var lastPeriodFullFilter = {
                            year: vm.lastYear != null ? vm.lastYear : null,
                            month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                            periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                            campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                            campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                            pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                            salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                        };
                    }

                    PharmaDashboard.querySalesmanByPharmacyAndCampaign(lastPeriodFullFilter, function (result) {
                        vm.lastSalesman = result[0];
                    }, function (error) {
                    });
                }, function (error) {
                });
            } else if (vm.filter.campaign == null && vm.filter.company != null && vm.filter.salesman != null) {

                if (vm.filter.campaignType != null) {
                    PharmaDashboard.querySalesmanByPharmacyAndCampaignType(filterRequestCampaignTypeAndPharmacyAndSalesman, function (result) {
                        vm.salesman = result[0];
                        if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                            vm.lastPeriodMonth = vm.filter.period.month;
                            vm.lastPeriodType = "FORTNIGHT1";

                            var lastPeriodfilterRequestCampaignTypeAndPharmacyAndSalesman = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                            };
                        } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                            if (vm.filter.period.month == 1) {
                                vm.lastPeriodMonth = 12;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - 1;
                                vm.lastYear = vm.filter.period.year;
                            }

                            vm.lastPeriodType = "FORTNIGHT2";

                            var lastPeriodfilterRequestCampaignTypeAndPharmacyAndSalesman = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                            };
                        } else {
                            if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year;
                            }

                            var lastPeriodfilterRequestCampaignTypeAndPharmacyAndSalesman = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                            };
                        }

                        PharmaDashboard.querySalesmanByPharmacy(lastPeriodfilterRequestCampaignTypeAndPharmacyAndSalesman, function (result) {
                            vm.lastSalesman = result[0];
                        }, function (error) {
                        });
                    }, function (error) {
                    });
                } else {
                    PharmaDashboard.querySalesmanByPharmacy(filterRequestNoCampaign, function (result) {
                        vm.salesman = result[0];
                        if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                            vm.lastPeriodMonth = vm.filter.period.month;
                            vm.lastPeriodType = "FORTNIGHT1";

                            var lastPeriodfilterRequestNoCampaign = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                            };

                        } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                            if (vm.filter.period.month == 1) {
                                vm.lastPeriodMonth = 12;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - 1;
                                vm.lastYear = vm.filter.period.year;
                            }

                            vm.lastPeriodType = "FORTNIGHT2";

                            var lastPeriodfilterRequestNoCampaign = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                            };
                        } else {
                            if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year;
                            }

                            var lastPeriodfilterRequestNoCampaign = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null,
                                salesmanCode: vm.filter.salesman != null ? vm.filter.salesman.salesmanDescription : null
                            };
                        }

                        PharmaDashboard.querySalesmanByPharmacy(lastPeriodfilterRequestNoCampaign, function (result) {
                            vm.lastSalesman = result[0];
                        }, function (error) {
                        });

                    }, function (error) {
                    });
                }
            }

            if (vm.filter.campaign != null && vm.filter.company != null) {
                PharmaDashboard
                    .queryPharmacyByCampaign(filterRequestNoSalesman, function (result) {
                        vm.pharmacy = result[0];
                        if (vm.filter.salesman == null) {
                            setSalesmanValues();
                        }
                        if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                            vm.lastPeriodMonth = vm.filter.period.month;
                            vm.lastPeriodType = "FORTNIGHT1";

                            var lastPeriodPharmFilter = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };

                        } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                            if (vm.filter.period.month == 1) {
                                vm.lastPeriodMonth = 12;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - 1;
                                vm.lastYear = vm.filter.period.year;
                            }

                            vm.lastPeriodType = "FORTNIGHT2";

                            var lastPeriodPharmFilter = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        } else {
                            if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year;
                            }

                            var lastPeriodPharmFilter = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                                campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        }
                        PharmaDashboard.queryPharmacyByCampaign(lastPeriodPharmFilter, function (result) {
                            vm.lastPharmacy = result[0];
                            if (vm.filter.salesman == null) {
                                setSalesmanValues();
                            }
                        }, function (error) {
                        });
                    }, function (error) {
                    });

            } else if (vm.filter.campaign == null && vm.filter.company != null) {
                if (vm.filter.campaignType != null) {
                    PharmaDashboard.queryPharmacyByCampaignType(filterRequestCampaignTypeAndPharmacy, function (result) {
                        vm.pharmacy = result[0];
                        if (vm.filter.salesman == null) {
                            setSalesmanValues();
                        }
                        if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                            vm.lastPeriodMonth = vm.filter.period.month;
                            vm.lastPeriodType = "FORTNIGHT1";

                            var lastPeriodPharmacyByCampaignType = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                            if (vm.filter.period.month == 1) {
                                vm.lastPeriodMonth = 12;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - 1;
                                vm.lastYear = vm.filter.period.year;
                            }

                            vm.lastPeriodType = "FORTNIGHT2";

                            var lastPeriodPharmacyByCampaignType = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        } else {
                            if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year;
                            }

                            var lastPeriodPharmacyByCampaignType = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        }

                        PharmaDashboard.queryPharmacyByCampaignType(lastPeriodPharmacyByCampaignType, function (result) {
                            vm.lastPharmacy = result[0];
                            if (vm.filter.salesman == null) {
                                setSalesmanValues();
                            }
                        }, function (error) {
                        });
                    }, function (error) {
                    });
                } else {
                    PharmaDashboard.queryPharmacyByPeriod(filterRequestNoSalesmanNoCampaign, function (result) {
                        vm.pharmacy = result[0];
                        if (vm.filter.salesman == null) {
                            setSalesmanValues();
                        }

                        if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                            vm.lastPeriodMonth = vm.filter.period.month;
                            vm.lastPeriodType = "FORTNIGHT1";

                            var lastPeriodPharmFilter = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };

                        } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                            if (vm.filter.period.month == 1) {
                                vm.lastPeriodMonth = 12;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - 1;
                                vm.lastYear = vm.filter.period.year;
                            }

                            vm.lastPeriodType = "FORTNIGHT2";

                            var lastPeriodPharmFilter = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        } else {
                            if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year;
                            }

                            var lastPeriodPharmFilter = {
                                year: vm.lastYear != null ? vm.lastYear : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                pharmacyCode: vm.filter.company != null ? vm.filter.company.client : null
                            };
                        }

                        PharmaDashboard.queryPharmacyByPeriod(lastPeriodPharmFilter, function (result) {
                            vm.lastPharmacy = result[0];
                            if (vm.filter.salesman == null) {
                                setSalesmanValues();
                            }
                        }, function (error) {
                        });
                    }, function (error) {
                    });
                }
            } else if (vm.filter.company == null) {
                if (vm.filter.campaignType != null) {
                    if (vm.filter.campaign != null) {
                        PharmaDashboard.queryPharmacyByCampaignNoPharmacy(filterRequestNoSalesmanNoPharmacy, function (result) {
                            vm.pharmacy = result[0];
                            if (vm.filter.salesman == null) {
                                setSalesmanValues();
                            }

                            if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                                vm.lastPeriodMonth = vm.filter.period.month;
                                vm.lastPeriodType = "FORTNIGHT1";

                                var lastPeriodByCampaign = {
                                    year: vm.filter.period != null ? vm.filter.period.year : null,
                                    month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                    periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                    campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                                    campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null
                                };

                            } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                                if (vm.filter.period.month == 1) {
                                    vm.lastPeriodMonth = 12;
                                    vm.lastYear = vm.filter.period.year - 1;
                                } else {
                                    vm.lastPeriodMonth = vm.filter.period.month - 1;
                                    vm.lastYear = vm.filter.period.year;
                                }

                                vm.lastPeriodType = "FORTNIGHT2";

                                var lastPeriodByCampaign = {
                                    year: vm.filter.period != null ? vm.filter.period.year : null,
                                    month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                    periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                    campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                                    campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null
                                };

                            } else {
                                if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                    vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                    vm.lastYear = vm.filter.period.year - 1;
                                } else {
                                    vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                    vm.lastYear = vm.filter.period.year;
                                }

                                var lastPeriodByCampaign = {
                                    year: vm.filter.period != null ? vm.filter.period.year : null,
                                    month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                    periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                    campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null,
                                    campaignCode: vm.filter.campaign != null ? vm.filter.campaign.id : null
                                };
                            }

                            PharmaDashboard.queryPharmacyByCampaignTypeNoPharmacy(lastPeriodByCampaign, function (result) {
                                vm.lastPharmacy = result[0];
                                if (vm.filter.salesman == null) {
                                    setSalesmanValues();
                                }
                            }, function (error) {
                            });
                        }, function (error) {
                        });
                    } else {
                        PharmaDashboard.queryPharmacyByCampaignTypeNoPharmacy(filterRequestCampaignTypeNoPharmacy, function (result) {
                            vm.pharmacy = result[0];
                            if (vm.filter.salesman == null) {
                                setSalesmanValues();
                            }

                            if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                                vm.lastPeriodMonth = vm.filter.period.month;
                                vm.lastPeriodType = "FORTNIGHT1";

                                var lastPeriodByCampaignType = {
                                    year: vm.filter.period != null ? vm.filter.period.year : null,
                                    month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                    periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                    campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null
                                };

                            } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                                if (vm.filter.period.month == 1) {
                                    vm.lastPeriodMonth = 12;
                                    vm.lastYear = vm.filter.period.year - 1;
                                } else {
                                    vm.lastPeriodMonth = vm.filter.period.month - 1;
                                    vm.lastYear = vm.filter.period.year;
                                }

                                vm.lastPeriodType = "FORTNIGHT2";

                                var lastPeriodByCampaignType = {
                                    year: vm.filter.period != null ? vm.filter.period.year : null,
                                    month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                    periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null,
                                    campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null
                                };

                            } else {
                                if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                    vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                    vm.lastYear = vm.filter.period.year - 1;
                                } else {
                                    vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                    vm.lastYear = vm.filter.period.year;
                                }

                                var lastPeriodByCampaignType = {
                                    year: vm.filter.period != null ? vm.filter.period.year : null,
                                    month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                    periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null,
                                    campaignType: vm.filter.campaignType != null ? vm.filter.campaignType : null
                                };
                            }

                            PharmaDashboard.queryPharmacyByCampaignTypeNoPharmacy(lastPeriodByCampaignType, function (result) {
                                vm.lastPharmacy = result[0];
                                if (vm.filter.salesman == null) {
                                    setSalesmanValues();
                                }
                            }, function (error) {
                            });
                        }, function (error) {
                        });
                    }
                } else {
                    PharmaDashboard.queryPharmacyByTimeNoPharmacy(filterRequestNoSalesmanNoCampaignNoPharmacy, function (result) {
                        vm.pharmacy = result[0];
                        if (vm.filter.salesman == null) {
                            setSalesmanValues();
                        }

                        if (vm.filter.periodType.periodType == "FORTNIGHT2") {
                            vm.lastPeriodMonth = vm.filter.period.month;
                            vm.lastPeriodType = "FORTNIGHT1";

                            var lastPeriodPharmFilterNoPharmacy = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null
                            };

                        } else if (vm.filter.periodType.periodType == "FORTNIGHT1") {
                            if (vm.filter.period.month == 1) {
                                vm.lastPeriodMonth = 12;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - 1;
                                vm.lastYear = vm.filter.period.year;
                            }

                            vm.lastPeriodType = "FORTNIGHT2";

                            var lastPeriodPharmFilterNoPharmacy = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.lastPeriodType != null ? vm.lastPeriodType : null
                            };

                        } else {
                            if (vm.filter.period.month - vm.filter.periodType.monthProportion < 1) {
                                vm.lastPeriodMonth = 13 - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year - 1;
                            } else {
                                vm.lastPeriodMonth = vm.filter.period.month - vm.filter.periodType.monthProportion;
                                vm.lastYear = vm.filter.period.year;
                            }

                            var lastPeriodPharmFilterNoPharmacy = {
                                year: vm.filter.period != null ? vm.filter.period.year : null,
                                month: vm.lastPeriodMonth != null ? vm.lastPeriodMonth : null,
                                periodType: vm.filter.periodType != null ? vm.filter.periodType.periodType : null
                            };
                        }

                        PharmaDashboard.queryPharmacyByTimeNoPharmacy(lastPeriodPharmFilterNoPharmacy, function (result) {
                            vm.lastPharmacy = result[0];
                            if (vm.filter.salesman == null) {
                                setSalesmanValues();
                            }
                        }, function (error) {
                        });
                    }, function (error) {
                    });
                }
            }

            $timeout(function () {
                vm.panelHeight = document.getElementById('pharmValuePanel').clientHeight;
                if (vm.panelHeight < 150) {
                    vm.panelHeight = 150;
                }
                vm.salesmanValuePanel = document.getElementById("salesmanValuePanel");
                vm.salesmanValuePanel.style.height = vm.panelHeight + 'px';
                vm.salesmanQuantityPanel = document.getElementById("salesmanQuantityPanel");
                vm.salesmanQuantityPanel.style.height = vm.panelHeight + 'px';
                vm.pharmValuePanel = document.getElementById('pharmValuePanel');
                vm.pharmValuePanel.style.height = vm.panelHeight + 'px';
                vm.pharmQuantityPanel = document.getElementById('pharmQuantityPanel');
                vm.pharmQuantityPanel.style.height = vm.panelHeight + 'px';
            }, 1250);
        }

        function setSalesmanValues() {
            vm.salesman.salesmanDescription = "Média " + vm.pharmacy.pharmacyDescription;
            vm.salesman.value = vm.pharmacy.meanValue;
            vm.salesman.quantity = vm.pharmacy.meanQuantity;
            vm.salesman.targetValue = vm.pharmacy.meanTargetValue;
            vm.salesman.targetQuantity = vm.pharmacy.meanTargetQuantity;

            vm.lastSalesman.salesmanDescription = "Média " + vm.lastPharmacy.pharmacyDescription;
            vm.lastSalesman.value = vm.lastPharmacy.meanValue;
            vm.lastSalesman.quantity = vm.lastPharmacy.meanQuantity;
            vm.lastSalesman.targetValue = vm.lastPharmacy.meanTargetValue;
            vm.lastSalesman.targetQuantity = vm.lastPharmacy.meanTargetQuantity;
        }

        $scope.loadPhotos = function (salesmanDes) {
            for (var i = 0; i < vm.allUsers.length; i++) {
                if (vm.allUsers[i].indicatorsName == salesmanDes) {
                    vm.tableUser = vm.allUsers[i];

                    if (vm.tableUser && vm.tableUser.thumbnailContentType) {
                        return vm.tableUser;
                    } else {
                        return null;
                    }
                }
            }
            return null;
        };

        $interval(function () {
            filterDashboard()
        }, 3600000);

        vm.openPopup = function (item) {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-dashboard/pharma-dashboard-table-dialog.html',
                controller: 'PharmaDashboardTableDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    tableType: function () {
                        return item;
                    },
                    tableFilter: function () {
                        return vm.fullFilterRequest;
                    }
                }
            });
        }
    }
})();
