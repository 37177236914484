(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ChoosePsychotropicsDate', ChoosePsychotropicsDate);

    ChoosePsychotropicsDate.$inject = ['$scope', '$timeout', 'pagingParams', 'allowValidations', 'paginationConstants', '$filter', '$uibModalInstance', '$translate', 'Notification', 'PsychotropicsRequisitions',
        'blockUI', '$state', 'Principal', 'ParseLinks'];

    function ChoosePsychotropicsDate($scope, $timeout, pagingParams, allowValidations, paginationConstants, $filter, $uibModalInstance, $translate, Notification, PsychotropicsRequisitions,
                                     blockUI, $state, Principal, ParseLinks) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.selectAll = false;
        vm.allowValidations = allowValidations;

        vm.getAllDataFromDataBase = function () {
            $timeout(function () {
                blockUI.start($translate.instant('pharmaPortalApp.psychotropics.loading'));
                PsychotropicsRequisitions.queryWithFilters({
                    month: vm.selectedMonth.id, year: vm.selectedYear, user: vm.loggedUser,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            })
            vm.generateInitialTree();
        };

        function getAllDataFromErp() {
            $timeout(function () {
                blockUI.start($translate.instant('pharmaPortalApp.psychotropics.loading'));
                PsychotropicsRequisitions.query({
                    month: vm.selectedMonth.id, year: vm.selectedYear, user: vm.loggedUser,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            })
            vm.generateInitialTree();

        }

        function onSuccess(data, headers) {
            blockUI.stop();
            vm.showLastPage = true;
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.newRequisitionsPsychotropics = data;
            vm.page = pagingParams.page;
        }

        function getLoggedUser() {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.loggedUser = account.login;
                    vm.account = account;
                } else {
                    $state.go('accessdenied');
                }
            });
        }

        getLoggedUser();

        function onError(error) {
            blockUI.stop();
            Notification.error({message: error.data.message, delay: ''});
        }

        vm.showLastPage = false;
        vm.clear = function (month, year) {
            $uibModalInstance.dismiss(month + "/" + year);
        };

        var actualYear = new Date().getFullYear();
        var actualMonth = new Date().getMonth();
        vm.years = [actualYear - 1, actualYear];
        vm.months = [
            {
                id: 0,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.January')
            },
            {
                id: 1,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.February')
            },
            {
                id: 2,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.March')
            },
            {
                id: 3,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.April')
            },
            {
                id: 4,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.May')
            },
            {
                id: 5,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.June')
            },
            {
                id: 6,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.July')
            },
            {
                id: 7,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.August')
            },
            {
                id: 8,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.September')
            },
            {
                id: 9,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.October')
            },
            {
                id: 10,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.November')
            },
            {
                id: 11,
                label: $translate.instant('pharmaPortalApp.psychotropics.months.December')
            }
        ]
        vm.monthsToShow = vm.months;

        vm.getAllRequisitions = function () {
            getAllDataFromErp();
        };
        vm.yearSelected = function (yearSelected) {
            if (vm.selectedYear != null && actualYear == yearSelected) {
                vm.monthsToShow = $filter('filter')(vm.months, function (value) {
                    return value.id <= actualMonth
                });
            } else {
                vm.monthsToShow = vm.months;
            }
            vm.selectedMonth = null;
            return vm.monthsToShow;
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            getAllDataFromErp();
        }

        vm.getTablesFromLines = function (psychotropicsHeader) {
            var list = [];
            psychotropicsHeader.infarmedRequisitionLines.forEach(function(element) {
                if(!list.includes(element.typeTab)){
                    list.push(element.typeTab);
                }
            });

            return list;
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        vm.selectAllPage = function (flag) {
            vm.disableButtons = !flag;
            angular.forEach(vm.newRequisitionsPsychotropics, function (value) {
                value.selected = flag;
            });
        };

        vm.checkIfIsAllLinesSelected = function () {
            var found = $filter('filter')(vm.newRequisitionsPsychotropics, function (value) {
                return value.selected === undefined || value.selected === false
            }, true);
            vm.selectAll = found.length === 0;
        };

        vm.disableButtons = true;
        vm.selectLine = function () {
            var found = $filter('filter')(vm.newRequisitionsPsychotropics, function (value) {
                return value.selected !== undefined && value.selected === true
            }, true);
            $timeout(function () {
                $scope.$apply(function () {
                    vm.disableButtons = found.length === 0;
                })
            })
        };

        vm.availableAll = function () {
            var found = $filter('filter')(vm.newRequisitionsPsychotropics, {selected: true}, true);
            if (found.length === 0) {
                showWarning('pharmaPortalApp.psychotropics.warning.selectAtLeastOne');
            } else {
                PsychotropicsRequisitions.availableListRequisitions(found, function () {
                    vm.getAllDataFromDataBase();
                    showSuccess("pharmaPortalApp.psychotropics.success.available");
                    vm.selectAll = false;
                }, function () {
                    showError("pharmaPortalApp.psychotropics.error.availableRequisitions")
                });
            }
        };

        vm.availableRequisition = function (found) {
            PsychotropicsRequisitions.availableRequisition(found, function () {
                vm.getAllDataFromDataBase();
                showSuccess("pharmaPortalApp.psychotropics.success.available");
            }, function () {
                showError("pharmaPortalApp.psychotropics.error.availableRequisitions")
            });
        };

        function showError(message) {
            Notification.error({
                message: $translate.instant(message),
                delay: ''
            });
        }

        vm.cancelRequisition = function (id) {
            PsychotropicsRequisitions.cancelRequisition(id, function () {
                vm.generateInitialTree();
            });
            transition();
        };

        vm.deleteRequisition = function (id) {
            PsychotropicsRequisitions.deleteRequisition({id: id}, function () {
                showSuccess("pharmaPortalApp.psychotropics.success.deleted");
            }, function () {
                showError("pharmaPortalApp.psychotropics.error.deleteRequisition")
            });
        };

        vm.cancelAll = function () {
            var list = [];
            angular.forEach(vm.newRequisitionsPsychotropics, function (value) {
                list.push(value.id);
            });
            PsychotropicsRequisitions.deleteAllRequisition(JSON.stringify(list), function () {
                vm.clear('cancel', '');
                vm.generateInitialTree();
            }, function () {
                showError("pharmaPortalApp.psychotropics.error.deleteRequisition")
            });
        };


        function showSuccess(message) {
            Notification.success({
                message: $translate.instant(message),
                delay: ''
            });
        }

        function showWarning(message) {
            Notification.warning({
                message: $translate.instant(message),
                delay: ''
            });
        }

        vm.tableFilters = {
            numberRequisition: null,
            date: null,
            search: null,
            cnp: null,
            taxNumber: null,
            responsible: null,
            table: null,
            type: null,
            status: 'CREATED'
        };

        vm.resetDataFilter = function () {
            vm.tableFilters.numberRequisition = null;
            vm.tableFilters.date = null;
            vm.tableFilters.search = null;
            vm.tableFilters.type = null;
            vm.tableFilters.table = null;
            vm.tableFilters.taxNumber = null;
        }



        vm.getAllTypesToTree = function (node) {
            PsychotropicsRequisitions.getAllTypesToTree({
                jsonHashMap: JSON.stringify(node.parents),
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };

        vm.getAllTablesToTree = function (node) {
            PsychotropicsRequisitions.getAllTablesToTree({
                jsonHashMap: JSON.stringify(node.parents),
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };

        vm.getAllEntitiesToTree = function (node) {
            PsychotropicsRequisitions.queryAllEntitiesToTree({
                jsonHashMap: JSON.stringify(node.parents),
                tableFilters: vm.tableFilters
            }, function (result) {
                node.children = result;
            });
        };


        vm.applyFiltersTree = function (node) {
            vm.resetDataFilter();
            if (node.parents == null) {
                return;
            }

            vm.tableFilters.status = 'CREATED';
            vm.tableFilters.search = vm.selectedMonth.id + "" + vm.selectedYear;
            vm.tableFilters.taxNumber = node.parents["taxNumber"];

            if (node.level === 3) {
                vm.tableFilters.type = node.label;
            }

            if (node.level === 4) {
                vm.tableFilters.table = node.label;
            }

            PsychotropicsRequisitions.queryAllFilters({
                tableFilters: vm.tableFilters,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        };

        vm.getNextTreeLevel = function (value, node) {
            if (!value || node.children.length !== 0) {
                return;
            }

            switch (node.level) {
                case 1:
                    vm.getAllEntitiesToTree(node);
                    break;
                case 2:
                    vm.getAllTypesToTree(node);
                    break;
                case 3:
                    vm.getAllTablesToTree(node);
                    break;
            }
        }

        vm.generateInitialTree = function () {
            var tax = null;
            if( vm.account.company != null && node.parents != null ){
                tax  = vm.account.company.nif;
            }

            PsychotropicsRequisitions.queryMonthAndYearToTree({
                month: vm.selectedMonth.id,
                year: vm.selectedYear,
                tax: tax
            }, function (result) {
                $scope.data = result;
            });
        };
    }
})();
