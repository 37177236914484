(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingDetailEditController', PharmaPickingDetailEditController);

    PharmaPickingDetailEditController.$inject = ['Notification', '$scope', '$state', '$http', '$translate', '$uibModal', '$timeout', 'PharmaPicking', 'entity', 'PortalSettings', 'ModuleSettings', 'Principal', '$rootScope', 'pagingParams'];

    function PharmaPickingDetailEditController(Notification, $scope, $state, $http, $translate, $uibModal, $timeout, PharmaPicking, entity, PortalSettings, ModuleSettings, Principal, $rootScope, pagingParams) {

        var vm = this;
        vm.portalSettings = PortalSettings.query();

        vm.pharmaPicking = entity;
        vm.pharmaPicking.documentDate = new Date(vm.pharmaPicking.documentDate);
        vm.pharmaPicking.dateCreated = new Date(vm.pharmaPicking.dateCreated);
        vm.pharmaPicking.lastModifyingDate = new Date(vm.pharmaPicking.lastModifyingDate);

        vm.header = {};
        angular.copy(vm.pharmaPicking, vm.header);
        vm.header.supplier = vm.pharmaPicking.supplierCode + " - " + vm.pharmaPicking.supplierName;
        vm.header.pharmacy = vm.pharmaPicking.pharmacyWarehouse + " - " + vm.pharmaPicking.pharmacyName;

        vm.locked = true;
        vm.working = false;

        vm.loadAll = loadAll;
        vm.save = save;

        vm.deleteRightTableLines = deleteRightTableLines;
        vm.deletePharmaPickingLine = deletePharmaPickingLine;
        vm.leftTableFilter = leftTableFilter;
        vm.rightTableFilter = rightTableFilter;
        vm.submitPharmaProduct = submitPharmaProduct;
        vm.submitPharmaPicking = submitPharmaPicking;

        vm.showInfo = showInfo;

        vm.products = [];

        var isOpen = pagingParams.open;
        if(isOpen == true){
            var status = 'OPEN';
        }

        vm.back = function(){
            if(isOpen == true && status != null && status == 'OPEN'){
                PharmaPicking.delete({id: vm.pharmaPicking.id, backButton: "yes"}, function(){}, function(){});
            }
            window.history.back();
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;
                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                }
                else {
                    Principal.identity().then(function (account) {
                        if (account) {
                            vm.user = account.login;
                            vm.userGeneric = account.generic;
                            if (account.login != vm.pharmaPicking.lastModifyingUser || vm.pharmaPicking.status == 'CLOSED') {
                                vm.locked = true;
                            } else {
                                vm.locked = false;
                                focusProductInput();
                            }
                        } else {
                            $state.go('accessdenied');
                        }
                    });

                    if(vm.pharmaPicking.pharmaPickingLines == null || vm.pharmaPicking.pharmaPickingLines.length <= 0) {
                        $http.get("api/_getPharmaPickingLinesFromErp/" + vm.pharmaPicking.supplierCode + "/" + vm.pharmaPicking.supplierReference).then(function (result) {
                            vm.pharmaPicking.pharmaPickingLines = result.data;
                            save();
                        });
                    }

                    // if (vm.pharmaPicking.status == 'EXECUTING') {
                    //     $http.post('api/common/_searchProductsByCNP', vm.pharmaPicking.id).then(function (result) {
                    //         if (result.status === 200) {
                    //             vm.products = result.data;
                    //         } else {
                    //             Notification.error({message: $translate.instant(result.headers("X-pharmaPortalApp-alert")), delay: ''});
                    //         }
                    //     });
                    // }
                }
            });
        }
        vm.loadAll();

        //DELETES...
        function deletePharmaPickingLine(pharmaPickingLine) {
            if(isOpen == true && status != null && status == 'OPEN'){
                vm.updateStatus();
            }
            vm.working = true;

            pharmaPickingLine.receivedQuantity = 0;
            pharmaPickingLine.lastModifyingUser = vm.user;
            pharmaPickingLine.lastModifyingDate = new Date();

            Notification.warning({
                message: $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.deleted', {description: pharmaPickingLine.description}),
                delay: 5000
            });
            save();
        }

        function deleteRightTableLines() {
            if(isOpen == true && status != null && status == 'OPEN'){
                vm.updateStatus();
            }
            vm.working = true;

            angular.forEach($scope.processedLines, function (value, key) {
                value.receivedQuantity = 0;
                value.lastModifyingUser = vm.user;
                value.lastModifyingDate = new Date();
            });
            Notification.warning({
                message: $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.deletedAll'),
                delay: 5000
            });
            save();
        }


        //FILTERS...
        function leftTableFilter(line) {
            if (line.invoicedQuantity - line.receivedQuantity > 0) {
                return true;
            }
            return false;
        }

        function rightTableFilter(line) {
            if (line.receivedQuantity > 0) {
                return true;
            }
            return false;
        }

        function overQuantityItems() {
            var items = 0;
            angular.forEach($scope.processedLines, function (value, key) {
                if (value.receivedQuantity > value.invoicedQuantity) {
                    items++;
                }
            });
            return items;
        }

        vm.updateStatus = function(){
            status = "EXECUTING";
        }

        //SUBMITS...
        function submitPharmaProduct() {
            if(isOpen == true && status != null && status == 'OPEN'){
                vm.updateStatus();
            }
            var product = {
                internalcode: "",
                itempackage: "",
                greatfamily: "",
                model: ""
            };
            var i = 0;

            for (i = 0; i < vm.pharmaPicking.pharmaPickingLines.length; i++) {

                if ( vm.pharmaPicking.pharmaPickingLines[i].barcode == vm.form.product) {
                    product =  vm.pharmaPicking.pharmaPickingLines[i];
                    break;
                }
                // if (vm.products[i].barcode == vm.form.product) {
                //     product = vm.products[i];
                //     break;
                // }
            }

            for (i = 0; i < vm.pharmaPicking.pharmaPickingLines.length; i++) {
                if (vm.pharmaPicking.pharmaPickingLines[i].cnp == vm.form.product || (
                    product.internalcode == vm.pharmaPicking.pharmaPickingLines[i].internalCode &&
                    product.itempackage == vm.pharmaPicking.pharmaPickingLines[i].itemPackage &&
                    product.greatfamily == vm.pharmaPicking.pharmaPickingLines[i].greatFamily &&
                    product.model == vm.pharmaPicking.pharmaPickingLines[i].model
                )) {


                    vm.pharmaPicking.pharmaPickingLines[i].lastModifyingUser = vm.user;
                    vm.pharmaPicking.pharmaPickingLines[i].lastModifyingDate = new Date();

                    if (vm.form.quantity != undefined) {
                        vm.pharmaPicking.pharmaPickingLines[i].receivedQuantity += parseInt(vm.form.quantity);
                        vm.message = {
                            message: $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.addedProduct', {
                                description: vm.pharmaPicking.pharmaPickingLines[i].description,
                                number: vm.form.quantity
                            }), delay: 5000
                        };
                    }
                    else {
                        vm.message = {
                            message: $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.addedProduct', {
                                description: vm.pharmaPicking.pharmaPickingLines[i].description,
                                number: 1
                            }), delay: 5000
                        };
                        vm.pharmaPicking.pharmaPickingLines[i].receivedQuantity++;
                    }


                    vm.form.product = undefined;
                    vm.form.quantity = undefined;

                    save();

                    focusProductInput();

                    return;
                }
            }
            var msg = $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.error.submission');
            msg += "<br>" + $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.error.productNotFound', {description: vm.form.product});
            Notification.error({message: msg, delay: 5000});
            vm.form.product = undefined;
            vm.form.quantity = undefined;
            focusProductInput();
        }

        function submitPharmaPicking() {
            //TODO saved on rootScope check if is needed
            if (vm.user == vm.pharmaPicking.lastModifyingUser && vm.pharmaPicking.status != 'CLOSED') {
                vm.working = false;
                if (!vm.userGeneric) {
                    $rootScope.userCloseConference = vm.user;
                    openLastModal();
                } else {
                    $uibModal.open({
                        templateUrl: 'app/entities/pharma-picking/pharma-close-conference-dialog.html',
                        controller: 'PharmaCloseConferenceController',
                        controllerAs: 'vm',
                        size: 'md',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function () {
                        // success callback implemented on own controller
                    }, function (flag) {
                        if (flag != "cancel" && flag != null) {
                            $rootScope.userCloseConference = flag;
                            openLastModal();
                        }
                    });
                }

            }
        }

        function openLastModal() {
            var templateUrl;
            var controller;

            if ($scope.pendingLines.length > 0) {
                templateUrl = 'app/entities/pharma-picking/pharma-picking-submit-incomplete-dialog.html';
                controller = 'PharmaPickingSubmitIncompleteController';
            }
            else if (overQuantityItems() > 0) {
                templateUrl = 'app/entities/pharma-picking/pharma-picking-submit-overquantity-dialog.html';
                controller = 'PharmaPickingSubmitOverQuantityController';
            }
            else {
                templateUrl = 'app/entities/pharma-picking/pharma-picking-submit-complete-dialog.html';
                controller = 'PharmaPickingSubmitCompleteController';
            }

            $uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    pharmaPicking: vm.pharmaPicking,
                    user: {user: vm.user}
                    // pharmaPicking: ['PharmaPicking', function (PharmaPicking) {
                    //     return PharmaPicking.get({
                    //         id: vm.pharmaPicking.id
                    //     }).$promise;
                    // }]
                }
            }).result.then(function () {
                // success callback implemented on own controller
            }, function (flag) {
                if (flag === "complaint") {
                    $state.go("pharma-supplier-complaint", {pharmaPicking : vm.pharmaPicking});
                }
            });

        }

        //Saves...
        function save() {
            vm.working = true;
            vm.pharmaPicking.lastModifyingDate = new Date();

            if (vm.user == vm.pharmaPicking.lastModifyingUser && vm.pharmaPicking.status != 'CLOSED') {
                vm.pharmaPicking.lastModifyingUser = vm.user;
                PharmaPicking.save(vm.pharmaPicking, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.working = false;
            if (vm.message != undefined) {
                Notification.success(vm.message);
            }
            vm.message = undefined;
            $state.go("pharma-picking-detail.edit", {
                id: result.id
            }, {
                reload: false
            });
        }

        function onSaveError(result) {
            vm.working = false;
            if (result.data.success) {
                Notification.success({message: result.data.message, delay: 5000});
            } else {
                Notification.error({message: result.data.message, delay: ''});
            }
            $state.go("pharma-picking-detail.edit", {
                id: result.id
            });
        }


        //UI Stuff...
        function focusProductInput() {
            $timeout(function () {
                angular.element("#product").focus();
            });
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };

        function showInfo() {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-picking/invoiced-and-claimed-dialog.html',
                controller: 'InvoicedAndClaimedDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    pharmaPicking: vm.pharmaPicking
                }
            }).result.then(function () {
            }, function (flag) {
            });
        }

    }
})();
