(function() {
	'use strict';

	angular.module('pharmaPortalApp').factory('AuthServerProvider', AuthServerProvider);

	AuthServerProvider.$inject = [ '$http', '$localStorage' ];

	function AuthServerProvider($http, $localStorage) {
		var service = {
			getToken : getToken,
			hasValidToken : hasValidToken,
			login : login,
			logout : logout
		};

		return service;

		function getToken() {
			var token = $localStorage.authenticationToken;
			return token;
		}

		function hasValidToken() {
			var token = this.getToken();
			return !!token;
		}

		function login(credentials) {
			var data = 'j_username=' + encodeURIComponent(credentials.username) + '&j_password=' + encodeURIComponent(credentials.password) + '&remember-me='
					+ credentials.rememberMe + '&submit=Login';

			return $http.post('api/authentication', data, {
				headers : {
					'Content-Type' : 'application/x-www-form-urlencoded'
				}
			}).then(function(response) {
				return response;
			});
		}

		function logout() {

                // logout from the server
                $http.post('api/logout').then(function(response) {
                    delete $localStorage.authenticationToken;
                    // to get a new csrf token call the api
                    $http.get('api/account');
                    return response;
                });

		}
	}
})();
