(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('Report', Report);

	Report.$inject = [ '$resource' ];

	function Report($resource) {
		var resourceUrl = 'api/reports/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			},
            'getByReportGroup':{
			    method: 'GET',
                isArray: true,
                url : 'api/reportsByReportGroup'
            },
            'getUserReportsOrder':{
			    method:'GET',
                isArray: true,
                url:'userReportOrderRes/userReportsOrder'
            },
            'saveReportOrder':{
			    method:'PUT',
                isArray: true,
                url:'userReportOrderRes/saveOrder'
            }
		});
	}
})();
