(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PolicyDefinitionController', PolicyDefinitionController);
    PolicyDefinitionController.$inject = ['Notification', '$scope', '$http', '$translate', 'PolicyDefinition', '$rootScope', '$window', '$q', '$uibModal'];

    function PolicyDefinitionController(Notification, $scope, $http, $translate, PolicyDefinition, $rootScope, $window, $q, $uibModal) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.reloadPolicyDefinitions = reloadPolicyDefinitions;
        vm.policyTypeSelection = policyTypeSelection;
        vm.stringEntity = stringEntity;
        vm.selectNode = selectNode;
        vm.hasPolicyDefinitionNode = hasPolicyDefinitionNode;
        vm.isPolicyDefinitionNode = isPolicyDefinitionNode;
        vm.toggleNode = toggleNode;
        vm.collapseAll = collapseAll;
        vm.expandAll = expandAll;
        vm.toggleSelectAll = toggleSelectAll;
        vm.updateSelection = updateSelection;
        vm.translateString = translateString;
        vm.openModal = openModal;
        vm.openModalEditForSelection = openModalEditForSelection;
        vm.openModalForNewPolicy = openModalForNewPolicy;
        vm.checkIfPolicyInputMatches = checkIfPolicyInputMatches;
        vm.initializePolicyDefinitionHierarchy = initializePolicyDefinitionHierarchy;

        vm.clearFilter = clearFilter;
        vm.toggleFilter = toggleFilter;
        vm.showNode = showNode;

        $scope.policyTypes = [];
        $scope.policyType;
        $scope.policyDefinitions = [];
        $scope.policyDefinition;
        $scope.selectedPolicyDefinition;
        $scope.searchPolicyDefinition;
        $scope.policyImplementations = [];
        $scope.policyImplementationsSelector = [];
        $scope.selectedNodes = [];
        $scope.typeaheadArray = [];
        $scope.lastNeedle = '';
        $scope.lastPage = 0;


        $scope.isSelectAll = false;
        $scope.filterObject = {};
        $scope.showFilter = false;

        function loadAll() {
            // GET POLICIES LIST
            $http.get("api/policy-builder/interfacesBySettings").then(function (result) {
                $scope.policyTypes = [];

                angular.forEach(result.data, function (v) {
                    $scope.policyTypes.push({
                        'fqn': v,
                        'name': $translate.instant(v)
                    });
                });
            });
        }


        vm.loadAll();

        function clearFilter() {
            $scope.filterObject = {};
            $scope.showFilter = false;
        }

        function toggleFilter() {
            $scope.showFilter = !$scope.showFilter
        }

        function isEmptyObject(obj) {
            return (obj && (Object.keys(obj).length === 0));
        }

        function showNode(node) {
            if (node && node.data && node.data.hierarchy && !isEmptyObject($scope.filterObject)) {
                // console.log(JSON.stringify({data: $scope.filterObject}, null, 4));
                var filterKeys = Object.keys($scope.filterObject);

                for (var key in filterKeys) {
                    // for (var key of Object.keys($scope.filterObject)) {
                    var value = $scope.filterObject[filterKeys[key]];
                    // var value = $scope.filterObject[key];
                    if (!value || (value === $translate.instant("select.none") || value === $translate.instant("select.all"))) {
                        continue;
                    }
                    if (filterKeys[key] === 'selectedImpl') {
                        return node.data.policyImplementation === value;
                    } else {
                        var nodeJson = node.data.hierarchy[filterKeys[key]];
                        var valueJson = JSON.stringify(value);
                        if (nodeJson !== valueJson) {
                            return false;
                        }
                    }
                }
            }
            return true;
        }

        function stringEntity(node) {
            var stripNode = null;
            try {
                stripNode = JSON.parse(node);
            } catch (e) {
                //Strings sem estar no formato {x:y} ( tipo "TRADE" pex) dao erro
                return $translate.instant('context.' + node);
            }
            return "(" + stripNode.code + ") - " + stripNode.name;
        }

        function policyTypeSelection(selectedPolicy) {
            $scope.isSelectAll = false;
            clearFilter();

            $scope.policyType = selectedPolicy;
            if (selectedPolicy) {
                reloadPolicyDefinitions(true);

                $http.get("api/policy-builder/implementationsBySettings/" + $scope.policyType.fqn).then(function (result) {
                    $scope.policyImplementationsSelector.push($translate.instant("select.all"));
                    for (var i = 0; i < result.data.length; i++) {
                        $scope.policyImplementations.push(result.data[i]);
                        $scope.policyImplementationsSelector.push(result.data[i]);
                    }
                });
            } else {
                $scope.policyDefinitions = [];
            }
        }

        function reloadPolicyDefinitions(reloadImplementations) {
            $scope.searchPolicyDefinition = null;
            $scope.policyDefinition = null;
            $scope.policyDefinitions = [];
            if (reloadImplementations) {
                $scope.policyImplementations = [];
                $scope.policyImplementationsSelector = [];
            }

            $http.get("api/_tree/policy-definitions/" + $scope.policyType.fqn).then(function (result) {
                $scope.policyDefinitions = result.data;
                if ($scope.policyDefinitions && $scope.policyDefinitions.children &&
                    $scope.policyDefinitions.children.length > 0) {
                    $scope.searchPolicyDefinition = angular.copy($scope.policyDefinitions.children[0].data);
                    // inicializar para se ter as combos preenchidas
                    initializePolicyDefinitionHierarchy($scope.searchPolicyDefinition);
                }
            });

            // repor os selecionados se houver
            if ($scope.selectedNodes && $scope.selectedNodes.length > 0) {
                var reloadedSelectedNodes = [];
                angular.forEach($scope.selectedNodes, function (selected) {
                    angular.forEach($scope.policyDefinitions, function (policyDefinition) {
                        if (selected.id === policyDefinition.id) {
                            policyDefinition.selected = true;
                            reloadedSelectedNodes.push(angular.copy(policyDefinition));
                        }
                    });
                });

                $scope.selectedNodes = reloadedSelectedNodes;
            }
        }

        function initializePolicyDefinitionHierarchy(policyDefinition) {
            // $SCOPE.POLICYDEFINITION.HIERARCHY.METADATA
            if (policyDefinition.hierarchy && policyDefinition.hierarchy.metadata) {
                angular.forEach(policyDefinition.hierarchy.metadata, function (value) {
                    if (value.type === 'select' || value.type === 'enum') {
                        $http.get("api/" + value.url + "/").then(function (result) {
                            policyDefinition.hierarchy[value.name + "_list"] = result.data;
                            if (policyDefinition.hierarchy[value.name]) {
                                policyDefinition.hierarchy[value.name] = eval("(" + policyDefinition.hierarchy[value.name] + ")");
                            }
                        });
                    }

                    if (value.type === 'searchSelect') {
                        if (policyDefinition.hierarchy[value.name]) {
                            policyDefinition.hierarchy[value.name] = eval("(" + policyDefinition.hierarchy[value.name] + ")");
                        }
                    }
                });
            }
        }

        function selectNode(node, showModal) {
            if (node.id) {
                $scope.policyDefinition = angular.copy(node);
                $scope.selectedPolicyDefinition = node;
            } else {
                $scope.policyDefinition = angular.copy(node.$modelValue.data);
                $scope.selectedPolicyDefinition = node.$modelValue.data;
            }

            if (showModal === true) {
                openModal([$scope.policyDefinition]);
            }
        }

        function hasPolicyDefinitionNode(node) {
            if (node && node.children[0] && node.children[0].data && node.children[0].data.hierarchy) {
                return node.children[0].data.hierarchy !== 'undefined';
            } else {
                return false;
            }
        }

        function isPolicyDefinitionNode(node) {
            if (node && node.data && node.data.hierarchy) {
                return node.data.hierarchy !== 'undefined';
            } else {
                return false;
            }
        }

        function toggleNode(node) {
            node.toggle();
        }

        function collapseAll() {
            $scope.$broadcast('angular-ui-tree:collapse-all');
        }

        function expandAll() {
            $scope.$broadcast('angular-ui-tree:expand-all');
        }

        function toggleSelectAll() {
            expandAll();
            angular.forEach($scope.policyDefinitions.children, function (value) {
                updateSelectionRecursive(value, $scope.isSelectAll);
            });
        }

        function updateSelectionRecursive(node, selected) {
            if (isPolicyDefinitionNode(node)) {
                // se houver seleccao sao so os visiveis
                if (selected && !showNode(node)) {
                    selected = false;
                }
                node.selected = selected;
                updateSelection(node.data, selected);
            } else if (node.children) {
                angular.forEach(node.children, function (value) {
                    updateSelectionRecursive(value, selected);
                });
            }
        }

        function updateSelection(nodeData, selected) {
            var index = $scope.selectedNodes.findIndex(function (i) {
                return i.id === nodeData.id
            });
            if (selected === true && index === -1) {
                $scope.selectedNodes.push(angular.copy(nodeData));
            } else if (selected === false && index !== -1) {
                $scope.selectedNodes.splice(index, 1);
            }
        }

        function openModalEditForSelection() {
            if ($scope.selectedNodes && $scope.selectedNodes.length > 0) {
                openModal($scope.selectedNodes);
            } else {
                Notification.warning({
                    message: $translate.instant('pharmaPortalApp.policyDefinition.selectedNodes.empty'),
                    delay: 5000
                });
            }
        }

        function openModalForNewPolicy() {
            openModal([], true);
        }

        function openModal(nodesArray, newPolicy) {
            if (nodesArray && nodesArray.length > 1) {
                if (!checkIfPolicyInputMatches(nodesArray)) {
                    Notification.warning({
                        message: $translate.instant('pharmaPortalApp.policyDefinition.selectedNodes.metadataMatchesFail'),
                        delay: 5000
                    });
                    return;
                }
            }

            $uibModal.open({
                templateUrl: 'app/entities/policy-definition/policy-definition-edit-dialog.html',
                controller: 'PolicyDefinitionEditDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    records: function () {
                        return nodesArray;
                    },
                    policyImplementations: function () {
                        return $scope.policyImplementations;
                    },
                    policyTypes: function () {
                        return $scope.policyTypes;
                    },
                    policyType: function () {
                        return $scope.policyType;
                    },
                    newPolicy: function () {
                        return newPolicy;
                    }
                }
            }).result.then(function () {
                reloadPolicyDefinitions(false);
            }, function () {
                reloadPolicyDefinitions(false);
            });
        }

        function checkIfPolicyInputMatches(nodesArray) {
            if (nodesArray && nodesArray.length > 0) {
                var first = nodesArray[0].input['@class'];
                var comp;
                for (var i = 1; i < nodesArray.length; i++) {
                    comp = nodesArray[i].input['@class'];
                    if (first !== comp) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        }

        function translateString(str) {
            return $translate.instant(str);
        }


        $scope.refreshSelectModelForSearchPolicyDefinition = function (query, url, fieldName) {
            $http.get("api/" + url + "/" + query).then(function (result) {
                if (result.data.length > 500) {
                    Notification.success({
                        message: "O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa",
                        delay: 5000
                    });
                } else {
                    $scope.searchPolicyDefinition.hierarchy[fieldName + '_list'] = result.data;
                }
            });
        };
    }
})();
