(function(){
    'use strict';

    angular.module('pharmaPortalApp').controller('ReportGroupController',ReportGroupController);

    ReportGroupController.$inject = ['ReportGroup','DataUtils'];

    function ReportGroupController(ReportGroup,DataUtils) {
        var vm = this;

        vm.reportGroups = [];

        loadAll();

        function loadAll() {
            ReportGroup.query(function (result) {
                vm.reportGroups = result;
            });

        }
    }
})();
