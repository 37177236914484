(function () {
    'use strict';

    angular.module('pharmaPortalApp').factory('AS400Messages', AS400Messages);

    AS400Messages.$inject = ['$resource'];

    function AS400Messages($resource) {
        var resourceUrl = 'api/as400';

        return $resource(
            resourceUrl,
            {},
            {
                'query': {
                    method: 'GET',
                    isArray: true
                },
                'getMessages': {
                    method: 'GET',
                    isArray: true,
                    url: 'api/as400/getMessages'
                }
            }
        )
    }
})();
