(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('ReportGroup', ReportGroup);

    ReportGroup.$inject = ['$resource', 'DateUtils'];

    function ReportGroup($resource, DateUtils) {
        var resourceUrl = 'api/reportGroups/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                    }
                    return data;
                }
            },
            'isUsed': {
                method: 'GET',
                isArray: true,
                url:'api/parentIsUsed'
            },
            'update': {method: 'PUT'}
        });
    }
})();
