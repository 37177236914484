(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('policy-definition', {
            parent: 'entity',
            url: '/policy-definitions',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'pharmaPortalApp.policyDefinition.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/policy-definition/policy-definitions.html',
                    controller: 'PolicyDefinitionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('policyDefinition');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('policy-definition.delete', {
            parent: 'policy-definition',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/policy-definition/policy-definition-delete-dialog.html',
                    controller: 'PolicyDefinitionDeleteDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        records: ['PolicyDefinition', function (PolicyDefinition) {
                            return [PolicyDefinition.get({
                                id: $stateParams.id
                            }).$promise];
                        }]
                    }
                }).result.then(function () {
                    $state.go('^');
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();
