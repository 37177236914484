(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('PharmaDashboard',
        PharmaDashboard);

    PharmaDashboard.$inject = ['$resource'];

    function PharmaDashboard($resource) {
        var resourceUrl = "api/pharma-dashboard";

        return $resource(
            resourceUrl,
            {},
            {
                'query': {
                    method: 'GET',
                    isArray: true
                },
                'getCampaignTypes': {
                    method: 'GET',
                    isArray: true,
                    url: 'api/campaigns/campaignTypes'
                },
                'getCampaignsByPeriod':{
                    method: 'GET',
                    isArray: true,
                    url: 'dashboardRes/campaignsByPeriod'
                },
                'getPortalUsers':{
                    method:'GET',
                    isArray:true,
                    url: 'dashboardRes/portalUsers'
                },
                'getPeriodFromInd': {
                    method: 'GET',
                    isArray: true,
                    url: 'dashboardRes/period'
                },
                'getPeriodTypeFromInd': {
                    method: 'GET',
                    isArray: true,
                    url: 'dashboardRes/periodType'
                },
                'queryTopSalesmanByPharmacyOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByPharmacyOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByPharmacyOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByPharmacyOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByCampaignTypeAndPharmacyOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByCampaignTypeAndPharmacyOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByCampaignTypeAndPharmacyOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByCampaignTypeAndPharmacyOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByCampaignTypeOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByCampaignTypeOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByCampaignTypeOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByCampaignTypeOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByCampaignOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByCampaignOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopSalesmanByCampaignOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByCampaignOrderByValue"},
                    url: '/dashboardRes/salesman'
                },
                'queryTopSalesmanByPharmacyAndCampaignOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByPharmacyAndCampaignOrderByQuantity"},
                    url: '/dashboardRes/salesman'
                },
                'queryTopSalesmanByPharmacyAndCampaignOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByPharmacyAndCampaignOrderByValue"},
                    url: '/dashboardRes/salesman'
                },
                'querySalesmanByPharmacyAndCampaign': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "salesmanByPharmacyAndCampaign"},
                    url: '/dashboardRes/salesman'
                },
                'querySalesmanByPharmacyAndCampaignType': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "salesmanByPharmacyAndCampaignType"},
                    url: '/dashboardRes/salesman'
                },
                'querySalesmanByPharmacy': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "salesmanByPharmacy"},
                    url: '/dashboardRes/salesman'
                },
                'queryPharmacyByCampaign': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "pharmacyByCampaign"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacyByCampaignType': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "pharmacyByCampaignType"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacyByPeriod': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "pharmacyByPeriod"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacyByCampaignOrderByValue': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "pharmacyByCampaignOrderByValue"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacyByCampaignTypeOrderByValue': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "pharmacyByCampaignTypeOrderByValue"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmaciesByTimeOrderByValue': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "pharmaciesByTimeOrderByValue"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacySalesman': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "queryAllPharmacySalesman"},
                    url: '/dashboardRes/salesman'
                },
                'querySelloutPharmacySalesman': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByTime"},
                    url: '/dashboardRes/selloutSalesman'
                },
                'querySelloutPharmacySalesmanAndPharmacy': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanByTimeAndPharmacy"},
                    url: '/dashboardRes/selloutSalesman'
                },
                'queryAllSelloutPharmacySalesman': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "allSalesmanByTime"},
                    url: '/dashboardRes/selloutSalesman'
                },
                'queryAllSelloutPharmacySalesmanAndPharmacy': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "allSalesmanByTimeAndPharmacy"},
                    url: '/dashboardRes/selloutSalesman'
                },
                'querySelloutPharmacy':{
                  method: 'GET',
                  isArray: true,
                  params: {methodName: "topPharmacyByTime"},
                  url: '/dashboardRes/selloutPharmacy'
                },
                'queryPharmacyByTimeNoPharmacy':{
                    method:'GET',
                    isArray: true,
                    params:{methodName:"pharmaciesByTimeNoPharmacy"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacyByCampaignTypeNoPharmacy':{
                    method:'GET',
                    isArray: true,
                    params:{methodName:"pharmaciesByCampaignTypeNoPharmacy"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryPharmacyByCampaignNoPharmacy':{
                    method:'GET',
                    isArray: true,
                    params:{methodName:"pharmaciesByCampaignNoPharmacy"},
                    url: '/dashboardRes/pharmacy'
                },
                'queryTopAllSalesmanByPharmacyOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByPharmacyOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "top10SalesmanOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByPharmacyOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByPharmacyOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByCampaignTypeAndPharmacyOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByCampaignTypeAndPharmacyOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByCampaignTypeAndPharmacyOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByCampaignTypeAndPharmacyOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByCampaignTypeOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByCampaignTypeOrderByValue"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByCampaignTypeOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByCampaignTypeOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByCampaignOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByCampaignOrderByQuantity"},
                    url: 'dashboardRes/salesman'
                },
                'queryTopAllSalesmanByCampaignOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByCampaignOrderByValue"},
                    url: '/dashboardRes/salesman'
                },
                'queryTopAllSalesmanByPharmacyAndCampaignOrderByQuantityList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByPharmacyAndCampaignOrderByQuantity"},
                    url: '/dashboardRes/salesman'
                },
                'queryTopAllSalesmanByPharmacyAndCampaignOrderByValueList': {
                    method: 'GET',
                    isArray: true,
                    params: {methodName: "topAllSalesmanByPharmacyAndCampaignOrderByValue"},
                    url: '/dashboardRes/salesman'
                }
            });
    }
})();
