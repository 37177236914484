(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('PharmaReceptionVatLine', PharmaReceptionVatLine);

	PharmaReceptionVatLine.$inject = [ '$resource' ];

	function PharmaReceptionVatLine($resource) {
		var resourceUrl = 'api/pharma-reception-vat-line/:id';

		return $resource(resourceUrl, {}, {
			'save' : {
				method : 'POST',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {
						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			}
		});
	}
})();
