(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CampaignNewDialogController', CampaignNewDialogController);

	CampaignNewDialogController.$inject = [ 'Notification', '$uibModalInstance', '$stateParams', 'entity', 'Campaign', 'Company', '$translate', '$state', 'moment', '$scope', 'ModuleSettings' ];

	function CampaignNewDialogController(Notification, $uibModalInstance, $stateParams, entity, Campaign, Company, $translate, $state, moment, $scope, ModuleSettings) {
		var vm = this;

		vm.campaign = entity;
		vm.companies = Company.getAllNotEmpty({ size : 1000 });

		vm.clear = clear;
		vm.save = save;
		vm.validateCampaign = validateCampaign;
		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.campaignsModule) {
					$state.go('accessdenied');
				}
			});
			vm.campaign.companies = vm.companies;
		}
		loadAll();

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			if (validateCampaign()) {
				vm.isSaving = true;
				if (vm.campaign.id !== null) {
					Campaign.update(vm.campaign, onSaveSuccess, onSaveError);
				} else {
					Campaign.save(vm.campaign, onSaveSuccess, onSaveError);
				}
			}
		}

		function onSaveSuccess(result) {
			Notification.success({
				message : $translate.instant("pharmaPortalApp.campaign.created"),
				delay : 5000
			});
			vm.isSaving = false;
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.isSaving = false;
			Notification.error({
				message : $translate.instant("pharmaPortalApp.campaign.error.create"),
				delay : ''
			});
		}

		function validateCampaign() {
			var errors = [];
			// NAME
			if (vm.campaign.name == null || vm.campaign.name == '') {
				errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.name'));
			}
			// CAMPAIGN TYPE
			if (vm.campaign.campaignType == null || vm.campaign.campaignType == '') {
				errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.campaignType'));
			}
			// CAMPAIGN STATUS
			if (vm.campaign.campaignStatus === null || vm.campaign.campaignStatus === '') {
				errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.campaignStatus'));
			}
			// START DATE - END DATE
			if (vm.campaign.startDate == null || vm.campaign.startDate == '' || vm.campaign.endDate == null || vm.campaign.endDate == '') {
				errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.dates'));
			} else {
				var checkDiff = true;
				// TODO: UNCHECK THIS DATE VALIDATION
				// if (moment().diff(vm.campaign.startDate, 'days') > 0) {
				// errors.push($translate.instant('pharmaPortalApp.campaign.error.dates.oldStartDate'));
				// checkDiff = false;
				// }
				// if (moment().diff(vm.campaign.endDate, 'days') > 0) {
				// errors.push($translate.instant('pharmaPortalApp.campaign.error.dates.oldEndDate'));
				// checkDiff = false;
				// }
				if (checkDiff && moment(vm.campaign.startDate).diff(vm.campaign.endDate, 'days') > 0) {
					errors.push($translate.instant('pharmaPortalApp.campaign.error.dates.endBeforeStart'));
				}
			}
			// COMPANIES
			if (vm.campaign.companies == null || vm.campaign.companies.length <= 0) {
				errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.companies'));
			}

			if (errors.length > 0) {
				var message = '';
				for (var i = 0; i < errors.length; i++) {
					if (i > 0) {
						message += '<br>';
					}
					message += errors[i];
				}
				Notification.error({
					message : $translate.instant('pharmaPortalApp.campaign.error.topMessage') + '<br>' + message,
					delay : ''
				});
				return false;
			}
			return true;
		}

		vm.statusItems = [ {
			value : true,
			label : $translate.instant('pharmaPortalApp.campaign.status.active'),
		}, {
			value : false,
			label : $translate.instant('pharmaPortalApp.campaign.status.inactive'),
		} ];

		vm.campaignTypes = [ {
			value : 'CHALLENGE',
			label : $translate.instant('pharmaPortalApp.campaignType.CHALLENGE'),
		}, {
			value : 'PROMOTION',
			label : $translate.instant('pharmaPortalApp.campaignType.PROMOTION'),
		} ];

		vm.datePopup = {
			start : {
				opened : false
			},
			end : {
				opened : false
			}
		};
		vm.openDatePopup = function(inputCalendar) {
			if (inputCalendar == 'start') {
				vm.datePopup.start.opened = true;
			} else {
				vm.datePopup.end.opened = true;
			}
		};

		vm.dateOptions = {
			formatYear : 'yyyy',
			startingDay : 1,
			showWeeks : false
		};
	}
})();
