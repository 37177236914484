(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PsychotropicsRequisitionsDetailController', PsychotropicsRequisitionsDetailController);

    PsychotropicsRequisitionsDetailController.$inject = ['$scope', 'pagingParams', '$state', 'paginationConstants', 'PsychotropicsRequisitions', 'ParseLinks'];

    function PsychotropicsRequisitionsDetailController($scope, pagingParams, $state, paginationConstants, PsychotropicsRequisitions, ParseLinks) {
        var vm = this;
        vm.requisition = pagingParams.requisition;
        vm.back = pagingParams.back;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        $scope.showSearch = false;

        vm.tableFilters = {
            invoice: null,
            quantitySent: null,
            description: null,
            cnp: null
        };

        if (pagingParams.requisition == null) {
            vm.requisition = JSON.parse(localStorage.getItem("requisition"));
        } else {
            vm.requisition = pagingParams.requisition;
            localStorage.setItem("requisition", JSON.stringify(vm.requisition));
        }

        vm.loadAllFilters = function () {
            PsychotropicsRequisitions.getAllFilterLines({
                idRequisition: vm.requisition.id
            }, onSuccessFilters, onError);
        };

        function onSuccessFilters(result) {
            vm.filters = result;
        }

        vm.loadAllFilters();

        vm.loadAll = function () {
            PsychotropicsRequisitions.getAllRequisitionLines({
                page: pagingParams.page - 1,
                tableFilters: JSON.stringify(vm.tableFilters),
                idRequisition: vm.requisition.id,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

        };

        vm.clearFilter = function () {
            vm.tableFilters = {
                invoice: null,
                quantitySent: null,
                description: null,
                cnp: null
            };
            vm.loadAll();

        };

        vm.loadAll();

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }


        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.infarmedRequisitionLines = data;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                idRequisition: vm.requisition.id,
                tableFilters: JSON.stringify(vm.tableFilters),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

    }
})
();
