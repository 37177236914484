(function() {
	'use strict';

	angular.module('pharmaPortalApp').directive('tableHoverHighlight', tableHoverHighlight);

	function tableHoverHighlight() {
		var directive = {
			restrict : 'A',
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element) {
			var highlightClass = "success";
			// DELEGATE ON TD:HOVER THE ADD AND REMOVE OF CLASS 'hover'
			$(element).delegate('td', 'mouseover mouseleave', function(e) {
				if (e.type == 'mouseover') {
					var curIdx = $(this).index() + 1;
					$(this).parent().addClass(highlightClass);
					$(element).find('tbody td:nth-child(' + (curIdx) + ')').addClass(highlightClass);
					$(element).find('thead th:nth-child(' + (curIdx) + ')').addClass(highlightClass);
					$(element).find('thead tr.topHeader th').removeClass(highlightClass);

					var j = 0;
					for (var i = 1; i <= $(this).index(); i++) {
						var elm = $(element).find('tr.topHeader th:nth-child(' + i + ')');

						if (elm.attr("colspan")) {
							curIdx -= parseInt(elm.attr("colspan"));
						} else {
							curIdx -= 1;
						}
						if (curIdx > 0) {
							j++;
						}
					}
					$(element).find('tr.topHeader th:nth-child(' + (j + 1) + ')').addClass(highlightClass);

					// HOVER ONLY IF HAS FLOAT THEAD
					var elm2 = $(element).parent().find('.floatThead-container .floatThead-table thead');
					if ($(elm2).length) {
						var curIdx2 = $(this).index() + 1;
						$(elm2).find('tr th:nth-child(' + (curIdx2) + ')').addClass(highlightClass);
						$(elm2).find('tr.topHeader th').removeClass(highlightClass);

						var k = 0;
						for (var i = 1; i <= $(this).index(); i++) {
							var el = $(elm2).find('tr.topHeader th:nth-child(' + i + ')');

							if (el.attr("colspan")) {
								curIdx2 -= parseInt(el.attr("colspan"));
							} else {
								curIdx2 -= 1;
							}
							if (curIdx2 > 0) {
								k++;
							}
						}
						$(elm2).find('tr.topHeader th:nth-child(' + (k + 1) + ')').addClass(highlightClass);
					}
				} else {
					$(element).find('td:nth-child(' + ($(this).index() + 1) + ')').removeClass(highlightClass);
					$(element).find('tr, th').removeClass(highlightClass);
					// UN-HOVER ONLY IF HAS FLOAT THEAD
					var elm2 = $(element).parent().find('.floatThead-container .floatThead-table thead');
					if ($(elm2).length) {
						$(elm2).find('th').removeClass(highlightClass);
					}
				}
			});
		}
	}
})();
