(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PurchaseConfirmOrderController', PurchaseConfirmOrderController);

    PurchaseConfirmOrderController.$inject = ['Notification', '$uibModalInstance', '$rootScope', '$http', '$localStorage', 'PortalSettings', '$state', 'Principal',
        'ModuleSettings'];

    function PurchaseConfirmOrderController(Notification, $uibModalInstance, $rootScope, $http, $localStorage, PortalSettings, $state, Principal, ModuleSettings) {
        var vm = this;

        vm.orderInfoValues = [];
        vm.orderReady = '';
        vm.orderDescription;
        vm.bodyRequest;
        vm.processing = false;

        vm.clear = clear;
        vm.confirmOrder = confirmOrder;
        vm.loadAll = loadAll;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmOrder() {
            vm.processing = true;

            vm.bodyRequest = {
                "description": vm.orderDescription,
                "orderProducts": $rootScope.purchaseOrderList,
                "invoiceTypeCode": vm.portalSettings.invoiceTypeCode,
                "invoiceTypeDesc": vm.portalSettings.invoiceTypeDesc,
                "billingTypeRetainedProductsCode": vm.portalSettings.billingTypeRetainedProductsCode,
                "billingTypeRetainedProductsDesc": vm.portalSettings.billingTypeRetainedProductsDesc,
                "chargeShippingCosts": vm.portalSettings.chargeShippingCosts,
                "cecServiceTypeCode": vm.portalSettings.cecServiceTypeCode,
                "cecServiceTypeDesc": vm.portalSettings.cecServiceTypeDesc,
                "shippingCosts": vm.portalSettings.shippingCosts,
                "isRetainedProductsOrder": $rootScope.isRetainedProductsOrder,
                "purchaseTotal": $rootScope.purchaseTotals.total,
                "configEmail": vm.portalSettings.salesEmail
            };

            $http.post("/api/pharma-purchase/saveOrder", vm.bodyRequest).then(function successCallback(response) {
                if (response.data.success) {
                    $rootScope.purchaseOrderList = [];
                    $rootScope.purchaseTotals = {
                        total: 0,
                        totalPvf: 0,
                        totalDesc: 0
                    };
                    $uibModalInstance.close(true);
                    delete $localStorage.purchaseOrder;
                    vm.processing = false;
                    Notification.success({message: response.data.message, delay: 5000});
                } else {
                    $uibModalInstance.close(true);
                    vm.processing = false;
                    Notification.error({message: response.data.message, delay: ''});
                }
            }, function errorCallback(response) {
                $uibModalInstance.close(true);
                vm.processing = false;
                Notification.error({message: response.data, delay: ''});
            });
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.purchasesModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        if (account && account.company) {
                            vm.portalSettings = PortalSettings.query(function (settings) {
                                if (settings.hasMinOrderValue == true && settings.minOrderValue > $rootScope.purchaseTotals.total && $rootScope.purchaseTotals.total > 0) {
                                    vm.orderInfoValues.minValue = settings.minOrderValue;
                                    vm.orderInfoValues.curDiff = settings.minOrderValue - $rootScope.purchaseTotals.total;
                                    vm.orderReady = '1';
                                } else if (vm.portalSettings.allowZeroPriceOrders && $rootScope.purchaseTotals.total == 0) {
                                    vm.orderReady = '2';
                                } else if ($rootScope.purchaseTotals.total <= 0) {
                                    vm.orderReady = '3';
                                } else {
                                    vm.orderInfoValues = [];
                                    vm.orderReady = '0';
                                }
                            });
                        } else {
                            $state.go('accessdenied');
                        }
                    });
                }
            });
        }

        loadAll();
    }
})();
