(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionLineDeleteController', PharmaTransfReceptionLineDeleteController);

	PharmaTransfReceptionLineDeleteController.$inject = [ 'Notification', '$uibModalInstance', '$translate', '$state', 'entity', 'PharmaTransfReceptionLine' ];

	function PharmaTransfReceptionLineDeleteController(Notification, $uibModalInstance, $translate, $state, entity, PharmaTransfReceptionLine) {
		var vm = this;

		vm.clear = clear;
		vm.confirmPharmaTransfReceptionLineDelete = confirmPharmaTransfReceptionLineDelete;
		vm.pharmaTransfReceptionLine = entity;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onDeleteSuccess(result) {
			$uibModalInstance.close(true);
			
			Notification.success({message: $translate.instant('pharmaPortalApp.pharmaTransfReception.message.pharmaTransfReceptionLineDeleted'), delay: 5000});
		}

		function onDeleteError(result) {
			$uibModalInstance.close(true);
			
			if(result.data.success) {
				Notification.success({message: result.data.message, delay: 5000});
			} else {
				Notification.error({message: result.data.message, delay: ''});
			}
		}

		function confirmPharmaTransfReceptionLineDelete() {
			PharmaTransfReceptionLine.delete({id: vm.pharmaTransfReceptionLine.id}, onDeleteSuccess, onDeleteError);
		}
		
	}
})();