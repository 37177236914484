(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ProductStockQtdImportController', ProductStockQtdImportController);

    ProductStockQtdImportController.$inject = ['Notification', 'blockUI', '$uibModalInstance', '$translate', '$stateParams', 'ProductStockQtd', 'Upload'];

    function ProductStockQtdImportController(Notification, blockUI, $uibModalInstance, $translate, $stateParams, ProductStockQtd, Upload) {
        var vm = this;

        vm.excelAction = "update";
        vm.clear = clear;
        vm.uploadFile = uploadFile;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        ProductStockQtd.query({
            id: $stateParams.id
        }, onSuccess, onError);

        function onSuccess(data, headers) {
            vm.productStockQtds = data;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function uploadFile(file) {
            blockUI.start($translate.instant('pharmaPortalApp.policyDefinition.productStockQtd.message.uploadingFile'));

            file.upload = Upload.upload({
                url: '/api/product-stock-qtd/upload',
                fields: {
                    excelAction: vm.excelAction,
                    policyInputId: $stateParams.id
                },
                file: file
            }).then(function (response) {
                blockUI.stop();
                $uibModalInstance.dismiss('cancel');

                if (response.data.success) {
                    Notification.success({message: response.data.message, delay: 5000});
                } else {
                    Notification.error({message: response.data.message, delay: ''});
                }

            }, function (response) {
                blockUI.stop();

                if (response.data.success) {
                    Notification.success({message: response.data.message, delay: 5000});
                } else {
                    Notification.error({message: response.data.message, delay: ''});
                }
            });
        }
    }
})();
