(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CampaignDetailDialogController', CampaignDetailDialogController);

	CampaignDetailDialogController.$inject = [ '$uibModalInstance', 'entity', 'Campaign', 'ModuleSettings' ];

	function CampaignDetailDialogController($uibModalInstance, entity, Campaign, ModuleSettings) {
		var vm = this;

		vm.campaign = entity;
		
		vm.clear = clear;
		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;
				
				if (!vm.moduleSettings.campaignsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}
	}
})();