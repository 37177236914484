(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('CampaignEditController', CampaignEditController);

    CampaignEditController.$inject = ['Notification', 'blockUI', 'entity', 'Campaign', 'Company', 'CampaignProduct', '$translate', '$window', 'Upload', 'moment', '$state', '$scope', '$filter', 'ModuleSettings'];

    function CampaignEditController(Notification, blockUI, entity, Campaign, Company, CampaignProduct, $translate, $window, Upload, moment, $state, $scope, $filter, ModuleSettings) {
        var vm = this;

        vm.campaign = entity;
        vm.campaign.startDate = new Date(vm.campaign.startDate);
        vm.campaign.endDate = new Date(vm.campaign.endDate);
        vm.companies = Company.query({size: 1000});

        vm.loadAll = loadAll;
        vm.uploadFile = uploadFile;
        vm.getTemplate = getTemplate;

        vm.cloneCampaignProduct = cloneCampaignProduct;
        vm.save = save;
        vm.saveCampaign = saveCampaign;
        vm.validateCampaign = validateCampaign;
        vm.toggleLineEditMode = toggleLineEditMode;
        vm.flatListToTreeViewData = flatListToTreeViewData;

        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.campaignsModule) {
                    $state.go('accessdenied');
                } else {
                    $scope.productContainerList = {};
                    $scope.productContainerList.items = [];

                    if (vm.campaign.campaignType === 'CHALLENGE') {
                        $scope.productContainerList.items = vm.campaign.products;
                    } else {
                        $scope.campaignProductList = [];
                        $scope.campaignProductList = vm.flatListToTreeViewData(vm.campaign.products);

                        angular.forEach(vm.campaign.products, function (prod) {
                            if (prod.promoLine || prod.promoRef == null) {
                                $scope.productContainerList.items.push(prod);
                            }
                        });
                        $scope.productContainerList.dragging = false;
                        $scope.campaignProductList.sort(function (a, b) {
                            var textA = a.description.toUpperCase();
                            var textB = b.description.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        setMenuOptions();
                    }
                }
            });

        }

        function uploadFile(file) {
            blockUI.start($translate.instant('pharmaPortalApp.campaignProduct.message.uploadingTemplate'));
            file.upload = Upload.upload({
                url: '/api/campaigns/upload',
                fields: {campaignId: vm.campaign.id},
                file: file
            }).then(function (response) {
                file.result = response.data;
                blockUI.stop();

                if (response.data.success) {
                    Notification.success({message: response.data.message, delay: 5000});
                } else {
                    Notification.error({message: response.data.message, delay: ''});
                }

                $state.go("campaign.edit", {id: vm.campaign.id}, {reload: true});
            }, function (response) {
                blockUI.stop();

                if (response.data.success) {
                    Notification.success({message: response.data.message, delay: 5000});
                } else {
                    Notification.error({message: response.data.message, delay: ''});
                }

                $state.go("campaign.edit", {id: vm.campaign.id}, {reload: true});
            }, function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }

        function getTemplate() {
            $window.open("/api/campaigns/template");
        }

        function cloneCampaignProduct(product) {
            if (product.promoLine) {
                Notification.error({message: $translate.instant('pharmaPortalApp.campaign.error.promoLineClone'), delay: ''});
            } else {
                vm.newProduct = {};
                angular.copy(product, vm.newProduct);
                vm.newProduct.id = null;
                vm.newProduct.campaign = vm.campaign;
                CampaignProduct.save(vm.newProduct, onSaveSuccess, onSaveError);
            }
        }

        vm.statusItems = [{
            value: true,
            label: $translate.instant('pharmaPortalApp.campaign.status.active')
        }, {
            value: false,
            label: $translate.instant('pharmaPortalApp.campaign.status.inactive')
        }];

        vm.campaignTypes = [{
            value: 'CHALLENGE',
            label: $translate.instant('pharmaPortalApp.campaignType.CHALLENGE')
        }, {
            value: 'PROMOTION',
            label: $translate.instant('pharmaPortalApp.campaignType.PROMOTION')
        }];

        vm.brandTypes = [{
            value: 'GOLD',
            label: $translate.instant('pharmaPortalApp.brandType.GOLD')
        }, {
            value: 'SILVER',
            label: $translate.instant('pharmaPortalApp.brandType.SILVER')
        }];

        vm.datePopup = {
            start: {opened: false},
            end: {opened: false}
        };

        vm.openDatePopup = function (inputCalendar) {
            if (inputCalendar == 'start') {
                vm.datePopup.start.opened = true;
            } else {
                vm.datePopup.end.opened = true;
            }
        };

        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };


        function saveCampaign(isButtonAction) {
            if (validateCampaign()) {
                vm.isSaving = true;

                if (vm.campaign.campaignType === 'CHALLENGE') {
                    // SE FOR DESAFIO LIMPA AS PROMOLINE E PROMOREF TODAS
                    angular.forEach(vm.campaign.products, function (product) {
                        product.promoLine = null;
                        product.promoRef = null;
                        product.promoRefId = null;
                        product.brandType = null;
                    });
                } else {
                    // SE FOR PROMOÇÃO MAPEAR LISTA DND PARA BD
                    $scope.tempProd = [];

                    if ($scope.campaignProductList) {
                        angular.forEach($scope.campaignProductList, function (model) {
                            angular.forEach(model.items, function (myItem) {
                                myItem.promoRef = model.cnp;
                                myItem.promoRefId = model.id;
                                myItem.promoLine = false;
                                $scope.tempProd.push(myItem);
                            });
                        });
                    }

                    vm.campaign.products = $scope.tempProd;

                    if ($scope.productContainerList) {
                        angular.forEach($scope.productContainerList.items, function (product) {
                            if (product.promoRef == null || product.promoLine == true) {
                                vm.campaign.products.push(product);
                            }
                        });
                    }

                }

                if (vm.campaign.id !== null) {
                    if (isButtonAction) {
                        Campaign.update(vm.campaign, function (res) {
                            vm.isSaving = false;
                            Notification.success({message: $translate.instant("pharmaPortalApp.campaign.updated"), delay: 5000});
                            $state.go("campaign");
                        }, onSaveError);
                    } else {
                        Campaign.update(vm.campaign, onAutoSaveSuccess, onAutoSaveError);
                    }
                } else {
                    Campaign.save(vm.campaign, onSaveSuccess, onSaveError);
                }
            }
        }

        function onAutoSaveSuccess(result) {
            Notification.success({
                message: $translate.instant("pharmaPortalApp.campaign.updated"),
                delay: 5000
            });
            vm.isSaving = false;
        }

        function onAutoSaveError(result) {
            vm.isSaving = false;
            Notification.error({
                message: $translate.instant("pharmaPortalApp.campaign.error.create"),
                delay: ''
            });
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            Notification.success({message: $translate.instant("pharmaPortalApp.campaign.updated"), delay: 5000});
            $state.go("campaign.edit", {
                id: vm.campaign.id
            }, {
                reload: 'campaign'
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function validateCampaign() {
            var errors = [];
            // NAME
            if (vm.campaign.name == null || vm.campaign.name == '') {
                errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.name'));
            }
            // CAMPAIGN TYPE
            if (vm.campaign.campaignType == null || vm.campaign.campaignType == '') {
                errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.campaignType'));
            }
            // CAMPAIGN STATUS
            if (vm.campaign.campaignStatus === null || vm.campaign.campaignStatus === '') {
                errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.campaignStatus'));
            }
            // START DATE - END DATE
            if (vm.campaign.startDate == null || vm.campaign.startDate == '' || vm.campaign.endDate == null || vm.campaign.endDate == '') {
                errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.dates'));
            } else {
                var checkDiff = true;
                // TODO: UNCHECK THIS DATE VALIDATION
//				if (moment().diff(vm.campaign.startDate, 'days') > 0) {
//					errors.push($translate.instant('pharmaPortalApp.campaign.error.dates.oldStartDate'));
//					checkDiff = false;
//				}
//				if (moment().diff(vm.campaign.endDate, 'days') > 0) {
//					errors.push($translate.instant('pharmaPortalApp.campaign.error.dates.oldEndDate'));
//					checkDiff = false;
//				}
                if (checkDiff && moment(vm.campaign.startDate).diff(vm.campaign.endDate, 'days') > 0) {
                    errors.push($translate.instant('pharmaPortalApp.campaign.error.dates.endBeforeStart'));
                }
            }
            // COMPANIES
            if (vm.campaign.companies == null || vm.campaign.companies.length <= 0) {
                errors.push($translate.instant('pharmaPortalApp.campaign.error.empty.companies'));
            }

            if (errors.length > 0) {
                var message = '';
                for (var i = 0; i < errors.length; i++) {
                    if (i > 0) {
                        message += '<br>';
                    }
                    message += errors[i];
                }
                Notification.error({message: $translate.instant('pharmaPortalApp.campaign.error.topMessage') + '<br>' + message, delay: ''});
                return false;
            }
            return true;
        }

        function toggleLineEditMode(campaignProduct) {
            if (angular.isDefined(campaignProduct.editMode) && campaignProduct.editMode == true) {
                campaignProduct.editMode = false;
            } else {
                campaignProduct.editMode = true;
            }
//			save();
        }


        $scope.onSelectItem = function (list, item, event) {
            if (angular.element(event.srcElement).hasClass("dragHandle") || event.target.nodeName == "TD") {
                if (item.promoLine) {
                    Notification.warning({message: "Artigos de promoção não podem ser movidos", delay: ''});
                } else {
                    item.selected = !item.selected;

                    var found = $filter('filter')(list, {cnp: item.cnp, selected: true}, true);
                    if (found.length > 1) {
                        item.selected = !item.selected
                        Notification.warning({message: "Não pode selecionar 2 artigos com o mesmo CNP", delay: ''});
                    } else {
                        // SELECIONADO / ADICIONADO COM SUCESSO
                    }
                }
            }
        };

        /**
         * dnd-dragging determines what data gets serialized and send to the receiver
         * of the drop. While we usually just send a single object, we send the array
         * of all selected items here.
         */
        $scope.getSelectedItemsIncluding = function (list, item) {
            item.selected = true;
            return list.items.filter(function (item) {
                return item.selected;
            });
        };

        /**
         * We set the list into dragging state, meaning the items that are being
         * dragged are hidden. We also use the HTML5 API directly to set a custom
         * image, since otherwise only the one item that the user actually dragged
         * would be shown as drag image.
         */
        $scope.onDragstart = function (list, event) {
            list.dragging = true;
            if (event.dataTransfer.setDragImage) {
                var img = new Image();
                img.src = 'content/images/copy_black.png';
                event.dataTransfer.setDragImage(img, 0, 0);
            }
        };

        /**
         * In the dnd-drop callback, we now have to handle the data array that we
         * sent above. We handle the insertion into the list ourselves. By returning
         * true, the dnd-list directive won't do the insertion itself.
         */
        $scope.onDrop = function (list, items, index) {
            var validItems = [];
            angular.forEach(items, function (curItem) {
                var found = $filter('filter')(list.items, {cnp: curItem.cnp}, true);
                if (found.length > 0) {
                    curItem.selected = !curItem.selected;
                    $scope.productContainerList.items.push(curItem);
                    Notification.warning({message: "Não é permitido ter mais do que 1 artigo com o mesmo CNP na mesma promoção. Estes não serão adicionados", delay: ''});
                } else {
                    // SELECIONADO / ADICIONADO COM SUCESSO
                    curItem.selected = false;
                    validItems.push(curItem);
                }
            });

            list.items = list.items.slice(0, index)
                .concat(validItems)
                .concat(list.items.slice(index));
            return true;
        }

        $scope.onDragEnd = function (productContainerList) {
            productContainerList.dragging = false
            saveCampaign();
        };
        /**
         * Last but not least, we have to remove the previously dragged items in the
         * dnd-moved callback.
         */
        $scope.onMoved = function (list) {
            list.items = list.items.filter(function (item) {
                return !item.selected;
            });
        };


        /**
         * ADD ITEM TO PRODUCT LIST
         * REMOVE ITEM FROM CONTAINER
         */
        $scope.dropItem = function (list, item) {
            // DEVOLVE ITEM À TABELA
            item.promoRef = null;
            item.promoRefId = null;
            $scope.productContainerList.items.push(item);
            // REMOVE ITEM DO CONTAINER
            var index = list.items.indexOf(item);
            list.items.splice(index, 1);
            saveCampaign();
        };

        function save() {
            if (validateCampaign()) {
                vm.isSaving = true;

                if (vm.campaign.campaignType !== 'CHALLENGE') {
                    $scope.promoLineCNP = [];
                    angular.forEach(vm.campaign.products, function (prod) {
                        if (prod.promoLine) {
                            $scope.promoLineCNP.push(prod.cnp);
                        } else {
                            prod.brandType = null;
                        }
                    });

                    angular.forEach(vm.campaign.products, function (prod) {
                        if (prod.promoRef != null && $scope.promoLineCNP.indexOf(prod.promoRef) < 0) {
                            prod.promoRef = null;
                            prod.promoRefId = null;
                        }
                    });
                }

                if (vm.campaign.id !== null) {
                    Campaign.update(vm.campaign, onSaveSuccess, onSaveError);
                } else {
                    Campaign.save(vm.campaign, onSaveSuccess, onSaveError);
                }
            }
        }

        // FLAT LIST TO TREE VIEW DATA
        function flatListToTreeViewData(dataList) {
            var tree = [], mappedArr = {}, arrElem, mappedElem;

            for (var i = 0, len = dataList.length; i < len; i++) {
                arrElem = dataList[i];
                mappedArr[arrElem.id] = arrElem;
                mappedArr[arrElem.id]['items'] = [];
            }

            for (var id in mappedArr) {
                if (mappedArr.hasOwnProperty(id)) {
                    mappedElem = mappedArr[id];
                    // ARRAY OF CHILDREN.
                    if (mappedElem.promoRefId) {
                        mappedArr[mappedElem['promoRefId']]['items'].push(mappedElem);
                    } else if (mappedElem.promoLine) {
                        tree.push(mappedElem);
                    }
                }
            }
            return tree;
        }

        vm.searchInTable = "";
        vm.selectTab = 0;
        vm.clearSearchInTable = clearSearchInTable;
        vm.selectAllValid = selectAllValid;
        vm.unselectAll = unselectAll;
        vm.onTabSelect = onTabSelect;

        function clearSearchInTable() {
            vm.searchInTable = "";
        };

        function unselectAll() {
            // UNSELECT ALL
            angular.forEach($scope.productContainerList.items, function (product) {
                product.selected = false;
            });
        }

        function selectAllValid() {
            vm.unselectAll();

            // GET ALREADY ALREADY ASSOCIATED LIST
            var curAddedProducts = [];
            var curAddedProducts = $scope.campaignProductList[vm.selectTab].items;
            var curAddedCnp = curAddedProducts.map(function (obj) {
                return obj.cnp;
            });

            // SELECT ONLY VALID TO DROP
            angular.forEach($filter('filter')($scope.productContainerList.items, vm.searchInTable), function (product) {
                if (!product.promoLine && curAddedCnp.indexOf(product.cnp) < 0) {
                    product.selected = true;
                    curAddedCnp.push(product.cnp);
                }
            });
        };

        function onTabSelect(index) {
            vm.selectTab = index;
        };

        function setMenuOptions() {
            var campaignDesc = [];
            var x = 0;
            angular.forEach($scope.campaignProductList, function (item) {
                var newItem = {
                    tab: x,
                    text: item.cnp + " - " + item.description,
                    click: function ($itemScope, $event, modelValue, text, $li) {
                        if (!$itemScope.product.promoLine) {
                            var list = [];
                            $itemScope.product.selected = true;
                            list = $scope.productContainerList.items.filter(function (item) {
                                return item.selected;
                            });
                            angular.forEach(list, function (item) {
                                // DEVOLVE ITEM À TABELA
                                item.promoRef = null;
                                item.promoRefId = null;
                                $scope.campaignProductList[newItem.tab].items.push(item);
                                // REMOVE ITEM DO CONTAINER
                                var index = $scope.productContainerList.items.indexOf(item);
                                $scope.productContainerList.items.splice(index, 1);
                                saveCampaign();
                                item.selected = false;
                            });
                            onTabSelect(newItem.tab);
                        }else{
                            Notification.warning({message: "Artigos de promoção não podem ser movidos", delay: 5000});
                        }
                    }
                };
                campaignDesc.push(newItem);
                x++;
            });
            $scope.menuOptions = [
                ['Adicionar a...',
                    campaignDesc
                ]
            ];
        }

        $scope.menuOptions2 = [
            {
                text: 'Remover',
                click: function ($itemScope, $event, modelValue, text, $li) {
                    var list = [];
                    $itemScope.item.selected = true;
                    list = $scope.campaignProductList[vm.selectTab].items.filter(function (item) {
                        return item.selected;
                    });
                    angular.forEach(list, function (item) {
                        // DEVOLVE ITEM À TABELA
                        item.promoRef = null;
                        item.promoRefId = null;
                        $scope.productContainerList.items.push(item);
                        // REMOVE ITEM DO CONTAINER
                        var index = $scope.campaignProductList[vm.selectTab].items.indexOf(item);
                        $scope.campaignProductList[vm.selectTab].items.splice(index, 1);
                        saveCampaign();
                    });
                    unselectAll();
                }
            }
        ];
    }
})();
