(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('NavbarIOController', NavbarIOController);

	NavbarIOController.$inject = [ '$rootScope', '$scope', 'Principal', '$state', 'Auth', '$interval', '$animate', '$http', 'PortalSettings' ];

	function NavbarIOController ($rootScope, $scope, Principal, $state, Auth, $interval, $animate, $http, PortalSettings) {
		var vm = this;
		vm.settings = PortalSettings.query();
		
		vm.authenticationError = false;
		vm.username = null;
		vm.password = null;
		vm.rememberMe = true;
		vm.login = login;
		vm.logout = logout;
		vm.goHome = goHome;
		vm.requestResetPassword = requestResetPassword;

		vm.account = null;
		vm.isAuthenticated = null;
		vm.environment = null;

		$scope.$on('authenticationSuccess', function() {
			getAccount();
		});
		
		getAccount();
		
		$scope.status = {
			isLoginOpen: false
		};
		
		$rootScope.toggleDropdown = function($event) {
			$event.preventDefault();
			$event.stopPropagation();
			$scope.isNavCollapsed = false;
			$scope.status.isLoginOpen = !$scope.status.isLoginOpen;
		};
		
		function getAccount() {
			Principal.identity().then(function(account) {
				vm.account = account;
				if(account && account.authorities.length){
					vm.isAuthenticated = Principal.isAuthenticated;
					// $state.go('pharma-purchase');
				} else {
					if(vm.username && vm.password) {
						vm.authenticationError = true;
					}
				}
			}).catch(function () {
				vm.isAuthenticated = null;
				$state.go('home.io');
			});
		}
		
		function login(event) {
			event.preventDefault();
			Auth.login({
				username: vm.username,
				password: vm.password,
				rememberMe: vm.rememberMe
			}).then(function () {
				vm.authenticationError = false;
				$state.go('pharma-purchase');
			}).catch(function () {
				vm.authenticationError = true;
			});
		};

		function logout() {
			Auth.logout();
			$state.go('home.io');
		}
		
		function goHome() {
			$state.go('pharma-purchase');
		}
		
		function requestResetPassword () {
			$state.go('requestReset');
		}
		
		vm.getAccount = getAccount;
	}
})();