(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('SaleClearOrderController', SaleClearOrderController);

	SaleClearOrderController.$inject = [ 'Notification', '$uibModalInstance', '$rootScope', '$localStorage', '$state', 'Principal', 'ModuleSettings' ];

	function SaleClearOrderController(Notification, $uibModalInstance, $rootScope, $localStorage, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.clear = clear;
		vm.confirmClearOrder = confirmClearOrder;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function confirmClearOrder() {
			$rootScope.saleOrderList = [];
			$rootScope.saleTotals = {
				total : 0,
				totalPvf : 0,
				totalDesc : 0
			};
			$uibModalInstance.close(true);
			delete $localStorage.saleOrder;
			Notification.warning({
				message : "Todos os produtos da encomenda foram removidos",
				delay : 5000
			});
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.salesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (account && account.company) {
							// DO NOTHING
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();