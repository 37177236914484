(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ReportByReportGroupController', ReportByReportGroupController);

    ReportByReportGroupController.$inject = ['DataUtils', 'Report', 'ParseLinks', '$http', '$state', '$stateParams', 'ModuleSettings', '$scope', 'Principal', '$timeout', 'Notification', '$translate'];

    function ReportByReportGroupController(DataUtils, Report, ParseLinks, $http, $state, $stateParams, ModuleSettings, $scope, Principal, $timeout, Notification, $translate) {
        var vm = this;

        vm.reportGroup = {
            group: $stateParams.reportGroup
        };
        vm.reports = [];
        vm.userReports = [];
        vm.orderedReports = [];
        vm.canSave = false;
        vm.userForQuery = {};

        vm.loadAll = loadAll;

        function loadAll() {
            vm.canSave = false;
            Principal.identity().then(function (account) {
                vm.loggedUser = account;
                vm.userForQuery.login = vm.loggedUser.login;
                Report.getUserReportsOrder(vm.userForQuery, function (result) {
                    vm.userReports = result;
                }, function (error) {
                });
            });
            Report.getByReportGroup(vm.reportGroup, function (result) {
                vm.reports = result;
                if (vm.userReports.length == 0) {
                    vm.orderedReports = vm.reports;
                    for (var i = 0; i < vm.orderedReports.length; i++) {
                        vm.orderedReports[i].order = i;
                    }
                } else {
                    for (var i = 0; i < vm.reports.length; i++) {
                        var report = vm.reports[i];
                        var found = false;
                        for (var j = 0; j < vm.userReports.length; j++) {
                            if (report.id == vm.userReports[j].reportId) {
                                report.order = vm.userReports[j].orderIndex;
                                vm.orderedReports.push(report);
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            report.order = vm.reports.length;
                            vm.orderedReports.push(report);
                        }
                    }
                }
                vm.orderedReports.sort(function (a, b) {
                    return a.order - b.order
                });
            }, function (error) {
            });
        }

        loadAll();

        $scope.onDrop = function (item) {
            item.selected = false;
            vm.canSave = true;
        };

        $scope.onDragStart = function (item) {
            item.selected = true;
        };

        $scope.save = function (list) {
            for (var i = 0; i < list.length; i++) {
                list[i].order = i;
                var report = {};
                report.userLogin = vm.loggedUser.login;
                report.reportId = list[i].id;
                report.orderIndex = list[i].order;

                Report.saveReportOrder(report, function (result) {
                    if (result != null) {
                        var success = true;
                    } else {
                        var success = false;
                        Notification.error({
                            message : $translate.instant('pharmaPortalApp.company.message.errorUpdating'),
                            delay : ''
                        });
                    }
                }, function (error) {
                    Notification.error({message : $translate.instant('pharmaPortalApp.company.message.errorUpdating'), delay : ''});
                });
            }
            Notification.success({message: $translate.instant('pharmaPortalApp.reports.saveSuccess'), delay: 5000});
        }
    }
})();
