(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ProductErpIntegrationDetailController', ProductErpIntegrationDetailController);

    ProductErpIntegrationDetailController.$inject = ['Notification', '$http', '$state', '$translate', '$filter', 'entity', 'ModuleSettings', '$stateParams', 'pagingParams',
                                                     '$scope', 'ParseLinks', 'ProductErpIntegration'];

    function ProductErpIntegrationDetailController(Notification, $http, $state, $translate, $filter, entity, ModuleSettings, $stateParams, pagingParams,
                                                   $scope, ParseLinks, ProductErpIntegration) {
        var vm = this;
        vm.working = false;
        vm.productErpIntegration = entity;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = 50;

        loadAll();
        vm.transition = transition;
        vm.canBeIntegrated = canBeIntegrated;
        vm.formatSizeUnits = formatSizeUnits;
        vm.formatErrorMessages = formatErrorMessages;
        vm.getIntegrationResult = getIntegrationResult;
        vm.getFormattedLineStatusMessage = getFormattedLineStatusMessage;
        vm.getFormattedClientPE = getFormattedClientPE;
        vm.integrateIntoERP = integrateIntoERP;

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.productsModule) {
                    $state.go('accessdenied');
                } else {
                    if (vm.productErpIntegration.integrationType == 'PRODUCTS') {
                        getProductLineByProductErpIntegration();
                    } else {
                        getPriceLineByProductErpIntegration();
                    }
                }
            });
        }

        function transition() {
            pagingParams.page = vm.page;
            if (vm.productErpIntegration.integrationType == 'PRODUCTS') {
                getProductLineByProductErpIntegration();
            } else {
                getPriceLineByProductErpIntegration();
            }
        }

        function getProductLineByProductErpIntegration() {
            ProductErpIntegration.findAllProductLineByProductErpIntegration({
                id: vm.productErpIntegration.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function getPriceLineByProductErpIntegration() {
            ProductErpIntegration.findAllPriceLineByProductErpIntegration({
                id: vm.productErpIntegration.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.lines = data;
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function canBeIntegrated() {
            return (vm.productErpIntegration.integrationStatus !== 'INTEGRATED' && vm.productErpIntegration.validLines != 0);
        }

        function formatSizeUnits(bytes) {
            if (bytes >= 1073741824) {
                bytes = (bytes / 1073741824).toFixed(2) + " GB";
            } else if (bytes >= 1048576) {
                bytes = (bytes / 1048576).toFixed(2) + " MB";
            } else if (bytes >= 1024) {
                bytes = (bytes / 1024).toFixed(2) + " KB";
            } else if (bytes > 1) {
                bytes = bytes + " bytes";
            } else if (bytes == 1) {
                bytes = bytes + " byte";
            } else {
                bytes = "0 bytes";
            }
            return bytes;
        }

        function formatErrorMessages(messageArray) {
            return JSON.parse(messageArray);
        }

        function getIntegrationResult() {
            var str = "";
            if (vm.productErpIntegration.integrationType == 'PRODUCTS') {
                var insertedProds = $filter('filter')(vm.productErpIntegration.productLines, {
                    status: 'OK',
                    alreadyExist: false
                }, true);

                var updatedProds = $filter('filter')(vm.productErpIntegration.productLines, {
                    status: 'OK',
                    alreadyExist: true
                }, true);

                str += insertedProds.length + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.inserted') + " / ";
                str += updatedProds.length + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.updated');
            } else {
                var insertedPrices = $filter('filter')(vm.productErpIntegration.priceLines, {
                    status: 'OK',
                }, true);

                str += insertedPrices.length + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.inserted');
            }
            return str;
        }

        function getFormattedLineStatusMessage(productErpIntegration) {
            var str = "" + productErpIntegration.nbLines;
            str += " (" + productErpIntegration.validLines + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.valid') + " / ";
            str += productErpIntegration.errorLines + " " + $translate.instant('pharmaPortalApp.productErpIntegration.import.invalid') + ")";
            return str;
        }

        function getFormattedClientPE(priceLine) {
            var str = "";
            if (priceLine.cliente != null) {
                str += priceLine.cliente;
            } else if (priceLine.pontoEntrega != null) {
                str += "---";
            }
            if (priceLine.cliente != null || priceLine.pontoEntrega != null) {
                str += "/";
            }
            if (priceLine.pontoEntrega != null) {
                return priceLine.cliente + '/' + priceLine.pontoEntrega
            } else if (priceLine.cliente != null) {
                str += "---";
            }
            return str;
        }

        function integrateIntoERP() {
            vm.working = true;
            $http.put("api/product-erp-integration/integrateIntoERP", vm.productErpIntegration).then(function (response) {
                if (response.status === 200) {
                    Notification.success({
                        message: $translate.instant(response.data.statusMessage),
                        delay: 5000
                    });
                    vm.productErpIntegration = response.data;
                } else if (response.data.statusMessage != "") {
                    Notification.error({
                        message: $translate.instant(response.data.statusMessage),
                        delay: 5000
                    });
                } else {
                    Notification.error({
                        message: $translate.instant('pharmaPortalApp.productErpIntegration.integration.message.error'),
                        delay: 5000
                    });
                }
                vm.working = false;
            }, function (result) {
                console.log("Error! Status = " + result.status + " Message = " + result);
                Notification.error({
                    message: $translate.instant('pharmaPortalApp.productErpIntegration.integration.message.error'),
                    delay: 5000
                });
                vm.working = false;
            });
        }
    }
})();
