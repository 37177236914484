(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingSubmitOverQuantityController', PharmaPickingSubmitOverQuantityController);

	PharmaPickingSubmitOverQuantityController.$inject = [ 'Notification', '$rootScope','blockUI', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'pharmaPicking', 'PharmaPicking', 'PortalSettings', 'ModuleSettings', 'user' ];

	function PharmaPickingSubmitOverQuantityController(Notification, $rootScope,blockUI, $uibModalInstance, $translate, $state, $http, $filter, pharmaPicking, PharmaPicking, PortalSettings, ModuleSettings, user) {
		var vm = this;

		vm.portalSettings = PortalSettings.query();
		
		vm.loadAll = loadAll;

		vm.clear = clear;
		vm.confirmPharmaPickingSubmit = confirmPharmaPickingSubmit;
		vm.pharmaPicking = pharmaPicking;
		vm.user = user.user;
		vm.supplier = vm.pharmaPicking.supplierCode + " - " + vm.pharmaPicking.supplierName;

		vm.passwordInvalid = false;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.pickingModule) {
					$state.go('accessdenied');
				}
			});
		}
		vm.loadAll();


		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onSubmitSuccess(result) {
			$uibModalInstance.close(true);
			blockUI.stop();
			if (result.success){
				Notification.success({message: result.message, delay: 5000});
				$state.go("pharma-picking", {}, {
					reload : true
				});
			} else {
				Notification.error({message: result.message, delay: ''});
				$state.go("pharma-picking-detail.edit", {
					id : vm.pharmaPicking.id
				}, {
					reload : true
				});
			}
		}

		function onSubmitError(result) {
			blockUI.stop();
			$uibModalInstance.close(true);

			if(result.success){
				Notification.success({message: result.message, delay: 5000});
			} else {
				Notification.error({message: result.message, delay: ''});
			}
		}

		function confirmPharmaPickingSubmit() {
			vm.passwordInvalid = false;
			if(vm.password != vm.portalSettings.pickingPassword){
				vm.passwordInvalid = true;
			}
			else{
				blockUI.start($translate.instant('pharmaPortalApp.pharmaPicking.message.submittingPharmaPicking'));

                vm.bodyRequest = {
                    "idPharmaPicking": pharmaPicking.id,
                    "userCloseConference": $rootScope.userCloseConference,
                    "supplierCode" : vm.pharmaPicking.supplierCode
                };

                $http.post("/api/pharma-picking/submit", vm.bodyRequest).then(function successCallback(response) {
                    onSubmitSuccess(response.data)
                }, function errorCallback(response) {
                    onSubmitError(response.data)
                });
			}
		}
	}
})();
