(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CampaignDeleteController',CampaignDeleteController);

	CampaignDeleteController.$inject = ['Notification', '$uibModalInstance', 'Campaign', '$translate', 'ModuleSettings', 'campaignId' ];

	function CampaignDeleteController(Notification, $uibModalInstance, Campaign, $translate, ModuleSettings, campaignId) {
		var vm = this;


		vm.campaignId = campaignId;

		vm.clear = clear;
		vm.confirmDelete = confirmDelete;
		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.campaignsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmDelete (id) {
			Campaign.delete({id: id}, function () {
				Notification.success({message: $translate.instant('pharmaPortalApp.campaign.deleted'), delay: 5000});
				$uibModalInstance.close(true);
			});
		}
	}
})();
