(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('SelloutIntegrationHistory', SelloutIntegrationHistory);

	SelloutIntegrationHistory.$inject = [ '$resource' ];

	function SelloutIntegrationHistory($resource) {
		var resourceUrl = 'api/sellout-integration-histories/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			},
            'getCompletedImports' : {
			    method : 'GET',
                isArray : true,
                url : 'api/completedSellouts'
            },
            'removeIntegration': {
                method : 'DELETE',
                url : 'api/sellout-integration-histories/removeIntegration/:id'
            }
		});
	}
})();
