(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalNotificationDialogController', PortalNotificationDialogController);

    PortalNotificationDialogController.$inject = ['Notification', '$scope', '$uibModalInstance', '$translate', 'entity', 'PortalNotification', 'User', 'Company'];

    function PortalNotificationDialogController(Notification, $scope, $uibModalInstance, $translate, entity, PortalNotification, User, Company) {
        var vm = this;
        vm.users = [];
        vm.companies = [];
        vm.portalNotification = entity;

        vm.clear = clear;
        vm.save = save;
        vm.loadAll = loadAll;

        function loadAll() {

            vm.users = User.queryMyCompanyUsers();

            Company.query(function (data) {
                vm.companies = data;
            }, function onError(error) {
                Notification.error({message: error.data.message, delay: ''});
            });
        }
        loadAll();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if ((vm.portalNotification.destinationId != null && vm.portalNotification.companyDestinationId != null) || (vm.portalNotification.destinationId == null && vm.portalNotification.companyDestinationId == null)) {
                vm.errorDestination = true;
                vm.isSaving = false;
            } else {
                vm.isSaving = true;
                if (vm.portalNotification.id !== null) {
                    PortalNotification.update(vm.portalNotification, onSaveSuccess, onSaveError);
                } else {
                    PortalNotification.save(vm.portalNotification, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('pharmaPortalApp:portalNotificationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(result) {
            vm.isSaving = false;
        }

        vm.categories = [{
            value: 'ERROR',
            label: $translate.instant('PortalNotificationCategory.ERROR'),
        }, {
            value: 'INFO',
            label: $translate.instant('PortalNotificationCategory.INFO'),
        }, {
            value: 'MESSAGE',
            label: $translate.instant('PortalNotificationCategory.MESSAGE'),
        }];

        vm.priorities = [{
            value: 'HIGH',
            label: $translate.instant('PortalNotificationPriority.HIGH')
        }, {
            value: 'MEDIUM',
            label: $translate.instant('PortalNotificationPriority.MEDIUM')
        }, {
            value: 'LOW',
            label: $translate.instant('PortalNotificationPriority.LOW')
        }];
    }
})();
