(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PortalAppDialogController', PortalAppDialogController);

	PortalAppDialogController.$inject = [ '$uibModalInstance', '$timeout', '$scope', '$stateParams', 'DataUtils', 'entity', 'PortalApp', '$state', 'ModuleSettings' ];

	function PortalAppDialogController($uibModalInstance, $timeout, $scope, $stateParams, DataUtils, entity, PortalApp, $state, ModuleSettings) {
		var vm = this;

		vm.portalApp = entity;
		vm.byteSize = DataUtils.byteSize;
		vm.openFile = DataUtils.openFile;

		vm.clear = clear;
		vm.save = save;
		vm.loadAll = loadAll;

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		vm.setThumbnail = function($file, portalApp) {
			if ($file && $file.$error === 'pattern') {
				return;
			}
			if ($file) {
				DataUtils.toBase64($file, function(base64Data) {
					$scope.$apply(function() {
						portalApp.thumbnail = base64Data;
						portalApp.thumbnailContentType = $file.type;
					});
				});
			}
		};

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			if (vm.portalApp.id !== null) {
				PortalApp.update(vm.portalApp, onSaveSuccess, onSaveError);
			} else {
				PortalApp.save(vm.portalApp, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$uibModalInstance.close(result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.applicationsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
	}
})();
