(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PolicySettingsController', PolicySettingsController);

	PolicySettingsController.$inject = ['Notification', '$translate', '$http', 'PolicySettings', '$timeout'];

	function PolicySettingsController(Notification, $translate, $http, PolicySettings, $timeout) {
		var vm = this;

		vm.settings = PolicySettings.query();
		
		vm.save = save;
		vm.loadAll = loadAll;
	
		function save() {
			try {
				vm.settings.activePolicyImplementations = vm.treeInstance.jstree(true).get_selected().toString();
				PolicySettings.save(vm.settings, function(httpResponse) {
					Notification.success({message: $translate.instant('pharmaPortalApp.policySettings.messages.success'), delay: 5000});
				});
			} catch (err) {
				Notification.error({message: $translate.instant('pharmaPortalApp.policySettings.messages.error'), delay: ''});
			}
		}

		function loadAll() {
			vm.treeData = [];
			$timeout(function(){	
				$http.get("api/policy-builder/interfaces").then(function(result) {
					angular.forEach(result.data, function(v, k) {
						vm.treeData.push({ id : v, parent : '#', text : $translate.instant(v), state: { opened: false} });
						
						$http.get("api/policy-builder/implementations/" + v).then(function(result) {
							for (var i = 0; i < result.data.length; i++) {
								vm.treeData.push({ id : result.data[i], parent : v, text : $translate.instant(result.data[i]), state: { opened: false} });
							}
						});
					});
				});
			}, 100);

			$timeout(function(){
				vm.treeConfig = {
					core : {
						multiple : true,
						animation: true,
						error : function(error) {
							console.log('treeCtrl: error from js tree - ' + angular.toJson(error));
						},
						themes : {
							dots : false,
							stripes : true
						},
						dblclick_toggle : true,
						check_callback : true,
						worker : true
					},
					version : 1,
					plugins : ['checkbox']
				};
			}, 1500);
			
			$timeout(function(){
				vm.current_settings = vm.settings.activePolicyImplementations;
				if(vm.current_settings && vm.current_settings.length > 0) {
					angular.forEach(vm.current_settings.split(','), function(v, k) {
						vm.treeInstance.jstree("select_node", "#"+v)
					});
				}
			}, 2000);
		}
		loadAll();
	}
})();
