(function() {
	'use strict';

	angular.module('pharmaPortalApp').directive('onEnter', onEnter);

	function onEnter() {
		var directive = {
			restrict : 'A',
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element, attrs) {
			element.bind("keydown keypress", function(event) {
				if (event.which === 13) {
					scope.$apply(function() {
						scope.$eval(attrs.onEnter);
					});

					event.preventDefault();
				}
			});
		}
		;
	}
})();