(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ChoosePsychotropicsReportsNumber', ChoosePsychotropicsReportsNumber);

    ChoosePsychotropicsReportsNumber.$inject = ['$uibModalInstance', '$scope'];

    function ChoosePsychotropicsReportsNumber($uibModalInstance, $scope) {
        var vm = this;

        vm.selectedCopies = '1';

        $scope.onChange = function(){
            document.getElementById('print').focus();
        };

        vm.clear = function (number) {
            $uibModalInstance.dismiss(number);
        };

    }
})();
