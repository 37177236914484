(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['Principal', 'Notification', '$uibModalInstance', '$stateParams', '$translate', 'entity', 'User', 'Company', 'JhiLanguageService', '$scope', '$timeout', 'DataUtils'];

    function UserManagementDialogController(Principal, Notification, $uibModalInstance, $stateParams, $translate, entity, User, Company, JhiLanguageService, $scope, $timeout, DataUtils) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_PHARMA_ADMIN', 'ROLE_PHARMA', 'ROLE_INTRANET', 'ROLE_COMERCIAL', 'ROLE_ADMIN', 'ROLE_PRODUCT_IMPORT', 'ROLE_INFARMED'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        if (vm.user.generic == null) {
            vm.user.generic = false;
        }

        vm.companies = Company.query({size: 1000});

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        vm.setThumbnail = function ($file, account) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        account.thumbnail = base64Data;
                        account.thumbnailContentType = $file.type;
                    });
                });
            }
        };

        vm.setSignatureThumbnail = function ($file, account) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        account.signatureThumbnail = base64Data;
                        account.signatureThumbnailContentType = $file.type;
                    });
                });
            }
        };

        vm.validateResponsiveAndWallet = function(){
            vm.user.allowDigitalSignature = false;
            vm.user.technicalDirectorName = null;
            vm.user.professionalWalletNumber = null;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(response) {
            $scope.$emit('updateNavbar');
            vm.isSaving = false;
            $uibModalInstance.close(response);
            Notification.success({
                message: $translate.instant('userManagement.updated', {param: response.login}),
                delay: 5000
            });
        }

        function onSaveError(response) {
            vm.isSaving = false;
            Notification.error({message: $translate.instant(response.headers('x-pharmaportalapp-error')), delay: ''});
        }

        function save() {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                vm.response = User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.response = User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
