(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pharma-covid', {
            parent: 'entity',
            url: '/pharma-covid',
            data: {
                authorities: ['ROLE_INFARMED'],
                pageTitle: 'pharmaPortalApp.pharmaCovid.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pharma-covid/pharma-covid-detail.html',
                    controller: 'PharmaCovidDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaCovid');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
