(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PurchaseSaveTemplateController', PurchaseSaveTemplateController);

	PurchaseSaveTemplateController.$inject = [ 'Notification', '$uibModalInstance', '$rootScope', '$http', '$localStorage', 'Company', '$state', 'Principal', 'ModuleSettings' ];

	function PurchaseSaveTemplateController(Notification, $uibModalInstance, $rootScope, $http, $localStorage, Company, $state, Principal, ModuleSettings) {
		var vm = this;
		vm.companies = Company.query({ size : 1000 });
		
		vm.orderDescription;
		vm.bodyRequest;

		vm.clear = clear;
		vm.confirmSaveTemplate = confirmSaveTemplate;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function confirmSaveTemplate() {
			vm.bodyRequest = {
				"description" : vm.orderDescription,
				"orderProducts" : $rootScope.purchaseOrderList
			};

			$http.post("api/pharma-purchase/saveTemplate", vm.bodyRequest).then(function successCallback(response) {
				$rootScope.purchaseOrderList = [];
				$rootScope.purchaseTotals = {
					total : 0,
					totalPvf : 0,
					totalDesc : 0
				};
				$uibModalInstance.close(true);
				delete $localStorage.purchaseOrder;
				Notification.success({
					message : response.data,
					delay : 5000
				});
			}, function errorCallback(response) {
				$uibModalInstance.close(true);
				Notification.error({
					message : response.data,
					delay : ''
				});
			});
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.purchasesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (!(account && account.company)) {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();
