(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPurchaseController', PharmaPurchaseController);

    PharmaPurchaseController.$inject = ['Notification', '$rootScope', '$scope', '$filter', '$state', '$http', '$q', '$localStorage', 'PortalSettings', 'ModuleSettings', 'Principal', '$translate'];

    function PharmaPurchaseController(Notification, $rootScope, $scope, $filter, $state, $http, $q, $localStorage, PortalSettings, ModuleSettings, Principal, $translate) {
        var vm = this;
        vm.portalSettings = PortalSettings.query();
        vm.isPharma = false;
        vm.ownWarehouse = false;

        vm.loadAll = loadAll;
        vm.isValidForOrder = isValidForOrder;
        vm.isNumeric = isNumeric;
        vm.addProduct = addProduct;
        vm.checkProductValidToOrder = checkProductValidToOrder;
        vm.addAllProducts = addAllProducts;
        vm.searchByLab = searchByLab;
        vm.clearSearchInTable = clearSearchInTable;
        vm.toggleLineEditMode = toggleLineEditMode;
        vm.validateLines = validateLines;

        vm.exportSearchToExcel = exportSearchToExcel;
        vm.orderToExcel = orderToExcel;
        vm.confirmOrderDialog = confirmOrderDialog;

        $scope.searchInTable = "";
        $scope.productsList = [];
        $scope.search = {
            searchQuery: "",
            showNoStock: false,
            ownWarehouse: false
        };
        $rootScope.purchaseTotals = {
            total: 0,
            totalPV: 0,
            totalDesc: 0
        };

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.purchasesModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        vm.isPharma = false;
                        vm.ownWarehouse = false;
                        vm.loggedUser = null;
                        vm.loggedUserClientPe = null;
                        vm.loggedUserClientType = "";
                        vm.loggedUserClientGroup = "";
                        if (account) {
                            if (account.authorities.indexOf("ROLE_PHARMA") != -1) {
                                vm.currentAccount = account;
                                vm.loggedUser = account.login;
                                if (account.company) {
                                    // 1) se tem ROLE PHARMA e tem company vai
                                    // para compras > Encomendar
                                    vm.loggedUserClientPe = account.clientPe;
                                    vm.loggedUserClientType = account.company.clientType;
                                    vm.loggedUserClientGroup = account.company.clientGroup;
                                    if (account.company.clientType == "F") {
                                        vm.isPharma = true;
                                    }
                                    if (account.company.pharmaWarehouse) {
                                        vm.ownWarehouse = account.company.pharmaWarehouse;
                                    }
                                    validateLines();
                                } else {
                                    // 2) se tem ROLE PHARMA e não tem company
                                    // vai para page not found
                                    $state.go('accessdenied');
                                }
                            } else {
                                if (account.authorities.indexOf("ROLE_INTRANET") != -1) {
                                    // 3) se tem intranet vai para produtos
                                    $state.go('portal-product');
                                } else {
                                    $state.go('accessdenied');
                                }
                            }
                        } else {
                            $state.go('accessdenied');
                        }
                    });
                }
            });
        }

        loadAll();

        function isValidForOrder(product) {
            if (vm.portalSettings.allowZeroPriceProducts == true) {
                return (!product.pvp == null || !product.warehousestock || !product.qtd || product.pvp < 0 || product.warehousestock <= 0 || product.qtd <= 0);
            } else {
                return (!product.pvp || !product.warehousestock || !product.qtd || product.pvp <= 0 || product.warehousestock <= 0 || product.qtd <= 0);
            }
        }

        function isNumeric(n) { // Check if passed value is numeric
            n = n.toString().replace(/,/g, ".");
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function addProduct(product) {
            switch (checkProductValidToOrder(product, $rootScope.purchaseOrderList)) {
            case 0:
                // $rootScope.message = "Produto adicionado com sucesso";
                // $state.go("pharma-purchase.success");
                break;
            case 1:
                $rootScope.message = "O produto indicado já existia nesta encomenda. A quantidade a encomendar foi atualizada";
                $state.go("pharma-purchase.success");
                break;
            case 2:
                $rootScope.message = "O produto não tem um preço válido e maior que zero";
                $state.go("pharma-purchase.alert");
                break;
            case 3:
                $rootScope.message = "A quantidade a encomendar tem de ser um número válido e maior que zero";
                $state.go("pharma-purchase.alert");
                break;
            case 4:
                $rootScope.message = "Não existe stock disponível deste produto";
                $state.go("pharma-purchase.alert");
                break;
            case 5:
                $rootScope.message = "A quantidade a encomendar tem um valor superior ao stock disponível";
                $state.go("pharma-purchase.alert");
                break;
            }
        }

        function checkProductValidToOrder(product, listOrderProducts) {
            if (!(product.pvp && isNumeric(product.pvp) && product.pvp > 0)) {
                // SE "Preço" ESTÁ VAZIO OU NÃO É NUMÉRICO OU FOR NEGATIVO OU IGUAL A ZERO
                if (vm.portalSettings.allowZeroPriceProducts != true) {
                    // SE NÃO PERMITIR "Preço" IGUAL A ZERO
                    return 2;
                }
            } else if (!(product.qtd && isNumeric(product.qtd) && product.qtd > 0)) {
                // SE "Qtd" ESTÁ VAZIO OU NÃO É NUMÉRICO OU FOR NEGATIVO OU IGUAL A ZERO
                return 3;
            } else if (!(product.warehousestock && isNumeric(product.warehousestock) && product.warehousestock > 0)) {
                // SE "warehousestock" ESTÁ VAZIO OU NÃO É NUMÉRICO OU FOR NEGATIVO OU IGUAL A ZERO
                return 4;
            } else if (product.qtd > product.warehousestock) {
                // SE "QTD" FOR MAIOR QUE "STOCK"
                return 5;
            }

            if (listOrderProducts.length > 0) {
                // SE "CNP" VALIDA SE EXISTE NO ARRAY DE CNP"s
                var keepGoing = true;
                angular.forEach(listOrderProducts, function (obj, index) {
                    if (keepGoing) {
                        if (obj.cnp === product.cnp) {
                            if (product.qtd > 0) {
                                $rootScope.purchaseOrderList.splice(index, 1, angular.copy(product));
                            } else {
                                $rootScope.purchaseOrderList.splice(index, 1);
                            }
                            $rootScope.updatePurchaseTotals();
                            keepGoing = false;
                        }
                    }
                });
                if (!keepGoing) {
                    return 1;
                }
            }
            $rootScope.purchaseOrderList.push(angular.copy(product));
            $rootScope.updatePurchaseTotals();
            return 0;
        }

        function addAllProducts() {
            var error_flag = false;
            var exists_flag = false;
            var initialOrderSize = $rootScope.purchaseOrderList.length;
            var countWithError = 0;
            var countUpdated = 0;
            var countInserted = 0;

            var filteredList = $filter('filter')($scope.productsList, $scope.searchInTable);

            angular.forEach(filteredList, function (obj, index) {
                switch (checkProductValidToOrder(obj, $rootScope.purchaseOrderList)) {
                case 0:
                    countInserted = countInserted + 1;
                    break;
                case 1:
                    countUpdated = countUpdated + 1;
                    exists_flag = true;
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    countWithError = countWithError + 1;
                    error_flag = true;
                    break;
                }
            });

            if (error_flag) {
                var str = "<b>Produtos adicionados:</b> " + countInserted + "<br/>";
                if (exists_flag) {
                    str += "<b>Produtos atualizados:</b> " + countUpdated + "<br/>";
                }
                str += "<b>Produtos sem preço válido, sem stock ou quantidade inválida:</b> " + countWithError + "<br/>";
                $rootScope.message = str;
                $state.go("pharma-purchase.alert");
            } else {
                if (exists_flag) {
                    var str = "<b>Produtos adicionados:</b> " + countInserted + "<br/>";
                    str += "<b>Produtos atualizados:</b> " + countUpdated + "<br/>";
                    $rootScope.message = str;
                    $state.go("pharma-purchase.success");
                }
            }
        }

        function searchByLab(lab) {
            $scope.search.searchQuery = lab;
            $scope.searchProducts();
        }

        function clearSearchInTable() {
            $scope.searchInTable = "";
        }

        function toggleLineEditMode(orderProdLine) {
            if (angular.isDefined(orderProdLine.editMode) && orderProdLine.editMode == true) {
                orderProdLine.editMode = false;
            } else {
                orderProdLine.editMode = true;
            }
            $rootScope.updatePurchaseTotals();
        }

        function validateLines(orderProd, keyEvent) {
            if (orderProd) {
                if (keyEvent.type == "blur" || (keyEvent.type == "keypress" && keyEvent.which === 13)) {
                    vm.hasErrors = false;
                    orderProd.editMode = false;
                    $rootScope.updatePurchaseTotals();
                    angular.forEach($rootScope.purchaseOrderList, function (value, key) {
                        isQtdInvalid(value);
                    });
                }
            } else {
                $rootScope.updatePurchaseTotals();
                angular.forEach($rootScope.purchaseOrderList, function (value, key) {
                    isQtdInvalid(value);
                });
            }
        }

        function isQtdInvalid(orderProd) {
            if (orderProd.qtd && orderProd.qtd >= 0 && parseInt(orderProd.qtd)) {
                orderProd.invalidQtd = false;
            } else {
                orderProd.invalidQtd = true;
                vm.hasErrors = true;
            }
        }

        $scope.searchProducts = function () {
            $scope.productsList = [];
            if ($scope.search && $scope.search.searchQuery && $scope.search.searchQuery.length > 2) {
                $rootScope.searchProductsAction();
            } else {
                // SE showNoStock então não permite
                if ($scope.search.showNoStock == true) {
                    $rootScope.message = "NOK";
                    $scope.productsList = [];
                    $state.go("pharma-purchase.fullSearch");
                } else {
                    // SE showNoStock = FALSE então ABRE DIALOG A CONFIRMAR
                    if ($scope.search.searchQuery == undefined) {
                        $scope.search.searchQuery = "";
                    }
                    $rootScope.message = "OK";
                    $state.go("pharma-purchase.fullSearch");
                }
            }
        };

        var canceler = $q.defer();
        $rootScope.searchProductsAction = function () {
            // PENDING HTTP CALLS CANCEL
            canceler.resolve();
            canceler = $q.defer();

            // DEFAULT QTD FROM SETTINGS
            vm.defaultQtd = 1;
            if (vm.portalSettings.defaultQtd != null) {
                vm.defaultQtd = vm.portalSettings.defaultQtd;
            }
            // HIDE NEGATIVE DISCOUNTS FROM SETTINGS
            vm.hideNegativeDiscounts = true;
            if (vm.portalSettings.showPvfOnNegativeDiscount) {
                vm.hideNegativeDiscounts = false;
            }

            // CHECK IF SEARCH IS OVER OWN WAREHOUSE OR POLICY GIVEN WAREHOUSE
            if ($scope.search.ownWarehouse) {
                $scope.search.customWarehouse = vm.ownWarehouse;
                searchForProducts();
            } else {
                getWarehouseForSearch();
            }
        }

        $rootScope.updatePurchaseTotals = function () {
            $rootScope.purchaseTotals = {
                total: 0,
                totalPV: 0,
                totalDesc: 0
            };

            angular.forEach($rootScope.purchaseOrderList, function (value, key) {
                $rootScope.purchaseTotals.total += (value.qtd * 1) * (value.pvp * 1);

                if (vm.isPharma) {
                    $rootScope.purchaseTotals.totalPV += (value.qtd * 1) * (value.pvfeur * 1);
                } else {
                    $rootScope.purchaseTotals.totalPV += (value.qtd * 1) * (value.pvaeur * 1);
                }
            });

            $rootScope.purchaseTotals.totalDesc = $rootScope.purchaseTotals.totalPV - $rootScope.purchaseTotals.total;

            if (vm.portalSettings.chargeShippingCosts == true && vm.portalSettings.hasMinOrderValue == true && vm.portalSettings.minOrderValue > $rootScope.purchaseTotals.total && $rootScope.purchaseTotals.total > 0) {
                $rootScope.purchaseTotals.total = $rootScope.purchaseTotals.total + vm.portalSettings.shippingCosts;
            }
        }
        $rootScope.updatePurchaseTotals();

        $scope.isOrderEmpty = function () {
            return $rootScope.purchaseOrderList.length <= 0;
        }

        $scope.isProductSearchEmpty = function () {
            return $scope.productsList.length <= 0;
        }

        $scope.isRetainedProductsOrder = function () {
            if ($rootScope.purchaseOrderList.length > 0) {
                var keepGoing = true;
                angular.forEach($rootScope.purchaseOrderList, function (value, key) {
                    if (keepGoing) {
                        if (value.originwarehouse) {
                            $scope.search.ownWarehouse = true;
                            keepGoing = false;
                        }
                    }
                });
                return true;
            } else {
                return false;
            }
        }

        if ($localStorage && $localStorage.purchaseOrder) {
            $rootScope.purchaseOrderList = $localStorage.purchaseOrder;
        } else {
            $rootScope.purchaseOrderList = [];
        }

        $scope.$watch($rootScope.purchaseOrderList, function () {
            $localStorage.purchaseOrder = $rootScope.purchaseOrderList;
        }, true);

        function dynamicSort(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        function exportSearchToExcel() {
            $http.post("api/pharma-purchase/searchToExcel/" + vm.isPharma, $scope.productsList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Produtos_Portal_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: $translate.instant('pharmaPurchase.messages.exportExcel.success'),
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: $translate.instant('pharmaPurchase.messages.exportExcel.error'),
                    delay: ''
                });
            });
        }

        function orderToExcel() {
            $http.post("api/pharma-purchase/orderToExcel/" + vm.isPharma, $rootScope.purchaseOrderList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Portal_Encomenda_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: $translate.instant('pharmaPurchase.messages.orderExcel.success'),
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: $translate.instant('pharmaPurchase.messages.orderExcel.error'),
                    delay: ''
                });
            });
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };

        function getWarehouseForSearch() {
            // EXECUTAR POLITICA PARA OBTER ARMAZÉM A UTILIZAR E LISTA DE EXCLUSÕES
            var policyType = "PharmaOrdersStockSale";
            var policyData = [];
            policyData.push({
                "hierarchy": [vm.loggedUserClientType, vm.loggedUserClientGroup, vm.loggedUserClientPe]
            });
            var requestBody = {
                "policyType": policyType,
                "policyData": policyData
            };

            $http.post("api/policyExecuter/execute", requestBody).then(function (result) {
                var curResult = $filter('filter')(result.data.output, {
                    hash: vm.loggedUserClientType + '' + vm.loggedUserClientGroup + '' + vm.loggedUserClientPe
                }, true);

                if (curResult[0]) {
                    $scope.search.customWarehouse = curResult[0].result.warehouse
                    $scope.search.productStockQtdManipulation = curResult[0].result.productStockQtd
                    searchForProducts();
                }
            }, function (result) {
                Notification.warning({
                    message: "Error! Status = " + result.status + " Message = " + result,
                    delay: 5000
                });
            });
        }

        var canceler = $q.defer();

        function searchForProducts() {
            canceler.resolve();
            canceler = $q.defer();
            // PESQUISA "NORMAL"
            $scope.resultProductsList = [];
            $http.post("api/common/_searchFromDbPharmaProducts", $scope.search, {
                timeout: canceler.promise
            }).then(function (result) {
                $scope.resultProductsList = result.data;
            }).then(function () {
                $scope.productsList = [];
                vm.policyData = [];

                if ($scope.search.ownWarehouse) {
                    vm.policyName = "RetainedProductPriceSale";

                    $($scope.resultProductsList).each(function (index, prod) {
                        var prodLab = prod.forncode ? "CIL" + prod.forncode : prod.labcode ? prod.labcode : "99999";
                        vm.policyData.push({
                            "hierarchy": [vm.loggedUserClientPe, prodLab, prod.cnp],
                            "parameters": [$scope.search.customWarehouse]
                        });
                        prod.hash = vm.loggedUserClientPe + "" + prodLab + "" + prod.cnp;
                    });
                } else {
                    vm.policyName = "PharmaOrdersPriceSale";

                    $($scope.resultProductsList).each(function (index, prod) {
                        var prodLab = prod.forncode ? "CIL" + prod.forncode : prod.labcode ? prod.labcode : "99999";
                        vm.policyData.push({
                            "hierarchy": [vm.loggedUserClientType, vm.loggedUserClientPe, prodLab, prod.cnp]
                        });
                        prod.hash = vm.loggedUserClientType + "" + vm.loggedUserClientPe + "" + prodLab + "" + prod.cnp;
                    });
                }

                vm.requestBody = {
                    "policyType": vm.policyName,
                    "policyData": vm.policyData
                };

                $http.post("api/policyExecuter/execute", vm.requestBody, {
                    timeout: canceler.promise
                }).then(function (result) {
                    $($scope.resultProductsList).each(function (index, prod) {
                        //CHECK EXCEPTION LIST EXISTS AND IF PRODUCT IS ON THE EXCEPTION LIST (case exists)
                        if (angular.isDefined($scope.search.productStockQtdManipulation) && $scope.search.productStockQtdManipulation != null && $scope.search.productStockQtdManipulation.length > 0) {
                            var curProdSearch = $filter('filter')($scope.search.productStockQtdManipulation, {
                                productCnp: prod.cnp
                            }, true);

                            if (angular.isDefined(curProdSearch) && curProdSearch.length > 0 && prod.warehousestock > curProdSearch[0].qtd) {
                                prod.warehousestock = curProdSearch[0].qtd;
                            }
                        }
                        var curResult = $filter('filter')(result.data.output, {
                            hash: prod.hash
                        }, true);

                        if (vm.portalSettings.allowZeroPriceProducts == true) {
                            // 1. PREÇO
                            prod.pvp = curResult[0].result;
                            if (prod.pvp < 0) {
                                prod.warehousestock = 0;
                            }

                        } else {
                            // 1. PREÇO
                            prod.pvp = curResult[0].result;
                            if (prod.pvp <= 0) {
                                prod.warehousestock = 0;
                            }
                        }

                        // 2. DIF PREÇO
                        if (vm.isPharma) {
                            if (prod.pvfeur) {
                                if (prod.pvp == 0 || prod.pvfeur == 0) {
                                    prod.pvfdesc = 0;
                                } else {
                                    prod.pvfdesc = (1 - (prod.pvp / prod.pvfeur)) * 100;

                                    if (prod.pvfdesc < 0 && vm.hideNegativeDiscounts) {
                                        prod.pvfdesc = 0;
                                        prod.pvfeur = prod.pvp;
                                    }
                                }
                            } else {
                                prod.pvfeur = prod.pvp;
                                prod.pvfdesc = 0;
                                prod.qtd = vm.defaultQtd;
                            }
                        } else {
                            if (prod.pvaeur) {
                                if (prod.pvp == 0 || prod.pvaeur == 0) {
                                    prod.pvadesc = 0;
                                } else {
                                    prod.pvadesc = (1 - (prod.pvp / prod.pvaeur)) * 100;
                                    if (prod.pvadesc < 0 && vm.hideNegativeDiscounts) {
                                        prod.pvadesc = 0;
                                        prod.pvaeur = prod.pvp;
                                    }
                                }
                            } else {
                                prod.pvaeur = prod.pvp;
                                prod.pvadesc = 0;
                                prod.qtd = vm.defaultQtd;
                            }
                        }
                        // 3. SE ESTAMOS A TRATAR PRODUTOS RETIDOS ENTÃO
                        // ADICIONA AO PRODUTO O WAREHOUSE DE ORIGEM
                        if ($scope.search.ownWarehouse) {
                            prod.originwarehouse = vm.ownWarehouse;
                        }

                        // 4. STOCK
                        if (prod.warehousestock > 0) {
                            prod.qtd = vm.defaultQtd;
                            $scope.productsList.push(prod);
                        } else {
                            prod.qtd = 0;
                            prod.warehousestock = 0;
                            if ($scope.search && $scope.search.showNoStock) {
                                $scope.productsList.push(prod);
                            }
                        }

                        $scope.productsList.sort(dynamicSort("fullName"));
                    });

                }, function (result) {
                    Notification.warning({
                        message: "Error! Status = " + result.status + " Message = " + result,
                        delay: 5000
                    });
                });
            });
        }

        function confirmOrderDialog() {
            $rootScope.isRetainedProductsOrder = $scope.search.ownWarehouse;
            $state.go('pharma-purchase.confirmOrder');
        }
    }
})();
