(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('SelfInvoiceCollectionRemoveLineController', SelfInvoiceCollectionRemoveLineController);

	SelfInvoiceCollectionRemoveLineController.$inject = [ 'Notification', '$uibModalInstance', '$translate', 'entity', '$rootScope', '$state', 'Principal', 'ModuleSettings' ];

	function SelfInvoiceCollectionRemoveLineController(Notification, $uibModalInstance, $translate, entity, $rootScope, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.cnpToDel = entity.cnpToDel;
		vm.product = null;

		vm.clear = clear;
		vm.confirmRemoveLine = confirmRemoveLine;
		vm.filterItemFromArray = filterItemFromArray;
		vm.deleteFilteredItem = deleteFilteredItem;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmRemoveLine() {
			deleteFilteredItem(vm.cnpToDel, $rootScope.selfInvoiceCollectionOrderList);
			$rootScope.updateTotalizers();
			$uibModalInstance.close(true);
			Notification.success({ message : $translate.instant('pharmaPortalApp.selfInvoiceCollection.message.productDeleted'), delay : 5000 });
		}

		function filterItemFromArray(cnpToDel, sourceArray) {
			vm.product = null;
			angular.forEach(sourceArray, function(obj, index) {
				if (obj.cnp === cnpToDel) {
					vm.product = obj;
					return;
				}
			});
		}

		function deleteFilteredItem(cnpToDel, sourceArray) {
			angular.forEach(sourceArray, function(obj, index) {
				if (obj.cnp === cnpToDel) {
					sourceArray.splice(index, 1);
					return;
				}
			});
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.selfInvoiceCollectionModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
                        if(account.authorities.indexOf("ROLE_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if(account.authorities.indexOf("ROLE_SUPER_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if(account.authorities.indexOf("ROLE_PHARMA_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if ((account && account.company && account.company.clientType == "F") || vm.isAdmin) {
							filterItemFromArray(vm.cnpToDel, $rootScope.selfInvoiceCollectionOrderList);
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();
