(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaReceptionValidateVatNumberController', PharmaReceptionValidateVatNumberController);

	PharmaReceptionValidateVatNumberController.$inject = ['Notification', '$uibModalInstance', '$http', '$state', '$filter', 'entity', 'PharmaReception'];

	function PharmaReceptionValidateVatNumberController(Notification, $uibModalInstance, $http, $state, $filter, entity, PharmaReception) {
		var vm = this;
		vm.vatNumber = null;
		
		vm.pharmaReception = entity;
		
		vm.clear = clear;
		vm.validateVatNumber = validateVatNumber;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function validateVatNumber() {
			if(vm.vatNumber !== null) {
				if(vm.pharmaReception.supplierNif == null) {
					Notification.error({message: "Não foi possível validar o fornecedor indicado. Verifique esta informação antes de avançar", delay: ''});
				} else {
					if(vm.vatNumber == vm.pharmaReception.supplierNif) {
						// Mensagem ao validar o contribuite: 
						Notification.success({message: "Número de contribuinte e fornecedor válidos", delay: 5000});
						vm.pharmaReception.vatValidated = vm.pharmaReception.supplierNif;
						PharmaReception.save(vm.pharmaReception);
						$uibModalInstance.close(true);
					} else {
						// Mensagem ao NÃO validar o contribuinte: 
						Notification.error({message: "Número de contribuinte não corresponde ao fornecedor indicado. Valide a informação antes de avançar", delay: ''});
					}
				}
			} else {
				Notification.error({message: "Tem de indicar um número de contribuinte", delay: ''});
			}
		}
	}
})();

