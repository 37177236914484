(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionLineNewController', PharmaTransfReceptionLineNewController);

	PharmaTransfReceptionLineNewController.$inject = [ 'Notification', '$uibModalInstance', '$scope', '$state', '$http', 'entity', 'PharmaTransfReceptionLine' ];

	function PharmaTransfReceptionLineNewController(Notification, $uibModalInstance, $scope, $state, $http, entity, PharmaTransfReceptionLine) {
		var vm = this;

		vm.clear = clear;
		vm.createPharmaTransfReceptionLine = createPharmaTransfReceptionLine;
		vm.loadSelects = loadSelects;

		vm.isSaving = false;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			$state.go("pharma-transf-reception-edit", {
				id : entity.id
			}, {
				reload : true
			});
			Notification.success({ message : "Linha adicionada com sucesso", delay : 5000 });
			$uibModalInstance.close(true);
		}

		function onSaveError(result) {
			vm.isSaving = false;

			if (result.data.success) {
				Notification.success({ message : result.data.message, delay : 5000 });
			} else {
				Notification.error({ message : result.data.message, delay : '' });
			}
		}

		function createPharmaTransfReceptionLine() {
			vm.isSaving = true;
			vm.pharmaTransfReceptionLine.pharmaTransfReceptionHeader = entity;
			vm.pharmaTransfReceptionLine.cnp = vm.pharmaTransfReceptionLine.product.cnp;
			vm.pharmaTransfReceptionLine.description = vm.pharmaTransfReceptionLine.product.fullName;
			vm.pharmaTransfReceptionLine.vatRate = vm.pharmaTransfReceptionLine.product.txiva;

			vm.pharmaTransfReceptionLine.invoicedQuantity = 0;

			vm.pharmaTransfReceptionLine.internalCode = vm.pharmaTransfReceptionLine.product.code;
			vm.pharmaTransfReceptionLine.modelCode = vm.pharmaTransfReceptionLine.product.model;
			vm.pharmaTransfReceptionLine.packageCode = vm.pharmaTransfReceptionLine.product.pack;
			vm.pharmaTransfReceptionLine.grandFamilyCode = vm.pharmaTransfReceptionLine.product.bigfam;
			vm.pharmaTransfReceptionLine.erpLine = false;

			PharmaTransfReceptionLine.save(vm.pharmaTransfReceptionLine, onSaveSuccess, onSaveError);
		}

		function loadSelects() {
			$http.get("api/common/_searchQualityStatusesForSelect").then(function(result) {
				vm.qualityStatuses = [{ "code" : '', "description" : '' }].concat(result.data);
			});
		}
		vm.loadSelects();

		$scope.onOpenClose = function(isOpen) {
			angular.element('.ui-select-choices-row').removeClass('active');
		}

		$scope.refreshSelectModel = function(query) {
			$http.get("api/common/_searchProductsForSelect/" + query).then(function(result) {
				if (result.data.length > 500) {
					Notification.warning({ message : "O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa", delay : 5000 });
					return;
				} else {
					vm.products = result.data;
				}
			});
		};
	}
})();
