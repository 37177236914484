(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('error', {
			parent : 'app',
			url : '/error',
			data : {
				authorities : [],
				pageTitle : 'error.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/layouts/error/error.html'
				}
			},
			resolve : {
				mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('error');
					return $translate.refresh();
				} ]
			}
		}).state('accessdenied', {
			parent : 'app',
			url : '/accessdenied',
			data : {
				authorities : []
			},
			views : {
				'content@' : {
					templateUrl : 'app/layouts/error/accessdenied.html'
				}
			},
			resolve : {
				mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('error');
					return $translate.refresh();
				} ]
			}
		});
	}
})();
