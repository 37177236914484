(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaCloseConferenceController', PharmaCloseConferenceController);

    PharmaCloseConferenceController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', '$translate', '$state'];

	function PharmaCloseConferenceController(Notification, blockUI, $uibModalInstance, $translate, $state) {
		var vm = this;

		vm.clear = clear;

		vm.userName = null;


		function clear(flag) {
			$uibModalInstance.dismiss(flag);
		}

		function onSubmitSuccess(result) {
			$uibModalInstance.close(true);
			blockUI.stop();
			if (result.success){
				Notification.success({message: result.message, delay: 5000});
				$state.go("pharma-picking", {}, {
					reload : true
				});
			} else {
				Notification.error({message: result.message, delay: ''});
				$state.go("pharma-picking-detail.edit", {
					id : vm.pharmaPicking.id
				}, {
					reload : true
				});
			}
		}

		function onSubmitError(result) {
			blockUI.stop();
			$uibModalInstance.close(true);

			if(result.success){
				Notification.success({message: result.message, delay: 5000});
			} else {
				Notification.error({message: result.message, delay: ''});
			}
		}

	}
})();
