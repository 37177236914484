(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('UserManagementDeleteController', UserManagementDeleteController);

	UserManagementDeleteController.$inject = ['Notification', '$uibModalInstance', '$translate', 'entity', 'User'];

	function UserManagementDeleteController (Notification, $uibModalInstance, $translate, entity, User) {
		var vm = this;

		vm.user = entity;
		vm.clear = clear;
		vm.confirmDelete = confirmDelete;

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

        function confirmDelete (login) {
			User.delete({login: login}, function (response) {
				$uibModalInstance.close(true);
                Notification.success({message: $translate.instant('userManagement.deleted', {param:login}), delay: 5000});
            }, function (err) {
                $uibModalInstance.close(true);
                Notification.error({message: $translate.instant('userManagement.deletedError', {param:login}), delay: 5000});
            });

        }
	}
})();
