(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaReceptionLineDeleteController', PharmaReceptionLineDeleteController);

	PharmaReceptionLineDeleteController.$inject = [ 'Notification', '$uibModalInstance', '$translate', '$state', 'pharmaReceptionLine', 'PharmaReceptionLine' ];

	function PharmaReceptionLineDeleteController(Notification, $uibModalInstance, $translate, $state, pharmaReceptionLine, PharmaReceptionLine) {
		var vm = this;

		vm.clear = clear;
		vm.confirmPharmaReceptionLineDelete = confirmPharmaReceptionLineDelete;
		vm.pharmaReceptionLine = pharmaReceptionLine;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onDeleteSuccess(result) {
			$uibModalInstance.close(true);
			
			Notification.success({message: $translate.instant('pharmaPortalApp.pharmaReception.message.pharmaReceptionLineDeleted'), delay: 5000});
		}

		function onDeleteError(result) {
			$uibModalInstance.close(true);
			
			if (result.data.success){
				Notification.success({message: result.data.message, delay: 5000});
			} else {
				Notification.error({message: result.data.message, delay: ''});
			}
		}

		function confirmPharmaReceptionLineDelete() {
			PharmaReceptionLine.delete({id: pharmaReceptionLine.id}, onDeleteSuccess, onDeleteError);
		}
	}
})();