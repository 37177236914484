(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('admin-settings', {
			parent : 'entity',
			url : '/admin-settings',
			data : {
				authorities : [ 'ROLE_SUPER_ADMIN' ],
				pageTitle : 'pharmaPolicyApp.adminSettings.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/admin-settings/admin-settings.html',
					controller : 'AdminSettingsController',
					controllerAs : 'vm'
				},
				'module-settings-view@admin-settings' : {
					templateUrl : 'app/entities/admin-settings/module-settings.html',
					controller : 'ModuleSettingsController',
					controllerAs : 'vm'
				},
				'policy-settings-view@admin-settings' : {
					templateUrl : 'app/entities/admin-settings/policy-settings.html',
					controller : 'PolicySettingsController',
					controllerAs : 'vm'
				},
				'theme-settings-view@admin-settings' : {
					templateUrl : 'app/entities/admin-settings/theme-settings.html',
					controller : 'ThemeSettingsController',
					controllerAs : 'vm'
				},
                'external-syncs-view@admin-settings':{
                    templateUrl : 'app/entities/admin-settings/external-syncs.html',
                    controller : 'ExternalSyncsController',
                    controllerAs : 'vm'
                }
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('adminSettings');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('module-settings', {
			parent : 'entity',
			url : '/module-settings',
			data : {
				authorities : [ 'ROLE_SUPER_ADMIN' ],
				pageTitle : 'pharmaPortalApp.moduleSettings.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/admin-settings/module-settings.html',
					controller : 'ModuleSettingsController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('adminSettings');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('policy-settings', {
			parent : 'entity',
			url : '/policy-settings',
			data : {
				authorities : [ 'ROLE_SUPER_ADMIN' ],
				pageTitle : 'pharmaPortalApp.policySettings.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/admin-settings/policy-settings.html',
					controller : 'PolicySettingsController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('adminSettings');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('theme-settings', {
			parent : 'entity',
			url : '/theme-settings',
			data : {
				authorities : [ 'ROLE_SUPER_ADMIN' ],
				pageTitle : 'pharmaPortalApp.themeSettings.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/admin-settings/theme-settings.html',
					controller : 'ThemeSettingsController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('adminSettings');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('external-syncs',{
		    parent : 'entity',
            url : '/external-syncs',
            data : {
		        authorities : [ 'ROLE_SUPER_ADMIN' ],
                pageTitle : 'pharmaPortalApp.externalSyncs.home.title'
            },
            views : {
		        'content@' : {
                    templateUrl : 'app/entities/admin-settings/external-syncs.html',
                    controller : 'ExternalSyncsController',
                    controllerAs : 'vm'
                }
            },
            resolve : {
                translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('adminSettings');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                } ]
            }
        });
	}
})();
