(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('portal-product', {
			parent : 'entity',
			url : '/portal-product?page&sort&search',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.portalProduct.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/portal-product/portal-products.html',
					controller : 'PortalProductController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				},
				search : null
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort),
						search : $stateParams.search
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('portalProduct');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('portal-product.fullSearch', {
			parent : 'portal-product',
			url : '/fullSearch',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/portal-product/portal-product-confirm.fullsearch-dialog.html',
					controller : 'PortalProductFullSearchController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('portal-product', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('portal-product.stockDetail', {
			parent : 'portal-product',
			url : '/stockDetail/{internalCode}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/portal-product/portal-product-stock.detail-dialog.html',
					controller : 'PortalProductStockDetailController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								internalCode : $stateParams.internalCode
							};
						}
					}
				}).result.then(function() {
					$state.go('portal-product', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
        }).state('portal-product.showDetail', {
            parent : 'portal-product',
            url : '/productDetails',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
            },
            params : {
                product : null
            },
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                if($stateParams.product != null) {
                    $uibModal.open({
                        templateUrl : 'app/entities/portal-product/portal-product-cnp.detail-dialog.html',
                        controller : 'PortalProductCnpDetailController',
                        controllerAs : 'vm',
                        size : 'lg modal-exl',
                        resolve : {
                            product : function() {
                                return $stateParams.product;
                            }
                        }
                    }).result.then(function() {
                        $state.go('portal-product', null, {
                            reload : false
                        });
                    }, function() {
                        $state.go('^');
                    });
                } else {
                    $state.go('portal-product', null, {
                        reload : false
                    });
                }
            } ]
		});
	}
})();
