(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingShowHistoryDetailController', PharmaPickingShowHistoryDetailController);

    PharmaPickingShowHistoryDetailController.$inject = ['$translate', 'blockUI', 'Notification', '$scope', '$state', '$http', '$filter', 'PharmaPicking', 'ParseLinks', 'paginationConstants', 'pagingParams',
        'ModuleSettings', '$localStorage'];

    function PharmaPickingShowHistoryDetailController($translate, blockUI, Notification, $scope, $state, $http, $filter, PharmaPicking, ParseLinks, paginationConstants, pagingParams, ModuleSettings, $localStorage) {
        var vm = this;

        vm.saveSearch = $state.params.saveSearch;
        vm.cnp = $state.params.cnp;

        if(!vm.cnp) {
            $state.go('pharma-picking-show-history');
        }

        if(!vm.saveSearch) {
            vm.saveSearch = JSON.parse(window.localStorage.getItem(vm.cnp));
        } else {
            window.localStorage.setItem(vm.cnp, JSON.stringify(vm.saveSearch));
        }
        vm.saveSearch.product = vm.cnp;
        vm.loadAll = loadAll;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.transition = transition;
        vm.back =back;
        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                } else {
                    PharmaPicking.getPickingHeaderForProductHistory({
                        tableFilters: prepareFilters(),
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            });
        }

        vm.sort = function(){
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                saveSearch: vm.saveSearch,
                cnp: vm.cnp
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.pharmaPickingHeaders = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            if(error.status == 401){
                Notification.error({
                    message: "É necessário estar autenticado!",
                    delay: ''
                });
            } else {
                Notification.error({
                    message: error.data.message,
                    delay: ''
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            PharmaPicking.getProductsForHistory({
                tableFilters: prepareFilters(),
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

        }

        //Filters...
        function prepareFilters() {
            var filters = {};
            filters.cnp = textOrNull(vm.cnp);
            filters.company = textOrNull(vm.saveSearch.company.value);
            filters.year = textOrNull(vm.saveSearch.year);
            filters.month = textOrNull(vm.saveSearch.month);
            filters.product = textOrNull(vm.saveSearch.product);
            // filters.pharmacy = textOrNull(vm.saveSearch.pharmacy.value);
            return filters;
        }

        function textOrNull(field) {
            return field != '' ? field : null;
        }


        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };

        function back() {
            window.history.back();
        }

    }
})();
