(function () {
    'use strict';

    angular.module('pharmaPortalApp')
        .controller('ProductErpIntegrationDeleteController', ProductErpIntegrationDeleteController);

    ProductErpIntegrationDeleteController.$inject = ['Notification', '$uibModalInstance', '$translate', 'entity', 'ProductErpIntegration'];

    function ProductErpIntegrationDeleteController(Notification, $uibModalInstance, $translate, entity, ProductErpIntegration) {
        var vm = this;
        vm.working = false;
        vm.productErpIntegration = entity;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            vm.working = false;
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            vm.working = true;
            ProductErpIntegration.delete({id: id}, onDeleteSuccess, onDeleteError);
        }

        function onDeleteSuccess() {
            vm.working = false;
            $uibModalInstance.close(true);
            Notification.success({
                message: $translate.instant('pharmaPortalApp.productErpIntegration.delete.deletedSuccess'),
                delay: 5000
            });
        }

        function onDeleteError() {
            vm.working = false;
            $uibModalInstance.close(true);
            Notification.error({
                message: $translate.instant('pharmaPortalApp.productErpIntegration.delete.deletedError'),
                delay: 5000
            });
        }
    }
})();
