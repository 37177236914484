(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaReturnController', PharmaReturnController);

    PharmaReturnController.$inject = ['$translate', 'Notification', '$q', '$rootScope', '$scope', '$http', 'PharmaReturn', '$localStorage', '$filter', '$state', 'ModuleSettings', 'PortalSettings', 'Principal', 'Company'];

    function PharmaReturnController($translate, Notification, $q, $rootScope, $scope, $http, PharmaReturn, $localStorage, $filter, $state, ModuleSettings, PortalSettings, Principal, Company) {
        var vm = this;

        vm.portalSettings = PortalSettings.query();

        vm.loadAll = loadAll;
        vm.addProduct = addProduct;
        vm.isNumeric = isNumeric;
        vm.exportToExcel = exportToExcel;
        vm.toggleLineEditMode = toggleLineEditMode;
        vm.validateLines = validateLines;
        vm.lastQuery = "";
        vm.isTotalSet = false;
        vm.updateCompany = updateCompany;
        vm.companies = Company.findByPharmacyClient();

        vm.validPolicyPrice = false;
        vm.products;
        $rootScope.totalizer = {
            qtd: 0,
            price: 0,
            docTotal: 0
        };

        function updateCompany($item) {
            Company.isInactive($item, function (result) {
                if (result.regDate != null) {
                    result.regDate = result.regDate.substring(6, 8) + "-" + result.regDate.substring(4, 6) + "-" + result.regDate.substring(0, 4);
                    result.regTime = result.regTime.substring(0, 2) + ":" + result.regTime.substring(2, 4) + ":" + result.regTime.substring(4, 6);
                    result.company = $item.namingForOptions;
                    $rootScope.message = result;
                    vm.currentCompany = null;
                    $state.go("pharma-return.inactive");
                } else {
                    vm.selectedCompany = $item;
                    $localStorage.currentCompany = vm.selectedCompany;
                    vm.loggedUserClientPe = $item.clientPe;
                }
            });
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.returnsModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        if (account) {
                            vm.loggedUser = account.login;

                            if (account.company && account.company.clientType == "F") {
                                if (!vm.currentCompany) {
                                    vm.currentCompany = account.company;
                                    updateCompany(account.company);
                                }
                                if (vm.loggedUserClientPe == null) {
                                    vm.loggedUserClientPe = account.clientPe;
                                }
                            } else {
                                vm.loggedUserClientPe = null;
                                vm.currentCompany = null;
                            }
                        } else {
                            vm.loggedUser = null;
                            vm.loggedUserClientPe = null;
                            $state.go('accessdenied');
                        }
                    });
                    validateLines();
                }
            });
            vm.isTotalSet = JSON.parse(localStorage.getItem("isTotalSet"));
        }

        loadAll();

        function addProduct() {
            // SE "CNP" ESTÁ VAZIO
            if (!vm.products || !vm.products.selected || !vm.products.selected.cnp) {
                Notification.error({message: "Não indicou nenhum artigo", delay: ''});
                return;
            }

            // SE "Qtd" ESTÁ VAZIO OU NÃO NUMÉRICO OU É MENOR QUE ZERO
            if (!vm.products.selected.qtd) {
                Notification.error({message: "Não indicou a quantidade a devolver", delay: ''});
                return;
            } else {
                vm.products.selected.qtd = vm.products.selected.qtd.toString().replace(",", ".");
                if (!isNumeric(vm.products.selected.qtd)) {
                    Notification.error({message: "A quantidade a devolver que indicou não é um número válido", delay: ''});
                    return;
                } else if (Number(vm.products.selected.qtd) % 1 !== 0 || vm.products.selected.qtd < 1) {
                    Notification.error({message: "A quantidade a devolver tem de ser um numero inteiro e maior que zero", delay: ''});
                    return;
                }
            }

            // SE "Preço" ESTÁ VAZIO OU NÃO NUMÉRICO OU É MENOR QUE ZERO
            if (vm.isTotalSet) {
                if (!vm.products.selected.total) {
                    Notification.error({message: "Não indicou o preço líquido do produto a devolver", delay: ''});
                    return;
                } else if (vm.products.selected.total < 0) {
                    Notification.error({message: "O preço líquido do produto tem de ser maior que zero", delay: ''});
                    return;
                } else {
                    vm.products.selected.total = parseFloat(vm.products.selected.total.toString().replace(",", "."));
                    vm.products.selected.price = Math.round((vm.products.selected.total / vm.products.selected.qtd) * 1000) / 1000;
                }
            } else {
                if (!vm.products.selected.price) {
                    Notification.error({message: "Não indicou o preço unitário do produto a devolver", delay: ''});
                    return;
                } else if (vm.products.selected.price < 0) {
                    Notification.error({message: "O preço unitário do produto tem de ser maior que zero", delay: ''});
                    return;
                } else {
                    vm.products.selected.price = vm.products.selected.price.toString().replace(",", ".");
                    vm.products.selected.total = vm.products.selected.price * vm.products.selected.qtd;
                }
            }

            // SE "CNP" VALIDA SE EXISTE NO ARRAY DE CNP"s
            if ($rootScope.orderList.length > 0) {
                var keepGoing = true;
                angular.forEach($rootScope.orderList, function (obj, index) {
                    if (keepGoing) {
                        if (obj.cnp === vm.products.selected.cnp) {
                            $rootScope.orderList.splice(index, 1);
                            keepGoing = false;
                        }
                    }
                });

                if (!keepGoing) {
                    Notification.warning({message: "O produto indicado já existe nesta devolução. Os valores indicados como quantidade e preço unitário foram atualizados", delay: 5000});
                }
            }

            vm.products.selected.validPolicyPrice = vm.validPolicyPrice;
            $rootScope.orderList.push(angular.copy(vm.products.selected));
            $rootScope.updateTotalizers();
            vm.products.selected = null;
            var uiSelect = angular.element('#filterProduct').controller('uiSelect');
            uiSelect.focusser[0].focus();
        }

        vm.changeToggle = function () {
            vm.showAnimation = false;
            if (vm.products != null) {
                vm.products.selected = null;
            }
            localStorage.setItem("isTotalSet", vm.isTotalSet);
            vm.showAnimation = true;
            setTimeout(function () {
                vm.showAnimation = false;
                $scope.$apply();
            }, 3000);
        };

        vm.getOnTranslation = function () {
            return $translate.instant('pharmaPortalApp.pharmaReturn.placeholder.totalLiquidPrice');
        };
        vm.getOffTranslation = function () {
            return $translate.instant('pharmaPortalApp.pharmaReturn.placeholder.unitLiquidPrice');
        };

        $scope.$on('$destroy', function () {
            localStorage.removeItem("isTotalSet");
        });

        function isNumeric(n) {
            // Check if passed value is numeric
            n = n.toString().replace(/,/g, ".");
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function exportToExcel() {
            $http.post("api/pharma-return/toExcel", $rootScope.orderList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Devoluções_Farmacias_" + dateTime + ".xlsx", true);
                Notification.success({message: "Devolução exportada com sucesso", delay: 5000});
            }, function errorCallback(response) {
                Notification.error({message: "Erro ao exportar devolução", delay: ''});
            });
        }

        vm.valueBeforeChanged = null;

        function toggleLineEditMode(returnProd) {
            if (vm.valueBeforeChanged == null || returnProd == vm.valueBeforeChanged) {

                if (angular.isDefined(returnProd.editMode) && returnProd.editMode == true) {
                    returnProd.editMode = false;
                    vm.valueBeforeChanged = null;
                } else {
                    returnProd.editMode = true;
                    vm.valueBeforeChanged = Object.assign({}, returnProd);
                }
            } else {
                angular.forEach($rootScope.orderList, function (value, key) {
                    value.editMode = false;
                });
                vm.valueBeforeChanged = null;

            }
            $rootScope.updateTotalizers();
        }

        function validateLines(returnProd, keyEvent) {
            if (returnProd) {
                if (keyEvent.type == "blur" || (keyEvent.type == "keypress" && keyEvent.which === 13) || keyEvent == 'onEnter') {
                    vm.hasErrors = false;

                    if (vm.valueBeforeChanged != null && returnProd.total !== vm.valueBeforeChanged.total) {
                        returnProd.total = parseFloat(returnProd.total);
                        returnProd.price = Math.round((returnProd.total / returnProd.qtd) * 1000) / 1000;
                    } else {
                        returnProd.total = returnProd.price * returnProd.qtd;
                    }

                    $rootScope.updateTotalizers();
                    angular.forEach($rootScope.orderList, function (value, key) {
                        isQtdInvalid(value);
                        isPriceInvalid(value);
                    });
                }
            } else {
                $rootScope.updateTotalizers();
                angular.forEach($rootScope.orderList, function (value, key) {
                    isQtdInvalid(value);
                    isPriceInvalid(value);
                });
            }
        }

        function isQtdInvalid(returnProd) {
            if (returnProd.qtd && returnProd.qtd >= 0 && parseInt(returnProd.qtd)) {
                returnProd.invalidQtd = false;
            } else {
                returnProd.invalidQtd = true;
                vm.hasErrors = true;
            }
        }

        function isPriceInvalid(returnProd) {
            if (returnProd.price && returnProd.price >= 0 && parseFloat(returnProd.price)) {
                returnProd.invalidPrice = false;
            } else {
                returnProd.invalidPrice = true;
                vm.hasErrors = true;
            }
        }

        $scope.onOpenClose = function (isOpen) {
            angular.element('.ui-select-choices-row').removeClass('active');
        }

        $scope.isOrderEmpty = function () {
            return $rootScope.orderList.length <= 0;
        }

        $scope.refreshSelectModel = function (query) {
            if (vm.lastQuery != query) {
                vm.lastQuery = query
                $http.get("api/common/_searchProductsForSelect/" + query).then(function (result) {
                    if (result.data.length > 500) {
                        Notification.warning({message: "O número de resultados é superior a 500 registos. Por questões de performance, refine a sua pesquisa", delay: 5000});
                        return;
                    } else {
                        vm.products = result.data;
                    }
                });
            }
        };

        if ($localStorage && $localStorage.returnOrder) {
            $rootScope.orderList = $localStorage.returnOrder;
        } else {
            $rootScope.orderList = [];
        }

        $scope.$watch($rootScope.orderList, function () {
            $localStorage.returnOrder = $rootScope.orderList;
        }, true);

        $rootScope.updateTotalizers = function () {
            $rootScope.totalizer = {
                qtd: 0,
                price: 0,
                docTotal: 0
            };
            angular.forEach($rootScope.orderList, function (value, key) {
                value.total = parseFloat(value.total);
                $rootScope.totalizer.qtd += value.qtd * 1;
                $rootScope.totalizer.price += value.total;
                $rootScope.totalizer.docTotal += value.total * (1 + value.txiva / 100);
            });
        }
        $rootScope.updateTotalizers();

        var canceler = $q.defer();
        $scope.getPriceFromPolicy = function ($item, $model, flag) {
            if (vm.portalSettings.priceFromPolicyForReturns && flag) {
                // PENDING HTTP CALLS CANCEL
                canceler.resolve();
                canceler = $q.defer();

                vm.validPolicyPrice = false;
                $item.labcode = $item.labcode != null ? $item.labcode : 0;
                $item.hash = vm.loggedUserClientPe + "" + $item.labcode + "" + $item.cnp;
                vm.requestBody = {
                    "policyType": "PharmaReturnsPriceCalculation",
                    "policyData": [{
                        "hierarchy": [vm.loggedUserClientPe, $item.labcode, $item.cnp]
                    }]
                };

                $http.post("api/policyExecuter/execute", vm.requestBody, {
                    timeout: canceler.promise
                }).then(function (result) {
                    vm.validPolicyPrice = true;

                    var curResult = $filter('filter')(result.data.output, {
                        hash: $item.hash
                    }, true);

                    vm.products.selected.price = curResult[0].result;
                    if (!curResult[0].result || curResult[0].result <= 0) {
                        vm.products.selected.price = 0;
                        vm.validPolicyPrice = false;
                    }
                });
            }
        };

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
    }
})();
