(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('portal-settings', {
			parent : 'entity',
			url : '/portal-settings',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'pharmaPortalApp.portalSettings.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/portal-settings/portal-settings.html',
					controller : 'PortalSettingsController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('portalSettings');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('intranet-settings', {
			parent : 'entity',
			url : '/intranet-settings',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'pharmaPortalApp.portalSettings.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/portal-settings/intranet-settings.html',
					controller : 'IntranetSettingsController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('portalSettings');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		});
	}
})();
