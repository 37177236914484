(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('pharma-self-test', {
            parent: 'entity',
            url: '/pharma-self-test',
            data: {
                authorities: ['ROLE_INFARMED'],
                pageTitle: 'pharmaPortalApp.pharmaSelfTest.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pharma-self-test/pharma-self-test-detail.html',
                    controller: 'PharmaSelfTestDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaSelfTest');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
