(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ThemeSettingsController', ThemeSettingsController);

	ThemeSettingsController.$inject = [ 'Notification', '$scope', '$translate', 'DataUtils', 'PortalSettings' ];

	function ThemeSettingsController(Notification, $scope, $translate, DataUtils, PortalSettings) {
		var vm = this;

		vm.save = save;
		vm.loadAll = loadAll;

		vm.setLogo = function($file, settings) {
			if ($file && $file.$error === 'pattern') {
				return;
			}
			if ($file) {
				DataUtils.toBase64($file, function(base64Data) {
					$scope.$apply(function() {
						vm.settings.logo = base64Data;
						vm.settings.logoContentType = $file.type;
					});
				});
			}
		};

		function save() {
			try {
				PortalSettings.update(vm.settings, function(httpResponse) {
					Notification.success({message : $translate.instant('pharmaPortalApp.themeSettings.messages.success'), delay : 5000});
				});
			} catch (err) {
				Notification.error({message : $translate.instant('pharmaPortalApp.themeSettings.messages.error'), delay : ''});
			}
		}

		function loadAll() {
			vm.settings = PortalSettings.query();
		}
		loadAll();
	}
})();
