(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaTransfReceptionController', PharmaTransfReceptionController);

    PharmaTransfReceptionController.$inject = ['Notification', '$scope', '$state', '$http', '$filter', 'PharmaTransfReception', 'ParseLinks', 'paginationConstants', 'pagingParams', 'ModuleSettings'];

    function PharmaTransfReceptionController(Notification, $scope, $state, $http, $filter, PharmaTransfReception, ParseLinks, paginationConstants, pagingParams, ModuleSettings) {
        var vm = this;
        vm.loadAll = loadAll;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.getWarehouseFullDescription = getWarehouseFullDescription;
        vm.getTransferTypeFullDescription = getTransferTypeFullDescription;
        vm.getInvoiceTypeFullDescription = getInvoiceTypeFullDescription;
        vm.getClientFullDescription = getClientFullDescription;

        vm.loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.receptionsWarehouseTransfModule) {
                    $state.go('accessdenied');
                } else {
                    PharmaTransfReception.query({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);

                    $http.get("api/common/_searchWarehousesForSelect").then(function (result) {
                        vm.warehouses = result.data;
                    });
                    $http.get("api/common/_searchInvoiceTypesForSelect").then(function (result) {
                        vm.invoiceTypes = result.data;
                    });
                    $http.get("api/common/_getTransferTypeDescription").then(function (result) {
                        vm.transferTypes = result.data;
                    });
                    $http.get("api/companies/findAll").then(function (result) {
                        vm.clients = result.data;
                    });
                }
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.pharmaTransfReceptionHeaders = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function getWarehouseFullDescription(warehouseCode) {
            if (angular.isDefined(vm.warehouses) && warehouseCode) {
                var warehouse = $filter('filter')(vm.warehouses, {
                    code: warehouseCode
                })[0];
                return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
            } else {
                return warehouseCode;
            }
        }

        function getTransferTypeFullDescription(transferTypeCode) {
            if (angular.isDefined(vm.transferTypes) && transferTypeCode) {
                var transferType = $filter('filter')(vm.transferTypes, {
                    code: transferTypeCode
                })[0];
                return transferTypeCode + (transferType != null ? ' - ' + transferType.description : '');
            } else {
                return transferTypeCode;
            }
        }

        function getInvoiceTypeFullDescription(invoiceTypeCode) {
            if (angular.isDefined(vm.invoiceTypes) && invoiceTypeCode) {
                var invoiceType = $filter('filter')(vm.invoiceTypes, {
                    code: invoiceTypeCode
                })[0];
                return invoiceTypeCode + (invoiceType != null ? ' - ' + invoiceType.description : '');
            } else {
                return invoiceTypeCode;
            }
        }

        function getClientFullDescription(clientPE) {
            var client = clientPE.substring(0, clientPE.length - 2);
            var deliveryPoint = clientPE.substring(clientPE.length - 2, clientPE.length);

            if (angular.isDefined(vm.clients) && clientPE && clientPE != '' && clientPE.length > 2) {
                var clientDesc = $filter('filter')(vm.clients, {
                    client: parseInt(clientPE.substring(0, clientPE.length - 2)),
                    deliveryPoint: parseInt(clientPE.substring(clientPE.length - 2, clientPE.length))
                })[0];
                return client + '(' + deliveryPoint + ')' + (clientDesc != null ? ' - ' + clientDesc.naming : '');
            } else {
                return clientPE != "0" ? client + '(' + deliveryPoint + ')' : "";
            }
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
    }
})();
