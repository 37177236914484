(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaBalanceController', PharmaBalanceController);

	PharmaBalanceController.$inject = [ 'Notification', 'blockUI', '$q', '$translate', '$stateParams', '$state', '$timeout', '$scope', '$http', '$filter', 'ModuleSettings', 'ProductCurrentAccountLock' ];

	function PharmaBalanceController(Notification, blockUI, $q, $translate, $stateParams, $state, $timeout, $scope, $http, $filter, ModuleSettings, ProductCurrentAccountLock) {
		var vm = this;
		vm.productCurrentAccountLocks = ProductCurrentAccountLock.query();
		vm.currentPeriod = $stateParams.workingPeriod;
		vm.curPeriod = new Date();
		
		vm.loadAll = loadAll;
		vm.isMonthClosed = isMonthClosed;
		vm.getFirstOpenPeriod = getFirstOpenPeriod;
		vm.prepareFilter = prepareFilter;
		vm.getTab1Content = getTab1Content;
		vm.getTab2Content = getTab2Content;
		vm.getTab3Content = getTab3Content;
		
		vm.closeMonth = closeMonth;
		vm.prevPeriod = prevPeriod;
		vm.nextPeriod = nextPeriod;
		vm.doFilter = doFilter;
		
		vm.prodFilter = "0";
		vm.currentTab = 1;
		vm.monthIsLock = false;
		vm.lastQuery = "";
		vm.products = [];
		vm.filter = [];
		vm.filter.pharmacies = [];	
		vm.filter.products = [];
		vm.curPharmaciesSelector = [];
		vm.curProductsSelector = [];
		
		$scope.detailedProductInfo = [];
		vm.detailedProductInfoList = [];
		
		vm.loadAll();

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.balanceModule) {
					$state.go('accessdenied');
				} else {
					vm.getFirstOpenPeriod();

					$http.get("api/pharma-balance/getDistinctCompany").then(function(result) {
						vm.curPharmaciesSelector = result.data;
					});
					
					$http.get("api/pharma-balance/getDistinctProduct").then(function(result) {
						angular.forEach(result.data, function(value, key) {
							vm.curProductsSelector.push({'cnp' : value[0], 'desc' : value[1]});
						});
					});
				}
			});
		}
		
		function isMonthClosed() {
			var period = $filter('date')(vm.currentPeriod, "yyyy'-'MM");
			if(vm.productCurrentAccountLocks != null && vm.productCurrentAccountLocks.period >= period) { vm.monthIsLock = true; }
			else { vm.monthIsLock = false; }
		}
		
		function getFirstOpenPeriod() {
			if($stateParams.workingPeriod != null) {
				vm.curPeriod = $stateParams.workingPeriod;
				vm.currentPeriod = new Date(vm.curPeriod);
			} else {
				if(vm.productCurrentAccountLocks.id != null){
					var period2 = new Date(vm.productCurrentAccountLocks.period);
					period2.setMonth(period2.getMonth() + 1);
					vm.currentPeriod = period2;
				} else {
					vm.currentPeriod = new Date();
				}
				vm.curPeriod = $filter('date')(vm.currentPeriod, "yyyy'-'MM");
			}
			
		    var q1 = vm.prepareFilter();
		    var q2 = vm.isMonthClosed();
			
			$q.all([q1, q2]).then(function () {
				if(vm.currentTab == 1) {
					vm.getTab1Content();
				} else if(vm.currentTab == 2) {
					vm.getTab2Content();
				} else {
					vm.getTab3Content();
				}
			});


			return true;
		}
		
		function prepareFilter(){
			vm.myFilter = [];
			vm.myFilter.pharmacies = [];
			vm.myFilter.products = [];
			
			angular.forEach(vm.filter.pharmacies, function(value, key) {
				if(value.warehouse === '999') { vm.myFilter.pharmacies.push('999'); } 
				else { vm.myFilter.pharmacies.push(value.pharmaWarehouse); }
			});
			
			angular.forEach(vm.filter.products, function(value, key) {
				vm.myFilter.products.push(value.cnp);
			});
		}
		
		function getTab1Content(){
			vm.currentTab = 1;
			blockUI.start($translate.instant('pharmaPortalApp.pharmaBalance.message.screenData.loading'));
			vm.prepareFilter();

			$http.get("api/pharma-balance/getGlobalInfoByPeriod/" + $filter('date')(vm.currentPeriod, "yyyy'-'MM"), {
				params: {pharmacies: vm.myFilter.pharmacies, products : vm.myFilter.products }
			}).then(function(result) {
				// LABELS DOS PERIODOS
				$scope.chartConfig.xAxis.categories = result.data["xAxisLabels"];
				// SERIES DO GRÁFICO
				$scope.chartSeries = [
					{ name: "Saídas", data: result.data["xAxisData"]["salesSerie"] },
					{ name: "Custo Vendas", data: result.data["xAxisData"]["salesCostSerie"] },
					{ name: "Acerto", data: result.data["xAxisData"]["adjustmentsSerie"] },
					{ name: "Margem", data: result.data["xAxisData"]["marginSerie"] },
					{ name: "Rácio Vendas/Total Stock", data: result.data["xAxisData"]["ratioSerie"] }
				];
				$scope.chartConfig.series = $scope.chartSeries;
				$scope.globalValues = result.data["treeGlobalMap"];
				blockUI.stop();
			}, function(result) {
				blockUI.stop();
			});
		}
		
		function getTab2Content() {
			vm.currentTab = 2;
			blockUI.start($translate.instant('pharmaPortalApp.pharmaBalance.message.screenData.loading'));
			vm.prepareFilter();
			
			$http.get("api/pharma-balance/getDetailedPharmacyInfoByPeriod/" + $filter('date')(vm.currentPeriod, "yyyy'-'MM"), {
				params: {pharmacies: vm.myFilter.pharmacies, products : vm.myFilter.products }
			}).then(function(result) {
				$scope.detailedPharmacyInfo = result.data;
				blockUI.stop();
			}, function(result) {
				blockUI.stop();
			});
		}
		
		function getTab3Content() {
			vm.currentTab = 3;
			blockUI.start($translate.instant('pharmaPortalApp.pharmaBalance.message.screenData.loading'));
			vm.prepareFilter();

			$scope.detailedProductInfo = [];
			
			$http.get("api/pharma-balance/getDetailedProductInfoByPeriod/" + $filter('date')(vm.currentPeriod, "yyyy'-'MM"), {
				params: {pharmacies: vm.myFilter.pharmacies, products : vm.myFilter.products, movementType : vm.prodFilter }
			}).then(function(result) {
				vm.detailedProductInfoList = result.data;
				$scope.loadMore();
				jQuery(".productsTable.jh-table").floatThead('reflow');
				blockUI.stop();
			}, function(result) {
				blockUI.stop();
			});
		}
		
		$scope.loadMore = function() {
			var i = 0;
			if(vm.detailedProductInfoList.length > 0) {
				
				angular.forEach(vm.detailedProductInfoList, function(value, key) {
					if (i < 15) {
						$scope.detailedProductInfo.push(value);
//						vm.detailedProductInfoList.splice(0,1);
						vm.detailedProductInfoList = vm.detailedProductInfoList.slice(1,vm.detailedProductInfoList.length);
					}
					i++;
				});
			}
		};

		function closeMonth() {
			blockUI.start($translate.instant('pharmaPortalApp.pharmaBalance.message.closeMonth.loading'));
			
			var period = $filter('date')(vm.currentPeriod, "yyyy'-'MM");
			$http.get("api/basic/product-current-account/calculate/?period=" + period).then(function(result) {
				Notification.success({message: $translate.instant('pharmaPortalApp.pharmaBalance.message.closeMonth.success'), delay: 5000});
				blockUI.stop();
			}, function(result) {
				Notification.error({message: $translate.instant('pharmaPortalApp.pharmaBalance.message.closeMonth.error'), delay: ''});
				blockUI.stop();
			});
		}
		
		function prevPeriod() {
			vm.currentPeriod.setMonth(vm.currentPeriod.getMonth() - 1);
			vm.curPeriod = $filter('date')(vm.currentPeriod, "yyyy'-'MM");
			vm.isMonthClosed();
			
			if(vm.currentTab == 1) {
				vm.getTab1Content();
			} else if(vm.currentTab == 2) {
				vm.getTab2Content();
			} else {
				vm.getTab3Content();
			}
		}

		function nextPeriod(){
			vm.currentPeriod.setMonth(vm.currentPeriod.getMonth() + 1);
			vm.curPeriod = $filter('date')(vm.currentPeriod, "yyyy'-'MM");
			vm.isMonthClosed();
			
			if(vm.currentTab == 1) {
				vm.getTab1Content();
			} else if(vm.currentTab == 2) {
				vm.getTab2Content();
			} else {
				vm.getTab3Content();
			}
		}
		
		function doFilter($item, $model) {
			if(vm.currentTab == 1) {
				vm.getTab1Content();
			} else if(vm.currentTab == 2) {
				vm.getTab2Content();
			} else {
				vm.getTab3Content();
			}
		}
		
		$scope.refreshSelectModel = function(query) {
			if (vm.lastQuery != query) {
				vm.lastQuery = query
				
				if (vm.products.length > 250) {
					Notification.warning({message: "O número de resultados é superior a 250 registos. Por questões de performance, refine a sua pesquisa", delay: 5000});
					return;
				} else {
					var cnps= $filter('filter')(vm.curProductsSelector, {cnp: query})
					var descs = $filter('filter')(vm.curProductsSelector, {desc: query})
					angular.extend(vm.products, cnps);
					angular.extend(vm.products, descs);
				}
			}
		};
		
		$scope.chartConfig = {
			credits: { enabled: false },
			chart : { type : 'column' },
			title : { text : '' },
			subtitle: { text: '' },
			xAxis: { categories: [] },
			yAxis : { title: { text: '' } },
		    series : null,
		    legend : { borderColor: 'rgb(204, 214, 235)', borderWidth: 1, backgroundColor: '#f2f2f2' }
		}
		
		$scope.floatTheadOptions1 = {position : 'absolute', responsiveContainer : function($table) {return $table.closest(".table-responsive1");}};
		$scope.floatTheadOptions2 = {position : 'absolute', responsiveContainer : function($table) {return $table.closest(".table-responsive2");}};
		$scope.floatTheadOptions3 = {position : 'absolute', responsiveContainer : function($table) {return $table.closest(".table-responsive3");}};
		$scope.floatTheadOptions4 = {position : 'absolute', responsiveContainer : function($table) {return $table.closest(".table-responsive4");}};
	}
})();
