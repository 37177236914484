(function() {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalNotificationDeleteController',PortalNotificationDeleteController);

    PortalNotificationDeleteController.$inject = ['$uibModalInstance', 'entity', 'PortalNotification'];

    function PortalNotificationDeleteController($uibModalInstance, entity, PortalNotification) {
        var vm = this;

        vm.portalNotification = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PortalNotification.delete({id: id}, function () {$uibModalInstance.close(true); });
        }
    }
})();
