(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('AS400MessagesController', AS400MessagesController);

    AS400MessagesController.$inject = ['$state', 'AS400Messages'];

    function AS400MessagesController($state, AS400Messages) {

        var vm = this;

        vm.messages = AS400Messages.query();
        vm.filter = {
            username: ""
        };
        vm.clearFilter = clearFilter;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            AS400Messages.getMessages(vm.filter, function (result) {
                vm.messages = result;
            });
        }

        function clearFilter() {
            vm.filter = {
                username: ""
            };
            loadAll();
        }
    }
})();
