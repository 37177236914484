(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CompanyDialogController', CompanyDialogController);

	CompanyDialogController.$inject = [ 'Notification', '$uibModalInstance', '$timeout', '$translate', 'entity', 'Company'];

	function CompanyDialogController(Notification, $uibModalInstance, $timeout, $translate, entity, Company) {
		var vm = this;

		vm.company = entity;

        vm.param = {
            companyId:vm.company.id
        };

        vm.schedule = {
            workingDayDisabled: false,
            saturdayDisabled: false,
            sundayDisabled: false,
            holidayDisabled: false
        };

		vm.clear = clear;
		vm.save = save;

        Company.getCompanySchedule(vm.param, function (result) {
            if (result.workingDayDisabled != null) {
                vm.schedule=result;
            }
        }, function (error) {
        });

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			if (vm.company.id !== null) {
			    if (vm.schedule.company == null) {
			        vm.schedule.company = vm.company;
                }
				Company.update(vm.company, onSaveSuccess, onSaveError);
				Company.saveCompanySchedule(JSON.stringify(vm.schedule), onSaveSuccess, onSaveError);
			} else {
				Notification.error({
					message : $translate.instant('pharmaPortalApp.company.message.errorUpdating'),
					delay : ''
				});
			}
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			Notification.success({
				message : $translate.instant('pharmaPortalApp.company.message.updated'),
				delay : 5000
			});
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.isSaving = false;
		}
	}
})();
