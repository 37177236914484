(function () {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .service('InvoiceAndClaim', InvoiceAndClaim);

    InvoiceAndClaim.$inject = ['$state', '$uibModal', '$translate', 'Notification', '$http', 'blockUI', '$rootScope', '$filter', '$sce', '$localStorage', 'PharmaPicking'];

    function InvoiceAndClaim($state, $uibModal, $translate, Notification, $http, blockUI, $rootScope, $filter, $sce, $localStorage, PharmaPicking) {

        this.currentPharmaPicking = {};
        this.user = null;
        this.products = null;
        this.claim = null;

        this.invoiceAndClaim = function (currentPharmaPicking, products, user) {
            this.currentPharmaPicking = angular.copy(currentPharmaPicking);
            this.products = angular.copy(products);
            this.user = angular.copy(user);
            this.claim = true;
            this.submitToErp();
        }

        this.invoice = function (currentPharmaPicking, products, user) {
            this.currentPharmaPicking = angular.copy(currentPharmaPicking);
            this.products = angular.copy(products);
            this.user = angular.copy(user);
            this.claim = false;
            this.submitToErp();
        };

        this.onSaveSuccess = function() {
            var bodyRequest = {
                "idPharmaPicking": this.currentPharmaPicking.id,
                "userCloseConference": $rootScope.userCloseConference,
                "listMissingProducts": this.products,
                "supplierCode": this.currentPharmaPicking.supplierCode
            };

            blockUI.start($translate.instant('pharmaPortalApp.pharmaPicking.message.submittingPharmaPicking'));
            var self = this;
            $http.post("/api/pharma-picking/submit", bodyRequest).then(function successCallback(response) {
                self.onSubmitSuccess(response.data)
            }, function errorCallback(response) {
                self.onSubmitError(response.data)
            });
        }

        this.onSaveError = function(result) {
            if (result.data.success) {
                this.onSaveSuccess();
            } else {
                Notification.error({message: result.data.message, delay: ''});
            }
        }

        this.submitToErp = function() {
            var curPP = this.currentPharmaPicking;
            if (this.user == curPP.lastModifyingUser && curPP.status != 'CLOSED') {
                var self = this;
                PharmaPicking.save(curPP, function (result) {
                    self.onSaveSuccess();
                }, function (result) {
                    self.onSaveError(result);
                });
            } else {
                Notification.error({message: "Não possui autorização para realizar a operação!", delay: ''});
            }
        }

        this.onSubmitSuccess = function(result) {
            blockUI.stop();
            if (result.success) {
                Notification.success({message: result.message, delay: 5000});
                if (this.claim) {
                    this.requestPdf();
                } else {
                    $state.go("pharma-picking", {}, {
                        reload: true
                    });
                }
            } else {
                Notification.error({message: result.message, delay: ''});
                $state.go("pharma-picking-detail.edit", {
                    id: this.currentPharmaPicking.id
                }, {
                    reload: true
                });
            }
        }

        this.onSubmitError = function(result) {
            blockUI.stop();
            if (result.success) {
                Notification.success({message: result.message, delay: 5000});
            } else {
                Notification.error({message: result.message, delay: ''});
            }
        }

        this.requestPdf = function() {
            blockUI.start($translate.instant('pharmaPortalApp.pharmaSupplierComplaint.generatingPDF'));
            var self = this;
            $http.post("/api/pharma-supplier-complaint/generatePDF",
                this.currentPharmaPicking,
                {responseType: "arraybuffer"})
                .then(function successCallback(response) {
                    blockUI.stop();
                    var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                    var blob = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    var fileURL = URL.createObjectURL(blob);
                    window.open($sce.trustAsResourceUrl(fileURL), '_blank');
                    saveAs(blob, "Complaint_" + self.currentPharmaPicking.id + "_" + dateTime + ".pdf", true);
                    Notification.success({message: "Report gerado com sucesso", delay: 5000});
                    self.resetLocalStorage();

                    $state.go("pharma-picking", {}, {
                        reload: true
                    });
                }, function errorCallback(response) {
                    blockUI.stop();
                    Notification.error({message: "Erro ao gerar report", delay: ''});
                });
        }

        this.resetLocalStorage = function() {
            $localStorage.pharmaPicking = null;
            $localStorage.changeState = null;
        }

    }
})();
