(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaBalanceReportController', PharmaBalanceReportController);

	PharmaBalanceReportController.$inject = [ 'Notification', 'blockUI', 'period', '$uibModalInstance', '$scope', '$state', '$http', 'PortalSettings', '$translate', '$filter', '$timeout'];

	function PharmaBalanceReportController(Notification, blockUI, period, $uibModalInstance, $scope, $state, $http, PortalSettings, $translate, $filter, $timeout) {
		var vm = this;

		vm.settings = PortalSettings.query();
		
		vm.clear = clear;
		vm.prevCompany = prevCompany;
		vm.nextCompany = nextCompany;
		vm.loadAll = loadAll;
		vm.getScreenData = getScreenData;
		vm.exportToPdf = exportToPdf;
		vm.pdfTableArray = pdfTableArray;

		vm.curPharmacy = {};
		$scope.detailedProductInfoDialog = [];
		vm.detailedProductInfoList = [];
		$scope.pdfTableStructure = [];

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function prevCompany() {
			var curIdx = vm.curPharmacy.selectedIndex;
			curIdx = curIdx - 1;
			if (curIdx < 0) {
				curIdx = $scope.pharmaciesSelector.length - 1;
			}
			vm.curPharmacy = $scope.pharmaciesSelector[curIdx]
			vm.curPharmacy.selectedIndex = curIdx;
			
			vm.getScreenData(vm.curPharmacy);
		}
		
		function nextCompany() {
			var curIdx = vm.curPharmacy.selectedIndex;
			curIdx = curIdx + 1;
			if (curIdx == $scope.pharmaciesSelector.length) {
				curIdx = 0;
			}
			vm.curPharmacy = $scope.pharmaciesSelector[curIdx]
			vm.curPharmacy.selectedIndex = curIdx;
			
			vm.getScreenData(vm.curPharmacy);
		}

		vm.loadAll();
		function loadAll() {
			// GET DISTINCT COMPANIES FOR PERIOD
			$http.get("api/pharma-balance/getDistinctCompanyForPeriod/" + period.period).then(function(result) {
				$scope.pharmaciesSelector = result.data;
				vm.curPharmacy = $scope.pharmaciesSelector[$scope.pharmaciesSelector.length - 1];
				vm.curPharmacy.selectedIndex = $scope.pharmaciesSelector.length - 1;
				vm.getScreenData(vm.curPharmacy);
			});
		}

		function getScreenData(curPharmacy) {
			blockUI.start($translate.instant('pharmaPortalApp.pharmaBalance.message.screenData.loading'));
			
			$scope.detailedProductInfoDialog = [];
			
			var myFilter = [];
			if (vm.curPharmacy.warehouse === '999') {
				myFilter.push('999');
			} else {
				myFilter.push(vm.curPharmacy.pharmaWarehouse);
			}

			// GET PHARMACY INFO FOR PERIOD
			$http.get("api/pharma-balance/getDetailedProductInfoByPeriod/" + period.period, {
				params : { pharmacies : myFilter, products : [] }
			}).then(function(result) {
				vm.detailedProductInfoList = result.data;
				
				vm.pdfTableArray();
				$scope.loadMoreItems();
				blockUI.stop();
			}, function(result) {
				blockUI.stop();
			});
		}

		$scope.loadMoreItems = function() {
			var i = 0;
			if(vm.detailedProductInfoList.length > 0) {
				angular.forEach(vm.detailedProductInfoList, function(value, key) {
					if (i < 15) {
						$scope.detailedProductInfoDialog.push(value);
						vm.detailedProductInfoList = vm.detailedProductInfoList.slice(1,vm.detailedProductInfoList.length);
					}
					i++;
				});
			}
			jQuery(".table-dialog .jh-table").floatThead('reflow');
		};
		
		function exportToPdf() {
			blockUI.start($translate.instant('pharmaPortalApp.pharmaBalance.message.screenData.generatingPdf'));
			
			$timeout(function() {
				var docDefinition = {
					pageSize : 'A4', // a string or { width: number, height: number }
					pageOrientation : 'landscape', // by default we use portrait, you can change it to landscape if you wish
					pageMargins : [ 20, 25, 20, 25 ], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
					defaultStyle : {
						columnGap : 15
					},
					footer : function(currentPage, pageCount) {
						if (currentPage != 1) {
							return {
								columns : [ {
									width : '*',
									text : 'Relatório de movimentos da farmácia ' + vm.curPharmacy.naming + ' no período ' + period.period,
									alignment : 'left', fontSize : 8, margin : [ 20, 5, 0, 0 ]
								}, {
									width : 200,
									text : 'Pág. ' + currentPage.toString() + ' de ' + pageCount,
									alignment : 'right', fontSize : 8, margin : [ 0, 5, 20, 0 ]
								} ]
							};
						} else {
							return { text : '' };
						}
					},
					styles : {
						tableHeader : {
							alignment : 'center', fontSize : 8, bold : true
						},
						content : {
							fontSize : 8
						}
					},
					content : [ {
						columns : [ {
							width : 200,
							image : 'data:' + vm.settings.logoContentType + ';base64,' + vm.settings.logo
						}, {
							width : '*',
							text : ''
						}, {
							width : 200,
							stack : [ vm.curPharmacy.naming, vm.curPharmacy.address, vm.curPharmacy.zipCode + '-' + vm.curPharmacy.zipLocal, 'NIF: ' + vm.curPharmacy.nif, ]
						} ]
					}, {
						text : 'Relatório de Movimentos no período ' + period.period,
						fontSize : 12,
						margin : [ 0, 10, 0, 10 ]
					}, {
						style : 'content',
						table : {
							widths : [ 175, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35 ],
							headerRows : 2,
							keepWithHeaderRows : 2,
							body : $scope.pdfTableStructure
						}
					} ]
				};
			
				pdfMake.createPdf(docDefinition).download('Report_F' + vm.curPharmacy.client + '_P' + period.period + '.pdf', function() {
					$scope.$apply(function() {
						blockUI.stop();
					});
				});
			}, 500);
		}
		
		function pdfTableArray() {
			$scope.pdfTableStructure = [];
			$scope.pdfTableStructure.push([ {},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.previousPeriodBalance'), style : 'tableHeader', colSpan : 2}, {},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.purchases'), style : 'tableHeader', colSpan : 2}, {},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.initialAndPurchases'), style : 'tableHeader', colSpan : 3}, {}, {},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.sales'), style : 'tableHeader',	colSpan : 2}, {},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.adjustment'), style : 'tableHeader', colSpan : 2}, {},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.nextPeriodBalance'), style : 'tableHeader', colSpan : 3}, {}, {}
			]);

			$scope.pdfTableStructure.push([
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.form.product'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.units'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.price'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.units'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.price'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.units'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.price'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.total'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.units'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.price'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.diff'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.total'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.units'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.price'), style : 'tableHeader'},
				{text : $translate.instant('pharmaPortalApp.pharmaBalance.table.total'), style : 'tableHeader'}
			]);

			angular.forEach(vm.detailedProductInfoList, function(value, key) {
				if(value.productCnp != "999999999"){
					return $scope.pdfTableStructure.push([
						{text : value.productCnp + ' - ' + value.productDesc},
						{text : value.previousBalanceQuantity, alignment : 'right'},
						{text : $filter('currency')(value.previousBalanceUnit, "€", 2), alignment : 'right'},
						{text : value.purchasesQuantity, alignment : 'right'},
						{text : $filter('currency')(value.purchasesUnit, "€", 2), alignment : 'right'},
						{text : value.initialBalanceQuantity, alignment : 'right'},
						{text : $filter('currency')(value.initialBalanceUnit, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.initialBalanceTotal, "€", 2), alignment : 'right'},
						{text : value.salesQuantity, alignment : 'right'},
						{text : $filter('currency')(value.salesUnit, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.adjustmentBalance, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.adjustmentTotal, "€", 2), alignment : 'right'},
						{text : value.remainBalanceQuantity, alignment : 'right'},
						{text : $filter('currency')(value.remainBalanceUnit, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.remainBalanceTotal, "€", 2), alignment : 'right'}
					]);
				} else {
					return $scope.pdfTableStructure.push([
						{text : 'TOTAIS', alignment : 'right'},
						{text : value.previousBalanceQuantity, alignment : 'right'},
						{text : $filter('currency')(0, "€", 2), alignment : 'right'},
						{text : value.purchasesQuantity, alignment : 'right'},
						{text : $filter('currency')(value.purchasesUnit, "€", 2), alignment : 'right'},
						{text : value.initialBalanceQuantity, alignment : 'right'},
						{text : $filter('currency')(0, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.initialBalanceTotal, "€", 2), alignment : 'right'},
						{text : value.salesQuantity, alignment : 'right'},
						{text : $filter('currency')(value.salesUnit, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.adjustmentBalance, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.adjustmentTotal, "€", 2), alignment : 'right'},
						{text : value.remainBalanceQuantity, alignment : 'right'},
						{text : $filter('currency')(0, "€", 2), alignment : 'right'},
						{text : $filter('currency')(value.remainBalanceTotal, "€", 2), alignment : 'right'}
					]);
				}
			});
			return $scope.pdfTableStructure;
		}
		
		$scope.floatTheadOptions5 = {
			position : 'absolute',
			scrollContainer : function($table) {
				return $table.closest(".table-responsive5");
			}
		};
	}
})();
