(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(localStorageConfig);

	localStorageConfig.$inject = [ '$localStorageProvider', '$sessionStorageProvider' ];

	function localStorageConfig($localStorageProvider, $sessionStorageProvider) {
		$localStorageProvider.setKeyPrefix('jhi-');
		$sessionStorageProvider.setKeyPrefix('jhi-');
	}
})();
