(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ProductListController', ProductListController);

	ProductListController.$inject = [ 'Notification', 'DataUtils', 'ProductList', 'ParseLinks', 'paginationConstants', 'pagingParams', '$state'];

	function ProductListController(Notification, DataUtils, ProductList, ParseLinks, paginationConstants, pagingParams, $state) {

		var vm = this;

		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.openFile = DataUtils.openFile;
		vm.byteSize = DataUtils.byteSize;

		vm.loadPage = loadPage;
		vm.transition = transition;
		vm.loadAll = loadAll;

        loadAll();

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch
			});
		}

		function loadAll() {
            ProductList.query({
                page : pagingParams.page - 1,
                size : vm.itemsPerPage,
                sort : sort()
            }, onSuccess, onError);
            function sort() {
                var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productLists = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                Notification.error({
                    message : error.data.message,
                    delay : ''
                });
            }
		}

	}
})();
