(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaSaleController', PharmaSaleController);

    PharmaSaleController.$inject = ['Notification', '$rootScope', '$scope', '$filter', '$state', '$http', '$q', '$localStorage', 'Company', 'PortalSettings', 'ModuleSettings', 'Principal'];

    function PharmaSaleController(Notification, $rootScope, $scope, $filter, $state, $http, $q, $localStorage, Company, PortalSettings, ModuleSettings, Principal) {
        var vm = this;
        vm.portalSettings = PortalSettings.query();
        vm.isPharma = false;
        vm.ownWarehouse = false;

        vm.companies = Company.query({size: 1000});
        vm.currentUserPe = null;
        vm.currentUserType = "";
        vm.currentUserClientGroup = "";

        vm.updateCompany = updateCompany;
        vm.loadAll = loadAll;
        vm.isValidForOrder = isValidForOrder;
        vm.isNumeric = isNumeric;
        vm.addProduct = addProduct;
        vm.checkProductValidToOrder = checkProductValidToOrder;
        vm.addAllProducts = addAllProducts;
        vm.searchByLab = searchByLab;
        vm.clearSearchInTable = clearSearchInTable;
        vm.toggleLineEditMode = toggleLineEditMode;
        vm.validateLines = validateLines;

        vm.exportSearchToExcel = exportSearchToExcel;
        vm.orderToExcel = orderToExcel;
        vm.confirmOrderDialog = confirmOrderDialog;

        $scope.searchInTable = "";
        $scope.productsList = [];
        $scope.search = {
            searchQuery: "",
            showNoStock: false,
            ownWarehouse: false
        };
        $rootScope.saleTotals = {
            total: 0,
            totalPV: 0,
            totalDesc: 0
        };

        function updateCompany($item, $model) {
            Company.isInactive($item, function (result) {
                if (result.regDate != null) {
                    result.regDate = result.regDate.substring(6,8) + "-" + result.regDate.substring(4,6) + "-" + result.regDate.substring(0,4);
                    result.regTime = result.regTime.substring(0,2) + ":" + result.regTime.substring(2,4) + ":" + result.regTime.substring(4,6);
                    result.company = $item.namingForOptions;
                    $rootScope.message = result;
                    vm.currentCompany = "";
                    $state.go("pharma-sale.inactive");
                } else {
                    if ($rootScope.saleOrderList.length > 0) {
                        vm.currentCompany = $localStorage.currentCompany;
                        Notification.error({
                            message: "Não é possível mudar de cliente se a encomenda atual não estiver vazia",
                            delay: ''
                        });
                    } else {
                        vm.selectedCompany = $item;
                        $localStorage.currentCompany = vm.currentCompany;

                        if ($item) {
                            vm.currentUserPe = $item.clientPe;
                            vm.currentUserType = $item.clientType;
                            vm.currentUserClientGroup = $item.clientGroup;
                            vm.isPharma = $item.clientType == "F";

                            if ($item.pharmaWarehouse) {
                                vm.ownWarehouse = $item.pharmaWarehouse;
                            } else {
                                vm.ownWarehouse = false;
                            }
                        }

                        // SE EXISTIR PESQUISA, VOLTA A FAZER
                        if ($scope.search.searchQuery != "") {
                            $scope.searchProducts();
                            if (!vm.isPharma && $scope.search.ownWarehouse) {
                                $scope.search.ownWarehouse = false;
                            }
                        } else {
                            $scope.search = {
                                searchQuery: "",
                                showNoStock: false,
                                ownWarehouse: false
                            };
                            $scope.productsList = [];
                        }
                    }
                }
            });
        };

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.salesModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        vm.isPharma = false;
                        vm.ownWarehouse = false;
                        vm.loggedUser = null;

                        if (account) {
                            vm.loggedUser = account.login;
                        } else {
                            $state.go('accessdenied');
                        }
                    });
                    validateLines();
                }
            });
        }

        loadAll();

        function isValidForOrder(product) {
            return (!product.pvp || !product.warehousestock || !product.qtd || product.pvp <= 0 || product.warehousestock <= 0 || product.qtd <= 0);
        }

        function isNumeric(n) { // Check if passed value is numeric
            n = n.toString().replace(/,/g, ".");
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function addProduct(product) {
            switch (checkProductValidToOrder(product, $rootScope.saleOrderList)) {
                case 0:
                    // $rootScope.message = "Produto adicionado com sucesso";
                    // $state.go("pharma-sale.success");
                    break;
                case 1:
                    $rootScope.message = "O produto indicado já existia nesta encomenda. A quantidade a encomendar foi atualizada";
                    $state.go("pharma-sale.success");
                    break;
                case 2:
                    $rootScope.message = "O produto não tem um preço válido e maior que zero";
                    $state.go("pharma-sale.alert");
                    break;
                case 3:
                    $rootScope.message = "A quantidade a encomendar tem de ser um número válido e maior que zero";
                    $state.go("pharma-sale.alert");
                    break;
                case 4:
                    $rootScope.message = "Não existe stock disponível deste produto";
                    $state.go("pharma-sale.alert");
                    break;
                case 5:
                    $rootScope.message = "A quantidade a encomendar tem um valor superior ao stock disponível";
                    $state.go("pharma-sale.alert");
                    break;
            }
        }

        function checkProductValidToOrder(product, listOrderProducts) {
            if (!(product.pvp && isNumeric(product.pvp) && product.pvp > 0)) {
                // SE "Preço" ESTÁ VAZIO OU NÃO É NUMÉRICO OU FOR NEGATIVO OU IGUAL A ZERO
                return 2;
            } else if (!(product.qtd && isNumeric(product.qtd) && product.qtd > 0)) {
                // SE "Qtd" ESTÁ VAZIO OU NÃO É NUMÉRICO OU FOR NEGATIVO OU IGUAL A ZERO
                return 3;
            } else if (!(product.warehousestock && isNumeric(product.warehousestock) && product.warehousestock > 0)) {
                // SE "warehousestock" ESTÁ VAZIO OU NÃO É NUMÉRICO OU FOR NEGATIVO OU IGUAL A ZERO
                return 4;
            } else if (product.qtd > product.warehousestock) {
                // SE "QTD" FOR MAIOR QUE "STOCK"
                return 5;
            }

            if (listOrderProducts.length > 0) {
                // SE "CNP" VALIDA SE EXISTE NO ARRAY DE CNP"s
                var keepGoing = true;
                angular.forEach(listOrderProducts, function (obj, index) {
                    if (keepGoing) {
                        if (obj.cnp === product.cnp) {
                            if (product.qtd > 0) {
                                $rootScope.saleOrderList.splice(index, 1, angular.copy(product));
                            } else {
                                $rootScope.saleOrderList.splice(index, 1);
                            }
                            $rootScope.updateSaleTotals();
                            keepGoing = false;
                        }
                    }
                });
                if (!keepGoing) {
                    return 1;
                }
            }
            $rootScope.saleOrderList.push(angular.copy(product));
            $rootScope.updateSaleTotals();
            return 0;
        }

        function addAllProducts() {
            var error_flag = false;
            var exists_flag = false;
            var initialOrderSize = $rootScope.saleOrderList.length;
            var countWithError = 0;
            var countUpdated = 0;
            var countInserted = 0;

            var filteredList = $filter('filter')($scope.productsList, $scope.searchInTable);

            angular.forEach(filteredList, function (obj, index) {
                switch (checkProductValidToOrder(obj, $rootScope.saleOrderList)) {
                    case 0:
                        countInserted = countInserted + 1;
                        break;
                    case 1:
                        countUpdated = countUpdated + 1;
                        exists_flag = true;
                        break;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        countWithError = countWithError + 1;
                        error_flag = true;
                        break;
                }
            });

            if (error_flag) {
                var str = "<b>Produtos adicionados:</b> " + countInserted + "<br/>";
                if (exists_flag) {
                    str += "<b>Produtos atualizados:</b> " + countUpdated + "<br/>";
                }
                str += "<b>Produtos sem preço válido, sem stock ou quantidade inválida:</b> " + countWithError + "<br/>";
                $rootScope.message = str;
                $state.go("pharma-sale.alert");
            } else {
                if (exists_flag) {
                    var str = "<b>Produtos adicionados:</b> " + countInserted + "<br/>";
                    str += "<b>Produtos atualizados:</b> " + countUpdated + "<br/>";
                    $rootScope.message = str;
                    $state.go("pharma-sale.success");
                }
            }
        }

        function searchByLab(lab) {
            $scope.search.searchQuery = lab;
            $scope.searchProducts();
        }

        function clearSearchInTable() {
            $scope.searchInTable = "";
        }

        function toggleLineEditMode(orderProdLine) {
            if (angular.isDefined(orderProdLine.editMode) && orderProdLine.editMode == true) {
                orderProdLine.editMode = false;
            } else {
                orderProdLine.editMode = true;
            }
            $rootScope.updateSaleTotals();
        }

        function validateLines(orderProd, keyEvent) {
            if (orderProd) {
                if (keyEvent.type == "blur" || (keyEvent.type == "keypress" && keyEvent.which === 13)) {
                    vm.hasErrors = false;
                    orderProd.editMode = false;
                    $rootScope.updateSaleTotals();
                    angular.forEach($rootScope.saleOrderList, function (value, key) {
                        isQtdInvalid(value);
                    });
                }
            } else {
                $rootScope.updateSaleTotals();
                angular.forEach($rootScope.saleOrderList, function (value, key) {
                    isQtdInvalid(value);
                });
            }
        }

        function isQtdInvalid(orderProd) {
            if (orderProd.qtd && orderProd.qtd >= 0 && parseInt(orderProd.qtd)) {
                orderProd.invalidQtd = false;
            } else {
                orderProd.invalidQtd = true;
                vm.hasErrors = true;
            }
        }

        $scope.searchProducts = function () {
            $scope.productsList = [];
            if ($scope.search && $scope.search.searchQuery && $scope.search.searchQuery.length > 2) {
                $rootScope.searchProductsAction();
            } else {
                // SE showNoStock então não permite
                if ($scope.search.showNoStock == true) {
                    $rootScope.message = "NOK";
                    $scope.productsList = [];
                    $state.go("pharma-sale.fullSearch");
                } else {
                    // SE showNoStock = FALSE então ABRE DIALOG A CONFIRMAR
                    if ($scope.search.searchQuery == undefined) {
                        $scope.search.searchQuery = "";
                    }
                    $rootScope.message = "OK";
                    $state.go("pharma-sale.fullSearch");
                }
            }
        };

        var canceler = $q.defer();
        $rootScope.searchProductsAction = function () {
            // PENDING HTTP CALLS CANCEL
            canceler.resolve();
            canceler = $q.defer();

            // DEFAULT QTD FROM SETTINGS
            vm.defaultQtd = 1;
            if (vm.portalSettings.defaultQtd != null) {
                vm.defaultQtd = vm.portalSettings.defaultQtd;
            }
            // HIDE NEGATIVE DISCOUNTS FROM SETTINGS
            vm.hideNegativeDiscounts = true;
            if (vm.portalSettings.showPvfOnNegativeDiscount) {
                vm.hideNegativeDiscounts = false;
            }

            // CHECK IF SEARCH IS OVER OWN WAREHOUSE OR POLICY GIVEN WAREHOUSE
            if ($scope.search.ownWarehouse) {
                $scope.search.customWarehouse = vm.ownWarehouse;
                searchForProducts();
            } else {
                getWarehouseForSearch();
            }
        }

        $rootScope.updateSaleTotals = function () {
            $rootScope.saleTotals = {
                total: 0,
                totalPV: 0,
                totalDesc: 0
            };

            angular.forEach($rootScope.saleOrderList, function (value, key) {
                $rootScope.saleTotals.total += (value.qtd * 1) * (value.pvp * 1);

                if (vm.isPharma) {
                    $rootScope.saleTotals.totalPV += (value.qtd * 1) * (value.pvfeur * 1);
                } else {
                    $rootScope.saleTotals.totalPV += (value.qtd * 1) * (value.pvaeur * 1);
                }
            });

            $rootScope.saleTotals.totalDesc = $rootScope.saleTotals.totalPV - $rootScope.saleTotals.total;

            if (vm.portalSettings.chargeShippingCosts == true && vm.portalSettings.hasMinOrderValue == true && vm.portalSettings.minOrderValue > $rootScope.saleTotals.total && $rootScope.saleTotals.total > 0) {
                $rootScope.saleTotals.total = $rootScope.saleTotals.total + vm.portalSettings.shippingCosts;
            }
        }
        $rootScope.updateSaleTotals();

        $scope.isOrderEmpty = function () {
            return $rootScope.saleOrderList.length <= 0;
        }

        $scope.isProductSearchEmpty = function () {
            return $scope.productsList.length <= 0;
        }

        $scope.isRetainedProductsOrder = function () {
            if ($rootScope.saleOrderList.length > 0) {
                var keepGoing = true;
                angular.forEach($rootScope.saleOrderList, function (value, key) {
                    if (keepGoing) {
                        if (value.originwarehouse) {
                            $scope.search.ownWarehouse = true;
                            keepGoing = false;
                        }
                    }
                });
                return true;
            } else {
                return false;
            }
        }

        if ($localStorage) {
            if ($localStorage.saleOrder && $localStorage.saleOrder) {
                $rootScope.saleOrderList = $localStorage.saleOrder;
            } else {
                $rootScope.saleOrderList = [];
            }

            if ($localStorage.currentCompany) {
                vm.currentCompany = $localStorage.currentCompany;

                vm.currentUserPe = vm.currentCompany.clientPe;
                vm.currentUserType = vm.currentCompany.clientType;
                vm.currentUserClientGroup = vm.currentCompany.clientGroup;
                vm.isPharma = vm.currentCompany.clientType == "F";

                if (vm.currentCompany.pharmaWarehouse) {
                    vm.ownWarehouse = vm.currentCompany.pharmaWarehouse;
                } else {
                    vm.ownWarehouse = false;
                }
            } else {
                vm.currentCompany = null;
                vm.currentUserPe = null;
                vm.currentUserType = null;
                vm.currentUserClientGroup = null;
                vm.isPharma = false;
            }
        }

        $scope.$watch($rootScope.saleOrderList, function () {
            $localStorage.saleOrder = $rootScope.saleOrderList;
        }, true);

        function dynamicSort(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        function exportSearchToExcel() {
            $http.post("api/pharma-sale/searchToExcel/" + vm.isPharma, $scope.productsList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Produtos_Portal_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: "Lista de produtos exportada com sucesso",
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: "Erro ao exportar lista de produtos",
                    delay: ''
                });
            });
        }

        function orderToExcel() {
            $http.post("api/pharma-sale/orderToExcel/" + vm.isPharma, $rootScope.saleOrderList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Portal_Encomenda_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: "Encomenda exportada com sucesso",
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: "Erro ao exportar encomenda",
                    delay: ''
                });
            });
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };

        function getWarehouseForSearch() {
            // EXECUTAR POLITICA PARA OBTER ARMAZÉM A UTILIZAR E LISTA DE EXCLUSÕES
            var policyType = "PharmaOrdersStockSale";
            var policyData = [];
            policyData.push({
                "hierarchy": [vm.currentUserType, vm.currentUserClientGroup, vm.currentUserPe]
            });
            var requestBody = {
                "policyType": policyType,
                "policyData": policyData
            };

            $http.post("api/policyExecuter/execute", requestBody).then(function (result) {
                var curResult = $filter('filter')(result.data.output, {
                    hash: vm.currentUserType + '' + vm.currentUserClientGroup + '' + vm.currentUserPe
                }, true);

                if (curResult[0]) {
                    $scope.search.customWarehouse = curResult[0].result.warehouse
                    $scope.search.productStockQtdManipulation = curResult[0].result.productStockQtd
                    searchForProducts();
                }
            }, function (result) {
                Notification.warning({
                    message: "Error! Status = " + result.status + " Message = " + result,
                    delay: 5000
                });
            });
        }

        var canceler = $q.defer();

        function searchForProducts() {
            canceler.resolve();
            canceler = $q.defer();
            // PESQUISA "NORMAL"
            $scope.resultProductsList = [];
            $http.post("api/common/_searchFromDbPharmaProducts", $scope.search, {
                timeout: canceler.promise
            }).then(function (result) {
                $scope.resultProductsList = result.data;
            }).then(function () {
                $scope.productsList = [];
                vm.policyData = [];

                if ($scope.search.ownWarehouse) {
                    vm.policyName = "RetainedProductPriceSale";

                    $($scope.resultProductsList).each(function (index, prod) {
                        var prodLab = prod.forncode ? "CIL" + prod.forncode : prod.labcode ? prod.labcode : "99999";
                        vm.policyData.push({
                            "hierarchy": [vm.currentUserPe, prodLab, prod.cnp],
                            "parameters": [$scope.search.customWarehouse]
                        });
                        prod.hash = vm.currentUserPe + "" + prodLab + "" + prod.cnp;
                    });
                } else {
                    vm.policyName = "PharmaOrdersPriceSale";

                    $($scope.resultProductsList).each(function (index, prod) {
                        var prodLab = prod.forncode ? "CIL" + prod.forncode : prod.labcode ? prod.labcode : "99999";
                        vm.policyData.push({
                            "hierarchy": [vm.currentUserType, vm.currentUserPe, prodLab, prod.cnp]
                        });
                        prod.hash = vm.currentUserType + "" + vm.currentUserPe + "" + prodLab + "" + prod.cnp;
                    });
                }

                vm.requestBody = {
                    "policyType": vm.policyName,
                    "policyData": vm.policyData
                };

                $http.post("api/policyExecuter/execute", vm.requestBody, {
                    timeout: canceler.promise
                }).then(function (result) {
                    $($scope.resultProductsList).each(function (index, prod) {
                        var curResult = $filter('filter')(result.data.output, {
                            hash: prod.hash
                        }, true);

                        if (vm.portalSettings.allowZeroPriceProducts == true) {
                            // 1. PREÇO
                            prod.pvp = curResult[0].result;
                            if (prod.pvp < 0) {
                                prod.warehousestock = 0;
                            }

                        } else {
                            // 1. PREÇO
                            prod.pvp = curResult[0].result;
                            if (prod.pvp <= 0) {
                                prod.warehousestock = 0;
                            }
                        }

                        // 2. DIF PREÇO
                        if (vm.isPharma) {
                            if (prod.pvfeur) {
                                if (prod.pvp == 0 || prod.pvfeur == 0) {
                                    prod.pvfdesc = 0;
                                } else {
                                    prod.pvfdesc = (1 - (prod.pvp / prod.pvfeur)) * 100;

                                    if (prod.pvfdesc < 0 && vm.hideNegativeDiscounts) {
                                        prod.pvfdesc = 0;
                                        prod.pvfeur = prod.pvp;
                                    }
                                }
                            } else {
                                prod.pvfeur = prod.pvp;
                                prod.pvfdesc = 0;
                                prod.qtd = vm.defaultQtd;
                            }
                        } else {
                            if (prod.pvaeur) {
                                if (prod.pvp == 0 || prod.pvaeur == 0) {
                                    prod.pvadesc = 0;
                                } else {
                                    prod.pvadesc = (1 - (prod.pvp / prod.pvaeur)) * 100;
                                    if (prod.pvadesc < 0 && vm.hideNegativeDiscounts) {
                                        prod.pvadesc = 0;
                                        prod.pvaeur = prod.pvp;
                                    }
                                }
                            } else {
                                prod.pvaeur = prod.pvp;
                                prod.pvadesc = 0;
                                prod.qtd = vm.defaultQtd;
                            }
                        }
                        // 3. SE ESTAMOS A TRATAR PRODUTOS RETIDOS ENTÃO
                        // ADICIONA AO PRODUTO O WAREHOUSE DE ORIGEM
                        if ($scope.search.ownWarehouse) {
                            prod.originwarehouse = vm.ownWarehouse;
                        }

                        // 4. STOCK
                        if (prod.warehousestock > 0) {
                            prod.qtd = vm.defaultQtd;
                            $scope.productsList.push(prod);
                        } else {
                            prod.qtd = 0;
                            prod.warehousestock = 0;
                            if ($scope.search && $scope.search.showNoStock) {
                                $scope.productsList.push(prod);
                            }
                        }

                        $scope.productsList.sort(dynamicSort("fullName"));
                    });

                }, function (result) {
                    Notification.warning({
                        message: "Error! Status = " + result.status + " Message = " + result,
                        delay: 5000
                    });
                });
            });
        }

        function confirmOrderDialog() {
            $rootScope.isRetainedProductsOrder = $scope.search.ownWarehouse;
            $state.go('pharma-sale.confirmOrder');
        }
    }
})();
