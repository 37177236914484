(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('sellout-integration-history', {
			parent : 'entity',
			url : '/sellout-integration-history',
			data : {
				authorities : [ 'ROLE_USER' ],
				pageTitle : 'pharmaPortalApp.selloutIntegrationHistory.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/sellout-integration/sellout-integration-histories.html',
					controller : 'SelloutIntegrationHistoryController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				},
				search: null
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('selloutIntegrationHistory');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		});
	}
})();