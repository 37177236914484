(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionDetailController', PharmaTransfReceptionDetailController);

	PharmaTransfReceptionDetailController.$inject = [ '$uibModalInstance', '$http', '$filter', 'pharmaTransfReception', 'PharmaTransfReception' ];

	function PharmaTransfReceptionDetailController($uibModalInstance, $http, $filter, pharmaTransfReception, PharmaTransfReception) {
		var vm = this;

		vm.pharmaTransfReception = pharmaTransfReception;

		vm.clear = clear;
		vm.loadAll = loadAll;
		vm.getWarehouseFullDescription = getWarehouseFullDescription;
		vm.getTransferTypeFullDescription = getTransferTypeFullDescription;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function loadAll() {
			vm.manualReceptionLines = $filter('filter')(vm.pharmaTransfReception.pharmaTransfReceptionLines, { erpLine : false });
			
			$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
				vm.warehouses = result.data;
			});
			$http.get("api/common/_getTransferTypeDescription").then(function(result) {
				vm.transferTypes = result.data;
			});
		}
		vm.loadAll();

		function getWarehouseFullDescription(warehouseCode) {
			if (angular.isDefined(vm.warehouses)) {
				var warehouse = $filter('filter')(vm.warehouses, {
					code : warehouseCode
				})[0];
				return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
			} else {
				return warehouseCode;
			}
		}

		function getTransferTypeFullDescription(transferTypeCode) {
			if (angular.isDefined(vm.transferTypes) && transferTypeCode != '') {
				var transferType = $filter('filter')(vm.transferTypes, {
					code : transferTypeCode
				})[0];
				return transferTypeCode + (transferType != null ? ' - ' + transferType.description : '');
			} else {
				return transferTypeCode;
			}
		}
	}
})();
