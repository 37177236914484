(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ProductListDeleteController',ProductListDeleteController);

	ProductListDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProductList', '$state'];

	function ProductListDeleteController($uibModalInstance, entity, ProductList, $state) {
		var vm = this;

		vm.productList = entity;
		vm.clear = clear;
		vm.confirmDelete = confirmDelete;
		vm.loadAll = loadAll;

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmDelete (id) {
			ProductList.delete({id: id}, function () { $uibModalInstance.close(true); });
		}

		function loadAll () {

		}
		loadAll();
	}
})();
