(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ProductStockQtdController', ProductStockQtdController);

    ProductStockQtdController.$inject = ['Notification', '$uibModalInstance', '$translate', '$stateParams', 'ProductStockQtd'];

    function ProductStockQtdController(Notification, $uibModalInstance, $translate, $stateParams, ProductStockQtd) {
        var vm = this;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        ProductStockQtd.query({
            id: $stateParams.id
        }, onSuccess, onError);

        function onSuccess(data, headers) {
            vm.productStockQtds = data;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }
    }
})();
