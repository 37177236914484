(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ModuleSettingsController', ModuleSettingsController);

	ModuleSettingsController.$inject = ['Notification', '$state', '$translate', 'ModuleSettings'];

	function ModuleSettingsController(Notification, $state, $translate, ModuleSettings) {
		var vm = this;

		vm.save = save;
		vm.loadAll = loadAll;

		function save() {
			try {
				ModuleSettings.save(vm.settings, function(httpResponse) {
					Notification.success({message: $translate.instant('pharmaPortalApp.moduleSettings.messages.success'), delay: 5000});
				});
			} catch (err) {
				Notification.error({message: $translate.instant('pharmaPortalApp.moduleSettings.messages.error'), delay: ''});
			}
		}

		function loadAll() {
			vm.settings = ModuleSettings.query();
		}
		loadAll();
	}
})();
