(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaBalanceConfirmController', PharmaBalanceConfirmController);

	PharmaBalanceConfirmController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', '$http', '$translate', '$filter', 'period' ];

	function PharmaBalanceConfirmController(Notification, blockUI, $uibModalInstance, $http, $translate, $filter, period) {
		var vm = this;

		vm.currentPeriod = period.period;
		vm.orderDate = new Date();
		
		vm.clear = clear;
		vm.confirmCloseMonth = confirmCloseMonth;
		
		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function confirmCloseMonth() {
			var suggestedDate = new Date(vm.currentPeriod);
			suggestedDate = new Date(suggestedDate.getFullYear(), suggestedDate.getMonth() + 1, 0);
			
			var period = $filter('date')(vm.currentPeriod, "yyyy'-'MM");
			
			if(vm.orderDate >= suggestedDate) {
				$http.get("api/pharma-balance/closeMonth/" + period, {
					params : { orderDate : vm.orderDate }
				}).then(function(result) {
					Notification.success({message : $translate.instant('pharmaPortalApp.pharmaBalance.message.closeMonth.success'), delay : 5000});
					blockUI.stop();
					$uibModalInstance.close(period);
				}, function(result) {
					Notification.error({message : $translate.instant('pharmaPortalApp.pharmaBalance.message.closeMonth.error'), delay : ''});
					blockUI.stop();
					$uibModalInstance.dismiss("cancel");
				});
			} else {
				Notification.error({message : $translate.instant('pharmaPortalApp.pharmaBalance.message.closeMonth.badDate'), delay : ''});
			}
		}
		
		vm.datePopup = { opened : false };
		vm.openDatePopup = function() { vm.datePopup.opened = true; };
		vm.dateOptions = { formatYear : 'yyyy', startingDay : 1, showWeeks : false };
	}
})();
