(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ActivationController', ActivationController);

	ActivationController.$inject = ['$stateParams', 'Auth', '$state'];

	function ActivationController ($stateParams, Auth, $state) {
		var vm = this;
		
		vm.login = login;
		
		Auth.activateAccount({key: $stateParams.key}).then(function () {
			vm.error = null;
			vm.success = 'OK';
		}).catch(function () {
			vm.success = null;
			vm.error = 'ERROR';
		});
		
		function login() {
			$state.go('home');
		}
	}
})();