(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ReturnConfirmController', ReturnConfirmController);

    ReturnConfirmController.$inject = ['Notification', '$uibModalInstance', '$rootScope', '$scope', 'Company', '$http', '$localStorage', '$state', 'Principal', 'ModuleSettings',
        'PortalSettings'];

    function ReturnConfirmController(Notification, $uibModalInstance, $rootScope, $scope, Company, $http, $localStorage, $state, Principal, ModuleSettings, PortalSettings) {
        var vm = this;

        vm.portalSettings = PortalSettings.query();

        vm.confirmOrder = confirmOrder;
        vm.clear = clear;
        vm.loadAll = loadAll;

        vm.order;
        vm.mybody;
        vm.processing = false;

        function confirmOrder() {
            vm.processing = true;

			vm.mybody = {
			    'company' : $localStorage.currentCompany.id,
				'returnGuide' : vm.order != null ? vm.order.returnGuide : "",
				'orderProducts' : $rootScope.orderList,
				"returnInternalClientId" : vm.portalSettings.returnInternalClient.id,
				"returnWarehouseTransfTypeCode" : vm.portalSettings.returnWarehouseTransfTypeCode,
				"returnWarehouseTransfTypeDesc" : vm.portalSettings.returnWarehouseTransfTypeDesc,
				"returnCreditTypeCode" : vm.portalSettings.returnCreditTypeCode,
				"returnCreditTypeDesc" : vm.portalSettings.returnCreditTypeDesc
			};

            saveOrderToServer(vm.mybody);
        }

		function saveOrderToServer(requestBody) {
			$http.post("api/pharma-return/saveReturn", requestBody).then(function successCallback(response) {
				$rootScope.orderList = [];
				$rootScope.totalizer = {
					qtd : 0,
					price : 0,
					docTotal : 0
				};
				$uibModalInstance.close(true);
				delete $localStorage.order;
				if (response.data.success) {
					Notification.success({ message : response.data.message, delay : 5000 });
				} else {
					Notification.error({ message : response.data.message, delay : '' });
				}
				vm.processing = false;
			}, function errorCallback(response) {
				vm.processing = false;
				$uibModalInstance.close(true);
				Notification.error({ message : response.data.message, delay : '' });
			});
		}

        function zeroPad(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

				if (!vm.moduleSettings.returnsModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
                        if(account.authorities.indexOf("ROLE_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if(account.authorities.indexOf("ROLE_SUPER_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if(account.authorities.indexOf("ROLE_PHARMA_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if ((account && account.company && account.company.clientType == "F") || vm.isAdmin) {
                            vm.order.returnGuide = "";
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();
