(function() {
    'use strict';
    angular
        .module('pharmaPortalApp')
        .factory('PortalPendingShoppingCarts', PortalPendingShoppingCarts);

    PortalPendingShoppingCarts.$inject = ['$resource'];

    function PortalPendingShoppingCarts ($resource) {
        var resourceUrl =  'api/portal-pending-shopping-carts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete': { method:'DELETE',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
