(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Notification', 'Principal', 'Auth', 'JhiLanguageService', '$translate', '$scope', '$timeout', 'DataUtils'];

    function SettingsController(Notification, Principal, Auth, JhiLanguageService, $translate, $scope, $timeout, DataUtils) {

        var vm = this;

        vm.save = save;
        vm.settingsAccount = null;

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        /**
         * Store the "settings account" in a separate variable, and not in the shared
         * "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                thumbnail: account.thumbnail,
                thumbnailContentType: account.thumbnailContentType
            };
        };

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
            var name = vm.settingsAccount.firstName + ' ' + vm.settingsAccount.lastName;
            var initials = name.match(/\b\w/g) || [];
            vm.nameInitials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        });

        vm.setThumbnail = function ($file, account) {
            if ($file && $file.$error === 'pattern') {return;}

            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        account.thumbnail = base64Data;
                        account.thumbnailContentType = $file.type;
                    });
                });
            }
        };

        function save() {
            Auth.updateAccount(vm.settingsAccount).then(function (response) {
                Principal.identity(true).then(function (account) {
                    vm.settingsAccount = copyAccount(account);
                    $scope.$emit('updateNavbar');
                });

                JhiLanguageService.getCurrent().then(function (current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
                Notification.success({message: $translate.instant('settings.messages.success'), delay: 5000});
            }).catch(function (response) {
                Notification.error({message: $translate.instant(response.headers('x-pharmaportalapp-error')), delay: ''});
            });
        }
    }
})();
