(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('TradeConfirmOrderController', TradeConfirmOrderController);

    TradeConfirmOrderController.$inject = ['Notification', '$uibModalInstance', '$q', '$rootScope', '$scope', 'Company', '$http', '$localStorage', '$state', '$filter',
        'Principal', 'ModuleSettings', 'PortalSettings'];

    function TradeConfirmOrderController(Notification, $uibModalInstance, $q, $rootScope, $scope, Company, $http, $localStorage, $state, $filter, Principal, ModuleSettings,
                                         PortalSettings) {
        var vm = this;
        vm.companies = [];
        vm.portalSettings = PortalSettings.query();

        vm.confirmOrder = confirmOrder;
        vm.selectCompany = selectCompany;
        vm.unselectCompany = unselectCompany;
        vm.clear = clear;
        vm.loadAll = loadAll;

        vm.order;
        vm.mybody;
        vm.processing = false;

        function confirmOrder() {
            if (!vm.order || !vm.order.company) {
                Notification.error({
                    message: "Não indicou a entidade receptora da encomenda",
                    delay: ''
                });
                return;
            }

            vm.processing = true;

            vm.mybody = {
                'clientTo': vm.order.company.client,
                'deliveryPointTo': vm.order.company.deliveryPoint,
                'description': vm.order.description,
                'orderProducts': $rootScope.orderList,
                "sellerHasDiffPrice": vm.portalSettings.sellerHasDiffPrice,
                "tradeInvoiceTypeCode": vm.portalSettings.tradeInvoiceTypeCode,
                "tradeInvoiceTypeDesc": vm.portalSettings.tradeInvoiceTypeDesc,
                "tradeCreditTypeCode": vm.portalSettings.tradeCreditTypeCode,
                "tradeCreditTypeDesc": vm.portalSettings.tradeCreditTypeDesc
            };

            var loopPromises = [];

            if (vm.portalSettings.priceFromPolicyForTrades && vm.portalSettings.sellerHasDiffPrice) {
                var customerTo = zeroPad(vm.order.company.client, 5)
                var deliveryPointTo = zeroPad(vm.order.company.deliveryPoint, 2);
                var clientPeTo = customerTo + '' + deliveryPointTo;

                vm.policyData = [];
                vm.policyName = "PharmaTradePriceCalculation";

                $($rootScope.orderList).each(function (index, prod) {
                    vm.policyData.push({
                        "hierarchy": [clientPeTo, prod.labcode, prod.cnp]
                    });
                    prod.hash = clientPeTo + "" + prod.labcode + "" + prod.cnp;
                });

                vm.requestBody = {
                    "policyType": vm.policyName,
                    "policyData": vm.policyData
                };

                var deferred = $q.defer();
                loopPromises.push(deferred.promise);

                $http.post("api/policyExecuter/execute", vm.requestBody).then(function (result) {
                    $($rootScope.orderList).each(function (index, prod) {
                        var curResult = $filter('filter')(result.data.output, {
                            hash: prod.hash
                        }, true);

                        if (curResult[0].result > 0) {
                            prod.priceTo = "" + curResult[0].result;
                            vm.mybody.orderProducts[index].priceTo = "" + curResult[0].result;
                        } else {
                            vm.mybody.orderProducts[index].priceTo = prod.price;
                        }
                    });
                    deferred.resolve();
                });
            } else {
                angular.forEach($rootScope.orderList, function (value, key) {
                    var deferred = $q.defer();
                    loopPromises.push(deferred.promise);
                    value.priceTo = value.price;
                    vm.mybody.orderProducts[key].priceTo = value.price;
                    deferred.resolve();
                });
            }

            $q.all(loopPromises).then(function () {
                saveOrderToServer(vm.mybody);
            });

        }

		function saveOrderToServer(requestBody) {
			$http.post("api/pharma-trade/saveOrder", requestBody).then(function successCallback(response) {
				$rootScope.orderList = [];
				$rootScope.totalizer = {
					qtd : 0,
					price : 0,
					docTotal : 0
				};
				$uibModalInstance.close(true);
				delete $localStorage.order;
				if (response.data.success) {
					Notification.success({
						message : response.data.message,
						delay : 5000
					});
				} else {
					Notification.error({
						message : response.data.message,
						delay : ''
					});
				}
				vm.processing = false;
			}, function errorCallback(response) {
				vm.processing = false;
				$uibModalInstance.close(true);
				Notification.error({
					message : response.data.message,
					delay : ''
				});
			});
		}

        function zeroPad(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        }

		function selectCompany($item, $model) {
		    Company.isInactive($item, function (result) {
                if (result.regDate != null) {
                    result.regDate = result.regDate.substring(6,8) + "-" + result.regDate.substring(4,6) + "-" + result.regDate.substring(0,4);
                    result.regTime = result.regTime.substring(0,2) + ":" + result.regTime.substring(2,4) + ":" + result.regTime.substring(4,6);
                    result.company = $item.namingForOptions;
                    $rootScope.message = result;
                    vm.order = null;
                    $state.go("pharma-trade.inactive");
                }else {
                    vm.order.company = $item;
                }
            });
		}

        function unselectCompany($item, $model) {
            vm.order = null;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

				if (!vm.moduleSettings.tradesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (account && account.company && account.company.clientType == "F") {
							$http.get("api/companies/typePharmacy").then(function(result) {
								if (vm.portalSettings.onlyTradeInGroup) {
									angular.forEach(result.data, function(v, k) {
										if (account.company.clientGroup === v.clientGroup && account.company.id != v.id) {
											vm.companies.push(v);
										}
									});
								} else {
									angular.forEach(result.data, function(v, k) {
										if (account.company.id != v.id) {
											vm.companies.push(v);
										}
									});
								}
							});
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();
