(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-picking', {
			parent : 'entity',
			url : '/pharma-picking',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaPicking.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-picking/pharma-picking.html',
					controller : 'PharmaPickingController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'dateCreated,desc',
					squash : true
				}
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort)
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaPicking');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-picking.new', {
			parent : 'entity',
			url : '/pharma-picking/new',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaPicking.new.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-picking/pharma-picking-new.html',
					controller : 'PharmaPickingNewController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaPicking');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-picking-delete', {
			parent : 'pharma-picking',
			url : '/{id}/delete',
			method : 'DELETE',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-picking/pharma-picking-delete-dialog.html',
					controller : 'PharmaPickingDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						pharmaPicking : [ 'PharmaPicking', function(PharmaPicking) {
							return PharmaPicking.get({
								id : $stateParams.id
							});
						} ]
					}
				}).result.then(function() {
					$state.go('pharma-picking', null, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-picking-detail', {
			parent : 'entity',
			url : '/pharma-picking/:id',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaPicking.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-picking/pharma-picking-detail.html',
					controller : 'PharmaPickingDetailController',
					controllerAs : 'vm',
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaPickingImportFile');
                    $translatePartialLoader.addPart('pharmaPicking');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'PharmaPicking', function($stateParams, PharmaPicking) {
					return PharmaPicking.get({
						id : $stateParams.id
					}).$promise;
				} ]
			}
		}).state('pharma-picking-detail.edit', {
			parent : 'entity',
			url : '/pharma-picking/:id/edit',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
				pageTitle : 'pharmaPortalApp.pharmaPicking.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-picking/pharma-picking-detail.html',
					controller : 'PharmaPickingDetailEditController',
					controllerAs : 'vm',
				}
			},
            params: {
                open: null
            },
			resolve : {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        open: $stateParams.open
                    };
                }],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaPickingImportFile');
                    $translatePartialLoader.addPart('pharmaPicking');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'PharmaPicking', function($stateParams, PharmaPicking) {
					return PharmaPicking.get({
						id : $stateParams.id
					}).$promise;
				} ]
			}
		}).state('pharma-picking-import-files', {
            parent : 'entity',
            url : '/pharma-picking-import-files',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
                pageTitle : 'pharmaPortalApp.pharmaPickingImportFile.home.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/pharma-picking/pharma-picking-import-files.html',
                    controller : 'PharmaPickingImportFilesController',
                    controllerAs : 'vm',
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'company,asc',
                    squash : true
                }
            },
            resolve : {
                pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil.parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil.parsePredicate($stateParams.sort),
                        ascending : PaginationUtil.parseAscending($stateParams.sort)
                    };
                } ],
                translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaPicking');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('pharmaPickingImportFile');
                    return $translate.refresh();
                } ]
            }
        }).state('pharma-picking-show-history', {
            parent : 'entity',
            url : '/pharma-picking-show-history',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
                pageTitle : 'pharmaPortalApp.pharmaPickingShowHistory.home.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/pharma-picking/pharma-picking-show-history.html',
                    controller : 'PharmaPickingShowHistoryController',
                    controllerAs : 'vm',
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,asc',
                    squash : true
                }
            },
            resolve : {
                pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil.parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil.parsePredicate($stateParams.sort),
                        ascending : PaginationUtil.parseAscending($stateParams.sort)
                    };
                } ],
                translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaPicking');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('pharmaPickingShowHistory');
                    return $translate.refresh();
                } ]
            }
        }).state('pharma-picking-show-history-detail', {
            parent : 'entity',
            url : '/pharma-picking-show-history-detail/:cnp',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
                pageTitle : 'pharmaPortalApp.pharmaPickingShowHistory.detail.title'
            },
            params : {
                saveSearch: null,
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'pharmaPickingHeader.dateCreated,desc',
                    squash : true
                }
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/pharma-picking/pharma-picking-show-history-detail.html',
                    controller : 'PharmaPickingShowHistoryDetailController',
                    controllerAs : 'vm',
                }
            },
            resolve : {
                pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil.parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil.parsePredicate($stateParams.sort),
                        ascending : PaginationUtil.parseAscending($stateParams.sort),
                        saveSearch: $stateParams.saveSearch
                    };
                } ],
                translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pharmaPicking');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('pharmaPickingShowHistory');
                    return $translate.refresh();
                } ]
            }
        })
	}
})();
