(function() {
	'use strict';

	angular.module('pharmaPortalApp').directive('price', price);

	price.$inject = [ '$filter' ];

	function price($filter) {
		var directive = {
			restrict : 'A',
			require : 'ngModel',
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element, attrs, ngModelController) {
			ngModelController.$parsers.push(function(data) {
				// convert data from view format to model format

				data = $filter('comma2decimal')(data);

				return data;
			});

			ngModelController.$formatters.push(function(data) {
				// convert data from model format to view format

				data = $filter('decimal2comma')(data);

				return data;
			});
		}
	}
})();
