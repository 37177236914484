(function() {
	'use strict';

	angular.module('pharmaPortalApp').directive('fixSelectedItem', fixSelectedItem);

	function fixSelectedItem() {
		var directive = {
			restrict : 'A',
			link : linkFunc
		};

		return directive;

		function linkFunc(scope, element) {
			var choices;
			var currentSelectedText;
			var choiceText;
			/*
			 * WATCHES FOR THE CHOICES LIST LENGTH WHICH CHANGES WHEN THE LIST
			 * IS OPEN
			 */
			scope.$watch(function() {
				return element.find('.ui-select-choices-row').length;
			}, function() {
				choices = element.find('.ui-select-choices-row');
				element.find(".ui-select-choices-group div").removeClass("active");
				if (choices.length > 0) {
					currentSelectedText = element.find('.ui-select-match .ui-select-match-text').text();
					choices.each(function($index) {
						choiceText = $(this).find('.ui-select-choices-row-inner > span').text();
						if (choiceText == currentSelectedText) {
							choices.eq($index).addClass('selected');
							choices.eq($index).addClass('active');
						}
					});
				}
				/* END WATCH */
			});
		}
	}
})();