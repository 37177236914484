(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('SelfInvoiceCollectionClearController', SelfInvoiceCollectionClearController);

	SelfInvoiceCollectionClearController.$inject = [ 'Notification', '$uibModalInstance', '$translate', '$rootScope', '$localStorage', '$state', 'Principal', 'ModuleSettings' ];

	function SelfInvoiceCollectionClearController(Notification, $uibModalInstance, $translate, $rootScope, $localStorage, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.clear = clear;
		vm.confirmClearOrder = confirmClearOrder;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmClearOrder() {
			$rootScope.selfInvoiceCollectionOrderList = [];
			$rootScope.totalizer = {
				qtd : 0,
				price : 0,
				docTotal : 0
			};
			$uibModalInstance.close(true);
			delete $localStorage.selfInvoiceCollectionOrder;
			Notification.success({ message : $translate.instant('pharmaPortalApp.selfInvoiceCollection.message.selfInvoiceCollectionCleared'), delay : 5000 });
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.selfInvoiceCollectionModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
                        if(account.authorities.indexOf("ROLE_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if(account.authorities.indexOf("ROLE_SUPER_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if(account.authorities.indexOf("ROLE_PHARMA_ADMIN") !== -1) {
                            vm.isAdmin = true;
                        }
                        if ((account && account.company && account.company.clientType == "F") || vm.isAdmin) {
							// DO NOTHING
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();
