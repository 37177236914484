(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaReceptionConfirmDifferencesController', PharmaReceptionConfirmDifferencesController);

	PharmaReceptionConfirmDifferencesController.$inject = ['Notification', '$uibModalInstance', '$http', '$state', '$filter', 'pharmaReception', 'PharmaReception'];

	function PharmaReceptionConfirmDifferencesController(Notification, $uibModalInstance, $http, $state, $filter, pharmaReception, PharmaReception) {
		var vm = this;

		vm.clear = clear;
		vm.close = close;
		vm.pharmaReception = pharmaReception;
		vm.exportToExcel = exportToExcel;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function close() {
			$uibModalInstance.close(true);
		}

		function exportToExcel() {
			$http.get("api/pharma-reception/" + vm.pharmaReception.id + "/toExcel", {
				responseType : "arraybuffer"
			}).then(function successCallback(response) {
				var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
				var blob = new Blob([ response.data ], {
					type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				});
				saveAs(blob, "Recepção_" + vm.pharmaReception.id + "_" + dateTime + ".xlsx", true);
				Notification.success({message: "Recepção exportada com sucesso", delay: 5000});

				$uibModalInstance.close(true);
			}, function errorCallback(response) {
				Notification.error({message: "Erro ao exportar recepção", delay: ''});
				$uibModalInstance.close(true);
			});
		}
	}
})();
