(function () {
    'use strict';

    angular.module('pharmaPortalApp').filter('complaint', function() {
        return function (item, flag) {
            var out = [];
            for (var i = 0; i < item.length; i++) {
                if (item[i].selected == flag && item[i].invoicedQuantity > item[i].receivedQuantity) {
                    out.push(item[i]);
                }
            }
            return out;
        }
    })
})();
