(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('ReportDetailController', ReportDetailController);

	ReportDetailController.$inject = [ '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', '$state', 'Report', 'ModuleSettings' ];

	function ReportDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, $state, Report, ModuleSettings) {
		var vm = this;

		vm.report = entity;
		vm.previousState = previousState.name;
		vm.byteSize = DataUtils.byteSize;
		vm.openFile = DataUtils.openFile;

		vm.loadAll = loadAll;

		var unsubscribe = $rootScope.$on('pharmaPortalApp:reportUpdate', function(event, result) {
			vm.report = result;
		});
		$scope.$on('$destroy', unsubscribe);

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.queriesModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
	}
})();
