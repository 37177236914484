(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ProductStockQtdExportController', ProductStockQtdExportController);

    ProductStockQtdExportController.$inject = ['Notification', 'blockUI', '$http', '$uibModalInstance', '$stateParams', '$translate'];

    function ProductStockQtdExportController(Notification, blockUI, $http, $uibModalInstance, $stateParams, $translate) {
        var vm = this;

        vm.clear = clear;
        vm.exportToExcel = exportToExcel;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function exportToExcel() {
            blockUI.start($translate.instant('pharmaPortalApp.policyDefinition.productStockQtd.message.exportingFile'));

            $http.get("/api/product-stock-qtd/" + $stateParams.id + "/export", {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Lista_artigos_a_condicionar.xlsx", true);
                blockUI.stop();
                //Notification.success({message: response.data.message, delay: 5000});

            }, function errorCallback(response) {
                blockUI.stop();
                Notification.error({message: response.data.message, delay: ''});
            });
            vm.clear()
        }
    }
})();
