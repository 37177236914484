(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingNewController', PharmaPickingNewController);

    PharmaPickingNewController.$inject = ['Notification', '$scope', '$state', '$http', 'PharmaPicking', 'Principal', 'ModuleSettings', 'blockUI', '$translate', '$uibModal'];

    function PharmaPickingNewController(Notification, $scope, $state, $http, PharmaPicking, Principal, ModuleSettings, blockUI, $translate, $uibModal) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.save = save;
        vm.isSaving = false;

        vm.warehouseSelected = '';
        vm.filterLine = filterLine;

        vm.tableFilters = {
            supplier: {
                label: '&nbsp;',
                value: ''
            },
            initDate: null,
            endDate: null,
            receipt: '',
            pharmacy: {
                label: '&nbsp;',
                value: ''
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go("pharma-picking-detail.edit", {
                id: result.id,
                open: true
            });
        }

        function onSaveError(result) {
            vm.isSaving = false;

            Notification.error({message: "Não foi possível efetuar a operação pretendida. Possivelmente a conferência já se encontra em processamento por outro utilizador", delay: '5000'});
            $state.reload();
        }

        function save(supplierCode, supplierReference) {
            vm.isSaving = true;
            var pharmaPickingAux = null;
            for (var i = 0; i < vm.lines.length; i++) {
                if (vm.lines[i].supplierCode == supplierCode && vm.lines[i].supplierReference == supplierReference) {
                    pharmaPickingAux = vm.lines[i];
                    break;
                }
            }
            PharmaPicking.save(pharmaPickingAux, onSaveSuccess, onSaveError);
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.isSaving = false;
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                } else {
                    Principal.identity().then(function (account) {
                        if (account) {
                            vm.account = account;
                            if (vm.account.company == null || (vm.account.company.pharmaWarehouse == null && (vm.account.company.warehouse == null || vm.account.company.warehouse == 0))) {
                                $uibModal.open({
                                    templateUrl: 'app/entities/pharma-picking/pharma-picking-define-warehouse.html',
                                    controller: 'PharmaPickingDefineWarehouseController',
                                    controllerAs: 'vm',
                                    size: 'lg',
                                    backdrop: 'static',
                                    keyboard: false
                                }).result.then(function () {
                                }, function (flag) {
                                    if (flag != 'cancel') {
                                        vm.warehouseSelected = flag.code + ' - ' + flag.description
                                        vm.tableFilters.pharmacy.value = vm.warehouseSelected;
                                        vm.tableFilters.pharmacy.code = flag.code;
                                        getPharmaPickingHeadersFromErp();
                                    } else {
                                        //TODO if MC dont want do load all
                                        $state.go('pharma-picking');
                                    }
                                });
                            } else {
                                getPharmaPickingHeadersFromErp();
                            }
                        } else {
                            vm.account = null;
                        }
                    });
                }
            });
        }

        function getPharmaPickingHeadersFromErp() {
            blockUI.start($translate.instant('pharmaPortalApp.pharmaPicking.message.screenData.loading'));
            $http.get("api/_getPharmaPickingHeadersFromErp").then(function (result) {
                vm.lines = result.data;
                vm.supplierFilterPicklist = [{
                    label: '&nbsp;',
                    value: ''
                }];
                vm.supplierFilterPicklistAux = [];
                vm.pharmaciesFilterPicklist = [{
                    label: '&nbsp;',
                    value: ''
                }];
                vm.pharmaciesFilterPicklistAux = [];

                for (var i = 0; i < vm.lines.length; i++) {
                    var supplierStrAux = vm.lines[i].supplierCode + ' - ' + vm.lines[i].supplierName;
                    var pharmacyNameAux = vm.lines[i].pharmacyWarehouse + ' - ' + vm.lines[i].pharmacyName;

                    if (vm.supplierFilterPicklist.findIndex(function (x){ return x.label == supplierStrAux}) === -1) {
                        vm.supplierFilterPicklist.push({
                            label: supplierStrAux,
                            value: supplierStrAux
                        });
                    }

                    if (vm.pharmaciesFilterPicklist.findIndex(function (x){ return x.label == pharmacyNameAux}) === -1) {
                        vm.pharmaciesFilterPicklist.push({
                            label: pharmacyNameAux,
                            value: pharmacyNameAux
                        });
                    }
                }
                blockUI.stop();
            }, function () {
                blockUI.stop();
            });
        }

        vm.loadAll();

        vm.initDatePopup = {
            opened: false
        };
        vm.endDatePopup = {
            opened: false
        };

        vm.openInitDatePopup = function () {
            vm.initDatePopup.opened = true;
        };

        vm.openEndDatePopup = function () {
            vm.endDatePopup.opened = true;
        };

        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        function filterLine(line) {
            var supplierStrAux = line.supplierCode + ' - ' + line.supplierName;
            var docDate = new Date(line.documentDate);
            var pharmacyNameAux = line.pharmacyWarehouse; // + ' - ' + line.pharmacyName;

            var viewLine = true;
            if (vm.tableFilters.receipt != undefined && vm.tableFilters.receipt != '' && line.receipt.indexOf(vm.tableFilters.receipt) < 0) {
                viewLine = false;
            }
            if (vm.tableFilters.supplier != undefined && vm.tableFilters.supplier != null && vm.tableFilters.supplier.value != undefined && vm.tableFilters.supplier.value != '' && supplierStrAux != vm.tableFilters.supplier.value) {
                viewLine = false;
            }
            if (vm.tableFilters.initDate != undefined && vm.tableFilters.initDate != null && vm.tableFilters.initDate != '' && docDate < vm.tableFilters.initDate) {
                viewLine = false;
            }
            if (vm.tableFilters.endDate != undefined && vm.tableFilters.endDate != null && vm.tableFilters.endDate != '' && docDate > vm.tableFilters.endDate) {
                viewLine = false;
            }
            if (vm.tableFilters.pharmacy != undefined && vm.tableFilters.pharmacy != null && vm.tableFilters.pharmacy.code != undefined && vm.tableFilters.pharmacy.code != '' && pharmacyNameAux != vm.tableFilters.pharmacy.code) {
                viewLine = false;
            }

            return viewLine;
        }

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
        $scope.showSearch = true;
    }
})();
