(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaSupplierComplaintController', PharmaSupplierComplaintController);

    PharmaSupplierComplaintController.$inject = ['Notification', '$scope', '$state', 'entity', '$http', '$filter', '$uibModal', '$rootScope', 'Company', 'PharmaPicking', 'ParseLinks', 'paginationConstants', 'pagingParams',
        'ModuleSettings', 'Principal', 'blockUI', '$translate', '$sce', '$localStorage', 'InvoiceAndClaim'];

    function PharmaSupplierComplaintController(Notification, $scope, $state, entity, $http, $filter, $uibModal, $rootScope, Company, PharmaPicking, ParseLinks, paginationConstants, pagingParams, ModuleSettings, Principal, blockUI, $translate, $sce, $localStorage, InvoiceAndClaim) {
        var vm = this;

        vm.generatePdf = generatePdf;

        function resetLocalStorage() {
            $localStorage.pharmaPicking = null;
            $localStorage.changeState = null;
        }

        vm.toInt = function(a,b) {
            return parseInt(a,10) + parseInt(b,10);
        };

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };

        function loadAll() {

            Principal.identity().then(function (account) {
                if (account) {
                    vm.user = account.login;
                    vm.userGeneric = account.generic;
                } else {
                    $state.go('accessdenied');
                }
            });

            if (pagingParams != null) {
                vm.pharmaPicking = angular.copy(pagingParams.pharmaPicking);
                vm.changeState = false;
            } else {
                if ($localStorage.pharmaPicking) {
                    vm.pharmaPicking = $localStorage.pharmaPicking;
                    vm.changeState = $localStorage.changeState;
                } else {
                    $state.go('pharma-picking');
                    return;
                }
            }
            // var t = $filter('complaint')(vm.pharmaPicking.pharmaPickingLines, vm.pharmaPicking.changeState);
            angular.forEach(vm.pharmaPicking.pharmaPickingLines, function (line) {
                if (line.selected == vm.pharmaPicking.changeState && line.invoicedQuantity > line.receivedQuantity) {
                    var i = line.invoicedQuantity ? line.invoicedQuantity : 0;
                    var r = line.receivedQuantity ? line.receivedQuantity : 0;
                    var s = line.suggestion ? line.suggestion : 0;
                    line.claimedQuantity = i - r - s;
                } else {
                    line.claimedQuantity = 0;
                }
            });

            $localStorage.pharmaPicking = vm.pharmaPicking;
            $localStorage.changeState = vm.changeState;

            vm.date = new Date();
        }

        loadAll();

        vm.back = function () {
            resetLocalStorage();
            $state.go('pharma-picking-detail.edit', {
                id: vm.pharmaPicking.id
            }, {
                reload: false
            });
        };

        function generatePdf() {
            var products = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
                var i = line.invoicedQuantity ? line.invoicedQuantity : 0;
                var r = line.receivedQuantity ? line.receivedQuantity : 0;
                var c = line.claimedQuantity ? line.claimedQuantity : 0;
                return i - r - c > 0;
            }, true);
            InvoiceAndClaim.invoiceAndClaim(vm.pharmaPicking, products, vm.user)
        }

    }
})();
