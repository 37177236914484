(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaReceptionNewController', PharmaReceptionNewController);

	PharmaReceptionNewController.$inject = [ 'Notification', '$scope', '$state', '$http', '$filter', 'PharmaReception', 'Principal', 'ModuleSettings', 'Company', '$rootScope' ];

	function PharmaReceptionNewController(Notification, $scope, $state, $http, $filter, PharmaReception, Principal, ModuleSettings, Company, $rootScope) {
		var vm = this;

		vm.loadAll = loadAll;
		vm.save = save;
		vm.isSaving = false;

        vm.checkCompanyWar = checkCompanyWar;
        vm.checkCompanySup = checkCompanySup;

		vm.pharmaReception = {
//			date : new Date(),
			orderType : 'DIR'
		};

		function onSaveSuccess(result) {
			vm.isSaving = false;
			$state.go("pharma-reception-detail", { id : result.id });
		}

		function onSaveError(result) {
			vm.isSaving = false;

			if (result.data.success) {
				Notification.success({ message : result.data.message, delay : 5000 });
			} else {
				Notification.error({ message : result.data.message, delay : '' });
			}
		}

		function save() {
			vm.isSaving = true;
			vm.pharmaReception.date.setHours(12); // trying to fix some timezone issues
			
			var curSupplier = $filter("filter")(vm.suppliers, { code : (vm.pharmaReception.supplier != null ? vm.pharmaReception.supplier : "") });
			if (angular.isDefined(curSupplier[0])){
				vm.pharmaReception.supplierNif = curSupplier[0].nif;  
			}
				
			PharmaReception.save(vm.pharmaReception, onSaveSuccess, onSaveError);
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.receptionsModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (account) {
							vm.account = account;
						} else {
							vm.account = null;
						}
					});
					$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
						vm.warehouses = result.data;
						for (var i = 0; i < vm.warehouses.length; i++) {
							if (angular.isDefined(vm.account.company.pharmaWarehouse) && vm.warehouses[i].code == vm.account.company.pharmaWarehouse) {
								vm.pharmaReception.warehouse = vm.account.company.pharmaWarehouse;
								break;
							}
						}
					});
					$http.get("api/common/_searchSuppliersForSelect").then(function(result) {
						vm.suppliers = result.data;
					});
					$http.get("api/common/_searchOrderTypesForSelect").then(function(result) {
						vm.orderTypes = result.data;
					});
				}
			});
		}
		vm.loadAll();

		vm.datePopup = {
			opened : false
		};

		vm.openDatePopup = function() {
			vm.datePopup.opened = true;
		};

		vm.dateOptions = {
			formatYear : 'yyyy',
			startingDay : 1,
			showWeeks : false
		};

		function checkCompanyWar($item, $model) {
            Company.isInactive({"id":$item.code}, function (result) {
                if (result.regDate != null) {
                    result.regDate = result.regDate.substring(6,8) + "-" + result.regDate.substring(4,6) + "-" + result.regDate.substring(0,4);
                    result.regTime = result.regTime.substring(0,2) + ":" + result.regTime.substring(2,4) + ":" + result.regTime.substring(4,6);
                    result.company = $item.code + ' - ' + $item.description;
                    $rootScope.message = result;
                    for (var i = 0; i < vm.warehouses.length; i++) {
                        if (angular.isDefined(vm.account.company.pharmaWarehouse) && vm.warehouses[i].code == vm.account.company.pharmaWarehouse) {
                            vm.pharmaReception.warehouse = vm.account.company.pharmaWarehouse;
                            break;
                        }
                    }
                    $state.go("pharma-reception.inactive");
                }
            });
        }

        function checkCompanySup($item, $model) {
            Company.isInactive({"id":$item.code}, function (result) {
                if (result.regDate != null) {
                    result.regDate = result.regDate.substring(6,8) + "-" + result.regDate.substring(4,6) + "-" + result.regDate.substring(0,4);
                    result.regTime = result.regTime.substring(0,2) + ":" + result.regTime.substring(2,4) + ":" + result.regTime.substring(4,6);
                    result.company = $item.code + ' - ' + $item.description;
                    $rootScope.message = result;
                    vm.pharmaReception.supplier = null;
                    $state.go("pharma-reception.inactive");
                }
            });
        }
	}
})();
