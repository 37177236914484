(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PurchaseOrderDetailController', PurchaseOrderDetailController);

	PurchaseOrderDetailController.$inject = [ '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PurchaseOrder', 'PurchaseOrderItem' ];

	function PurchaseOrderDetailController($scope, $rootScope, $stateParams, previousState, entity, PurchaseOrder, PurchaseOrderItem) {
		var vm = this;

		vm.purchaseOrder = entity;
		vm.previousState = previousState.name;

		var unsubscribe = $rootScope.$on('pharmaPortalApp:purchaseOrderUpdate', function(event, result) {
			vm.purchaseOrder = result;
		});
		$scope.$on('$destroy', unsubscribe);
	}
})();
