(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('UserManagementController', UserManagementController);

	UserManagementController.$inject = [ 'Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', '$scope' ];

	function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, $scope) {
		var vm = this;

		vm.authorities = [ 'ROLE_USER', 'ROLE_PHARMA_ADMIN', 'ROLE_PHARMA', 'ROLE_INTRANET', 'ROLE_COMERCIAL', 'ROLE_ADMIN', 'ROLE_PRODUCT_IMPORT' , 'ROLE_INFARMED'];
		vm.currentAccount = null;
		vm.languages = null;

		vm.setActive = setActive;
		vm.users = [];

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clearSearchInTable = clearSearchInTable;
        $scope.searchInTable = "";
		vm.loadAll = loadAll;


		vm.loadAll();

		function loadAll() {
			User.query({
				page : pagingParams.page - 1,
				size : vm.itemsPerPage,
				sort : sort()
			}, onSuccess, onError);
			function sort() {
				var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				// hide anonymous user from user management: it's a required
				// user for Spring Security
				var hiddenUsersSize = 0;
				for ( var i in data) {
					if (data[i]['login'] === 'anonymoususer') {
						data.splice(i, 1);
						hiddenUsersSize++;
					}
				}
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count') - hiddenUsersSize;
				vm.queryCount = vm.totalItems;
				vm.users = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
			});
		}

		JhiLanguageService.getAll().then(function(languages) {
			vm.languages = languages;
		});
		Principal.identity().then(function(account) {
			vm.currentAccount = account;
		});

		function setActive(user, isActivated) {
			user.activated = isActivated;
			User.update(user, function() {
				vm.loadAll();
			});
		}

        function clearSearchInTable() {
            $scope.searchInTable = "";
        }
	}
})();
