(function() {
    'use strict';

    angular.module('pharmaPortalApp').controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = [ '$stateParams', 'User' ];

    function UserManagementDetailController($stateParams, User) {
        var vm = this;

        User.get({
            login : $stateParams.login
        }, function(result) {
            vm.user = result;
            var name = vm.user.firstName + ' ' + vm.user.lastName;
            var initials = name.match(/\b\w/g) || [];
            vm.nameInitials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        });
    }
})();
