(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('ReportGroupDialogController', ReportGroupDialogController);

    ReportGroupDialogController.$inject = ['$timeout','$scope', '$stateParams','$uibModalInstance','$q','entity','ReportGroup', 'ModuleSettings'];

    function ReportGroupDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, ReportGroup, ModuleSettings) {
        var vm = this;

        vm.reportGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.loadAll = loadAll;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if(vm.reportGroup.id !== null){
                ReportGroup.update(vm.reportGroup, onSaveSuccess, onSaveError);
            }else{
                ReportGroup.save(vm.reportGroup,onSaveSuccess,onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('pharmaPortalApp:reportGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function(result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.queriesModule) {
                    $state.go('accessdenied');
                }
            });
        }
        loadAll();

    }
})();
