(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('CampaignController', CampaignController);

    CampaignController.$inject = ['Notification', '$state', 'Campaign', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Company', '$translate', '$http', 'ModuleSettings', '$uibModal', '$scope'];

    function CampaignController(Notification, $state, Campaign, ParseLinks, paginationConstants, pagingParams, Company, $translate, $http, ModuleSettings, $uibModal, $scope) {
        var vm = this;

        vm.campaigns = Campaign.query();
        vm.companies = Company.query({size: 1000});

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.deleteCampaign = "";

        vm.cloneCampaign = cloneCampaign;
        vm.setActive = setActive;
        vm.clearFilter = clearFilter;
        vm.filterCampaigns = filterCampaigns;
        vm.filter = [{
            startDate: null,
            endDate: null,
            campaignStatus: null,
            name: null,
            company: null
        }];

        loadAll();

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.campaignsModule) {
                    $state.go('accessdenied');
                } else {
                    Campaign.query({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    }, onSuccess, onError);

                }
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'startDate') {
                result.push('startDate');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.campaigns = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function cloneCampaign(id) {
            $http.get('api/campaigns/clone/' + id).then(function (result) {
                if (result.status === 200) {
                    $state.go('campaign.edit', {
                        id: result.data.id
                    }, {
                        reload: false
                    });
                    Notification.success({
                        message: $translate.instant("pharmaPortalApp.campaign.cloned"),
                        delay: 5000
                    });
                } else {
                    Notification.error({
                        message: $translate.instant(result.headers("X-pharmaPortalApp-alert")),
                        delay: ''
                    });
                }
            });
        }

        function setActive(campaign, isActivated) {
            campaign.campaignStatus = isActivated;
            Campaign.update(campaign, function () {
                loadAll();
            });
        }

        function clearFilter() {
            loadAll();
            vm.filter = [{
                startDate: null,
                endDate: null,
                campaignStatus: null,
                name: null,
                company: null
            }];
        }

        function filterCampaigns() {
            if (vm.filter.campaignStatus == null && vm.filter.company == null && vm.filter.endDate == null && vm.filter.name == null && vm.filter.startDate == null) {
                return vm.clearFilter();
            }

            var filterRequest = {};
            filterRequest.startDate = vm.filter.startDate != null ? vm.filter.startDate : null;
            filterRequest.endDate = vm.filter.endDate != null ? vm.filter.endDate : null;
            filterRequest.campaignStatus = vm.filter.campaignStatus != null ? vm.filter.campaignStatus : null;
            filterRequest.name = vm.filter.name != null ? vm.filter.name.name : null;
            filterRequest.companyId = vm.filter.company != null ? vm.filter.company.id : null;

            $http.post('api/campaigns/searchFilter', filterRequest).then(function (result) {
                if (result.status === 200) {
                    vm.campaigns = result.data;
                    vm.links = ParseLinks.parse(result.headers('link'));
                    vm.totalItems = result.headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                } else {
                    Notification.error({
                        message: $translate.instant(result.headers("X-pharmaPortalApp-alert")),
                        delay: ''
                    });
                }
            });
        }

        vm.statusItems = [{
            value: true,
            label: $translate.instant('pharmaPortalApp.campaign.status.active')
        }, {
            value: false,
            label: $translate.instant('pharmaPortalApp.campaign.status.inactive')
        }];

        vm.datePopup = {
            start: {
                opened: false
            },
            end: {
                opened: false
            }
        };

        vm.openDatePopup = function (inputCalendar) {
            if (inputCalendar == 'start') {
                vm.datePopup.start.opened = true;
            } else {
                vm.datePopup.end.opened = true;
            }
        };

        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.deleteCampaign = function (item) {
            $uibModal.open({
                templateUrl: 'app/entities/campaign/campaign-delete-dialog.html',
                controller: 'CampaignDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    campaignId: function () {
                        return item;
                    }
                }
            }).result.then(function () {
                // called when you close the modal
                // do your stuff
                filterCampaigns();
            }, function () {
                // called when you dismiss modal by clicking anywhere outside modal
                // do your stuff
            });

        };

    }
})();
