(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('CampaignProductNewDialogController', CampaignProductNewDialogController);

	CampaignProductNewDialogController.$inject = ['Notification', '$uibModalInstance', '$stateParams', 'entity', 'Campaign', 'CampaignProduct', '$translate', '$state', 'ModuleSettings' ];

	function CampaignProductNewDialogController(Notification, $uibModalInstance, $stateParams, entity, Campaign, CampaignProduct, $translate, $state, ModuleSettings) {
		var vm = this;

		vm.campaign = Campaign.get({ id : $stateParams.id });
		vm.campaignProduct = entity;

		vm.clear = clear;
		vm.save = save;
		vm.validateCampaignProduct = validateCampaignProduct;
		vm.loadAll = loadAll;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;
				
				if (!vm.moduleSettings.campaignsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
		
		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			if (validateCampaignProduct()) {
				vm.isSaving = true;
				if (vm.campaignProduct.id !== null) {
					CampaignProduct.update(vm.campaignProduct, onSaveSuccess, onSaveError);
				} else {
					vm.campaignProduct.campaign = vm.campaign;
					CampaignProduct.save(vm.campaignProduct, onSaveSuccess, onSaveError);
				}
			}
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			Notification.success({ message : $translate.instant("pharmaPortalApp.campaignProduct.created"), delay : 5000 });
			$state.go("campaign.edit", { id : vm.campaign.id }, { reload : true });
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.isSaving = false;
			Notification.error({ message : $translate.instant("pharmaPortalApp.campaignProduct.error.create"), delay : '' });
		}

		function validateCampaignProduct() {
			var errors = [];
			// CNP
			if (vm.campaignProduct.cnp === null || vm.campaignProduct.cnp === '') {
				errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.empty.cnp'));
			}
			// DESCRIPTION
			if (vm.campaignProduct.description === null || vm.campaignProduct.description === '') {
				errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.empty.description'));
			}

			if (vm.campaign.campaignType === 'CHALLENGE') {
				// TARGET AMOUNT
				if (vm.campaignProduct.targetAmount === null || vm.campaignProduct.targetAmount === '') {
					errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.empty.targetAmount'));
				} else {
					if (!angular.isNumber(vm.campaignProduct.targetAmount) || isNaN(vm.campaignProduct.targetAmount)) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.numeric.targetAmount'));
					} else if (vm.campaignProduct.targetAmount <= 0) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.belowZero.targetAmount'));
					}
				}
				// REFERENCE PRICE
				if (vm.campaignProduct.referencePrice === null || vm.campaignProduct.referencePrice === '') {
					errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.empty.referencePrice'));
				} else {
					if (!angular.isNumber(vm.campaignProduct.referencePrice) || isNaN(vm.campaignProduct.referencePrice)) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.numeric.referencePrice'));
					} else if (vm.campaignProduct.referencePrice <= 0) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.belowZero.referencePrice'));
					}
				}
			} else {
				// TARGET AMOUNT
				if (vm.campaignProduct.targetAmount !== null && vm.campaignProduct.targetAmount !== '' && !isNaN(vm.campaignProduct.targetAmount)) {
					if (!angular.isNumber(vm.campaignProduct.targetAmount) || isNaN(vm.campaignProduct.targetAmount)) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.numeric.targetAmount'));
					} else if (vm.campaignProduct.targetAmount <= 0) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.belowZero.targetAmount'));
					}
				} else {
					vm.campaignProduct.targetAmount = null;
				}
				// REFERENCE PRICE
				if (vm.campaignProduct.referencePrice !== null && vm.campaignProduct.referencePrice !== '' && !isNaN(vm.campaignProduct.referencePrice)) {
					if (!angular.isNumber(vm.campaignProduct.referencePrice) || isNaN(vm.campaignProduct.referencePrice)) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.numeric.referencePrice'));
					} else if (vm.campaignProduct.referencePrice <= 0) {
						errors.push($translate.instant('pharmaPortalApp.campaignProduct.error.belowZero.referencePrice'));
					}
				} else {
					vm.campaignProduct.referencePrice = null;
				}
			}

			if (errors.length > 0) {
				var message = '';
				for (var i = 0; i < errors.length; i++) {
					if (i > 0) {
						message += '<br>';
					}
					message += errors[i];
				}
				Notification.error({
					message : $translate.instant('pharmaPortalApp.campaignProduct.error.topMessage') + '<br>' + message,
					delay : ''
				});
				return false;
			}
			return true;
		}

		vm.brandTypes = [ {
			value : 'GOLD',
			label : $translate.instant('pharmaPortalApp.brandType.GOLD')
		}, {
			value : 'SILVER',
			label : $translate.instant('pharmaPortalApp.brandType.SILVER')
		} ];
	}
})();
