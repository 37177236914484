(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PortalProductStockDetailController', PortalProductStockDetailController);

	PortalProductStockDetailController.$inject = [ '$uibModalInstance', '$rootScope', '$scope', '$http', '$state', 'entity', 'ModuleSettings' ];

	function PortalProductStockDetailController($uibModalInstance, $rootScope, $scope, $http, $state, entity, ModuleSettings) {
		var vm = this;

		vm.internalCode = entity.internalCode;
		$scope.warehouseStockList = [];

		vm.clear = clear;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.productsModule) {
					$state.go('accessdenied');
				} else {
					$http.get("api/portal-product/stockDetails/" + vm.internalCode).then(function(result) {
						$scope.warehouseStockList = result.data;
					});
				}
			});
		}
		loadAll();

		$scope.getTotal = function() {
			var total = 0;
			for (var i = 0; i < $scope.warehouseStockList.length; i++) {
				var warehouseStock = $scope.warehouseStockList[i];
				total += (warehouseStock.stock * 1);
			}
			return total;
		}
	}
})();
