(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingSubmitCompleteController', PharmaPickingSubmitCompleteController);

	PharmaPickingSubmitCompleteController.$inject = [ 'Notification', '$rootScope','blockUI', '$uibModalInstance', '$uibModal', '$translate', '$state', '$http', '$filter', 'pharmaPicking', 'PharmaPicking', 'PortalSettings', 'ModuleSettings', 'user', 'InvoiceAndClaim'];

	function PharmaPickingSubmitCompleteController(Notification, $rootScope, blockUI, $uibModalInstance, $uibModal, $translate, $state, $http, $filter, pharmaPicking, PharmaPicking, PortalSettings, ModuleSettings, user, InvoiceAndClaim) {
		var vm = this;

		vm.portalSettings = PortalSettings.query();

		vm.loadAll = loadAll;

		vm.clear = clear;
		vm.confirmPharmaPickingSubmit = confirmPharmaPickingSubmit;
		vm.pharmaPicking = pharmaPicking;
		vm.user = user.user;
		vm.supplier = vm.pharmaPicking.supplierCode + " - " + vm.pharmaPicking.supplierName;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.pickingModule) {
					$state.go('accessdenied');
				}
			});
		}
		vm.loadAll();


		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onSubmitSuccess(result) {
			$uibModalInstance.close(true);
			blockUI.stop();
			if (result.success){
				Notification.success({message: result.message, delay: 5000});
				$state.go("pharma-picking", {}, {
					reload : true
				});
			} else {
				Notification.error({message: result.message, delay: ''});
				$state.go("pharma-picking-detail.edit", {
					id : vm.pharmaPicking.id
				}, {
					reload : true
				});
			}
		}

		function onSubmitError(result) {
			blockUI.stop();
			$uibModalInstance.close(true);

			if(result.success){
				Notification.success({message: result.message, delay: 5000});
			} else {
				Notification.error({message: result.message, delay: ''});
			}
		}

		function confirmPharmaPickingSubmit() {

            var received = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (item) {
                return item.receivedQuantity > 0;
            });
            if (received.length > 0) {
                $uibModal.open({
                    templateUrl: 'app/entities/pharma-picking/pharma-picking-keep-in-pharmacy-dialog.html',
                    controller: 'PharmaPickingKeepInPharmacyController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        pharmaPicking: vm.pharmaPicking,
                        complaint: false,
                        user: {user: vm.user}
                    }
                }).result.then(function () {
                    // success callback implemented on own controller
                    var products = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
                        return line.invoicedQuantity - line.receivedQuantity > 0;
                    }, true);
                    submitToErp(products)

                }, function (flag) {
                    // Back button
                    if (flag == 'goToPickingDetail') {
                        vm.clear('close');
                    }
                });
            } else {
                var products = $filter('filter')(vm.pharmaPicking.pharmaPickingLines, function (line) {
                    return line.invoicedQuantity - line.receivedQuantity > 0;
                }, true);
                submitToErp(products)
            }
		}

        function submitToErp(products) {
            $uibModalInstance.close(true);
            InvoiceAndClaim.invoice(vm.pharmaPicking, products, vm.user);
        }

	}
})();
