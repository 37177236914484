(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state('product-list', {
					parent : 'entity',
					url : '/product-list?page&sort&search',
					data : {
						authorities : [ 'ROLE_USER' ],
						pageTitle : 'pharmaPortalApp.productList.home.title'
					},
					views : {
						'content@' : {
							templateUrl : 'app/entities/product-list/product-lists.html',
							controller : 'ProductListController',
							controllerAs : 'vm'
						}
					},
					params : {
						page : {
							value : '1',
							squash : true
						},
						sort : {
							value : 'id,asc',
							squash : true
						},
						search : null
					},
					resolve : {
						pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
							return {
								page : PaginationUtil.parsePage($stateParams.page),
								sort : $stateParams.sort,
								predicate : PaginationUtil.parsePredicate($stateParams.sort),
								ascending : PaginationUtil.parseAscending($stateParams.sort),
								search : $stateParams.search
							};
						} ],
						translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('productList');
							$translatePartialLoader.addPart('global');
							return $translate.refresh();
						} ]
					}
				})
				.state('product-list-detail', {
					parent : 'product-list',
					url : '/product-list/{id}',
					data : {
						authorities : [ 'ROLE_USER' ],
						pageTitle : 'pharmaPortalApp.productList.home.title'
					},
					views : {
						'content@' : {
							templateUrl : 'app/entities/product-list/product-list-detail.html',
							controller : 'ProductListDetailController',
							controllerAs : 'vm'
						}
					},
					resolve : {
						translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('productList');
							return $translate.refresh();
						} ],
						entity : [ '$stateParams', 'ProductList', function($stateParams, ProductList) {
							return ProductList.get({
								id : $stateParams.id
							}).$promise;
						} ],
						previousState : [ "$state", function($state) {
							var currentStateData = {
								name : $state.current.name || 'product-list',
								params : $state.params,
								url : $state.href($state.current.name, $state.params)
							};
							return currentStateData;
						} ]
					}
				})
				.state('product-list-detail.edit', {
					parent : 'product-list-detail',
					url : '/detail/edit',
					data : {
						authorities : [ 'ROLE_USER' ]
					},
					onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
						$uibModal.open({
							templateUrl : 'app/entities/product-list/product-list-dialog.html',
							controller : 'ProductListDialogController',
							controllerAs : 'vm',
							backdrop : 'static',
							size : 'lg',
							resolve : {
								entity : [ 'ProductList', function(ProductList) {
									return ProductList.get({
										id : $stateParams.id
									}).$promise;
								} ]
							}
						}).result.then(function() {
							$state.go('^', {}, {
								reload : false
							});
						}, function() {
							$state.go('^');
						});
					} ]
				})
				.state(
						'product-list.new',
						{
							parent : 'product-list',
							url : '/new',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/product-list/product-list-dialog.html',
													controller : 'ProductListDialogController',
													controllerAs : 'vm',
													backdrop : 'static',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																name : null,
																parent : null,
																id : null
															};
														}
													}
												}).result.then(function() {
											$state.go('product-list', null, {
												reload : 'product-list'
											});
										}, function() {
											$state.go('product-list');
										});
									} ]
						}).state('product-list.edit', {
					parent : 'product-list',
					url : '/{id}/edit',
					data : {
						authorities : [ 'ROLE_USER' ]
					},
					onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
						$uibModal.open({
							templateUrl : 'app/entities/product-list/product-list-dialog.html',
							controller : 'ProductListDialogController',
							controllerAs : 'vm',
							backdrop : 'static',
							size : 'lg',
							resolve : {
								entity : [ 'ProductList', function(ProductList) {
									return ProductList.get({
										id : $stateParams.id
									}).$promise;
								} ]
							}
						}).result.then(function() {
							$state.go('product-list', null, {
								reload : 'product-list'
							});
						}, function() {
							$state.go('^');
						});
					} ]
				}).state('product-list.delete', {
					parent : 'product-list',
					url : '/{id}/delete',
					data : {
						authorities : [ 'ROLE_USER' ]
					},
					onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
						$uibModal.open({
							templateUrl : 'app/entities/product-list/product-list-delete-dialog.html',
							controller : 'ProductListDeleteController',
							controllerAs : 'vm',
							size : 'md',
							resolve : {
								entity : [ 'ProductList', function(ProductList) {
									return ProductList.get({id : $stateParams.id}).$promise;
								} ]
							}
						}).result.then(function() {
							$state.go('product-list', null, {
								reload : 'product-list'
							});
						}, function() {
							$state.go('^');
						});
					} ]
				}).state('product-list-board', {
					parent : 'product-list',
					url : '/product-list-board',
					data : {
						authorities : [ 'ROLE_ADMIN', 'ROLE_INTRANET' ],
						pageTitle : 'pharmaPortalApp.productList.home.title'
					},
					views : {
						'content@' : {
							templateUrl : 'app/entities/product-list/product-list-board.html',
							controller : 'ProductListBoardController',
							controllerAs : 'vm'
						}
					},
					resolve : {
						translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('productList');
							return $translate.refresh();
						} ],
						previousState : [ "$state", function($state) {
							var currentStateData = {
								name : $state.current.name || 'product-list',
								params : $state.params,
								url : $state.href($state.current.name, $state.params)
							};
							return currentStateData;
						} ]
					}
				});
	}
})();
