(function() {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaTradeInactiveController', PharmaTradeInactiveController);

    PharmaTradeInactiveController.$inject = [ '$uibModalInstance', '$rootScope', '$state', 'ModuleSettings' ];

    function PharmaTradeInactiveController($uibModalInstance, $rootScope, $state, ModuleSettings) {
        var vm = this;

        vm.clear = clear;
        vm.confirmAddProducts = confirmAddProducts;
        vm.loadAll = loadAll;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmAddProducts() {
            $rootScope.searchProductsAction()
            $uibModalInstance.close(true);
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function(result) {
                vm.moduleSettings = result;

                /*if (!vm.moduleSettings.productsModule) {
                    $state.go('accessdenied');
                }*/
            });
        }
        loadAll();
    }
})();
