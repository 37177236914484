(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('SaleSuccessController', SaleSuccessController);

	SaleSuccessController.$inject = [ '$uibModalInstance', '$state', 'Principal', 'ModuleSettings' ];

	function SaleSuccessController($uibModalInstance, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.clear = clear;
		vm.confirmAddProducts = confirmAddProducts;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function confirmAddProducts() {
			$uibModalInstance.close(true);
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.salesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (account && account.company) {
							// DO NOTHING
						} else {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();