(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PortalAppDeleteController',PortalAppDeleteController);

	PortalAppDeleteController.$inject = ['$uibModalInstance', 'entity', 'PortalApp', '$state', 'ModuleSettings'];

	function PortalAppDeleteController($uibModalInstance, entity, PortalApp, $state, ModuleSettings) {
		var vm = this;

		vm.report = entity;
		vm.clear = clear;
		vm.confirmDelete = confirmDelete;
		vm.loadAll = loadAll;

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmDelete (id) {
			PortalApp.delete({id: id}, function () { $uibModalInstance.close(true); });
		}

		function loadAll () {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function (result) {
				vm.moduleSettings = result;

				if(!vm.moduleSettings.applicationsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
	}
})();
