(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PolicyDefinitionDeleteDialogController', PolicyDefinitionDeleteDialogController);

    PolicyDefinitionDeleteDialogController.$inject = ['Notification', '$uibModalInstance', '$scope', '$translate', 'records', 'PolicyDefinition'];

    function PolicyDefinitionDeleteDialogController(Notification, $uibModalInstance, $scope, $translate, records, PolicyDefinition) {
        var vm = this;

        $scope.records = records;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.getPolicyDesc = getPolicyDesc;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getPolicyDesc(policyDefinition) {
            return $translate.instant(policyDefinition.policyImplementation) + ' ' + policyDefinition.input.metadataValues;
        };

        function confirmDelete() {
            if ($scope.records) {
                var ids = [];
                angular.forEach($scope.records, function (value) {
                    ids.push(value.id);
                });

                PolicyDefinition.deleteBulk(ids, function () {
                    Notification.success({
                        message: "Política(s) apagada com sucesso",
                        delay: 5000
                    });
                    $uibModalInstance.close(true);
                }, function () {
                    Notification.error({
                        message: "Erro ao apagar política(s)",
                        delay: 5000
                    });
                    $uibModalInstance.close(false);
                });
            } else {
                $uibModalInstance.close(false);
            }
        }
    }
})();
