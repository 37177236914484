(function() {
	'use strict';

	angular
	.module('pharmaPortalApp')
	.filter('decimal2comma', decimal2comma);

	function decimal2comma() {
		return decimal2commaFilter;

		function decimal2commaFilter(input) {
			var ret = (input) ? input.toString().replace(".", ",") : null;
			if (ret) {
				var decArr = ret.split(",");
				if (decArr.length > 1) {
					var dec = decArr[1].length;
					if (dec === 1) {
						ret += "0";
					}
				}//this is to show prices like 12,20 and not 12,2
			}
			return ret;
		}
	}
})();
