(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalSettingsController', PortalSettingsController);

    PortalSettingsController.$inject = ['Notification', '$scope', '$http', 'PortalSettings', 'ModuleSettings', 'Company', '$timeout'];

    function PortalSettingsController(Notification, $scope, $http, PortalSettings, ModuleSettings, Company, $timeout) {
        var vm = this;

        vm.selfInvoiceCollectionEmail = undefined;

        vm.addSelfInvoiceCollectionEmail = addSelfInvoiceCollectionEmail;
        vm.removeSelfInvoiceCollectionEmail = removeSelfInvoiceCollectionEmail;

        vm.loadAll = loadAll;
        vm.save = save;
        Company.getClientGroups(function (result) {
            vm.clientGroups = result;
        }, function (error) {
        });

        vm.activeGroups = [];

        PortalSettings.getAllActiveClientGroups(function (result) {
            vm.activeGroups = result;
        });

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.settings = PortalSettings.query();

            vm.settings.$promise.then(function (result) {
                vm.settings.invoiceType = {code: vm.settings.invoiceTypeCode, description: vm.settings.invoiceTypeDesc};
                vm.settings.conferenceType = {code: vm.settings.billingTypeRetainedProductsCode, description: vm.settings.billingTypeRetainedProductsDesc};
                vm.settings.tradeInvoiceType = {code: vm.settings.tradeInvoiceTypeCode, description: vm.settings.tradeInvoiceTypeDesc};
                vm.settings.tradeCreditType = {code: vm.settings.tradeCreditTypeCode, description: vm.settings.tradeCreditTypeDesc};
                vm.settings.cecServiceType = {code: vm.settings.cecServiceTypeCode, description: vm.settings.cecServiceTypeDesc};
                vm.settings.warehouseSales = {code: vm.settings.warehouseSalesCode, description: vm.settings.warehouseSalesDesc};
                vm.settings.returnWarehouseTransfType = {code: vm.settings.returnWarehouseTransfTypeCode, description: vm.settings.returnWarehouseTransfTypeDesc};
                vm.settings.returnCreditType = {code: vm.settings.returnCreditTypeCode, description: vm.settings.returnCreditTypeDesc};
                vm.settings.billingType = {code: vm.settings.billingTypeCode, description: vm.settings.billingTypeDesc};
                vm.settings.transportType = {code: vm.settings.transportTypeCode, description: vm.settings.transportTypeDesc};
                vm.settings.selfInvoiceCollectionEmails = vm.settings.selfInvoiceCollectionEmails ? JSON.parse(vm.settings.selfInvoiceCollectionEmails) : [];
            });

            $http.get("api/common/_searchInvoiceTypesForSelect").then(function (result) {
                $scope.documentTypes = result.data;
            });

            $http.get("api/common/_searchInvoiceTypesWithCorrectionForSelect").then(function (result) {
                $scope.documentTypesWithCorrection = result.data;
            });

            $http.get("api/common/_getServicesForSelect").then(function (result) {
                $scope.serviceTypes = result.data;
            });

            $http.get("api/common/_searchWarehousesForSelect").then(function (result) {
                $scope.warehouses = result.data;
            });

            $http.get("api/companies/findAll").then(function (result) {
                $scope.companiesWithWarehouse = result.data;
            });
        }

        loadAll();

        function save() {
            try {
                if (angular.isDefined(vm.settings.conferenceType)) {
                    vm.settings.billingTypeRetainedProductsCode = vm.settings.conferenceType.code;
                    vm.settings.billingTypeRetainedProductsDesc = vm.settings.conferenceType.description;
                }
                if (angular.isDefined(vm.settings.invoiceType)) {
                    vm.settings.invoiceTypeCode = vm.settings.invoiceType.code;
                    vm.settings.invoiceTypeDesc = vm.settings.invoiceType.description;
                }
                if (angular.isDefined(vm.settings.tradeInvoiceType)) {
                    vm.settings.tradeInvoiceTypeCode = vm.settings.tradeInvoiceType.code;
                    vm.settings.tradeInvoiceTypeDesc = vm.settings.tradeInvoiceType.description;
                }
                if (angular.isDefined(vm.settings.tradeCreditType)) {
                    vm.settings.tradeCreditTypeCode = vm.settings.tradeCreditType.code;
                    vm.settings.tradeCreditTypeDesc = vm.settings.tradeCreditType.description;
                }
                if (angular.isDefined(vm.settings.cecServiceType)) {
                    vm.settings.cecServiceTypeCode = vm.settings.cecServiceType.code;
                    vm.settings.cecServiceTypeDesc = vm.settings.cecServiceType.description;
                }
                if (angular.isDefined(vm.settings.warehouseSales)) {
                    vm.settings.warehouseSalesCode = vm.settings.warehouseSales.code;
                    vm.settings.warehouseSalesDesc = vm.settings.warehouseSales.description;
                }
                if (angular.isDefined(vm.settings.returnWarehouseTransfType)) {
                    vm.settings.returnWarehouseTransfTypeCode = vm.settings.returnWarehouseTransfType.code;
                    vm.settings.returnWarehouseTransfTypeDesc = vm.settings.returnWarehouseTransfType.description;
                }
                if (angular.isDefined(vm.settings.returnCreditType)) {
                    vm.settings.returnCreditTypeCode = vm.settings.returnCreditType.code;
                    vm.settings.returnCreditTypeDesc = vm.settings.returnCreditType.description;
                }
                if (angular.isDefined(vm.settings.billingType)) {
                    vm.settings.billingTypeCode = vm.settings.billingType.code;
                    vm.settings.billingTypeDesc = vm.settings.billingType.description;
                }
                if (angular.isDefined(vm.settings.transportType)) {
                    vm.settings.transportTypeCode = vm.settings.transportType.code;
                    vm.settings.transportTypeDesc = vm.settings.transportType.description;
                }
                if (angular.isDefined(vm.settings.selfInvoiceCollectionEmails)) {
                    vm.settings.selfInvoiceCollectionEmails = JSON.stringify(vm.settings.selfInvoiceCollectionEmails);
                }

                PortalSettings.update(vm.settings, function () {
                    Notification.success({message: 'Parametrizações atualizadas com sucesso', delay: 5000});
                    $timeout(function () {
                        location.reload();
                    }, 2000);
                });

                if (vm.settings.onlyTradeInGroup) {
                    PortalSettings.deleteActiveTradeGroups(function (result) {
                        for (var i = 0; i < vm.activeGroups.length; i++) {
                            PortalSettings.saveActiveTradeGroups(vm.activeGroups[i], function (result) {
                                var flag = result;
                            }, function (error) {
                                var flag = error;
                            });
                        }
                    }, function (error) {
                        Notification.error({message: 'active trade groups nao apagados', delay: 5000});
                    });

                } else {
                    PortalSettings.deleteActiveTradeGroups(function (result) {
                        vm.activeGroups = [];
                    }, function (error) {
                        Notification.error({message: 'active trade groups nao apagados', delay: 5000});
                    });
                }

            } catch (err) {
                Notification.error({message: 'Não foi possível atualizar as parametrizações', delay: ''});
            }
        }

        $scope.setDocReason = function ($item) {
            if ($item.withreason == 'S') {
                vm.settings.rectifDocReason = $item.reasoncode + ' - ' + $item.reasondescription;
            } else {
                vm.settings.rectifDocReason = null;
            }
        }

        function addSelfInvoiceCollectionEmail() {
            if(!angular.isDefined(vm.settings.selfInvoiceCollectionEmails)) {
                vm.settings.selfInvoiceCollectionEmails = [];
            }
            vm.settings.selfInvoiceCollectionEmails.push({email: vm.selfInvoiceCollectionEmail});
            vm.selfInvoiceCollectionEmail = undefined;
        }

        function removeSelfInvoiceCollectionEmail(item) {
            var index = vm.settings.selfInvoiceCollectionEmails.indexOf(item);
            vm.settings.selfInvoiceCollectionEmails.splice(index, 1);
        }

    }
})();
