(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaReceptionDeleteController', PharmaReceptionDeleteController);

	PharmaReceptionDeleteController.$inject = ['Notification', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'pharmaReception', 'PharmaReception', 'ModuleSettings'];

	function PharmaReceptionDeleteController(Notification, $uibModalInstance, $translate, $state, $http, $filter, pharmaReception, PharmaReception, ModuleSettings) {
		var vm = this;

		vm.clear = clear;
		vm.confirmPharmaReceptionDelete = confirmPharmaReceptionDelete;
		vm.pharmaReception = pharmaReception;
		
		vm.loadAll = loadAll;
		vm.getWarehouseFullDescription = getWarehouseFullDescription;
		vm.getSupplierFullDescription = getSupplierFullDescription;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onDeleteSuccess(result) {
			Notification.success({message: $translate.instant('pharmaPortalApp.pharmaReception.message.pharmaReceptionDeleted'), delay: 5000});
			$uibModalInstance.close(true);
		}

		function onDeleteError(result) {
			$uibModalInstance.close(true);
			
			if(result.data.success){
				Notification.success({message: result.data.message, delay: 5000});
			} else {
				Notification.error({message: result.data.message, delay: ''});
			}
		}

		function confirmPharmaReceptionDelete() {
			PharmaReception.delete({id: pharmaReception.id}, onDeleteSuccess, onDeleteError);
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.receptionsModule) {
					$state.go('accessdenied');
				} else {
					$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
						vm.warehouses = result.data;
					});
					$http.get("api/common/_searchSuppliersForSelect").then(function(result) {
						vm.suppliers = result.data;
					});
				}
			});
		}
		vm.loadAll();

		function getWarehouseFullDescription(warehouseCode) {
			if (angular.isDefined(vm.warehouses)) {
				var warehouse = $filter('filter')(vm.warehouses, {
					code : warehouseCode
				})[0];
				return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
			} else {
				return warehouseCode;
			}
		}

		function getSupplierFullDescription(supplierCode) {
			if (angular.isDefined(vm.suppliers)) {
				var supplier = $filter('filter')(vm.suppliers, {
					code : supplierCode
				})[0];
				return supplierCode + (supplier != null ? ' - ' + supplier.description : '');
			} else {
				return supplierCode;
			}
		}
	}
})();
