(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('campaign', {
			parent : 'entity',
			url : '/campaign?page&sort&search',
			data : {
				authorities : [ 'ROLE_USER' ],
				pageTitle : 'pharmaPortalApp.campaign.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/campaign/campaigns.html',
					controller : 'CampaignController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'startDate,desc',
					squash : true
				}
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort)
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('campaign');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('campaign-detail', {
			parent : 'campaign',
			url : '/{id}',
			data : {
				authorities : [ 'ROLE_USER' ],
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/campaign/campaign-detail-dialog.html',
					controller : 'CampaignDetailDialogController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'lg',
					resolve : {
						entity : [ 'Campaign', function(Campaign) {
							return Campaign.get({
								id : $stateParams.id
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('campaign', {}, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('campaign.new', {
			parent : 'campaign',
			url : '/campaign/new',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/campaign/campaign-new-dialog.html',
					controller : 'CampaignNewDialogController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'lg',
					resolve : {
						entity : function() {
							return {
								id : null,
								name : null,
								campaignType : null,
								startDate : null,
								endDate : null,
								campaignStatus : null,
								companies : null,
								products : null
							};
						}
					}
				}).result.then(function(result) {
					$state.go('campaign.edit', {
						id : result.id
					}, {
						reload : false
					});
				}, function() {
					$state.go('campaign');
				});
			} ]
		}).state('campaign.edit', {
			parent : 'campaign',
			url : '/{id}/edit',
			data : {
				authorities : [ 'ROLE_USER' ],
				pageTitle : 'pharmaPortalApp.campaign.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/campaign/campaign-edit.html',
					controller : 'CampaignEditController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('campaign');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'Campaign', function($stateParams, Campaign) {
					return Campaign.get({
						id : $stateParams.id
					}).$promise;
				} ],
				previousState : [ "$state", function($state) {
					var currentStateData = {
						name : $state.current.name || 'campaign',
						params : $state.params,
						url : $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				} ]
			}
		}).state('campaign.delete', {
			parent : 'campaign',
			url : '/{id}/delete',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/campaign/campaign-delete-dialog.html',
					controller : 'CampaignDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : [ 'Campaign', function(Campaign) {
							return Campaign.get({
								id : $stateParams.id
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('campaign', null, {
						reload : 'campaign'
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('campaign-product-delete', {
			parent : 'campaign.edit',
			url : '/campaign-products/{prodId}',
			method : 'DELETE',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/campaign/campaign-product-delete-dialog.html',
					controller : 'CampaignProductDeleteDialogController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : [ 'CampaignProduct', function(CampaignProduct) {
							return CampaignProduct.get({
								id : $stateParams.prodId
							});
						} ]
					}
				}).result.then(function() {
					$state.go('campaign.edit', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('campaign-product-new', {
			parent : 'campaign.edit',
			url : '/new',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/campaign/campaign-product-new-dialog.html',
					controller : 'CampaignProductNewDialogController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								id : null,
								society : null,
								cnp : null,
								description : null,
								targetAmount : null,
								referencePrice : null,
								campaign : null,
								promoRef : null,
								promoLine : null
							};
						}
					}
				}).result.then(function() {
					$state.go('campaign-product-new', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('campaign-product-clear-list', {
			parent : 'campaign.edit',
			url : '/campaign-products-clear-list',
			data : {
				authorities : [ 'ROLE_USER' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/campaign/campaign-product-clear-list-dialog.html',
					controller : 'CampaignProductClearListDialogController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : [ 'Campaign', function(Campaign) {
							return Campaign.get({
								id : $stateParams.id
							});
						} ]
					}
				}).result.then(function() {
					$state.go('campaign.edit', {
						id : $stateParams.id
					}, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		});
	}
})();
