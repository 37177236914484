(function () {
    'use strict';

    angular.module('pharmaPortalApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('product-stock-qtd-list', {
            parent: 'policy-definition',
            url: '/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/policy-definition/policy-entities/product-stock-qtd.html',
                    controller: 'ProductStockQtdController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function () {
                    $state.go('policy-definition', {}, {
                        reload: false
                    });
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('product-stock-qtd-import', {
            parent: 'policy-definition',
            url: '/{id}/import',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/policy-definition/policy-entities/product-stock-qtd-import.html',
                    controller: 'ProductStockQtdImportController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function () {
                    $state.go('policy-definition', {}, {
                        reload: false
                    });
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('product-stock-qtd-export', {
            parent: 'policy-definition',
            url: '/{id}/export',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/policy-definition/policy-entities/product-stock-qtd-export.html',
                    controller: 'ProductStockQtdExportController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md'
                }).result.then(function () {
                    $state.go('policy-definition', {}, {
                        reload: false
                    });
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();
