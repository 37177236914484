(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('PortalSettings', PortalSettings);

	PortalSettings.$inject = [ '$resource' ];

	function PortalSettings($resource) {
		var resourceUrl = 'api/portal-settings';

		return $resource(resourceUrl, {}, {
            'query': {
                method : 'GET'
            },
			'update' : {
				method : 'PUT'
            },
            'saveActiveTradeGroups':{
                method:'GET',
                url:'api/activeTradeGroups/saveActiveGroup'
            },
            'deleteActiveTradeGroups': {
                method:'GET',
                url:'api/activeTradeGroups/deleteActiveTradeGroups'
            },
            'getAllActiveClientGroups':{
                method:'GET',
                isArray: true,
                url:'api/activeTradeGroups/getAllActiveGroups'
            }
		});
	}
})();
