(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('AdminSettingsController', AdminSettingsController);

	AdminSettingsController.$inject = [ '$scope' ];

	function AdminSettingsController($scope) {
		var vm = this;
	}
})();
