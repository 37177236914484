(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalNotificationController', PortalNotificationController);

    PortalNotificationController.$inject = ['$scope', '$state', 'Notification', 'ParseLinks', 'paginationConstants', 'pagingParams', '$translate', '$http', '$timeout', 'PortalNotification', 'Principal', 'User'];

    function PortalNotificationController($scope, $state, Notification, ParseLinks, paginationConstants, pagingParams, $translate, $http, $timeout, PortalNotification, Principal, User) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.filterPortalNotifications = filterPortalNotifications;
        vm.clearFilter = clearFilter;

        vm.setRead = setRead;

        $scope.toggleAll = function () {
            var toggleStatus = $scope.isAllSelected;
            angular.forEach(vm.portalNotifications, function (itm) {
                itm.selected = toggleStatus;
            });
        };

        $scope.optionToggled = function () {
            $scope.isAllSelected = vm.portalNotifications.every(function (itm) {
                return itm.selected;
            })
        };

        $scope.isAnyOptionSelected = function () {
            var noSelection = true;
            angular.forEach(vm.portalNotifications, function (itm) {
                if (noSelection && itm.selected) {
                    noSelection = false;
                }
            });
            return noSelection;
        };

        $scope.markAsReadOrUnread = function (flag) {
            angular.forEach(vm.portalNotifications, function (itm) {
                if (itm.selected) {
                    if (itm.isRead != flag) {
                        itm.isRead = flag;
                        PortalNotification.update(itm);
                    }
                    itm.selected = false;
                }
            });
            $scope.isAllSelected = false;
            $timeout(function () {
                $scope.$emit('readOrUnreadEvent');
            }, 1000)
        };

        $scope.goToDetail = function (portalNotification) {
            if (portalNotification.isRead == true) {
                $state.go('portal-notification-detail', {id: portalNotification.id});
            } else {
                portalNotification.isRead = true;
                PortalNotification.update(portalNotification, function () {
                    $scope.$emit('readOrUnreadEvent');
                    $state.go('portal-notification-detail', {id: portalNotification.id});
                }, function () {
                    $state.go('portal-notification-detail', {id: portalNotification.id});
                });
            }
        };

        function setRead(portalNotification, isRead) {
            portalNotification.isRead = isRead;
            PortalNotification.update(portalNotification, function () {
                loadAll();
                $scope.$emit('readOrUnreadEvent')
            });
        }

        vm.openDatePopup = function (inputCalendar) {
            if (inputCalendar == 'start') {
                vm.datePopup.start.opened = true;
            }
        };

        vm.datePopup = {
            start: {
                opened: false
            }
        };

        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.categories = [
            {value: "ERROR", label: $translate.instant('PortalNotificationCategory.ERROR')},
            {value: "INFO", label: $translate.instant('PortalNotificationCategory.INFO')},
            {value: "MESSAGE", label: $translate.instant('PortalNotificationCategory.MESSAGE')}
        ];

        vm.priorities = [
            {value: "HIGH", label: $translate.instant('PortalNotificationPriority.HIGH')},
            {value: "MEDIUM", label: $translate.instant('PortalNotificationPriority.MEDIUM')},
            {value: "LOW", label: $translate.instant('PortalNotificationPriority.LOW')}
        ];

        vm.states = [
            {value: true, label: $translate.instant('pharmaPortalApp.portalNotification.isRead.read')},
            {value: false, label: $translate.instant('pharmaPortalApp.portalNotification.isRead.noRead')}
        ];

        vm.filter = [{
            date: null,
            origin: null,
            category: null,
            priority: null,
            isRead: null
        }];

        function clearFilter() {
            loadAll();
            vm.filter = [{
                date: null,
                origin: null,
                category: null,
                priority: null,
                isRead: null
            }];
        }

        function filterPortalNotifications() {
            if (vm.filter.date == null && vm.filter.origin == null && vm.filter.category == null && vm.filter.priority == null && vm.filter.isRead == null) {
                return vm.clearFilter();
            }

            var filterRequest = {};
            filterRequest.date = vm.filter.date != null ? vm.filter.date : null;
            filterRequest.origin = vm.filter.origin != null ? vm.filter.origin : null;
            filterRequest.category = vm.filter.category != null ? vm.filter.category : null;
            filterRequest.priority = vm.filter.priority != null ? vm.filter.priority : null;
            filterRequest.isRead = vm.filter.isRead != null ? vm.filter.isRead : null;

            $http.post('api/portalNotifications/searchFilter', filterRequest).then(function (result) {
                if (result.status === 200) {
                    vm.portalNotifications = result.data;
                    vm.links = ParseLinks.parse(result.headers('link'));
                    vm.totalItems = result.headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                } else {
                    Notification.error({message: $translate.instant(result.headers("X-pharmaPortalApp-alert")), delay: ''});
                }
            });
        }

        loadAll();

        $scope.saveUser = function () {
            User.update(vm.user);
        };

        function loadAll() {
            Principal.identity().then(function (account) {
                if (account) {
                    User.get({login: account.login}, function (result) {
                        vm.user = result;
                    });
                }
            });
            PortalNotification.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.portalNotifications = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                Notification.error({message: $translate.instant("error.data.message"), delay: ''});
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
