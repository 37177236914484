(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaReceptionDetailController', PharmaReceptionDetailController);

    PharmaReceptionDetailController.$inject = ['Notification', 'blockUI', '$scope', '$state', '$filter', '$http', '$window', '$translate', '$uibModal', 'ParseLinks', 'PharmaReception', 'Upload', 'entity', 'PortalSettings', 'ModuleSettings'];

    function PharmaReceptionDetailController(Notification, blockUI, $scope, $state, $filter, $http, $window, $translate, $uibModal, ParseLinks, PharmaReception, Upload, entity, PortalSettings, ModuleSettings) {
        var vm = this;

        vm.portalSettings = PortalSettings.query();
        vm.pharmaReception = entity;
        vm.working = false;
        vm.pharmaReception.date = new Date(vm.pharmaReception.date);

        vm.loadAll = loadAll;
        vm.submitPharmaReception = submitPharmaReception;
        vm.save = save;
        vm.backToReceptions = backToReceptions;
        vm.togglePharmaReceptionLineEditMode = togglePharmaReceptionLineEditMode;
        vm.onBlurFromLot = onBlurFromLot;
        vm.onBlurFromReceivedQuantity = onBlurFromReceivedQuantity;
        vm.onBlurFromInvoicedQuantity = onBlurFromInvoicedQuantity;
        vm.onBlurFromBonusQuantity = onBlurFromBonusQuantity;
        vm.getTemplate = getTemplate;
        vm.uploadFile = uploadFile;
        vm.exportToExcel = exportToExcel;
        vm.exportToLogitoolsExcel = exportToLogitoolsExcel;
        vm.updateTotalizers = updateTotalizers;

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.receptionsModule) {
                    $state.go('accessdenied');
                } else {
                    $http.get("api/common/_searchWarehousesForSelect").then(function (result) {
                        vm.warehouses = result.data;
                    });
                    $http.get("api/common/_searchSuppliersForSelect").then(function (result) {
                        vm.suppliers = result.data;
                    });
                    $http.get("api/common/_searchOrderTypesForSelect").then(function (result) {
                        vm.orderTypes = result.data;
                    });
                    $http.get("api/common/_searchQualityStatusesForSelect").then(function (result) {
                        vm.qualityStatuses = [{
                            "code": '',
                            "description": ''
                        }].concat(result.data);
                    });
                }
            });
        }

        loadAll();

        function submitPharmaReception() {
            if (isDataValidForSubmission()) {
                PharmaReception.save(vm.pharmaReception, validateVatNumber, onSaveError);
            }
        }

        function isDataValidForSubmission() {
            var errors = [];
            if (angular.isDefined(vm.pharmaReception.pharmaReceptionLines) && vm.pharmaReception.pharmaReceptionLines.length > 0) {
                for (var i = 0; i < vm.pharmaReception.pharmaReceptionLines.length; i++) {
                    var value = vm.pharmaReception.pharmaReceptionLines[i];
                    var receivedQuantity = (value.receivedQuantity != 'null' && angular.isNumber(value.receivedQuantity) && !isNaN(value.receivedQuantity) ? value.receivedQuantity : 0);
                    var invoicedQuantity = (value.invoicedQuantity != 'null' && angular.isNumber(value.invoicedQuantity) && !isNaN(value.invoicedQuantity) ? value.invoicedQuantity : 0);
                    var bonusQuantity = (value.bonusQuantity != 'null' && angular.isNumber(value.bonusQuantity) && !isNaN(value.bonusQuantity) ? value.bonusQuantity : 0);
                    // negative quantities
                    if (receivedQuantity < 0 || invoicedQuantity < 0 || bonusQuantity < 0) {
                        errors.push($translate.instant('pharmaPortalApp.pharmaReception.message.error.negativeQuantities', {cnp: value.cnp}));
                    }
                    var unitPrice = (value.unitPrice != 'null' && angular.isNumber(value.unitPrice) && !isNaN(value.unitPrice)) ? value.unitPrice : 0;
                    // negative price
                    if (unitPrice < 0) {
                        errors.push($translate.instant('pharmaPortalApp.pharmaReception.message.error.negativeUnitPrice', {cnp: value.cnp}));
                    }
                    // quantity without unit price
                    if (!(angular.isDefined(vm.portalSettings.receptionDefineNoteCreation) && vm.portalSettings.receptionDefineNoteCreation == true) && invoicedQuantity != 0 && unitPrice == 0) {
                        errors.push($translate.instant('pharmaPortalApp.pharmaReception.message.error.quantityWithoutUnitPrice', {cnp: value.cnp}));
                    }
                }
                if (errors.length > 0) {
                    var message = '';
                    for (var i = 0; i < errors.length; i++) {
                        if (i > 0) {
                            message += '<br>';
                        }
                        message += errors[i];
                    }
                    Notification.error({
                        message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.submission') + '<br>' + message,
                        delay: ''
                    });
                    return false;
                }
                return true;
            } else {
                Notification.error({
                    message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.submission') + $translate.instant('pharmaPortalApp.pharmaReception.message.error.noLines'),
                    delay: ''
                });
                return false;
            }
        }

        function validateVatNumber() {
            vm.working = false;
            if (vm.pharmaReception.vatValidated == vm.pharmaReception.supplierNif) {
                onSaveSuccessFromSubmit();
            } else {
                vm.updateTotalizers();
                $uibModal.open({
                    templateUrl: 'app/entities/pharma-reception/pharma-reception-validate-vat-number-dialog.html',
                    controller: 'PharmaReceptionValidateVatNumberController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PharmaReception', function (PharmaReception) {
                            return PharmaReception.get({
                                id: vm.pharmaReception.id
                            });
                        }]
                    }
                }).result.then(function () {
                    onSaveSuccessFromSubmit();
                });
            }
        }

        function onSaveSuccessFromSubmit() {
            vm.working = false;
            vm.updateTotalizers();
            $uibModal.open({
                templateUrl: 'app/entities/pharma-reception/pharma-reception-submit-dialog.html',
                controller: 'PharmaReceptionSubmitController',
                controllerAs: 'vm',
                size: vm.portalSettings.exportPurchaseInvoice ? 'lg modal-exl' : 'md',
                resolve: {
                    pharmaReception: ['PharmaReception', function (PharmaReception) {
                        return PharmaReception.get({
                            id: vm.pharmaReception.id
                        }).$promise;
                    }]
                }
            }).result.then(function () {
                // success callback implemented on own controller
            }, function () {
                $state.go('pharma-reception-detail', {
                    id: vm.pharmaReception.id
                }, {
                    reload: true
                });
            });
        }

        function save() {
            vm.working = true;
            if (vm.pharmaReception.date) {
                vm.pharmaReception.date.setHours(12); // trying to fix some timezone issues
            }

            var curSupplier = $filter("filter")(vm.suppliers, {code: (vm.pharmaReception.supplier != null ? vm.pharmaReception.supplier : "")});
            if (angular.isDefined(curSupplier) && angular.isDefined(curSupplier[0])) {
                vm.pharmaReception.supplierNif = curSupplier[0].nif;
            }

            PharmaReception.save(vm.pharmaReception, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.working = false;
            vm.updateTotalizers();
            $state.go("pharma-reception-detail", {
                id: result.id
            });
        }

        function onSaveError(result) {
            vm.working = false;
            if (result.data.success) {
                Notification.success({
                    message: result.data.message,
                    delay: 5000
                });
            } else {
                Notification.error({
                    message: result.data.message,
                    delay: ''
                });
            }
        }

        function backToReceptions() {
            $state.go("pharma-reception");
        }

        function togglePharmaReceptionLineEditMode(pharmaReceptionLine) {
            if (angular.isDefined(pharmaReceptionLine.editMode) && pharmaReceptionLine.editMode == true) {
                pharmaReceptionLine.editMode = false;
            } else {
                pharmaReceptionLine.editMode = true;
            }
            save();
        }

        function onBlurFromLot(pharmaReceptionLine) {
            if (angular.isDefined(pharmaReceptionLine.lot) && pharmaReceptionLine.lot != null) {
                pharmaReceptionLine.lot = pharmaReceptionLine.lot.toUpperCase();
            }
            save();
        }

        function onBlurFromReceivedQuantity(pharmaReceptionLine) {
            // check valid quantity
            if (pharmaReceptionLine.receivedQuantity == null) {
                pharmaReceptionLine.receivedQuantity = pharmaReceptionLine.oldReceivedQuantity;
                return;
            }
            if (pharmaReceptionLine.receivedQuantity < 0) {
                pharmaReceptionLine.errorOnReceivedQuantity = true;
            } else {
                pharmaReceptionLine.errorOnReceivedQuantity = false;
            }
            pharmaReceptionLine.oldReceivedQuantity = pharmaReceptionLine.receivedQuantity;
            save();
        }

        function onBlurFromInvoicedQuantity(pharmaReceptionLine) {
            if (!angular.isDefined(pharmaReceptionLine.invoicedQuantity) || pharmaReceptionLine.invoicedQuantity == null) {
                pharmaReceptionLine.invoicedQuantity = pharmaReceptionLine.receivedQuantity - (angular.isDefined(pharmaReceptionLine.bonusQuantity) ? pharmaReceptionLine.bonusQuantity : 0);
            }
            // check valid quantity
            if (pharmaReceptionLine.invoicedQuantity < 0) {
                pharmaReceptionLine.errorOnInvoicedQuantity = true;
            } else {
                pharmaReceptionLine.errorOnInvoicedQuantity = false;
            }
            save();
        }

        function onBlurFromBonusQuantity(pharmaReceptionLine) {
            // check valid quantity
            if (pharmaReceptionLine.bonusQuantity < 0) {
                pharmaReceptionLine.errorOnBonusQuantity = true;
            } else {
                pharmaReceptionLine.errorOnBonusQuantity = false;
            }
            save();
        }

        function getTemplate() {
            $window.open("api/pharma-reception/template");
        }

        function uploadFile(file) {
            vm.working = true;
            save();
            blockUI.start($translate.instant('pharmaPortalApp.pharmaReception.message.uploadingTemplate'));
            file.upload = Upload.upload({
                url: 'api/pharma-reception/upload',
                fields: {
                    pharmaReceptionHeaderId: vm.pharmaReception.id
                },
                file: file
            }).then(function (response) {
                file.result = response.data;
                if (response.data.success) {
                    Notification.success({
                        message: response.data.message,
                        delay: 5000
                    });
                } else {
                    Notification.error({
                        message: response.data.message,
                        delay: ''
                    });
                }

                $state.go("pharma-reception-detail", {id: vm.pharmaReception.id}, {reload: true});
                vm.working = false;
                blockUI.stop();
            }, function (response) {
                if (response.status == 200) {
                    if (response.data.success) {
                        Notification.success({
                            message: response.data.message,
                            delay: 5000
                        });
                    } else {
                        Notification.error({
                            message: response.data.message,
                            delay: ''
                        });
                    }
                } else {
                    Notification.error({
                        message: response.status + ': ' + response.data,
                        delay: ''
                    });
                }

                $state.go("pharma-reception-detail", {id: vm.pharmaReception.id}, {reload: true});
                vm.working = false;
                blockUI.stop();
            }, function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }

        function exportToExcel() {
            save();
            $http.get("api/pharma-reception/" + vm.pharmaReception.id + "/toExcel", {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                saveAs(blob, "Recepção_" + vm.pharmaReception.id + "_" + dateTime + ".xlsx", true);
                Notification.success({
                    message: "Recepção exportada com sucesso",
                    delay: 5000
                });
            }, function errorCallback(response) {
                Notification.error({
                    message: "Erro ao exportar recepção",
                    delay: ''
                });
            });
        }

        function exportToLogitoolsExcel() {
            if (isDataValidForSubmission()) {
                save();
                $http.get("api/pharma-reception/" + vm.pharmaReception.id + "/toLogitoolsExcel", {
                    responseType: "arraybuffer"
                }).then(function successCallback(response) {
                    var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                    var blob = new Blob([response.data], {
                        type: "application/vnd.ms-excel"
                    });
                    saveAs(blob, "Recepção_" + vm.pharmaReception.id + "_" + dateTime + ".xlsx", true);
                    Notification.success({
                        message: "Recepção exportada com sucesso",
                        delay: 5000
                    });
                }, function errorCallback(response) {
                    Notification.error({
                        message: "Erro ao exportar recepção",
                        delay: ''
                    });
                });
            } else {
                $("html, body").animate({scrollTop: 0});
            }
        }

        function updateTotalizers() {
            vm.totalizer = {
                receivedQuantity: 0,
                invoicedPlusBonusQuantity: 0,
                differences: 0,
                incidenceBase: 0,
                discount: 0,
                vatValue: 0,
                total: 0,
                vatTable: []
            };
            angular.forEach(vm.pharmaReception.pharmaReceptionLines, function (value, key) {
                var receivedQuantity = (value.receivedQuantity != null && angular.isNumber(value.receivedQuantity) && !isNaN(value.receivedQuantity) ? value.receivedQuantity : 0);
                var invoicedQuantity = (value.invoicedQuantity != null && angular.isNumber(value.invoicedQuantity) && !isNaN(value.invoicedQuantity) ? value.invoicedQuantity : 0);
                var bonusQuantity = (value.bonusQuantity != null && angular.isNumber(value.bonusQuantity) && !isNaN(value.bonusQuantity) ? value.bonusQuantity : 0);

                var unitPrice = (value.unitPrice != null && angular.isNumber(value.unitPrice) && !isNaN(value.unitPrice) ? value.unitPrice : 0);
                var lineTotalValue = ((invoicedQuantity - bonusQuantity) * unitPrice).toFixed(2);

                var discount1 = (value.discount1 != null && angular.isNumber(value.discount1) && !isNaN(value.discount1) ? value.discount1 : 0);
                var discount2 = (value.discount2 != null && angular.isNumber(value.discount2) && !isNaN(value.discount2) ? value.discount2 : 0);
                var unitPriceWithDiscount = (unitPrice * (1 - (discount1 / 100)) * (1 - (discount2 / 100))).toFixed(3);
                var lineTotalValueWithDiscount = ((invoicedQuantity - bonusQuantity) * unitPriceWithDiscount).toFixed(2);

                vm.totalizer.receivedQuantity += receivedQuantity * 1;
                vm.totalizer.invoicedPlusBonusQuantity += invoicedQuantity * 1 + bonusQuantity * 1;
                vm.totalizer.differences += ((receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount).toFixed(2);

                var vatRateTotalizer = $filter("filter")(vm.totalizer.vatTable, {vatRate: (value.vatRate != null ? value.vatRate : 0)});
                if (!angular.isDefined(vatRateTotalizer[0])) {
                    vatRateTotalizer[0] = {
                        vatRate: (value.vatRate != null ? value.vatRate : 0),
                        incidenceBase: 0,
                        discount: 0,
                        vatValue: 0,
                        total: 0,
                        differences: 0
                    };
                    vm.totalizer.vatTable.push(vatRateTotalizer[0]);
                }

                vatRateTotalizer[0].differences += ((receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount).toFixed(2);

                var incidenceBase = invoicedQuantity * unitPrice.toFixed(2);
                incidenceBase = Number(incidenceBase.toFixed(2));
                vatRateTotalizer[0].incidenceBase += incidenceBase;
                vatRateTotalizer[0].incidenceBase = Number(vatRateTotalizer[0].incidenceBase.toFixed(3));
                vm.totalizer.incidenceBase += incidenceBase;
                vm.totalizer.incidenceBase = Number(vm.totalizer.incidenceBase.toFixed(3));

                var discount = lineTotalValue - lineTotalValueWithDiscount;
                vatRateTotalizer[0].discount -= discount;
                vatRateTotalizer[0].discount  = Number(vatRateTotalizer[0].discount.toFixed(2));
                vm.totalizer.discount -= discount;
                vm.totalizer.discount = Number(vm.totalizer.discount.toFixed(3));

                var vatValue = (incidenceBase - discount) * (value.vatRate != null && angular.isNumber(value.vatRate) && !isNaN(value.vatRate) ? value.vatRate / 100 : 0);
                vatRateTotalizer[0].vatValue += Number(vatValue.toFixed(3));
                vm.totalizer.vatValue += vatValue;
                vm.totalizer.vatValue = Number(vm.totalizer.vatValue .toFixed(3));

                var total = incidenceBase - discount + vatValue;
                total = Number(total.toFixed(2));
                vatRateTotalizer[0].total += total;
                vatRateTotalizer[0].total  = Number(vatRateTotalizer[0].total.toFixed(2));
                vm.totalizer.total += total;
                vm.totalizer.total = Number(vm.totalizer.total.toFixed(2));
            });

            angular.forEach(vm.pharmaReception.pharmaReceptionLines, function (value, key) {
                if (value.serviceLine) {
                    // TOTALIZER BY VAT
                    var vatRateTotalizer = $filter("filter")(vm.totalizer.vatTable, {vatRate: (value.vatRate != null ? value.vatRate : 0)});
                    var vatValue = value.unitPrice * (value.vatRate != null && angular.isNumber(value.vatRate) && !isNaN(value.vatRate) ? value.vatRate / 100 : 0);
                    vatValue = Number(vatValue.toFixed(2));
                    vatRateTotalizer[0].vatValue += vatValue;
                    vatRateTotalizer[0].incidenceBase += value.unitPrice;
                    vatRateTotalizer[0].total += value.unitPrice + vatValue;
                    // TOTALIZER
                    vm.totalizer.vatValue += vatValue;
                    vm.totalizer.incidenceBase += value.unitPrice;
                    vm.totalizer.total += value.unitPrice + vatValue;
                }
            });
        }

        vm.updateTotalizers();

        // watch to implement auto-save
        $scope.$watch('[vm.pharmaReception.warehouse,vm.pharmaReception.supplier,vm.pharmaReception.orderType]', function () {
            save()
        }, true);

        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };

        vm.datePopup = {opened: false};
        vm.openDatePopup = function () {
            vm.datePopup.opened = true;
        };
        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        $scope.sorterFunc = function (vatRateTotalizer) {
            return parseInt(vatRateTotalizer.vatRate);
        };
    }
})();
