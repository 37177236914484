(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionDeleteController', PharmaTransfReceptionDeleteController);

	PharmaTransfReceptionDeleteController.$inject = [ 'Notification', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'pharmaTransfReception', 'PharmaTransfReception' ];

	function PharmaTransfReceptionDeleteController(Notification, $uibModalInstance, $translate, $state, $http, $filter, pharmaTransfReception, PharmaTransfReception) {
		var vm = this;

		vm.clear = clear;
		vm.confirmPharmaTransfReceptionDelete = confirmPharmaTransfReceptionDelete;
		vm.pharmaTransfReception = pharmaTransfReception;
		
		vm.loadAll = loadAll;
		vm.getWarehouseFullDescription = getWarehouseFullDescription;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function onDeleteSuccess(result) {
			$uibModalInstance.close(true);
			Notification.success({message: $translate.instant('pharmaPortalApp.pharmaTransfReception.message.pharmaTransfReceptionDeleted'), delay: 5000});
		}

		function onDeleteError(result) {
			$uibModalInstance.close(true);
			
			if(result.data.success) {
				Notification.success({message: result.data.message, delay: 5000});
			} else {
				Notification.error({message: result.data.message, delay: ''});
			}
		}

		function confirmPharmaTransfReceptionDelete() {
			PharmaTransfReception.delete({id: pharmaTransfReception.id}, onDeleteSuccess, onDeleteError);
		}

		function loadAll() {
			$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
				vm.warehouses = result.data;
			});
		}
		vm.loadAll();

		function getWarehouseFullDescription(warehouseCode) {
			if (angular.isDefined(vm.warehouses)) {
				var warehouse = $filter('filter')(vm.warehouses, {
					code : warehouseCode
				})[0];
				return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
			} else {
				return warehouseCode;
			}
		}
	}
})();
