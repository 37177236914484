(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionNewController', PharmaTransfReceptionNewController);

	PharmaTransfReceptionNewController.$inject = [ 'Notification', '$scope', '$state', '$http', '$filter', 'PharmaTransfReception', 'Principal', 'ModuleSettings', 'Company', '$rootScope' ];

	function PharmaTransfReceptionNewController(Notification, $scope, $state, $http, $filter, PharmaTransfReception, Principal, ModuleSettings, Company, $rootScope) {
		var vm = this;

        $scope.sortType = "autoSort";
        $scope.sortReverse = true;

		vm.isSaving = false;
		vm.destinationWarehouse = null;
		vm.currentPharmaTransfReceptionHeader = [];

		vm.loadAll = loadAll;
		vm.getWarehouseFullDescription = getWarehouseFullDescription;
		vm.getTransferTypeFullDescription = getTransferTypeFullDescription;
		vm.getInvoiceTypeFullDescription = getInvoiceTypeFullDescription;
		vm.getClientFullDescription = getClientFullDescription;
		vm.save = save;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.receptionsWarehouseTransfModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						account ? vm.account = account : vm.account = null;
					});

					$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
						vm.warehouses = result.data;
					});

					$http.get("api/common/_getTransferTypeDescription").then(function(result) {
						vm.transferTypes = result.data;
					});

					$http.get("api/common/_searchInvoiceTypesForSelect").then(function(result) {
						vm.invoiceTypes = result.data;
					});

					$http.get("api/companies/withWarehouse").then(function(result) {
						vm.clients = result.data;
					});
				}
			});
		}
		vm.loadAll();

		$scope.getReceptionsFromDestinationWarehouse = function($item, $model) {
			if ($item) {
                Company.isInactive({"id":$item.code}, function (result) {
                    if (result.regDate != null) {
                        result.regDate = result.regDate.substring(6, 8) + "-" + result.regDate.substring(4, 6) + "-" + result.regDate.substring(0, 4);
                        result.regTime = result.regTime.substring(0, 2) + ":" + result.regTime.substring(2, 4) + ":" + result.regTime.substring(4, 6);
                        result.company = $item.code + ' - ' + $item.description;
                        $rootScope.message = result;
                        vm.destinationWarehouse = null;
                        $state.go("pharma-transf-reception.inactive");
                    } else {
                        $http.post("api/common/_searchErpUniqueWarehouseTransfers", $item.code).then(function (result) {
                            vm.currentPharmaTransfReceptionHeader = result.data;
                        });
                    }
                });
			}
		};

		function getWarehouseFullDescription(warehouseCode) {
			if (angular.isDefined(vm.warehouses) && warehouseCode != '') {
				var warehouse = $filter('filter')(vm.warehouses, {
					code : warehouseCode
				})[0];
				return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
			} else {
				return warehouseCode;
			}
		}

		function getTransferTypeFullDescription(transferTypeCode) {
			if (angular.isDefined(vm.transferTypes) && transferTypeCode != '') {
				var transferType = $filter('filter')(vm.transferTypes, {
					code : transferTypeCode
				})[0];
				return transferTypeCode + (transferType != null ? ' - ' + transferType.description : '');
			} else {
				return transferTypeCode;
			}
		}

		function getInvoiceTypeFullDescription(invoiceTypeCode) {
			if (angular.isDefined(vm.invoiceTypes) && invoiceTypeCode != '') {
				var invoiceType = $filter('filter')(vm.invoiceTypes, {
					code : invoiceTypeCode
				})[0];
				return invoiceTypeCode + (invoiceType != null ? ' - ' + invoiceType.description : '');
			} else {
				return invoiceTypeCode;
			}
		}

		function getClientFullDescription(clientPE) {
			var client = clientPE.substring(0, clientPE.length - 2);
			var deliveryPoint = clientPE.substring(clientPE.length - 2, clientPE.length);

			if (angular.isDefined(vm.clients) && clientPE && clientPE != '' && clientPE.length > 2) {
				var clientDesc = $filter('filter')(vm.clients, {
					client : parseInt(clientPE.substring(0, clientPE.length - 2)),
					deliveryPoint : parseInt(clientPE.substring(clientPE.length - 2, clientPE.length))
				})[0];
				return client + '(' + deliveryPoint + ')' + (clientDesc != null ? ' - ' + clientDesc.naming : '');
			} else {
				return clientPE != "0" ? client + '(' + deliveryPoint + ')' : "";
			}
		}

		function save(key) {
			vm.isSaving = true;
			$http.post("api/common/_createWarehouseTransferFromErpByKey", {
				destinationWarehouse : vm.destinationWarehouse,
				key : key
			}).then(function(result) {
				vm.isSaving = false;
				$state.go("pharma-transf-reception-edit", {
					id : result.data.id
				});
			}, function(result) {
				vm.isSaving = false;
				if (result.status == 400) {
					Notification.error({ message : "Esta guia está em utilização no ERP", delay : '' });
				}
			});
		}

		vm.datePopup = {
			opened : false
		};

		vm.openDatePopup = function() {
			vm.datePopup.opened = true;
		};

		vm.dateOptions = {
			formatYear : 'yyyy',
			startingDay : 1,
			showWeeks : false
		};

		$scope.floatTheadOptions = {
			position : 'absolute',
			responsiveContainer : function($table) {
				return $table.closest(".table-responsive");
			}
		};
	}
})();
