(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PurchaseAlertController', PurchaseAlertController);

	PurchaseAlertController.$inject = [ '$uibModalInstance', '$state', 'Principal', 'ModuleSettings' ];

	function PurchaseAlertController($uibModalInstance, $state, Principal, ModuleSettings) {
		var vm = this;

		vm.clear = clear;
		vm.confirmAlert = confirmAlert;
		vm.loadAll = loadAll;

		function clear() {
			$uibModalInstance.dismiss("cancel");
		}

		function confirmAlert() {
			$uibModalInstance.close(true);
		}

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.purchasesModule) {
					$state.go('accessdenied');
				} else {
					Principal.identity().then(function(account) {
						if (!(account && account.company)) {
							$state.go('accessdenied');
						}
					});
				}
			});
		}
		loadAll();
	}
})();