(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaReceptionSubmitController', PharmaReceptionSubmitController);

    PharmaReceptionSubmitController.$inject = ['Notification', 'blockUI', '$uibModalInstance', '$translate', '$scope', '$state', '$http', '$filter', '$timeout', 'pharmaReception',
        'PharmaReception', 'PortalSettings', 'ModuleSettings', 'PharmaReceptionServiceByVat', 'PharmaReceptionInvoice', 'PharmaReceptionVatLine'];

    function PharmaReceptionSubmitController(Notification, blockUI, $uibModalInstance, $translate, $scope, $state, $http, $filter, $timeout, pharmaReception,
                                             PharmaReception, PortalSettings, ModuleSettings, PharmaReceptionServiceByVat, PharmaReceptionInvoice, PharmaReceptionVatLine) {
        var vm = this;

        vm.pharmaReceptionServiceByVat = PharmaReceptionServiceByVat.query();
        vm.portalSettings = PortalSettings.query();

        vm.noteDetails = {};

        vm.save = save;
        vm.loadAll = loadAll;
        vm.clear = clear;
        vm.getWarehouseFullDescription = getWarehouseFullDescription;
        vm.getSupplierFullDescription = getSupplierFullDescription;

        vm.confirmPharmaReceptionSubmit = confirmPharmaReceptionSubmit;
        vm.confirmReceptionPurchaseInvoiceSubmit = confirmReceptionPurchaseInvoiceSubmit;
        vm.checkIfNoteClosed = checkIfNoteClosed;
        vm.onSubmitInvoiceSuccess = onSubmitInvoiceSuccess;
        vm.spliceVatSummary = spliceVatSummary;
        vm.updateTotalizers = updateTotalizers;
        vm.pharmaReception = pharmaReception;
        vm.createReceptionNote = true;

        function save() {
            PharmaReception.save(vm.pharmaReception, autoSaveOk, autoSaveNok);
        }

		function autoSaveOk(result) {}
		function autoSaveNok(err) {}

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.receptionsModule) {
                    $state.go('accessdenied');
                } else {
					$http.get("api/common/_searchWarehousesForSelect").then(function(result) { vm.warehouses = result.data; });
					$http.get("api/common/_searchSuppliersForSelect").then(function(result) { vm.suppliers = result.data; });
					$http.get("api/common/_searchPurchaseTypesForSelect").then(function(result) { vm.purchaseTypes = result.data; });
                    if (vm.pharmaReception.pharmaReceptionInvoice == null) {
                        vm.pharmaReception.pharmaReceptionInvoice = {};
                        vm.pharmaReception.pharmaReceptionInvoice.invoiceAmount = 0;
                        vm.pharmaReception.pharmaReceptionInvoice.paymentDiscount = 0;
                        if (vm.pharmaReception.pharmaReceptionInvoice.invoiceDate != null) {
                            vm.pharmaReception.pharmaReceptionInvoice.invoiceDate = new Date(vm.pharmaReception.pharmaReceptionInvoice.invoiceDate);
                        }
                        $http.post("api/common/_getDefaultOrderTypeForUser", vm.pharmaReception.supplier).then(function (result) {
                            vm.pharmaReception.pharmaReceptionInvoice.invoicePurchaseType = result.data;
                        });
                    } else {
						if(vm.pharmaReception.pharmaReceptionInvoice.invoiceAmount == null) { vm.pharmaReception.pharmaReceptionInvoice.invoiceAmount = 0; }
						if(vm.pharmaReception.pharmaReceptionInvoice.paymentDiscount == null) { vm.pharmaReception.pharmaReceptionInvoice.paymentDiscount = 0; }

                        if (vm.pharmaReception.pharmaReceptionInvoice.invoiceDate != null) {
                            vm.pharmaReception.pharmaReceptionInvoice.invoiceDate = new Date(vm.pharmaReception.pharmaReceptionInvoice.invoiceDate);
                        }
                        if (vm.pharmaReception.pharmaReceptionInvoice.invoicePurchaseType == null) {
                            $http.post("api/common/_getDefaultOrderTypeForUser", vm.pharmaReception.supplier).then(function (result) {
                                vm.pharmaReception.pharmaReceptionInvoice.invoicePurchaseType = result.data;
                            });
                        }
                    }
                }
            });
        }
        vm.loadAll();

        $scope.saveInvoicePurchaseType = function ($item, $model) {
            vm.pharmaReception.pharmaReceptionInvoice.invoicePurchaseType = $model;
            save();
        }

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        // TODO this should be done atomically; done this way due to the lack of time
        function getWarehouseFullDescription() {
            if (!angular.isDefined(vm.pharmaReception.warehouseFullDescription)) {
                if (angular.isDefined(vm.warehouses)) {
                    var warehouse = $filter('filter')(vm.warehouses, {
                        code: vm.pharmaReception.warehouse
                    })[0];
                    vm.pharmaReception.warehouseFullDescription = vm.pharmaReception.warehouse + (warehouse != null ? ' - ' + warehouse.description : '');
                } else {
                    return vm.pharmaReception.warehouse;
                }
            }
            return vm.pharmaReception.warehouseFullDescription;
        }

        // TODO this should be done atomically; done this way due to the lack of time
        function getSupplierFullDescription() {
            if (!angular.isDefined(vm.pharmaReception.supplierFullDescription)) {
                if (angular.isDefined(vm.suppliers)) {
                    var supplier = $filter('filter')(vm.suppliers, {
                        code: vm.pharmaReception.supplier
                    })[0];
                    vm.pharmaReception.supplierFullDescription = vm.pharmaReception.supplier + (supplier != null ? ' - ' + supplier.description : '');
                } else {
                    return vm.pharmaReception.supplier;
                }
            }
            return vm.pharmaReception.supplierFullDescription;
        }

        function confirmPharmaReceptionSubmit() {
            blockUI.start($translate.instant('pharmaPortalApp.pharmaReception.message.submittingPharmaReception'));

            PharmaReception.submit({
                id: pharmaReception.id,
                createReceptionNote: vm.createReceptionNote
            }, onSubmitSuccess, onSubmitError);
        }

        function onSubmitSuccess(result) {
            blockUI.stop();
            $uibModalInstance.close(true);

            if (result.success) {
                Notification.success({message: result.message, delay: 5000});
                if (vm.totalizer.differencesAmount != 0) {
                    $state.go("pharma-reception.confirmDifferences", {id: vm.pharmaReception.id}, {reload: true});
                } else {
                    $state.go("pharma-reception", {}, {reload: true});
                }
            } else {
                Notification.error({message: result.message, delay: ''});
                $state.go("pharma-reception-detail", {id: vm.pharmaReception.id}, {reload: true});
            }
        }

        function onSubmitError(result) {
            blockUI.stop();
            $uibModalInstance.close(true);

            if (result.success) {
                Notification.success({message: result.message, delay: 5000});
            } else {
                Notification.error({message: result.message, delay: ''});
            }
        }

        function validateInvoiceForm() {
            var totalValidation = 0;
            var vatValidation = 0;

            angular.forEach(vm.totalizer.vatTable, function (value, key) {
                totalValidation = totalValidation + value.vatAmount + value.productsAmount - value.discountProductsAmount + value.servicesAmount - value.discountServicesAmount;
                vatValidation += value.vatAmount;
            });

            if (Number(vm.pharmaReception.pharmaReceptionInvoice.invoiceAmount.toFixed(2)) < Number(vatValidation.toFixed(2))) {
                Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.highVat'), delay: ''});
                return false;
            } else if ((Number(vm.pharmaReception.pharmaReceptionInvoice.invoiceAmount) + Number(vm.pharmaReception.pharmaReceptionInvoice.paymentDiscount.toFixed(2))) != Number(totalValidation.toFixed(2))) {
                Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.diffTotals'), delay: ''});
                return false;
            }
            return true;
        }

        function confirmReceptionPurchaseInvoiceSubmit() {
            // PASS LINES TO TOTALIZER AGAIN
            vm.totalizer.vatTable = [];
            angular.forEach(vm.pharmaReception.pharmaReceptionInvoice.pharmaReceptionVatLines, function (value, key) {
                var vatRateTotalizer = new PharmaReceptionVatLine();
                vatRateTotalizer.vatRate = value.vatRate;
                vatRateTotalizer.vatCode = value.vatCode;
                vatRateTotalizer.vatAmount = value.vatAmount != null ? value.vatAmount : 0;
                vatRateTotalizer.productsAmount = value.productsAmount != null ? value.productsAmount : 0;
                vatRateTotalizer.discountProductsAmount = value.discountProductsAmount != null ? value.discountProductsAmount : 0;
                vatRateTotalizer.servicesAmount = value.servicesAmount != null ? value.servicesAmount : 0;
                vatRateTotalizer.discountServicesAmount = value.discountServicesAmount != null ? value.discountServicesAmount : 0;
                vatRateTotalizer.differencesAmount = value.differencesAmount != null ? value.differencesAmount : 0;

                vm.totalizer.vatTable.push(vatRateTotalizer);
            });

            if (validateInvoiceForm()) {
                // GENERATE NOTE AND INVOICE
                if (pharmaReception.status === 'CREATED') {
                    blockUI.start($translate.instant('pharmaPortalApp.pharmaReception.message.submittingPharmaReception'));
                    PharmaReception.submit({
                        id: pharmaReception.id,
                        createReceptionNote: vm.createReceptionNote
                    }, onSuccessSubmitInvoice, onErrorSubmitInvoice);
                } else {
                    // GENERATE INVOICE ONLY
                    vm.checkIfNoteClosed(1);
                }
            }
        }

        function onSuccessSubmitInvoice(result) {
            blockUI.stop();

            if (result.success) {
                vm.checkIfNoteClosed(1);
                Notification.success({message: result.message, delay: ''});
            } else {
                Notification.error({message: result.message, delay: ''});
            }
        }

        function onErrorSubmitInvoice(result) {
            blockUI.stop();
            $uibModalInstance.close(true);

            if (result.success) {
                Notification.success({message: result.message, delay: 5000});
            } else {
                Notification.error({message: result.message, delay: ''});
            }
        }

        function checkIfNoteClosed(iteration) {
            var attemps = 5;
            var attempsInterval = 1000;
            if (vm.portalSettings.attemptsBeforeTimeout != null) {
                attemps = vm.portalSettings.attemptsBeforeTimeout;
            }

            if (vm.portalSettings.attemptsIntervalBeforeTimeout != null) {
                attempsInterval = vm.portalSettings.attemptsIntervalBeforeTimeout * 1000;
            }

            // CHECK IF NOTE EXISTS AND IS CLOSED IN QUANTITY SO GENERATE INVOICE ONLY
            vm.iterator = angular.isDefined(iteration) ? iteration : 0;

            vm.noteStatus = "";
            blockUI.start($translate.instant('pharmaPortalApp.pharmaReception.message.checkIfNoteIsClosed'));

            $http.get("api/common/checkIfNoteIsClosed/" + pharmaReception.id).then(function (result) {
                vm.noteStatus = result.data;
                vm.iterator++;

                if (vm.iterator <= attemps && vm.noteStatus !== "Q") {
                    $timeout(function () {
                        vm.checkIfNoteClosed(vm.iterator);
                    }, attempsInterval);
                } else {
                    if (vm.noteStatus === "Q") {
                        vm.iterator = attemps;
                        if (pharmaReception.status === "INVOICED") {
                            Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.invoiceExists'), delay: ''});
                        } else {
                            onSubmitInvoiceSuccess({success: true});
                        }
                    } else if (vm.iterator >= attemps) {
                        if (vm.noteStatus === "V") {
                            Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.noteClosedInValue'), delay: ''});
                        } else {
                            Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.noteTimeout'), delay: ''});
                        }
                    }
                    blockUI.reset();
                }
            });
        }

        function onSubmitInvoiceSuccess(result) {
            $http.get("api/pharma-reception/" + pharmaReception.id + "/orderVatSummary").then(function (requestResult) {
                vm.noteDetails = requestResult.data;

                blockUI.stop();
                if (vm.spliceVatSummary()) {
                    if (!result || result.success) {
                        if (result.message) {
                            Notification.success({message: result.message, delay: 5000});
                        }

                        blockUI.start($translate.instant('pharmaPortalApp.pharmaReception.message.submittingPharmaReceptionInvoice'));

                        vm.totalizer.id = pharmaReception.id;

                        PharmaReception.submitInvoice({
                            params: vm.totalizer
                        }, function (result) {
                            blockUI.stop();
                            vm.hasErrors = false;
                            if (result.createSupplierInvoice11Reply.replyCode !== 'OK') {
                                vm.hasErrors = true;
                                vm.errorMessage = result.createSupplierInvoice11Reply.replyDescription;
                                if (result.errors !== undefined && result.errors.length > 0) {
                                    angular.forEach(result.errors, function (error) {
                                        if (vm.errorMessage !== undefined && error.errorDescription !== undefined) {
                                            vm.errorMessage += '<br>' + error.errorDescription;
                                        }
                                    });
                                }
                                vm.msgStr = $translate.instant('pharmaPortalApp.pharmaReception.message.error.submittingPharmaReceptionInvoice', {errorDescription: vm.errorMessage});
                                var decodedMsg = angular.element('<textarea />').html(vm.msgStr).text();
                                Notification.error({message: decodedMsg, delay: ''});
                                $uibModalInstance.close(true);
                                $state.go("pharma-reception-detail", { id : vm.pharmaReception.id }, { reload : true });
                            }

                            if (!vm.hasErrors) {
                                Notification.success({message: $translate.instant('pharmaPortalApp.pharmaReception.message.pharmaReceptionInvoiceSubmited'), delay: ''});
                                $uibModalInstance.close(true);
                                $state.go("pharma-reception", {}, {reload: true});
                            }
                        }, onSubmitError);
                    } else {
                        Notification.error({message: result.message, delay: ''});
                    }
                }
            }, function (requestResult) {
                Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.noteVatList'), delay: ''});
            });
        }

        function spliceVatSummary() {
            var totals = {vatTable: [], diffTable: []};

            // GET ORDER VAT SUMMARY
            if (angular.isDefined(vm.noteDetails)) {
                angular.forEach(vm.noteDetails, function (value, key) {
//					var vatRateTotalizer = $filter("filter")(vm.totalizer.vatTable, {vatRate:(value.vatrate != null ? value.vatrate : 0), vatCode : value.vatcode});
                    var vatRateTotalizer = $filter("filter")(vm.totalizer.vatTable, {vatRate: (value.vatrate != null ? value.vatrate : 0)});

                    if (!angular.isDefined(vatRateTotalizer[0])) {
                        // vatcode: "BCL", vatrate: "6", vatamount: "1.944000000000", productamount: "36.000000", productdiscountamount: "3.600000"
                        vatRateTotalizer[0] = {
                            vatRate: value.vatrate,
                            vatCode: value.vatcode,
                            vatAmount: value.vatamount,
                            productsAmount: value.productamount,
                            productsDiscountAmount: value.productdiscountamount,
                            servicesAmount: 0,
                            servicesDiscountAmount: 0,
                            differencesAmount: 0
                        };
                    } else {
                        vatRateTotalizer[0].vatCode = value.vatcode;
                        vatRateTotalizer[0].differencesAmount = 0;
                    }
                    totals.vatTable.push(vatRateTotalizer[0]);
                });
            }

            // GET ORDER SERVICE SUMMARY
            angular.forEach(vm.pharmaReception.pharmaReceptionLines, function (value, key) {
                if (value.serviceLine) {
                    var vatRateTotalizer = $filter("filter")(vm.totalizer.vatTable, {vatRate: (value.vatRate != null ? value.vatRate : 0), vatCode: value.lot});

                    if (!angular.isDefined(vatRateTotalizer[0])) {
                        vatRateTotalizer[0] = {
                            vatRate: value.vatRate,
                            vatCode: value.lot,
                            vatAmount: 0,
                            productsAmount: 0,
                            productsDiscountAmount: 0,
                            servicesAmount: 0,
                            servicesDiscountAmount: 0,
                            differencesAmount: 0
                        };
                        totals.vatTable.push(vatRateTotalizer[0]);

                        var vatValue = value.unitPrice * (value.vatRate != null && angular.isNumber(value.vatRate) && !isNaN(value.vatRate) ? value.vatRate / 100 : 0);
                        vatRateTotalizer[0].servicesAmount += value.unitPrice;
                        vatRateTotalizer[0].vatAmount += vatValue;
                    }
                }
            });

            // CHECK DIFFERENCES AND CONVERT IT TO SERVICES IF NEEDED

            angular.forEach(vm.totalizer.vatTable, function (value, key) {
                if (value.differencesAmount !== 0) {
                    //PROCURA NOS SERVIÇOS DO SETTINGS POR BASE DE IVA
                    var serviceCodeByCurVat = $filter("filter")(vm.pharmaReceptionServiceByVat, {vatRate: value.vatRate});
                    if (!angular.isDefined(serviceCodeByCurVat[0])) {
                        Notification.error({message: $translate.instant('pharmaPortalApp.pharmaReception.message.error.diffVatService') + value.vatRate + " %", delay: ''});
                        return false;
                    } else {
                        //PROCURA NAS BASES DE IVA JÁ EXISTENTES NA FATURA POR VATCODE E VATRATE
                        var invoiceVats = $filter("filter")(totals.vatTable, {vatRate: serviceCodeByCurVat[0].vatRate, vatCode: serviceCodeByCurVat[0].vatCode});
                        if (!angular.isDefined(invoiceVats[0])) {
                            // CRIA NOVO
                            invoiceVats[0] = {
                                vatRate: serviceCodeByCurVat[0].vatRate,
                                vatCode: serviceCodeByCurVat[0].vatCode,
                                vatAmount: -1 * (value.differencesAmount * (Number(serviceCodeByCurVat[0].vatRate) / 100)),
                                productsAmount: 0,
                                productsDiscountAmount: 0,
                                servicesAmount: -1 * value.differencesAmount,
                                servicesDiscountAmount: 0
                            };
                            totals.vatTable.push(invoiceVats[0]);
                        } else {
                            // ADICIONA
                            invoiceVats[0].servicesAmount -= value.differencesAmount;
                            invoiceVats[0].vatAmount -= (value.differencesAmount * (Number(serviceCodeByCurVat[0].vatRate) / 100));
                        }

                        var diffLine = {
                            serviceCode: serviceCodeByCurVat[0].code,
                            vatCode: serviceCodeByCurVat[0].vatCode,
                            serviceAmount: -1 * value.differencesAmount,
                            discountAmount: 0,
                            discountPercentage: 0
                        }
                        totals.diffTable.push(diffLine);
                    }
                }
            });

            vm.totalizer.diffTable = totals.diffTable;
            vm.totalizer.vatTable = totals.vatTable;
            return true;
        }

        function updateTotalizers() {
            vm.totalizer = {differencesAmount: 0, vatTable: []};

            if (vm.pharmaReception.pharmaReceptionInvoice === null) {
                vm.pharmaReception.pharmaReceptionInvoice = {};
                vm.pharmaReception.pharmaReceptionInvoice.pharmaReceptionVatLines = [];
            }

            angular.forEach(vm.pharmaReception.pharmaReceptionLines, function (value, key) {

                var receivedQuantity = (value.receivedQuantity != null && angular.isNumber(value.receivedQuantity) && !isNaN(value.receivedQuantity) ? value.receivedQuantity : 0);
                var invoicedQuantity = (value.invoicedQuantity != null && angular.isNumber(value.invoicedQuantity) && !isNaN(value.invoicedQuantity) ? value.invoicedQuantity : 0);
                var bonusQuantity = (value.bonusQuantity != null && angular.isNumber(value.bonusQuantity) && !isNaN(value.bonusQuantity) ? value.bonusQuantity : 0);
                var unitPrice = (value.unitPrice != null && angular.isNumber(value.unitPrice) && !isNaN(value.unitPrice) ? value.unitPrice : 0);
                var discount1 = (value.discount1 != null && angular.isNumber(value.discount1) && !isNaN(value.discount1) ? value.discount1 : 0);
                var discount2 = (value.discount2 != null && angular.isNumber(value.discount2) && !isNaN(value.discount2) ? value.discount2 : 0);
                var unitPriceWithDiscount = unitPrice * (1 - (discount1 / 100)) * (1 - (discount2 / 100));

                var vatRateTotalizer = $filter("filter")(vm.pharmaReception.pharmaReceptionInvoice.pharmaReceptionVatLines, {vatRate: (value.vatRate != null ? value.vatRate : 0)});
                if (!angular.isDefined(vatRateTotalizer[0])) {
                    var vatRateTotalizer = new PharmaReceptionVatLine();
                    vatRateTotalizer.vatRate = value.vatRate;
                    vatRateTotalizer.vatCode = value.vatCode;
                    vatRateTotalizer.vatAmount = value.vatValue != null ? value.vatValue : 0;
                    vatRateTotalizer.productsAmount = value.productsAmount != null ? value.productsAmount : 0;
                    vatRateTotalizer.discountProductsAmount = value.discountProductsAmount != null ? value.discountProductsAmount : 0;
                    vatRateTotalizer.servicesAmount = value.servicesAmount != null ? value.servicesAmount : 0;
                    vatRateTotalizer.discountServicesAmount = value.discountServicesAmount != null ? value.discountServicesAmount : 0;
                    vatRateTotalizer.differencesAmount = (receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount;
                    vm.pharmaReception.pharmaReceptionInvoice.pharmaReceptionVatLines.push(vatRateTotalizer);

                    vm.totalizer.differencesAmount += (receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount;
                    vm.totalizer.vatTable.push(vatRateTotalizer);
                } else {
                    if (vatRateTotalizer[0].differencesAmount == null) {
                        vatRateTotalizer[0].differencesAmount = (receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount;
                    } else {
                        vatRateTotalizer[0].differencesAmount += (receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount;
                    }

                    vm.totalizer.differencesAmount += (receivedQuantity - invoicedQuantity - bonusQuantity) * unitPriceWithDiscount;
                    vm.totalizer.vatTable.push(vatRateTotalizer[0]);
                }

                var vatRateTotalizer = $filter("filter")(vm.totalizer.vatTable, {vatRate: (value.vatRate != null ? value.vatRate : 0)});
                if (!angular.isDefined(vatRateTotalizer[0])) {
                    vatRateTotalizer[0] = {
                        vatRate: (value.vatRate != null ? value.vatRate : 0),
                        vatCode: "",
                        vatValue: 0,
                        productsAmount: 0,
                        discountProductsAmount: 0,
                        servicesAmount: 0,
                        discountServicesAmount: 0,
                        differencesAmount: 0
                    };
                    vm.totalizer.vatTable.push(vatRateTotalizer[0]);
                }
            });
        }
        vm.updateTotalizers();

        vm.datePopup = {opened: false};
		vm.openDatePopup = function() { vm.datePopup.opened = true; };
        vm.dateOptions = {formatYear: 'yyyy', startingDay: 1, showWeeks: false, maxDate: new Date()};
		$scope.sorterFunc = function(vatRateTotalizer){ return parseInt(vatRateTotalizer.vatRate); };
    }
})();
