(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaSelfTestDetailController', PharmaSelfTestDetailController);

    PharmaSelfTestDetailController.$inject = ['Notification', 'blockUI', '$http', '$translate', '$timeout', '$uibModal', 'PharmaSelfTest'];

    function PharmaSelfTestDetailController(Notification, blockUI, $http, $translate, $timeout, $uibModal, PharmaSelfTest) {
        var vm = this;
        vm.startDate = null;
        vm.endDate = null;

        vm.exportCSV = exportCSV;
        vm.intervalModal = intervalModal;
        vm.itemsList = [];

        vm.loadAll = function () {
            if (vm.startDate == null || vm.endDate == null) {
                vm.intervalModal();
            }
        };
        vm.loadAll();

        function exportCSV() {
            $http.post("api/pharma-self-test/toCsv", vm.itemsList, {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var frmStartDate = moment(vm.startDate).format('YYYYMMDD');
                var frmEndDate = moment(vm.endDate).format('YYYYMMDD');

                var blob = new Blob([response.data], {
                    type: "text/csv"
                });
                saveAs(blob, "ReportAutoTestes-" + frmStartDate + "-" + frmEndDate + ".csv");
                Notification.success({
                    message: "Relatório exportado com sucesso",
                    delay: 5000
                });
            }, function errorCallback() {
                Notification.error({
                    message: "Erro ao exportar relatório",
                    delay: ''
                });
            });
        }

        function intervalModal() {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-self-test/pharma-self-test-dialog.html',
                controller: 'PharmaSelfTestDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    startDate: vm.startDate,
                    endDate: vm.endDate,
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('pharmaSelfTest');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (response) {
                blockUI.start($translate.instant('pharmaPortalApp.pharmaSelfTest.loading'));
                vm.startDate = response.startDate;
                vm.endDate = response.endDate;
                vm.itemsList = PharmaSelfTest.query({startDate: moment(response.startDate).format('YYYYMMDD'), endDate: moment(response.endDate).format('YYYYMMDD')});

                vm.itemsList.$promise.then(function () {
                    if(vm.itemsList && vm.itemsList.length <= 0){
                        Notification.warning({
                            message: "Sem resultados para o intervalo definido",
                            delay: 5000
                        });
                    }
                    $timeout(function () {
                        blockUI.stop();
                    }, 250);
                }, function () {
                    $timeout(function () {
                        blockUI.stop();
                    }, 250);
                });
            }, function () {
                blockUI.stop();
            });
        }
    }
})();
