(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('purchase-order-item', {
			parent : 'entity',
			url : '/purchase-order-item?page&sort',
			data : {
				authorities : [ 'ROLE_USER' ],
				pageTitle : 'pharmaPortalApp.purchaseOrderItem.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/purchase-order-item/purchase-order-items.html',
					controller : 'PurchaseOrderItemController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				}
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort)
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('purchaseOrderItem');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('purchase-order-item-detail', {
			parent : 'entity',
			url : '/purchase-order-item/{id}',
			data : {
				authorities : [ 'ROLE_USER' ],
				pageTitle : 'pharmaPortalApp.purchaseOrderItem.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/purchase-order-item/purchase-order-item-detail.html',
					controller : 'PurchaseOrderItemDetailController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('purchaseOrderItem');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'PurchaseOrderItem', function($stateParams, PurchaseOrderItem) {
					return PurchaseOrderItem.get({
						id : $stateParams.id
					}).$promise;
				} ],
				previousState : [ "$state", function($state) {
					var currentStateData = {
						name : $state.current.name || 'purchase-order-item',
						params : $state.params,
						url : $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				} ]
			}
		});
	}

})();
