(function() {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .controller('PharmaFaultSettingsController', PharmaFaultSettingsController);

    PharmaFaultSettingsController.$inject = ['Notification', '$translate', '$scope', 'entity', 'PharmaFaultSettings'];

    function PharmaFaultSettingsController(Notification, $translate, $scope, entity, PharmaFaultSettings) {
        var vm = this;

        vm.pharmaFaultSettings = entity;
        vm.save = save;

        function save () {
            vm.isSaving = true;
            if (vm.pharmaFaultSettings.id !== null) {
                PharmaFaultSettings.update(vm.pharmaFaultSettings, onSaveSuccess, onSaveError);
            } else {
                PharmaFaultSettings.save(vm.pharmaFaultSettings, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pharmaPortalApp:pharmaFaultSettingsUpdate', result);
            vm.isSaving = false;
            Notification.success({
                message : $translate.instant('pharmaPortalApp.pharmaFaultSettings.updated'),
                delay : 5000
            });
        }

        function onSaveError () {
            vm.isSaving = false;
            Notification.error({
                message : $translate.instant('pharmaPortalApp.pharmaFaultSettings.error'),
                delay : ''
            });
        }
    }
})();
