(function() {
	'use strict';

	angular.module('pharmaPortalApp').factory('Auth', Auth);

	Auth.$inject = ['$rootScope', '$state', '$sessionStorage', '$q', '$translate', 'Principal', 'AuthServerProvider', 'Account', 'LoginService', 'Activate', 'Password', 'PasswordResetInit', 'PasswordResetFinish', '$localStorage', 'PortalPendingShoppingCarts'];

	function Auth ($rootScope, $state, $sessionStorage, $q, $translate, Principal, AuthServerProvider, Account, LoginService, Activate, Password, PasswordResetInit, PasswordResetFinish, $localStorage, PortalPendingShoppingCarts) {
		var service = {
			activateAccount: activateAccount,
			authorize: authorize,
			changePassword: changePassword,
			getPreviousState: getPreviousState,
			login: login,
			logout: logout,
			resetPasswordFinish: resetPasswordFinish,
			resetPasswordInit: resetPasswordInit,
			resetPreviousState: resetPreviousState,
			storePreviousState: storePreviousState,
			updateAccount: updateAccount
		};

		return service;

		function activateAccount (key, callback) {
			var cb = callback || angular.noop;

			return Activate.get(key,
					function (response) {
				return cb(response);
			},
			function (err) {
				return cb(err);
			}.bind(this)).$promise;
		}

		function authorize (force) {
			var authReturn = Principal.identity(force).then(authThen);

			return authReturn;

			function authThen () {
				var isAuthenticated = Principal.isAuthenticated();

				// an authenticated user can't access to login
				if (isAuthenticated && $rootScope.toState.parent === 'account' && $rootScope.toState.name === 'login') {
					$state.go('home');
				}

				// recover and clear previousState after external login redirect
				// (e.g. oauth2)
				if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
					var previousState = getPreviousState();
					resetPreviousState();
					$state.go(previousState.name, previousState.params);
				}

				if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
					if (isAuthenticated) {
						// user is signed in but not authorized for desired state
						$state.go('accessdenied');
					} else {
						// user is not authenticated. stow the state they wanted
						// before you send them to the login service, so you can return
						// them when you're done
						storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

						// now, send them to the signin state so they can log in
						$state.go('accessdenied').then(function() {
							LoginService.open();
						});
					}
				}
			}
		}

		function changePassword (newPassword, callback) {
			var cb = callback || angular.noop;

			return Password.save(newPassword, function () {
				return cb();
			}, function (err) {
				return cb(err);
			}).$promise;
		}

		function login (credentials, callback) {
			var cb = callback || angular.noop;
			var deferred = $q.defer();

			AuthServerProvider.login(credentials)
			.then(loginThen)
			.catch(function (err) {
				this.logout();
				deferred.reject(err);
				return cb(err);
			}.bind(this));

			function loginThen (data) {
				Principal.identity(true).then(function(account) {
					// After the login the language will be changed to
					// the language selected by the user during his registration
					if (account!== null) {
						$translate.use(account.langKey).then(function () {
							$translate.refresh();
						});
					}
					$localStorage.$reset();

					//TODO read sales order and purchases
                    var tmp = PortalPendingShoppingCarts.get(function (response) {
                        for(var i=0 ; i< response.length; i++){
                            switch (response[i].screen){
                                case "PURCHASE":
                                    $localStorage.purchaseOrder = JSON.parse(response[i].cart_info);
                                    break;
                                case "SALE":
                                    $localStorage.saleOrder = JSON.parse(response[i].cart_info);
                                    break;
                                case "CURRENT_COMPANY":
                                    $localStorage.currentCompany = JSON.parse(response[i].cart_info);
                                    break;
                                case "TRADE":
                                    $localStorage.tradeOrder = JSON.parse(response[i].cart_info);
                                    break;
                                case "RETURN":
                                    $localStorage.returnOrder = JSON.parse(response[i].cart_info);
                                    break;
                            }
                        }
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.resolve(data);
                    });


				});
				return cb();
			}

			return deferred.promise;
		}

		function logout () {
            var tmp = [];

            var sales = saveSalesOrder();
            if(sales){
                tmp.push(sales);
            }
            var purchases = savePurchase();
            if(purchases){
                tmp.push(purchases);
            }
            var company = saveCurrentCompany();
            if(company){
                tmp.push(company);
            }
            var trade = saveTradeOrder();
            if(trade){
                tmp.push(trade);
            }
            var returnOrder = saveReturnOrder();
            if(returnOrder){
                tmp.push(returnOrder);
            }

            if(tmp.length > 0){
                PortalPendingShoppingCarts.save(tmp, function (result) {
                    delete $localStorage.saleOrder;
                    delete $localStorage.purchaseOrder;
                    delete $localStorage.currentCompany;
                    delete $localStorage.tradeOrder;
                    delete $localStorage.returnOrder;
                    AuthServerProvider.logout();
                    Principal.authenticate(null);

                }, function(result){
                    AuthServerProvider.logout();
                    Principal.authenticate(null);
                });
            }else{
                AuthServerProvider.logout();
                Principal.authenticate(null);
            }

		}

        function saveSalesOrder() {
            if($localStorage.saleOrder){
                var tmp = {};
                tmp.screen = "SALE";
                tmp.cart_info = JSON.stringify($localStorage.saleOrder);
                return tmp;
            }else{
                return null;
            }
        };

        function savePurchase() {
            if($localStorage.purchaseOrder && $localStorage.purchaseOrder != ""){
                var tmp = {};
                tmp.screen = "PURCHASE";
                tmp.cart_info = JSON.stringify($localStorage.purchaseOrder);
                return tmp;
            }else{
                return null;
            }
        };
        function saveCurrentCompany() {
            if($localStorage.currentCompany && $localStorage.currentCompany != ""){
                var tmp = {};
                tmp.screen = "CURRENT_COMPANY";
                tmp.cart_info = JSON.stringify($localStorage.currentCompany);
                return tmp;
            }else{
                return null;
            }
        };
        function saveTradeOrder() {
            if($localStorage.tradeOrder && $localStorage.tradeOrder != ""){
                var tmp = {};
                tmp.screen = "TRADE";
                tmp.cart_info = JSON.stringify($localStorage.tradeOrder);
                return tmp;
            }else{
                return null;
            }
        };
        function saveReturnOrder() {
            if($localStorage.returnOrder && $localStorage.returnOrder){
                var tmp = {};
                tmp.screen = "RETURN";
                tmp.cart_info = JSON.stringify($localStorage.returnOrder);
                return tmp;
            }else{
                return null;
            }
        };


		function resetPasswordFinish (keyAndPassword, callback) {
			var cb = callback || angular.noop;

			return PasswordResetFinish.save(keyAndPassword, function () {
				return cb();
			}, function (err) {
				return cb(err);
			}).$promise;
		}

		function resetPasswordInit (mail, callback) {
			var cb = callback || angular.noop;

			return PasswordResetInit.save(mail, function() {
				return cb();
			}, function (err) {
				return cb(err);
			}).$promise;
		}

		function updateAccount (account, callback) {
			var cb = callback || angular.noop;

			return Account.save(account,
					function () {
				return cb(account);
			},
			function (err) {
				return cb(err);
			}.bind(this)).$promise;
		}

		function getPreviousState() {
			var previousState = $sessionStorage.previousState;
			return previousState;
		}

		function resetPreviousState() {
			delete $sessionStorage.previousState;
		}

		function storePreviousState(previousStateName, previousStateParams) {
			var previousState = { "name": previousStateName, "params": previousStateParams };
			$sessionStorage.previousState = previousState;
		}
	}
})();
