(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PortalAppDetailController', PortalAppDetailController);

	PortalAppDetailController.$inject = [ '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PortalApp', '$state', 'ModuleSettings' ];

	function PortalAppDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PortalApp, $state, ModuleSettings) {
		var vm = this;

		vm.portalApp = entity;
		vm.previousState = previousState.name;
		vm.byteSize = DataUtils.byteSize;
		vm.openFile = DataUtils.openFile;

		vm.loadAll = loadAll;

		var unsubscribe = $rootScope.$on('pharmaPortalApp:portalAppUpdate', function(event, result) {
			vm.portalApp = result;
		});
		$scope.$on('$destroy', unsubscribe);

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.applicationsModule) {
					$state.go('accessdenied');
				}
			});
		}
		loadAll();
	}
})();
