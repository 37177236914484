(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('home', {
			parent : 'app',
			url : '/',
			data : {
				authorities : [],
                pageTitle: 'home.fl.title'
			},
			views : {
				'navbar@' : {
					templateUrl : 'app/layouts/navbar/navbar.html',
					controller : 'NavbarController',
					controllerAs : 'vm'
				},
				'sidenav@' : {
					templateUrl : 'app/layouts/sidenav/sidenav.html',
					controller : 'SidenavController',
					controllerAs : 'vm'
				},
				'content@' : {
					template : '<div ui-view ng-cloak></div>',
					controller : 'HomeController',
					controllerAs : 'vm'
				},
				'footer@' : {
					templateUrl : 'app/layouts/footer/footer-no-version.html',
					controller : 'FooterController',
					controllerAs : 'vm'
				}
			},
            resolve : {
                mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('login');
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                } ]
            }
		}).state('home.fl', {
			parent : 'home',
			url : '^',
			data : {
				authorities : [],
                pageTitle: 'home.fl.title'
			},
			views : {
				'navbar@' : {
					templateUrl : 'app/layouts/navbar/navbar.html',
					controller : 'NavbarController',
					controllerAs : 'vm'
				},
				'sidenav@' : {
					templateUrl : 'app/layouts/sidenav/sidenav.html',
					controller : 'SidenavController',
					controllerAs : 'vm'
				},
				'content@' : {
					templateUrl : 'app/home/home-fl.html',
					controller : 'HomeFLController',
					controllerAs : 'vm'
				},
				'footer@' : {
					templateUrl : 'app/layouts/footer/footer-no-version.html',
					controller : 'FooterController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('login');
					$translatePartialLoader.addPart('home');
					return $translate.refresh();
				} ]
			}
		}).state('home.mz', {
			parent : 'home',
			url : '^',
			data : {
				authorities : [],
                pageTitle: 'home.mz.title'
			},
			views : {
				'navbar@' : {
					templateUrl : 'app/layouts/navbar/navbar.html',
					controller : 'NavbarController',
					controllerAs : 'vm'
				},
				'sidenav@' : {
					templateUrl : 'app/layouts/sidenav/sidenav.html',
					controller : 'SidenavController',
					controllerAs : 'vm'
				},
				'content@' : {
					templateUrl : 'app/home/home-mz.html',
					controller : 'HomeMZController',
					controllerAs : 'vm'
				},
				'footer@' : {
					templateUrl : 'app/layouts/footer/footer-no-version.html',
					controller : 'FooterController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('login');
					$translatePartialLoader.addPart('home');
					return $translate.refresh();
				} ]
			}
		}).state('home.io', {
			parent : 'home',
			url : '^',
			data : {
				authorities : [],
                pageTitle: 'home.io.title'
			},
			views : {
				'navbar@' : {
					templateUrl : 'app/layouts/navbar-io/navbar-io.html',
					controller : 'NavbarIOController',
					controllerAs : 'vm'
				},
				'sidenav@' : {
					templateUrl : 'app/layouts/sidenav/sidenav.html',
					controller : 'SidenavController',
					controllerAs : 'vm'
				},
				'content@' : {
					templateUrl : 'app/home/home-io.html',
					controller : 'HomeIOController',
					controllerAs : 'vm'
				},
				'footer@' : {
					templateUrl : 'app/layouts/footer/footer-io.html',
					controller : 'FooterController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('login');
					$translatePartialLoader.addPart('home');
					return $translate.refresh();
				} ]
			}
		});
	}
})();
