(function () {
    'use strict';

    angular
        .module('pharmaPortalApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.router',
            'infinite-scroll',
            'angular-loading-bar',
            'ui.select',
            'ngSanitize',
            'ngAnimate',
            'blockUI',
            'ui.tree',
            'ui.toggle',
            'duScroll',
            'ui.carousel',
            'ngMap',
            'floatThead',
            'ngJsTree',
            'angularMoment',
            'ui.calendar',
            'dndLists',
            'ui-notification',
            'highcharts-ng',
            'ngAvatar',
            'moment-picker',
            'ui.bootstrap.contextMenu',
            'ui-select-infinity'
            // jhipster-needle-angularjs-add-module JHipster will add new module here
        ])
        .run(run)
        .config(config);

    run.$inject = ['stateHandler', 'translationHandler', 'uibPaginationConfig', '$templateCache'];
    config.$inject = ['$qProvider', 'uiSelectConfig', 'blockUIConfig', 'NotificationProvider'];

    function run(stateHandler, translationHandler, uibPaginationConfig, $templateCache) {
        stateHandler.initialize();
        translationHandler.initialize();

        uibPaginationConfig.previousText = "‹";
        uibPaginationConfig.nextText = "›";
        uibPaginationConfig.firstText = "«";
        uibPaginationConfig.lastText = "»";

        $templateCache.put("bootstrap/match-multiple.tpl.html", "<span class=\"ui-select-match\"><span ng-repeat=\"$item in $select.selected track by $index\"><span class=\"col-md-12 ui-select-match-item btn btn-info btn-xs\" tabindex=\"-1\" type=\"button\" ng-disabled=\"$select.disabled\" ng-click=\"$selectMultiple.activeMatchIndex = $index;\" ng-class=\"{\'btn-info\':$selectMultiple.activeMatchIndex === $index, \'select-locked\':$select.isLocked(this, $index)}\" ui-select-sort=\"$select.selected\"><span class=\"close ui-select-match-close\" ng-hide=\"$select.disabled\" ng-click=\"$selectMultiple.removeChoice($index)\">&nbsp;&times;</span>&nbsp;<span uis-transclude-append=\"\"></span></span></span></span>");
    }

    function config($qProvider, uiSelectConfig, blockUIConfig, NotificationProvider) {
        $qProvider.errorOnUnhandledRejections(false);

        uiSelectConfig.theme = 'bootstrap';
        uiSelectConfig.closeOnSelect = true;
        uiSelectConfig.spinnerEnabled = true;
        uiSelectConfig.spinnerClass = 'fa fa-refresh ui-select-spin';

        // blockUI
        // Disable automatically blocking of the user interface
        blockUIConfig.autoBlock = false;

        new WOW().init();

        NotificationProvider.setOptions({
            delay: 5000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'top',
            maxCount: 1,
            replaceMessage: true
        });
    }
})();
