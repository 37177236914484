(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingConfirmProductsController', PharmaPickingConfirmProductsController);

    PharmaPickingConfirmProductsController.$inject = [ 'Notification', 'blockUI', '$uibModalInstance', 'confirmProducts', 'missingProducts', 'pharmaPicking', '$scope', '$state'];

	function PharmaPickingConfirmProductsController(Notification, blockUI, $uibModalInstance, confirmProducts, missingProducts, pharmaPicking, $scope, $state) {
		var vm = this;

		vm.clear = clear;

		function clear(flag) {
			$uibModalInstance.dismiss(flag);
            $state.go('pharma-supplier-complaint', {
                 pharmaPicking : vm.pharmaPicking
                //id : vm.pharmaPicking.id
            });
		}

		vm.products = missingProducts;
		vm.confirmProducts = confirmProducts;
		vm.pharmaPicking = pharmaPicking;

        $scope.totalItems = vm.products.length;
        vm.page = 1;
        $scope.itemsPerPage = 7;

        $scope.$watch("vm.page", function () {
            setPagingData(vm.page);
        });

        function setPagingData(page) {
            var pagedData = vm.products.slice(
                (page - 1) * $scope.itemsPerPage,
                page * $scope.itemsPerPage
            );
            $scope.products = pagedData;
        }

	}
})();
