(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('PolicyDefinition', PolicyDefinition);

    PolicyDefinition.$inject = ['$resource'];

    function PolicyDefinition($resource) {
        var resourceUrl = 'api/policy-definitions/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    try {
                        angular.forEach(data, function (meta) {

                        });
                    } catch (err) {
                    }
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    try {
                        angular.forEach(data, function (meta) {

                        });
                    } catch (err) {
                    }
                    return angular.toJson(data);
                }
            },
            'updateAll': {
                method: 'PUT',
                url: "api/policy-definitions-update-bulk",
                transformRequest: function (data) {
                    try {
                        angular.forEach(data, function (meta) {

                        });
                    } catch (err) {
                    }
                    return angular.toJson(data);
                }
            },
            'deleteBulk': {
                method: 'POST',
                url: "api/policy-definitions-delete-bulk",
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
