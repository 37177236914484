(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('UserQuestionController', UserQuestionController);

    UserQuestionController.$inject = ['$uibModalInstance', 'data'];

    function UserQuestionController($uibModalInstance, data) {
        var vm = this;

        vm.clear = clear;
        vm.close = close;
        vm.allSelected = data.allSelected;

        function clear(flag) {
            $uibModalInstance.dismiss(flag);
        }

        function close(flag) {
            $uibModalInstance.close(flag);
        }

    }
})();
