(function () {
    'use strict';
    angular.module('pharmaPortalApp').factory('AdminSettings', AdminSettings);
    AdminSettings.$inject = ['$resource'];

    function AdminSettings($resource) {
        var resourceUrl = "api/admin-settings";

        return $resource(
            resourceUrl,
            {},
            {
                'query': {
                    method: 'GET',
                    isArray: true
                },
                'syncProductsServ': {
                    method: 'GET',
                    isArray: true,
                    url: 'adminSettingsRes/syncProducts'
                },
                'sendNotificationServ': {
                    method: 'GET',
                    isArray: true,
                    url:'adminSettingsRes/sendNotification'
                }
            });
    }
})();
