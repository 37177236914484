(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaTransfReceptionEditController', PharmaTransfReceptionEditController);

	PharmaTransfReceptionEditController.$inject = [ 'Notification', 'blockUI', 'previousState', '$scope', '$state', '$filter', '$http', '$window', '$translate', '$uibModal', 'ParseLinks', 'PharmaTransfReception', 'Upload',
			'entity', 'PortalSettings', 'ModuleSettings' ];

	function PharmaTransfReceptionEditController(Notification, blockUI, previousState, $scope, $state, $filter, $http, $window, $translate, $uibModal, ParseLinks, PharmaTransfReception, Upload, entity, PortalSettings, ModuleSettings) {
		var vm = this;

		vm.portalSettings = PortalSettings.query();
		vm.pharmaTransfReception = entity;
		vm.working = false;
		vm.previousState = previousState.name;

		vm.loadAll = loadAll;
		vm.submitPharmaTransfReception = submitPharmaTransfReception;
		vm.save = save;
		vm.backToReceptions = backToReceptions;
		vm.togglePharmaTransfReceptionLineEditMode = togglePharmaTransfReceptionLineEditMode;
		vm.onBlurFromLot = onBlurFromLot;
		vm.onBlurFromReceivedQuantity = onBlurFromReceivedQuantity;
		vm.getTemplate = getTemplate;
		vm.uploadFile = uploadFile;
		vm.exportToExcel = exportToExcel;
		vm.exportToLogitoolsExcel = exportToLogitoolsExcel;
		vm.getWarehouseFullDescription = getWarehouseFullDescription;
		vm.getTransferTypeFullDescription = getTransferTypeFullDescription;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function (result) {
				vm.moduleSettings = result;

				if (!vm.moduleSettings.receptionsWarehouseTransfModule) {
					$state.go('accessdenied');
				} else {
					vm.manualReceptionLines = $filter('filter')(vm.pharmaTransfReception.pharmaTransfReceptionLines, {erpLine:false});

					$http.get("api/common/_searchQualityStatusesForSelect").then(function(result) {
						vm.qualityStatuses = [ { "code" : '', "description" : '' } ].concat(result.data);
					});

					$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
						vm.warehouses = result.data;
					});

					$http.get("api/common/_getTransferTypeDescription").then(function(result) {
						vm.transferTypes = result.data;
					});
				}
			});
		}
		vm.loadAll();

		function submitPharmaTransfReception() {
			if (isDataValidForSubmission()){
				PharmaTransfReception.save(vm.pharmaTransfReception, onSaveSuccessFromSubmit, onSaveError);
			}
		}

		function isDataValidForSubmission(){
			var errors = [];
			if (angular.isDefined(vm.pharmaTransfReception.pharmaTransfReceptionLines) && vm.pharmaTransfReception.pharmaTransfReceptionLines.length > 0){
				for (var i = 0; i < vm.pharmaTransfReception.pharmaTransfReceptionLines.length; i++) {
					var value = vm.pharmaTransfReception.pharmaTransfReceptionLines[i];
					var receivedQuantity = (value.receivedQuantity != null && angular.isNumber(value.receivedQuantity) && !isNaN(value.receivedQuantity) ? value.receivedQuantity : 0);
					// negative quantities
					if (receivedQuantity < 0){
						errors.push($translate.instant('pharmaPortalApp.pharmaTransfReception.message.error.negativeQuantities', {cnp: value.cnp}));
					}
				}
				if (errors.length > 0){
					var message = '';
					for (var i = 0; i < errors.length; i++){
						if (i > 0){
							message += '<br>';
						}
						message += errors[i];
					}
					Notification.error({message: $translate.instant('pharmaPortalApp.pharmaTransfReception.message.error.submission') + '<br>' + message, delay: ''});
					return false;
				}
				return true;
			} else {
				Notification.error({message: $translate.instant('pharmaPortalApp.pharmaTransfReception.message.error.submission') + $translate.instant('pharmaPortalApp.pharmaTransfReception.message.error.noLines'), delay: ''});
				return false;
			}
		}

		function onSaveSuccessFromSubmit() {
			vm.working = false;
			$uibModal.open({
				templateUrl : 'app/entities/pharma-transf-reception/pharma-transf-reception-submit-dialog.html',
				controller : 'PharmaTransfReceptionSubmitController',
				controllerAs : 'vm',
				size : 'lg modal-exl',
				resolve : {
					entity : [ 'PharmaTransfReception', function(PharmaTransfReception) {
						return PharmaTransfReception.get({
							id : vm.pharmaTransfReception.id
						}).$promise;
					} ]
				}
			}).result.then(function() {
				// success callback implemented on own controller
			}, function() {
				$state.go('pharma-transf-reception-edit', {
					id : vm.pharmaTransfReception.id
				}, {
					reload : false
				});
			});
		}

		function save() {
			vm.working = true;
			PharmaTransfReception.save(vm.pharmaTransfReception, onSaveSuccess, onSaveError);
		}

		function onSaveSuccess(result) {
			vm.working = false;
			$state.go("pharma-transf-reception-edit", { id : result.id });
		}

		function onSaveError(result) {
			vm.working = false;
			if(result.data.success) {
				Notification.success({message: result.data.message, delay: 5000});
			} else {
				Notification.error({message: result.data.message, delay: ''});
			}
		}

		function backToReceptions(){
			// se nenhuma linha recepcionada apaga
			vm.manualReceptionLines = $filter('filter')(vm.pharmaTransfReception.pharmaTransfReceptionLines, {erpLine:false});
			if(vm.manualReceptionLines.length === 0) {
				PharmaTransfReception.delete({id: vm.pharmaTransfReception.id});
			}
			if(vm.previousState = 'pharma-transf-reception-edit') {
				$state.go('pharma-transf-reception');
			}else{
				$state.go(vm.previousState);
			}

		}

		function togglePharmaTransfReceptionLineEditMode(pharmaTransfReceptionLine) {
			pharmaTransfReceptionLine.editMode = !(angular.isDefined(pharmaTransfReceptionLine.editMode) && pharmaTransfReceptionLine.editMode == true);
			save();
		}

		function onBlurFromLot(pharmaTransfReceptionLine) {
			if (angular.isDefined(pharmaTransfReceptionLine.lot) && pharmaTransfReceptionLine.lot != null) {
				pharmaTransfReceptionLine.lot = pharmaTransfReceptionLine.lot.toUpperCase();
			}
			save();
		}

		function onBlurFromReceivedQuantity(pharmaTransfReceptionLine) {
			// check valid quantity
			if (pharmaTransfReceptionLine.receivedQuantity == null) {
				pharmaTransfReceptionLine.receivedQuantity = pharmaTransfReceptionLine.oldReceivedQuantity;
				return;
			}
			if (pharmaTransfReceptionLine.receivedQuantity < 0) {
				pharmaTransfReceptionLine.errorOnReceivedQuantity = true;
			} else {
				pharmaTransfReceptionLine.errorOnReceivedQuantity = false;
			}
			pharmaTransfReceptionLine.oldReceivedQuantity = pharmaTransfReceptionLine.receivedQuantity;
			save();
		}

		function getTemplate() {
			$window.open("api/pharma-transf-reception/template");
		}

		function uploadFile(file) {
			vm.working = true;
			save();
			blockUI.start($translate.instant('pharmaPortalApp.pharmaTransfReception.message.uploadingTemplate'));
			file.upload = Upload.upload({
				url : 'api/pharma-transf-reception/upload',
				fields : {
					pharmaTransfReceptionHeaderId : vm.pharmaTransfReception.id
				},
				file : file
			}).then(function(response) {
				file.result = response.data;
				if(response.data.success) {
					Notification.success({message: response.data.message, delay: 5000});
				} else {
					Notification.error({message: response.data.message, delay: ''});
				}

				$state.go("pharma-transf-reception-edit", { id : vm.pharmaTransfReception.id }, { reload : true });
				vm.working = false;
				blockUI.stop();
			}, function(response) {
				if (response.status == 200) {
					if(response.data.success) {
						Notification.success({message: response.data.message, delay: 5000});
					} else {
						Notification.error({message: response.data.message, delay: ''});
					}
				} else {
					Notification.error({message: response.status + ': ' + response.data, delay: ''});
				}

				$state.go("pharma-transf-reception-edit", { id : vm.pharmaTransfReception.id }, { reload : true });
				vm.working = false;
				blockUI.stop();
			}, function(evt) {
				// Math.min is to fix IE which reports 200% sometimes
				file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
			});
		}

		function exportToExcel() {
			save();
			$http.get("api/pharma-transf-reception/" + vm.pharmaTransfReception.id + "/toExcel", {
				responseType : "arraybuffer"
			}).then(function successCallback(response) {
				var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
				var blob = new Blob([ response.data ], {
					type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				});
                saveAs(blob, "Recepção_" + vm.pharmaTransfReception.id + "_" + dateTime + ".xlsx", true);
				Notification.success({message: "Recepção exportada com sucesso", delay: 5000});
			}, function errorCallback(response) {
				Notification.error({message: "Erro ao exportar recepção", delay: ''});
			});
		}

		function exportToLogitoolsExcel() {
			if (isDataValidForSubmission()) {
				save();
				$http.get("api/pharma-transf-reception/" + vm.pharmaTransfReception.id + "/toLogitoolsExcel", {
					responseType : "arraybuffer"
				}).then(function successCallback(response) {
					var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
					var blob = new Blob([ response.data ], {
						type : "application/vnd.ms-excel"
					});
					saveAs(blob, "Recepção_" + vm.pharmaTransfReception.id + "_" + dateTime + ".xlsx", true);
					Notification.success({message: "Recepção exportada com sucesso", delay: 5000});
				}, function errorCallback(response) {
					Notification.error({message: "Erro ao exportar recepção", delay: ''});
				});
			} else {
				$("html, body").animate({
					scrollTop : 0
				});
			}
		}

		function getWarehouseFullDescription(warehouseCode) {
			if (angular.isDefined(vm.warehouses) && warehouseCode != '') {
				var warehouse = $filter('filter')(vm.warehouses, {
					code : warehouseCode
				})[0];
				return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
			} else {
				return warehouseCode;
			}
		}

		function getTransferTypeFullDescription(transferTypeCode) {
			if (angular.isDefined(vm.transferTypes) && transferTypeCode != '') {
				var transferType = $filter('filter')(vm.transferTypes, {
					code : transferTypeCode
				})[0];
				return transferTypeCode + (transferType != null ? ' - ' + transferType.description : '');
			} else {
				return transferTypeCode;
			}
		}

		$scope.floatTheadOptions = {
			position : 'absolute',
			responsiveContainer : function($table) {
				return $table.closest(".table-responsive");
			}
		};

		vm.datePopup = { opened : false };
		vm.openDatePopup = function() { vm.datePopup.opened = true; };
		vm.dateOptions = { formatYear : 'yyyy', startingDay : 1, showWeeks : false };
	}
})();
