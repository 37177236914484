(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('IntranetSettingsController', IntranetSettingsController);

	IntranetSettingsController.$inject = [ 'Notification', '$http', '$scope', 'PortalSettings', 'PharmaReceptionServiceByVat', 'PharmaReceptionServiceList', 'ModuleSettings' ];

	function IntranetSettingsController(Notification, $http, $scope, PortalSettings, PharmaReceptionServiceByVat, PharmaReceptionServiceList, ModuleSettings) {
		var vm = this;

		vm.pharmaReceptionServiceByVats = PharmaReceptionServiceByVat.query();
		vm.servicesToProvide = PharmaReceptionServiceList.query();

		vm.loadAll = loadAll;
		vm.addNewPharmaReceptionServiceByVat = addNewPharmaReceptionServiceByVat;
		vm.save = save;
		vm.savePharmaReceptionServiceByVats = savePharmaReceptionServiceByVats;
		vm.saveReceptionServiceList = saveReceptionServiceList;

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.settings = PortalSettings.query();

			vm.settings.$promise.then(function(result) {
				vm.settings.balanceCentralWarehouse = { code : vm.settings.balanceCentralWarehouseCode, description : vm.settings.balanceCentralWarehouseDesc };
				vm.settings.balanceServiceType = { code : vm.settings.balanceServiceTypeCode, description : vm.settings.balanceServiceTypeDesc };
				vm.settings.balanceInvoiceType = { code : vm.settings.balanceInvoiceTypeCode, description : vm.settings.balanceInvoiceTypeDesc };
				vm.settings.balanceCreditType = { code : vm.settings.balanceCreditTypeCode, description : vm.settings.balanceCreditTypeDesc };
			});

			$http.get("api/common/_searchInvoiceTypesForSelect").then(function(result) {
				$scope.documentTypes = result.data;
			});

			$http.get("api/common/_getServicesForSelect").then(function(result) {
				$scope.serviceTypes = result.data;
			});

			$http.get("api/common/_searchWarehousesForSelect").then(function(result) {
				$scope.warehouses = result.data;
			});

			vm.pharmaReceptionServiceByVats.$promise.then(function(result) {
				angular.forEach(result, function(value, key) {
					value.serviceVatType = { code : value.code, description : value.description, vatRate : value.vatRate, vatCode : value.vatCode };
				})
			});
		}
		loadAll();

		function addNewPharmaReceptionServiceByVat() {
			if (!angular.isObject(vm.pharmaReceptionServiceByVats)) {
				vm.pharmaReceptionServiceByVats = [];
			}
			vm.pharmaReceptionServiceByVats.push(new PharmaReceptionServiceByVat());
		}

		function save() {
			try {
				if(savePharmaReceptionServiceByVats() && saveReceptionServiceList()) {
					vm.settings.balanceCentralWarehouseCode = vm.settings.balanceCentralWarehouse.code;
					vm.settings.balanceCentralWarehouseDesc = vm.settings.balanceCentralWarehouse.description;

					vm.settings.balanceServiceTypeCode = vm.settings.balanceServiceType.code;
					vm.settings.balanceServiceTypeDesc = vm.settings.balanceServiceType.description;

					vm.settings.balanceInvoiceTypeCode = vm.settings.balanceInvoiceType.code;
					vm.settings.balanceInvoiceTypeDesc = vm.settings.balanceInvoiceType.description;

					vm.settings.balanceCreditTypeCode = vm.settings.balanceCreditType.code;
					vm.settings.balanceCreditTypeDesc = vm.settings.balanceCreditType.description;

					PortalSettings.update(vm.settings, function(httpResponse) {
						Notification.success({message: 'Parametrizações atualizadas com sucesso', delay: 5000});
					});
				}
			} catch (err) {
				Notification.error({message: 'Não foi possível atualizar as parametrizações', delay: ''});
			}
		}

		function savePharmaReceptionServiceByVats() {
			var isValid = true;
			try {
				if(vm.settings.exportPurchaseInvoice) {
					if(vm.pharmaReceptionServiceByVats.length === 0) {
						Notification.error({message: 'Não indicou nenhum serviço para as diferenças de compras', delay: ''});
						isValid = false;
					} else {
						var uniqueVatRates = [];
						angular.forEach(vm.pharmaReceptionServiceByVats, function(value, key) {
							value.code = value.serviceVatType.code;
							value.description = value.serviceVatType.description;
							value.vatRate = value.serviceVatType.vatRate;
							value.vatCode = value.serviceVatType.vatCode;

							if(uniqueVatRates.indexOf(""+value.vatRate) > -1) {
								Notification.error({message: 'Apenas pode indicar um serviço por cada taxa de IVA', delay: ''});
								isValid = false;
							} else {
								uniqueVatRates.push(""+value.vatRate);
							}
						})
					}
				} else {
					vm.pharmaReceptionServiceByVats = [];
				}

				if(isValid) {
					PharmaReceptionServiceByVat.save(vm.pharmaReceptionServiceByVats);
				}

				return isValid;

			} catch (err) {
				Notification.error({message: 'Não foi possível atualizar as parametrizações dos códigos de serviço para as diferenças de compras', delay: ''});
			}
		}

		function saveReceptionServiceList() {
			var isValid = true;
			try {
				if(vm.settings.filterServicesToShow) {
					if(vm.servicesToProvide.length === 0) {
						Notification.error({message: 'A lista de serviços a disponibilizar não pode ser vazia', delay: ''});
						isValid = false;
					}
				} else {
					vm.servicesToProvide = [];
				}

				if(isValid) {
					PharmaReceptionServiceList.save(vm.servicesToProvide);
				}
				return isValid;

			} catch (err) {
			    Notification.error({message : 'Não foi possível atualizar as parametrizações da lista de serviços a disponibilizar', delay : ''});
			}
		}
	}
})();
