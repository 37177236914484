(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('PharmaTrade', PharmaTrade);

	PharmaTrade.$inject = [ '$resource' ];

	function PharmaTrade($resource) {
		var resourceUrl = 'api/pharma-trade/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'save' : {
				method : 'POST',
				transformRequest : function(data) {
					try {
						angular.forEach(data, function(meta) {

						});
					} catch (err) {
					}
					return angular.toJson(data);
				}
			}
		});
	}
})();
