(function() {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .controller('PharmaFaultReportHeaderDeleteController',PharmaFaultReportHeaderDeleteController);

    PharmaFaultReportHeaderDeleteController.$inject = ['$uibModalInstance', 'entity', 'PharmaFaultReportHeader'];

    function PharmaFaultReportHeaderDeleteController($uibModalInstance, entity, PharmaFaultReportHeader) {
        var vm = this;

        vm.pharmaFaultReportHeader = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PharmaFaultReportHeader.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
