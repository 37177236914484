(function() {
	'use strict';

	angular.module('pharmaPortalApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pharma-sale', {
			parent : 'entity',
			url : '/pharma-sale',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ],
				pageTitle : 'pharmaPortalApp.pharmaSale.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/pharma-sale/pharma-sales.html',
					controller : 'PharmaSaleController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('pharmaSale');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			}
		}).state('pharma-sale.alert', {
			parent : 'pharma-sale',
			url : '/alert',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-sale/sale-alert-dialog.html',
					controller : 'SaleAlertController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-sale', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-sale.success', {
			parent : 'pharma-sale',
			url : '/success',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-sale/sale-success-dialog.html',
					controller : 'SaleSuccessController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-sale', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-sale.clearOrder', {
			parent : 'pharma-sale',
			url : '/clear',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-sale/sale-clear-order-dialog.html',
					controller : 'SaleClearOrderController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-sale', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-sale.confirmOrder', {
			parent : 'pharma-sale',
			url : '/confirm',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-sale/sale-confirm-order-dialog.html',
					controller : 'SaleConfirmOrderController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-sale', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-sale.removeProduct', {
			parent : 'pharma-sale',
			url : '/removeProduct/{cnpToDel}',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-sale/sale-remove-product-dialog.html',
					controller : 'SaleRemoveProductController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : function() {
							return {
								cnpToDel : $stateParams.cnpToDel
							};
						}
					}
				}).result.then(function() {
					$state.go('pharma-sale', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-sale.fullSearch', {
			parent : 'pharma-sale',
			url : '/fullSearch',
			data : {
				authorities : [ 'ROLE_ADMIN', 'ROLE_COMERCIAL' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/entities/pharma-sale/sale-confirm.fullsearch-dialog.html',
					controller : 'SaleConfirmFullSearchController',
					controllerAs : 'vm',
					size : 'md'
				}).result.then(function() {
					$state.go('pharma-sale', null, {
						reload : false
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('pharma-sale.inactive', {
            parent : 'pharma-sale',
            onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/pharma-sale/pharma-sale.inactive-dialog.html',
                    controller : 'PharmaSaleInactiveController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                    $state.go('pharma-sale', null, {
                        reload : true
                    });
                }, function() {
                    $state.go('^');
                });
            } ]
        });
	}
})();
