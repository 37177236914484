(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PortalProductCnpDetailController', PortalProductCnpDetailController);

    PortalProductCnpDetailController.$inject = ['$uibModalInstance', '$http', '$state', 'product', 'ModuleSettings', 'Product'];

    function PortalProductCnpDetailController($uibModalInstance, $http, $state, product, ModuleSettings, Product) {
        var vm = this;

        vm.product = product;

        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.drawPlot = drawPlot;
        vm.productInfo = "";

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.productsModule) {
                    $state.go('accessdenied');
                } else {
                    Product.getProductInfo({"cnp": vm.product.cnp}, function (result) {
                        vm.productInfo = result;
                    }, function (error) {
                    });
                    $http.get("api/dbPharma/productPriceHistory/" + vm.product.society + "/" + vm.product.cnp).then(function (result2) {
                        vm.productPriceHistoryDetailsByCnp = result2.data;

                        var pvpArr = [];
                        var pvaArr = [];
                        var pvfArr = [];
                        var dateFromAPI = [];
                        var chartDate = [];
                        var monName = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

                        for (var i = 0; i < vm.productPriceHistoryDetailsByCnp.length; i++) {
                            pvpArr.push(vm.productPriceHistoryDetailsByCnp[i].pvpciva);
                            pvaArr.push(vm.productPriceHistoryDetailsByCnp[i].pva);
                            pvfArr.push(vm.productPriceHistoryDetailsByCnp[i].pvf);
                            dateFromAPI.push(vm.productPriceHistoryDetailsByCnp[i].initdate);
                            var date = new Date(dateFromAPI[i]);
                            chartDate[i] = [date.getDate() + " " + monName [date.getMonth()] + " " + date.getFullYear()];
                        }
                        drawPlot(pvpArr, pvaArr, pvfArr, chartDate);
                    });
                }
            });
        }

        loadAll();

        function drawPlot(pvpArr, pvaArr, pvfArr, chartDate) {
            Highcharts.chart('container', {
                title: {
                    text: ''
                },
                xAxis: {
                    categories: chartDate
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                series: [{
                    name: "PVP",
                    data: pvpArr
                }, {
                    name: 'PVA',
                    data: pvaArr
                }, {
                    name: 'PVF',
                    data: pvfArr
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                },
                credits: {
                    enabled: false
                }
            });
        }
    }
})();
