(function() {
	'use strict';

	angular
	.module('pharmaPortalApp')
	.filter('comma2decimal', comma2decimal);

	function comma2decimal() {
		return comma2decimalFilter;

		function comma2decimalFilter(input) {
			var ret = (input) ? input.toString().trim().replace(",", ".") : null;
			if(isNaN(ret)) {
			    return input;
            }
            return parseFloat(ret);
		}
	}
})();
