(function() {
    'use strict';
    angular
        .module('pharmaPortalApp')
        .factory('PharmaFaultReportHeader', PharmaFaultReportHeader);

    PharmaFaultReportHeader.$inject = ['$resource', 'DateUtils'];

    function PharmaFaultReportHeader ($resource, DateUtils) {
        var resourceUrl =  'api/pharma-fault-report-headers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.reportDate = DateUtils.convertLocalDateFromServer(data.reportDate);
                        data.orderDate = DateUtils.convertLocalDateFromServer(data.orderDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.reportDate = DateUtils.convertLocalDateToServer(copy.reportDate);
                    copy.orderDate = DateUtils.convertLocalDateToServer(copy.orderDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.reportDate = DateUtils.convertLocalDateToServer(copy.reportDate);
                    copy.orderDate = DateUtils.convertLocalDateToServer(copy.orderDate);
                    return angular.toJson(copy);
                }
            },
            'resync': {
                method:'GET',
                url:  'api/pharma-fault-report-headers/resync'
            },
            'resend': {
                method:'GET',
                url:  'api/pharma-fault-report-headers/resend'
            }
        });
    }
})();
