(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingController', PharmaPickingController);

    PharmaPickingController.$inject = ['$translate', 'blockUI', 'Notification', '$scope', '$state', '$http', '$filter', 'PharmaPicking', 'ParseLinks', 'paginationConstants', 'pagingParams',
        'ModuleSettings', 'Principal', '$uibModal', '$localStorage'];

    function PharmaPickingController($translate, blockUI, Notification, $scope, $state, $http, $filter, PharmaPicking, ParseLinks, paginationConstants, pagingParams, ModuleSettings, Principal, $uibModal, $localStorage) {
        var vm = this;
        vm.tableFilters = {
            supplier: {label: '&nbsp;', value: ''},
            initDate: null,
            endDate: null,
            receipt: '',
            pharmacy: {label: '&nbsp;', value: ''},
            user: {label: '&nbsp;', value: ''},
            status: {label: 'EXECUTING', value: 'EXECUTING'}
        };

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.haveItemsToTransport = 0;

        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.getWarehouseFullDescription = getWarehouseFullDescription;
        vm.getSupplierFullDescription = getSupplierFullDescription;

        vm.clearFilter = clearFilter;

        vm.generatePdf = generatePdf;
        vm.transportAction = transportAction;

        function transportAction(id) {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-picking/pharma-picking-transport-dialog.html',
                controller: 'PharmaPickingTransportController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false
            }).result.then(function () {
            }, function (flag) {
                if (flag != null && flag != "cancel") {
                    checkIfUserHasCompany(flag, id);
                }
            });
        }

        function checkIfUserHasCompany(name, id) {
            if (vm.userData.company && vm.userData.company.pharmaWarehouse != null) {
                sendToERP(name, id)
            } else {
                $uibModal.open({
                    templateUrl: 'app/entities/pharma-picking/pharma-picking-define-pharmacy.html',
                    controller: 'PharmaPickingDefinePharmacyController',
                    controllerAs: 'vm',
                    size: 'md',
                    backdrop: 'static',
                    keyboard: false
                }).result.then(function () {
                }, function (flag) {
                    if (flag != 'cancel') {
                        sendToERP(name, id, flag);
                    }
                });
            }
        }

        function sendToERP(name, id, flag) {
            blockUI.start($translate.instant('pharmaPortalApp.pharmaPicking.message.submitToErp'));
            var company;
            if (flag == null) {
                company = vm.userData.company;

            } else {
                company = flag;
            }

            vm.bodyRequest = {
                "user": name,
                "pharmaWarehouse": company.pharmaWarehouse
            };

            $http.post("api/pharma-picking/submitTransport", vm.bodyRequest).then(function successCallback(result) {
                blockUI.stop();
                if (result.data.success){
                    Notification.success({message: result.data.message, delay: 5000});
                } else {
                    Notification.error({message: result.data.message, delay: ''});
                }
                $state.go("pharma-picking", {}, {
                    reload : true
                });
            }, function errorCallback(result) {
                blockUI.stop();
                Notification.error({
                    message: result.data.message,
                    delay: ''
                });
            });
        }

        loadAll();

        function loadAll() {
            vm.tableFilters.status.value = $localStorage.statusValue ? $localStorage.statusValue : null;
            vm.tableFilters.status.label = $localStorage.statusLabel ? $localStorage.statusLabel : null;

            vm.moduleSettings = ModuleSettings.query();
            Principal.identity().then(function (account) {
                if (account) {
                    vm.user = account.login;
                    vm.userData = account;
                    vm.noPharmacy = account.company == null;
                }
            });
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                } else {
                    PharmaPicking.query({
                        tableFilters: prepareFilters(),
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);

                    $http.get("api/_searchPickingStatusForSelect").then(function (result) {
                        vm.pickingStatus = [{label: '&nbsp;', value: ''}];
                        vm.pickingStatusAux = [];
                        for (var i = 0; i < result.data.length; i++) {
                            var statusStrAux = result.data[i];
                            if (!vm.pickingStatusAux.includes(statusStrAux)) {
                                vm.pickingStatus.push({label: statusStrAux, value: statusStrAux});
                                vm.pickingStatusAux.push(statusStrAux);
                            }
                        }
                    });
                }
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.pharmaPickingHeaders = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            Notification.error({
                message: error.data.message,
                delay: ''
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        vm.datePopup1 = {
            opened: false
        };

        vm.datePopup2 = {
            opened: false
        };

        vm.openDatePopup = function (i) {
            if (i == 1)
                vm.datePopup1.opened = true;
            else
                vm.datePopup2.opened = true;
        };

        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        function getSuppliersFilter() {
            vm.supplierFilterPicklist = [{label: '&nbsp;', value: ''}];
            PharmaPicking.suppliersFilter({}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    vm.supplierFilterPicklist.push({
                        label: data[i].supplierCode + ' - ' + data[i].supplierName,
                        value: data[i].supplierCode
                    });
                }
            }, function (err) {
            });
        }
        getSuppliersFilter();

        function getPharmaciesFilter() {
            vm.pharmaciesFilterPicklist = [{label: '&nbsp;', value: ''}];
            PharmaPicking.pharmaciesFilter({}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    vm.pharmaciesFilterPicklist.push({
                        label: data[i].pharmacyWarehouse + ' - ' + data[i].pharmacyName,
                        value: data[i].pharmacyWarehouse
                    });
                }
            }, function (err) {
            });
        }
        getPharmaciesFilter();

        function getUserFilter() {
            vm.userFilterPicklist = [{label: '&nbsp;', value: ''}];
            PharmaPicking.usersFilter({}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    vm.userFilterPicklist.push({
                        label: data[i],
                        value: data[i]
                    });
                }
            }, function (err) {
            });
        }
        getUserFilter();

        function haveItemsToTransport() {
            PharmaPicking.haveItemsToTransport({}, function (data) {
                vm.haveItemsToTransport = data.body;
            }, function (err) {
            });
        }

        haveItemsToTransport();

        function filter() {
            PharmaPicking.query({
                tableFilters: vm.tableFilters,
                page: pagingParams.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pharmaPickingHeaders = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                Notification.error({
                    message: error.data.message,
                    delay: ''
                });
            }
        }

        function clearFilter() {
            vm.tableFilters = {
                supplier: {label: '&nbsp;', value: ''},
                initDate: null,
                endDate: null,
                receipt: '',
                pharmacy: {label: '&nbsp;', value: ''},
                user: {label: '&nbsp;', value: ''},
                status: {label: 'EXECUTING', value: 'EXECUTING'}
            };

            transition();
        }

        vm.sort = function(){
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function transition() {
            /*$state.transitionTo($state.$current, {
                page : vm.page,
                sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                tableFilters: vm.tableFilters
            });*/
            $localStorage.statusValue = vm.tableFilters.status.value;
            $localStorage.statusLabel = vm.tableFilters.status.label;

            PharmaPicking.query({
                tableFilters: prepareFilters(),
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pharmaPickingHeaders = data;
            }

            function onError(error) {
                Notification.error({
                    message: error.data.message,
                    delay: ''
                });
            }
        }

        function getWarehouseFullDescription(warehouseCode) {
            if (angular.isDefined(vm.warehouses)) {
                var warehouse = $filter('filter')(vm.warehouses, {
                    code: warehouseCode
                })[0];
                return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
            } else {
                return warehouseCode;
            }
        }

        function getSupplierFullDescription(supplierCode) {
            if (angular.isDefined(vm.suppliers)) {
                var supplier = $filter('filter')(vm.suppliers, {
                    code: supplierCode
                })[0];
                return supplierCode + (supplier != null ? ' - ' + supplier.description : '');
            } else {
                return supplierCode;
            }
        }

        function generatePdf(lineID) {
            $http.get("api/pharma-picking/" + lineID + "/print", {
                responseType: "arraybuffer"
            }).then(function successCallback(response) {
                var dateTime = $filter("date")(new Date(), "ddMMyyyy_HHmm");
                var blob = new Blob([response.data], {
                    type: "application/pdf"
                });
                saveAs(blob, "Picking_" + lineID + "_" + dateTime + ".pdf", true);
                Notification.success({message: "Report gerado com sucesso", delay: 5000});
            }, function errorCallback(response) {
                Notification.error({message: "Erro ao gerar report", delay: ''});
            });
        }

        //Filters...
        function prepareFilters() {
            var filters = {};
            filters.supplier = textOrNull(vm.tableFilters.supplier.value);
            filters.initDate = textOrNull(vm.tableFilters.initDate);
            filters.endDate = textOrNull(vm.tableFilters.endDate);
            filters.receipt = textOrNull(vm.tableFilters.receipt);
            filters.pharmacy = textOrNull(vm.tableFilters.pharmacy.value);
            filters.user = textOrNull(vm.tableFilters.user.value);
            filters.status = textOrNull(vm.tableFilters.status.value);

            return filters;
        }

        function textOrNull(field) {
            return field != '' ? field : null;
        }


        $scope.floatTheadOptions = {
            position: 'absolute',
            responsiveContainer: function ($table) {
                return $table.closest(".table-responsive");
            }
        };
        $scope.showSearch = true;
    }
})();
