(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('PharmaPickingDeleteController', PharmaPickingDeleteController);

    PharmaPickingDeleteController.$inject = ['Notification', '$uibModalInstance', '$translate', '$state', '$http', '$filter', 'pharmaPicking', 'PharmaPicking', 'ModuleSettings', 'Principal'];

    function PharmaPickingDeleteController(Notification, $uibModalInstance, $translate, $state, $http, $filter, pharmaPicking, PharmaPicking, ModuleSettings, Principal) {
        var vm = this;

        vm.clear = clear;
        vm.confirmPharmaPickingDelete = confirmPharmaPickingDelete;
        vm.pharmaPicking = pharmaPicking;

        vm.loadAll = loadAll;
        vm.getWarehouseFullDescription = getWarehouseFullDescription;
        vm.getSupplierFullDescription = getSupplierFullDescription;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function onDeleteSuccess(result) {
            Notification.success({message: $translate.instant('pharmaPortalApp.pharmaPicking.message.pharmaPickingDeleted'), delay: 5000});
            $uibModalInstance.close(true);
        }

        function onDeleteError(result) {
            $uibModalInstance.close(true);
            Notification.error({message: result.data.message, delay: ''});
        }

        function confirmPharmaPickingDelete() {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.user = account.login;
                    vm.role = $filter('filter')(account.authorities, "ROLE_ADMIN");
                    vm.status = vm.pharmaPicking.status;
                    PharmaPicking.delete({id: pharmaPicking.id}, onDeleteSuccess, onDeleteError);
                    // if (vm.status == "CLOSED" && (vm.role.length) > 0) {
                    //     PharmaPicking.delete({id: pharmaPicking.id}, onDeleteSuccess, onDeleteError);
                    // } else {
                    //     if (account.company == vm.pharmaPicking.lastModifyingUser || (vm.role.length) > 0) {
                    //         PharmaPicking.delete({id: pharmaPicking.id}, onDeleteSuccess, onDeleteError);
                    //     } else {
                    //         Notification.error({message: $translate.instant('pharmaPortalApp.pharmaPicking.detail.message.wrongUser', {description: pharmaPicking.lastModifyingUser}), delay: 5000});
                    //         vm.hide = true;
                    //     }
                    // }
                }
            });

        }

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;

                if (!vm.moduleSettings.pickingModule) {
                    $state.go('accessdenied');
                } else {
                    $http.get("api/common/_searchWarehousesForSelect").then(function (result) {
                        vm.warehouses = result.data;
                    });
                    $http.get("api/common/_searchSuppliersForSelect").then(function (result) {
                        vm.suppliers = result.data;
                    });
                }
            });
        }

        vm.loadAll();

        function getWarehouseFullDescription(warehouseCode) {
            if (angular.isDefined(vm.warehouses)) {
                var warehouse = $filter('filter')(vm.warehouses, {
                    code: warehouseCode
                })[0];
                return warehouseCode + (warehouse != null ? ' - ' + warehouse.description : '');
            } else {
                return warehouseCode;
            }
        }

        function getSupplierFullDescription(supplierCode) {
            if (angular.isDefined(vm.suppliers)) {
                var supplier = $filter('filter')(vm.suppliers, {
                    code: supplierCode
                })[0];
                return supplierCode + (supplier != null ? ' - ' + supplier.description : '');
            } else {
                return supplierCode;
            }
        }

        function checkUser() {}
    }
})();
