(function() {
	'use strict';

	angular.module('pharmaPortalApp').controller('PharmaPickingDetailController', PharmaPickingDetailController);

	PharmaPickingDetailController.$inject = [ 'Notification', 'blockUI', '$scope', '$state', '$http', '$window', '$translate', '$uibModal', '$timeout', 'PharmaPicking',
			'entity', 'PortalSettings', 'ModuleSettings', 'Principal'];

	function PharmaPickingDetailController(Notification, blockUI, $scope, $state, $http, $window, $translate, $uibModal, $timeout, PharmaPicking, entity, PortalSettings, ModuleSettings, Principal) {
		var vm = this;
		vm.portalSettings = PortalSettings.query();

		vm.pharmaPicking = entity;
		vm.pharmaPicking.documentDate = new Date(vm.pharmaPicking.documentDate);
		vm.pharmaPicking.dateCreated = new Date(vm.pharmaPicking.dateCreated);
		vm.pharmaPicking.lastModifyingDate = new Date(vm.pharmaPicking.lastModifyingDate);

		vm.header = {};
		angular.copy(vm.pharmaPicking, vm.header);
		vm.header.supplier = vm.pharmaPicking.supplierCode + " - " + vm.pharmaPicking.supplierName;
		vm.header.pharmacy = vm.pharmaPicking.pharmacyWarehouse + " - " + vm.pharmaPicking.pharmacyName;

		vm.locked = true;
		vm.working = false;

		vm.loadAll = loadAll;
		vm.save = dummyFunction;

		vm.deleteRightTableLines = dummyFunction;
		vm.deletePharmaPickingLine = dummyFunction;
		vm.leftTableFilter = leftTableFilter;
		vm.rightTableFilter = rightTableFilter;
		vm.submitPharmaProduct = dummyFunction;
		vm.submitPharmaPicking = dummyFunction;

        vm.showInfo = showInfo;

		vm.products = [];

		function loadAll() {
			vm.moduleSettings = ModuleSettings.query();
			vm.moduleSettings.$promise.then(function(result) {
				vm.moduleSettings = result;
				if (!vm.moduleSettings.pickingModule) {
					$state.go('accessdenied');
				}
				else {
					Principal.identity().then(function(account) {
						if (!account) {
							$state.go('accessdenied');
						}
					});

				}
			});
		}
		vm.loadAll();

        vm.back = function(){
            window.history.back();
        }

		//shared view - dummy function not to break everything
		function dummyFunction(){
			vm.working = true;
		}

		//Filters...
		function leftTableFilter(line){
			if(line.invoicedQuantity-line.receivedQuantity > 0){
				return true;
			}
			return false;
		}

		function rightTableFilter(line){
			if(line.receivedQuantity > 0){
				return true;
			}
			return false;
		}


		$scope.floatTheadOptions = {
			position : 'absolute',
			responsiveContainer : function($table) {
				return $table.closest(".table-responsive");
			}
		};

        function showInfo() {
            $uibModal.open({
                templateUrl: 'app/entities/pharma-picking/invoiced-and-claimed-dialog.html',
                controller: 'InvoicedAndClaimedDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    pharmaPicking: vm.pharmaPicking
                }
            }).result.then(function () {
            }, function (flag) {
            });
        }

	}
})();
