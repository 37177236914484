(function() {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .controller('PharmaFaultReportHeaderDetailController', PharmaFaultReportHeaderDetailController);

    PharmaFaultReportHeaderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PharmaFaultReportHeader', 'PharmaFaultReportLine', 'PharmaRepHeaderError'];

    function PharmaFaultReportHeaderDetailController($scope, $rootScope, $stateParams, previousState, entity, PharmaFaultReportHeader, PharmaFaultReportLine, PharmaRepHeaderError) {
        var vm = this;

        vm.pharmaFaultReportHeader = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pharmaPortalApp:pharmaFaultReportHeaderUpdate', function(event, result) {
            vm.pharmaFaultReportHeader = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
