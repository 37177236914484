(function() {
	'use strict';

	angular.module('pharmaPortalApp').filter('parseDate', parseDate);

	parseDate.$inject = [ '$filter' ];

	function parseDate($filter) {
		return parseDateFilter;

		function parseDateFilter(input, param, prefix) {
			if (!param || param.endsWith("yyyy")) {
				var pattern = /(\d{2})(\d{2})(\d{4})/;
				var dt = new Date(input.replace(pattern, '$3-$2-$1'));
				return dt;
			} else {
				var pattern = /(\d{4})(\d{2})(\d{2})/;
				var dt = new Date(input.replace(pattern, '$1-$2-$3'));
				return dt;
			}
		}
	}
})();