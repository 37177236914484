(function() {
	'use strict';
	angular.module('pharmaPortalApp').factory('Company', Company);

	Company.$inject = [ '$resource' ];

	function Company($resource) {
		var resourceUrl = 'api/companies/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
            },
            'getAllNotEmpty':{
                method:'GET',
                isArray : true,
                url:"api/companies/findAllNotEmpty"
            },
			'findByClient' : {
				method : 'GET',
				url : "api/companies/findByClient/:client"
			},
            'findByPharmacyClient': {
                method: 'GET',
                isArray:true,
                url:"api/companies/typePharmacy"
            },
            'findAllWithPharmacyWarehouse': {
                method: 'GET',
                isArray:true,
                url:"api/companies/withPharmacyWarehouse"
            },
            'findByPharmacyClientAndSelloutNickname': {
                method: 'GET',
                isArray:true,
                url:"api/companies/findAllNotEmptyTypePharmacy"
            },
            'getClientGroups' : {
			    method: 'GET',
                isArray: true,
                url:"api/companies/clientGroups"
            },
            'getCompanySchedule': {
                method: 'GET',
                url:"api/pharmaSchedule/getByCompany"
            },
            'saveCompanySchedule': {
			    method:'PUT',
                url:"api/pharmaSchedule/update"
            },
            'isInactive': {
			    method: 'GET',
                url: "api/companies/isInactive"
            }
		});
	}
})();
