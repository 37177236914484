(function () {
    'use strict';

    angular.module('pharmaPortalApp').controller('SelloutIntegrationStatusDialogController', SelloutIntegrationStatusDialogController);

    SelloutIntegrationStatusDialogController.$inject = ['Notification', '$uibModalInstance', 'entity', 'SelloutIntegrationHistory', 'ModuleSettings'];

    function SelloutIntegrationStatusDialogController(Notification, $uibModalInstance, entity, SelloutIntegrationHistory, ModuleSettings) {
        var vm = this;

        if (entity != null) {
            vm.integrationHistory = SelloutIntegrationHistory.get({id: entity});
        }

        vm.loadAll = loadAll;
        vm.clear = clear;
        vm.removeIntegration = removeIntegration;

        function loadAll() {
            vm.moduleSettings = ModuleSettings.query();
            vm.moduleSettings.$promise.then(function (result) {
                vm.moduleSettings = result;
                if (!vm.moduleSettings.selloutsModule) {
                    $state.go('accessdenied');
                }
            });
        }

        loadAll();

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function removeIntegration() {
            SelloutIntegrationHistory.removeIntegration({id: entity}).$promise.then(function (result) {
                Notification.success({message: "Integração eliminada com sucesso", delay: '5000'});
                $uibModalInstance.dismiss("cancel");
                $('#selloutIntegrationHistoryCalendar').fullCalendar('refetchEvents');
            });
        }
    }
})();
