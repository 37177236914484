(function () {
    'use strict';

    angular
        .module('pharmaPortalApp')
        .controller('PharmaFaultReportHeaderHistoryController', PharmaFaultReportHeaderHistoryController);

    PharmaFaultReportHeaderHistoryController.$inject = ['$uibModalInstance', 'entity'];

    function PharmaFaultReportHeaderHistoryController($uibModalInstance, entity) {
        var vm = this;

        vm.headerErrors = entity.pharmaRepHeaderErrors;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
